import { useEffect, useRef, useState } from "react";
import Slider2 from "@mui/material/Slider";
export const Carousel2 = ({ scroll }: any) => {
  const [isDrag, setIsDrag] = useState(false);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [carouselValue, setCarouselValue] = useState(0);
  const ofScrollRef = useRef<HTMLDivElement>(null); // "of-scroll" 클래스가 적용된 요소에 대한 ref
  const imageRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  const [scrollXPosition, setScrollXPosition] = useState(0);
  const [imgIdx, setImgIdx] = useState(0);
  const imageList2 = [
    "/asset/images/profile/leader1.svg",
    "/asset/images/profile/leader2.svg",
    "/asset/images/profile/leader3.svg",
    "/asset/images/profile/leader4.svg",
    "/asset/images/profile/leader5.svg",
    "/asset/images/profile/leader6.svg",
    "/asset/images/profile/leader7.svg",
    "/asset/images/profile/leader8.svg",
  ];

  const [scrollableDistance, setScrollableDistance] = useState(0);

  useEffect(() => {
    const ofScrollContainer = ofScrollRef.current;

    if (!ofScrollContainer) {
      return;
    }

    const handleScroll = () => {
      const distance =
        ofScrollContainer.scrollWidth - ofScrollContainer.clientWidth;
      setScrollableDistance(distance);
      console.log("distance", distance);
      const currentScrollX = ofScrollContainer.scrollLeft || 0;
      setScrollXPosition(currentScrollX);
      console.log("scroll", currentScrollX);
      updateImgIdx(currentScrollX, distance);
    };

    const updateImgIdx = (currentScrollX: any, distance: any) => {
      let imgWidth = 296;
      let gap = 16;

      if (distance) {
        if (currentScrollX / distance <= 1 / 8) {
          setImgIdx(0);
        } else if (currentScrollX / distance <= 2 / 8) {
          setImgIdx(1);
        } else if (currentScrollX / distance <= 3 / 8) {
          setImgIdx(2);
        } else if (currentScrollX / distance <= 4 / 8) {
          setImgIdx(3);
        } else if (currentScrollX / distance <= 5 / 8) {
          setImgIdx(4);
        } else if (currentScrollX / distance <= 6 / 8) {
          setImgIdx(5);
        } else if (currentScrollX / distance <= 7 / 8) {
          setImgIdx(6);
        } else if (currentScrollX / distance <= 1) {
          setImgIdx(7);
        }
      }
    };

    handleScroll();

    ofScrollContainer.addEventListener("scroll", handleScroll);

    // 컴포넌트가 언마운트될 때 이벤트 핸들러 제거
    return () => {
      ofScrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [ofScrollRef]);
  useEffect(() => {
    console.log("imgidx", imgIdx);
  }, [imgIdx]);
  useEffect(() => {
    console.log("slidervalue", scrollXPosition / scrollableDistance);
  }, [scrollXPosition]);
  return (
    <div
      className={`flex-column browser-max-w-1200 ${
        scroll > 3098 && `fade-in-up`
      }`}
    >
      <div className="flex-row gap-40">
        <div
          className="flex-row  of-scroll gap-16 padding-x-40"
          ref={ofScrollRef}
          style={{ overflowX: "scroll", width: `${window.innerWidth}px` }}
        >
          {imageList2.map((image, index) => (
            <div
              key={index}
              ref={imageRefs[index]}
              draggable={true}
              onTouchStart={(e) => {
                setIsDrag(true);
              }}
              onTouchEnd={() => {
                setIsDrag(false);
              }}
            >
              <img className="w-296 h-364" src={image} />
            </div>
          ))}
        </div>
      </div>
      {/* window.innerWidth > 498 && scrollXPosition > 350 */}
      <div className="flex center-x padding-x-40">
        <Slider2
          className="w-100per"
          value={(scrollXPosition / scrollableDistance) * 100}
          onChange={(e: any) => {
            ofScrollRef.current?.scrollTo({
              left: (e.target.value / 100) * scrollableDistance,
              behavior: "smooth",
            });
          }}
          size="small"
          aria-label="Small"
          valueLabelDisplay="auto"
          sx={{
            marginTop: "30px",
            height: "3px",

            "& .MuiSlider-thumb": {
              display: "none", // Hide the Thumb
            },
            "& .MuiSlider-track": { color: "black" },
            "& .MuiSlider-rail": { color: "#F0F0F0" },

            "& .Mui-active": {
              boxShadow: "#1E9B6B30 0px 0px 0px 12px",
            },
            "& .MuiSlider-valueLabel": { background: "none" },
            "& .MuiSlider-mark": {
              backgroundColor: "#FFFFFF",
              height: 8,
            },
          }}
        />
      </div>
      <div className="end-x flex w-100per mt-10">
        <div className="flex mt-16 gap-10">
          <img
            className="pointer"
            src="/asset/images/arrow/ArrowWithCircleLeft.svg"
            onClick={() => {
              if (imgIdx <= 1) {
                setImgIdx(0);
                ofScrollRef.current?.scrollTo({
                  left: 0,
                  behavior: "smooth",
                });
              } else {
                setImgIdx(imgIdx - 1);
                ofScrollRef.current?.scrollTo({
                  left: (imgIdx / 8) * scrollableDistance - 40 * (8 - imgIdx),
                  behavior: "smooth",
                });
              }
            }}
          ></img>
          <img
            className="pointer"
            src="/asset/images/arrow/ArrowWithCircleRight.svg"
            onClick={() => {
              if (imgIdx >= 6) {
                setImgIdx(7);
                ofScrollRef.current?.scrollTo({
                  left: scrollableDistance,
                  behavior: "smooth",
                });
              } else {
                setImgIdx(imgIdx + 1);
                ofScrollRef.current?.scrollTo({
                  left:
                    ((imgIdx + 1) / 8) * scrollableDistance + 40 * (imgIdx + 1),
                  behavior: "smooth",
                });
              }
            }}
          ></img>
        </div>
      </div>
    </div>
  );
};
