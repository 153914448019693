import { useState } from "react";
import {
  RowSectionTitle,
  RowTableValue2,
  RowTableGreenBold,
  RowTableValueBold,
} from "../../../../component/report/Row";
import { TextSectionTitle } from "../../../../component/report/Text";
import { transNum } from "../../../../component/function/function";
import { BoxGreen1 } from "../../../../component/report/Box";
interface data {
  avgInvestRate: number;
  facilityCost: number;
  farmMachineryCost: number;
  infracost: number;
  investRate: number;
  landPurchaseCost: number;
  realIncome: number;
}

const ReportDetail8 = ({ data }: { data: data }) => {
  const [onToolTip, setOnToolTip] = useState(false);

  return (
    <div className='padding-b-24 relative'>
      <RowSectionTitle>
        <TextSectionTitle>8. 예상 투자수익</TextSectionTitle>
      </RowSectionTitle>
      <div className='padding-16-16-10 relative'>
        <RowTableGreenBold
          title='예상 투자수익률'
          value={`${data.investRate}%`}
          warn
          onClick={() => setOnToolTip(true)}
        />
        <div className='absolute center-x'>
          {onToolTip && (
            <div
              className='flex-row bg-blue-gray-700 border-radius-8 padding-12-16 tool-tip start-y center-x w-218 gap-10 z-10 absolute'
              style={{
                top: `${-60}px`,
                left: "0px",
              }}
            >
              <div className='flex-column gap-16'>
                <div className='flex-row center-y gap-3'>
                  <div className='font-medium f-size-11 line-h-12 color-EFF0F6 no-wrap'>
                    투자 수익률(ROI) =
                  </div>
                  <div className='flex-column gap-10'>
                    <div className='font-medium f-size-11 line-h-12 color-EFF0F6 no-wrap center-x'>
                      순이익
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "2px",
                        background: "#eff0f6",
                      }}
                    />
                    <div className='font-medium f-size-11 line-h-12 color-EFF0F6 no-wrap center-x'>
                      초기 투자비
                    </div>
                  </div>
                  <div className='font-medium f-size-11 line-h-12 color-EFF0F6 no-wrap'>
                    × 100
                  </div>
                </div>
                <div className='font-medium f-size-11 line-h-12 color-EFF0F6'>
                  투자수익률은 내 투자금에 대한 순이익 비율을 뜻함
                  <br />
                  <br />
                  예시) 3% 투자 수익률 = 100만원 투자 시 3만원의 순이익 창출
                </div>
              </div>
              <img
                alt=''
                className='hover'
                src='/asset/images/close/CloseWhite12.svg'
                onClick={() => setOnToolTip(false)}
              />
            </div>
          )}
        </div>
      </div>
      <div className='padding-8-16-0 flex-column gap-8'>
        <RowTableValueBold
          title='초기 투자 비용'
          value={`${transNum(data.infracost)}원`}
        />
      </div>
      <div className='padding-8-16 flex-column gap-8'>
        <RowTableValue2
          title='토지 매입비'
          value={`${transNum(data.landPurchaseCost)}원`}
        />
        <RowTableValue2
          title='시설 구축비'
          value={`${transNum(data.facilityCost)}원`}
        />
        <RowTableValue2
          title='농기계 구입비'
          value={`${transNum(data.farmMachineryCost)}원`}
        />
      </div>
      <div className='padding-8-16 flex-column gap-8'>
        <RowTableValueBold
          title='순이익'
          value={`${transNum(data.realIncome)}원`}
        />
      </div>
      <div className='padding-8-16 gap-10'>
        <BoxGreen1>
          <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
            AI 예상 투자수익률은 {data.investRate}%로
          </div>
          <div
            className='font-bold f-size-16 line-h-24 '
            style={{
              color:
                data.avgInvestRate <= data.investRate ? "#1E9B6B" : "#d62554",
            }}
          >
            전국 평균 {data.avgInvestRate}%
            {data.investRate > data.avgInvestRate
              ? `보다 ${
                  Math.round((data.investRate - data.avgInvestRate) * 10) / 10
                }% 높습니다.`
              : data.investRate < data.avgInvestRate
              ? `보다 ${
                  Math.round((data.avgInvestRate - data.investRate) * 10) / 10
                }% 낮습니다.`
              : "와 같습니다."}
          </div>
        </BoxGreen1>
      </div>
    </div>
  );
};

export default ReportDetail8;
