import { set } from 'lodash';
import PayService from '../../service/pay/Pay';

export const PointCancelPopup = ({
  setOnPopUp,
  setOnPopUp2,
  paymentIdx,
  getPointHistoryApi,
  setRefundCheck,
}: any) => {
  const Refund = async () => {
    try {
      const result = await PayService.Refund(Number(paymentIdx));
      setRefundCheck(true);
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      className='fixed z-999 center-x center-y padding-24'
      style={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#00000075',
      }}
    >
      <div
        className='bg-FFFFFF border-radius-16 flex-column w-312 flex-center gap-12'
        style={{ padding: '24px 16px' }}
      >
        <div className='flex end-x w-100per'>
          <img src='/asset/images/close/CloseGray24.svg' />
        </div>
        <div className='flex-column gap-24 flex-center'>
          <div className='flex-center flex-column gap-16'>
            <div className='m-b1-16-b color-dark-primary'>
              정말 포인트 충전을 취소하시겠습니까?
            </div>
            <div className='m-b2-14-m color-disabled'>
              취소 수수료가 발생할 수 있습니다.
            </div>
          </div>
          <div className='flex-row gap-8 w-100per'>
            <div
              className='pointer padding-12 border-radius-8 flex-center bg-blue-gray-100'
              style={{ width: '136px' }}
              onClick={() => {
                setOnPopUp(false);
              }}
            >
              <div className='m-b2-14-m color-disabled'>닫기</div>
            </div>
            <div
              className='pointer padding-12 border-radius-8 flex-center bg-primary-500'
              style={{ width: '136px' }}
              onClick={() => {
                Refund();
                setOnPopUp2(true);
                setOnPopUp(false);
              }}
            >
              <div className='m-b2-14-m color-FFFFFF'>포인트 충전 취소</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
