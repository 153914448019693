import { useEffect, useState } from 'react';
import MapContainerReport from '../../aiFarming/map/MapContainerReport';
import { Content1, Footer, ReportTitle } from '../component';
import LandController from '../../../controller/land/LandController';

interface PropsType {
  data: any;
  address: string;
}

export const Location = ({ data, address }: PropsType) => {
  const [stickPolygon, setStickPolygon]: any = useState(() => {});
  const [currentMarker, setCurrentMarker]: any = useState();
  const [areaList, setAreaList]: any = useState([]);

  const [fixMarker, setFixMarker]: any = useState(); //function
  const [zoomIn, setZoomIn]: any = useState(); //function
  const [zoomIn2, setZoomIn2]: any = useState(true); //function
  const [panTo, setPanTo]: any = useState(); //function
  const [markerArray, setMarkerArray]: any = useState([]);
  const [panToAndMarker, setPanToAndMarker]: any = useState(); //function
  const [isSearch, setIsSearch] = useState(true);
  const content = [
    `본 물건은 ${data.address}에 소재한 ${data.category}임`,
    `부지에 접한 도로는 ${data.roadSurface}(${data.roadSurface_desc})임`,
  ];
  useEffect(() => {
    setTimeout(() => {
      LandController.coordinate2(panToAndMarker, address, true);
    }, 1000);
  }, [panToAndMarker]);
  return (
    <div className='flex-column w-100per space-between h-100per'>
      <div className='flex-column  w-100per'>
        <ReportTitle address={address} category='물건 위치' />
        <div className='flex-column padding-24 gap-12'>
          <Content1 content={content} />
          <MapContainerReport
            setStickPolygon={setStickPolygon}
            location={
              window.location.pathname.slice(-1) === '/'
                ? window.location.pathname.slice(0, -1)
                : window.location.pathname
            }
            setCurrentMarker={setCurrentMarker}
            setFixMarker={setFixMarker}
            setZoomIn={setZoomIn}
            setZoomIn2={setZoomIn2}
            setMarkerArray={setMarkerArray}
            setAreaList={setAreaList}
            setPanTo={setPanTo}
            setPanToAndMarker={setPanToAndMarker}
            setIsSearch={setIsSearch}
            hash={address}
            address={address}
          />
        </div>
      </div>
      <Footer number={1} />
    </div>
  );
};
