import React from "react";
import styled, { keyframes } from "styled-components";

const SpiinerAction = keyframes`
    from {
        transform: rotate(0deg);
    }
    
    to {
        transform: rotate(360deg);
    }
`;

const SpinnerDiv = styled.div`
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: black;
  border-bottom-color: black;
  animation: ${SpiinerAction} 0.8s ease infinite;
`;

function Spinner2() {
  return (
    <div>
      <SpinnerDiv></SpinnerDiv>
    </div>
  );
}

export default React.memo(Spinner2);
