import { Content1, Footer, ReportTitle } from '../component';

export const Surroundings = () => {
  const content = [
    '본 물건의 주변의 실거래 물건을 분석한 결과,',
    '주변 1km 이내 물건들은 최근 2년 평균 xx,xxx원/평에서 최근 1년 xx,xxx원/평으로 17% 상승하고 있음이 분석됨',
    '주변 2km 이내 물건들은 최근 2년 평균 xx,xxx원/평에서 최근 1년 xx,xxx원/평으로 17% 상승하고 있음이 분석됨',
  ];
  return (
    <div className='flex-column w-100per space-between page'>
      <div className='flex-column w-100per'>
        <ReportTitle
          address='전북특별자치도 김제시 순동 445-19'
          category='주변 실거래 가격'
        />
        <div className='flex-column padding-24 gap-12'>
          <Content1 content={content} />
        </div>
      </div>
      <Footer number={7} />
    </div>
  );
};
