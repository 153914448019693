import { useState } from "react";
import { Title } from "./Content";
const PurchaseType = ({ type, setType }: any) => {
  return (
    <div className="flex-column report_result_box bg-white  border-radius-8 gap-8 padding-b-16">
      <Title text="5. 농지 소유 여부" />

      <div className="flex-row padding-x-16 gap-12">
        <div
          className={
            type === "R"
              ? "w-50per bg-primary-600 flex-center padding-12-12-12-8 border-radius-8"
              : "w-50per bg-light-gray-200 flex-center padding-12-12-12-8 border-radius-8"
          }
          onClick={() => {
            setType("R");
          }}
        >
          {type === "R" ? (
            <div className="font-bold f-size-14 line-h-20 color-white">
              ✓ 임차
            </div>
          ) : (
            <div className="font-medium f-size-14 line-h-20 color-disabled">
              임차
            </div>
          )}
        </div>
        <div
          className={
            type === "P"
              ? "w-50per bg-primary-600 flex-center padding-12-12-12-8 border-radius-8"
              : "w-50per bg-light-gray-200 flex-center padding-12-12-12-8 border-radius-8"
          }
          onClick={() => {
            setType("P");
          }}
        >
          {type === "P" ? (
            <div className="font-bold f-size-14 line-h-20 color-white">
              ✓ 매입
            </div>
          ) : (
            <div className="font-medium f-size-14 line-h-20 color-disabled">
              매입
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseType;
