import { useNavigate } from 'react-router-dom';
import { isMobile } from '../../../../component/function/function';
import { ConsultingBtn } from './Button';

export const Personal = () => {
  const navigate = useNavigate();
  return (
    <div className='flex-column w-100per flex-center'>
      <div
        className='flex-column gap-16 flex-center w-100per'
        style={{ paddingBottom: isMobile(window.innerWidth) ? '48px' : '64px' }}
      >
        <div className='m-h2-24-b color-dark-primary w-t1-32-b'>
          개인 토지 분석
        </div>
        <div
          className='color-text-tertiary text-center'
          style={{
            fontWeight: isMobile(window.innerWidth) ? '500' : '400',
            fontSize: isMobile(window.innerWidth) ? '16px' : '20px',
            lineHeight: isMobile(window.innerWidth) ? '25px' : '32px',
          }}
        >
          토지는 아파트 다음으로 거래가 많지만 <br />
          전문 지식 부족으로 피해사례가 빈번합니다. <br />
          토지 활용 방안, 토지 가치 <br />
          트랜스파머가 지켜드리겠습니다.
        </div>
      </div>

      <div className='link-to-form-box'>
        <div
          className={`flex-column border-radius-8 bg-light-gray-100 padding-40-16`}
          style={{ width: '328px' }}
        >
          <div className='flex-column gap-24'>
            <div
              className={`text-center ${
                isMobile(window.innerWidth) ? `m-t1-20-b` : `m-h2-24-b`
              } color-dark-primary`}
            >
              토지를 사고 싶어요
            </div>
            <div
              className='text-center color-text-tertiary'
              style={{
                fontWeight: isMobile(window.innerWidth) ? '500' : '400',
                fontSize: isMobile(window.innerWidth) ? '16px' : '18px',
                lineHeight: isMobile(window.innerWidth) ? '24px' : '26px',
              }}
            >
              절대 손해보지 않는 토지거래 <br />
              쉽고 명확한 가격으로 <br />
              분석 리포트 진행하세요.
            </div>
          </div>
          <img
            src='/asset/images/promotion/consulting_img1.png'
            className='mt-24'
            alt=''
          />
          <div className='flex-column w-100per'>
            <div className='w-100per gap-16 flex-column padding-32-16 center-y border-b-171E26 pointer'>
              <div
                className='flex-row space-between w-100per'
                onClick={() => {
                  window.open('https://forms.gle/UrofywhrLK8trJXA9');
                }}
              >
                <div
                  className={`${
                    isMobile(window.innerWidth) ? `m-b1-16-b` : `m-t2-18-b`
                  } color-dark-secondary`}
                >
                  적정 시세와 전망이 <br /> 궁금해요.
                </div>
                <ConsultingBtn />
              </div>
              <div className='amount-info'>
                <span className='f-size-12 font-medium color-text-primary'>
                  토지가치 분석 보고서 + 브리핑 제공
                </span>
                <span className='f-size-12 font-medium color-primary-400'>
                  비용 15만원
                </span>
              </div>
            </div>

            <div
              className='w-100per gap-16 flex-column center-y pointer'
              style={{ padding: '24px 16px 0 16px' }}
              onClick={() => {
                window.open('https://forms.gle/KpjmcpPaCM47Z42FA');
              }}
            >
              <div className='flex-row space-between w-100per'>
                <div
                  className={`${
                    isMobile(window.innerWidth) ? `m-b1-16-b` : `m-t2-18-b`
                  } color-dark-secondary`}
                >
                  사고 싶은 토지(물건)이 <br /> 있는데 문제 없을까요?
                </div>
                <ConsultingBtn />
              </div>
              <div className='amount-info'>
                <span className='f-size-12 font-medium color-text-primary'>
                  토지가치 분석 보고서 + 브리핑 제공
                </span>
                <span className='f-size-12 font-medium color-primary-400'>
                  비용 15만원
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className=' flex-column border-radius-8 bg-light-gray-100 padding-40-16'
          style={{ width: '328px' }}
        >
          <div className='flex-column gap-24'>
            <div
              className={`text-center ${
                isMobile(window.innerWidth) ? `m-t1-20-b` : `m-h2-24-b`
              } color-dark-primary`}
            >
              토지를 가지고 있어요
            </div>
            <div
              className='text-center color-text-tertiary'
              style={{
                fontWeight: isMobile(window.innerWidth) ? '500' : '400',
                fontSize: isMobile(window.innerWidth) ? '16px' : '18px',
                lineHeight: isMobile(window.innerWidth) ? '24px' : '26px',
              }}
            >
              토지의 숨겨진 가치를 콕! <br />
              토지 분석을 통한 <br />
              다양한 조합의 수익 솔루션 제공
            </div>
          </div>
          <img
            src='/asset/images/promotion/consulting_img2.png'
            className='mt-24'
            alt=''
          />
          <div className='flex-column w-100per'>
            <div className='w-100per gap-16 flex-column padding-32-16 center-y border-b-171E26 pointer'>
              <div
                className='flex-row space-between w-100per '
                onClick={() => {
                  window.open('https://forms.gle/YYZgbbM6oAmpDkCW9');
                }}
              >
                <div
                  className={`${
                    isMobile(window.innerWidth) ? `m-b1-16-b` : `m-t2-18-b`
                  } color-dark-secondary`}
                >
                  토지가치를 올리고 <br /> 싶어요.
                </div>
                <ConsultingBtn />
              </div>
              <div className='amount-info'>
                <span className='f-size-12 font-medium color-text-primary'>
                  토지가치 분석 보고서 + 브리핑 제공
                </span>
                <span className='f-size-12 font-medium color-primary-400'>
                  비용 15만원
                </span>
              </div>
            </div>

            <div
              className='flex-column gap-16  space-between w-100per center-y pointer'
              style={{ padding: '24px 16px 0 16px' }}
            >
              <div
                className='flex-row space-between w-100per'
                onClick={() => {
                  window.open('https://forms.gle/kJamoxuBpvSS4Sdy9');
                }}
              >
                <div
                  className={`${
                    isMobile(window.innerWidth) ? `m-b1-16-b` : `m-t2-18-b`
                  } color-dark-secondary`}
                >
                  토지를 활용해 <br />
                  노후대책을 하고 싶어요.
                </div>
                <ConsultingBtn />
              </div>
              <div className='amount-info'>
                <span className='f-size-12 font-medium color-text-primary'>
                  토지가치 분석 보고서 + 브리핑 제공
                </span>
                <span className='f-size-12 font-medium color-primary-400'>
                  비용 15만원
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='pay-script-box'>
        <div
          className='w-100per bg-light-gray-100 padding-16 flex-column gap-8 font-medium f-size-14 line-h-20  color-disabled'
          style={{ borderRadius: '16px' }}
        >
          <div className='pay-script'>
            <div className='text-dot'></div>
            <p>토지가치 분석 심층 보고서는 유료로 제공됩니다.</p>
          </div>
          <div className='pay-script'>
            <div className='text-dot'></div>
            <p className='font-bold'>트랜스파머 회원은 10% 할인됩니다.</p>
          </div>
          <div className='pay-script'>
            <div className='text-dot'></div>
            <p>
              심층보고서에 대한 브리핑은 카톡 또는 전화로 진행됩니다.{' '}
              <span className='color-text-171E2656'>(1회 무료)</span>
            </p>
          </div>
          <div className='pay-script'>
            <div className='text-dot'></div>
            <p>분석 소요일은 결제 후 2일 이내(영업일 기준)입니다.</p>
          </div>
          <div className='pay-script'>
            <div className='text-dot'></div>
            <p>신청 시 현금영수증 발급 가능합니다.</p>
          </div>
        </div>
      </div>
    </div>
  );
};
