import Banner from '../../../component/AIIntroduction/Footer';
import UserGuideSlider from '../usageGuideSlider/UsageGuideSlider';
import './SignUpPromotion.css';
import CompanyInfoSlider from '../infoSlider/InfoSlider';
import FooterBrowser from '../../../component/browser/Footer';
import { Helmet } from 'react-helmet';
import { TopNavNewBrowser } from '../../../component/mobile/Topnav';
import { useEffect, useState } from 'react';
import { PromotionTopNav } from '../../../component/mobile/PromotionTopNav';
import ABTest from '../../../service/promotion/Promotion';
import TopBanner from '../../../component/banner/TopBanner';
import CTA from '../ctaBtn/CTA';
import LandingBanner from './components/Landing-banner';
import ScrollToTopButton from '../../../component/common/ScrollToTop';
import { useNavigate } from 'react-router-dom';

const SignupPromotion = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const [randomSection, setRandomSection] = useState(0);

  // useEffect(() => {
  //   const randomIndex = Math.floor(Math.random() * 2);
  //   setRandomSection(randomIndex);
  // }, []);

  useEffect(() => {
    sessionStorage.setItem('landingType', 'price');

    const fetchData = async () => {
      try {
        // const type = randomSection === 0 ? 'A' : 'B';
        const data = { type: 'X', title: '가격분석', action: 'N' };
        const result = await ABTest.ABLog(data);
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const actionUpdate = async () => {
    try {
      // const type = randomSection === 0 ? 'A' : 'B';
      const data = { type: 'X', title: '가격분석', action: 'Y' };
      const result = await ABTest.ABLog(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    sessionStorage.setItem('landingType', 'price');
    // sessionStorage.setItem('beforeUrl', document.referrer);
    sessionStorage.setItem('beforeUrl', window.location.href);
  }, []);

  return (
    <div className='wrap'>
      <Helmet>
        <title>
          가장 정확한 전국 토지, 농지, 농가, 전원주택 가격 검색 - 트랜스파머
        </title>
        <meta
          name='description'
          content='가장 정확한 전국 부동산 가격 추정을 보장합니다. 트랜스파머는 빅데이터 기반 AI 분석과 자체 모형으로 실거래가 95% 이상의 정확도로 전국의 토지, 농지, 농가주택, 시골집,  전원주택 가격 추정이 가능합니다.'
        />
        <meta name='robots' content='index, follow' />
        <meta
          name='keyword'
          content='부동산,  토지, 농지,  시골집,  전원주택,  농가주택,  토지가격, 농지가격, 주택가격, 매매, 실거래'
        />
        <link
          rel='canonical'
          href='https://www.transfarmer.co.kr/promotion/정확한-토지-농지-주택-가격을-알아보는-방법'
        />
        {/* 오픈그래프 영역 */}
        <meta property='og:title' content='트랜스파머' />
        <meta
          property='og:description'
          content='가장 정확한 전국 토지, 주택 가격 검색'
        />
        <meta
          property='og:image'
          content='/asset/images/promotion/og-img-promotion-1.png'
        />
        <meta
          property='og:url'
          content='https://transfarmer.co.kr/promotion/정확한-토지-농지-주택-가격을-알아보는-방법'
        />
      </Helmet>
      <div className='container'>
        <ScrollToTopButton bottom={'130px'} mbottom={'90px'} isVisible={true} />
        <TopBanner />
        <PromotionTopNav open={open} setOpen={setOpen} />
        <TopNavNewBrowser />
        <div className='section section-price '>
          <div className='section01-title-box'>
            <h1 className='menu-tag'>가격 분석</h1>
            <div className='section-description'>간편한 지도 검색으로</div>
            <div className='section-title-box'>
              <div className='section-title'>
                <div className='text34'>
                  전국 토지와 전원 주택
                  <br />
                  가격을 한번에!
                </div>
              </div>
            </div>
          </div>
          <div className='section01-content-box '>
            <img
              src='/asset/images/promotion/price-main.svg'
              alt='농지가치 평가보고서'
            />
          </div>
        </div>
        <div className='section section05'>
          <div className='section05-title-box'>
            <div className='text32'>토지 / 농지 / 주택가격</div>
            <div className='section05-title--box-1'>
              <p className='text32 text-color-box'>쉽게 검색하고</p>
              <p className='text32 text-color-box'>똑똑하게 분석하는</p>
              <p className='text32'>합리적인 농지테크</p>
            </div>
            <div className='line-column'></div>
          </div>
          <div className='section05-content-box'>
            <div className='section05-speech-bubble-box'>
              <div className='section05-speech-bubble'>
                <div className='text20'>
                  내 <span>농지 가격</span>이 얼마나 될지,
                  <br />
                  얼마에 팔면 적절할지 <br /> 알고 싶어요!
                </div>
                <img
                  className='color-bubble'
                  src='/asset/images/promotion/grey-bubble-big.svg'
                  alt='말풍선'
                />
              </div>
              <div className='people-img'>
                <img
                  src='/asset/images/promotion/people_illust_01.svg'
                  alt='사람1'
                />
              </div>
            </div>
            <div className='section05-speech-bubble-box-right'>
              <div className='section05-speech-bubble'>
                <div className='text20'>
                  <span>귀농을 준비</span>하는데 <br />
                  <span>관심 지역</span> 농지가 너무 비싼 건 <br />
                  아닌지 알아보고 싶어요!
                </div>
                <img
                  className='line-bubble'
                  src='/asset/images/promotion/line-bubble-big.svg'
                  alt='말풍선'
                />
              </div>
              <div className='people-img people-img-right'>
                <img
                  src='/asset/images/promotion/people_illust_03.svg'
                  alt='사람2'
                />
              </div>
            </div>
            <div className='section05-speech-bubble-box'>
              <div className='section05-speech-bubble'>
                <div className='text20'>
                  <span>시골집</span>을 사고 싶은데 <br />
                  관심 지역 <span>주택가격</span>이 <br />
                  얼마인지 알아보고 싶어요!
                </div>
                <img
                  className='color-bubble'
                  src='/asset/images/promotion/grey-bubble-big.svg'
                  alt='말풍선'
                />
              </div>
              <div className='people-img'>
                <img
                  src='/asset/images/promotion/people_illust_02.svg'
                  alt='사람3'
                />
              </div>
            </div>
            <div className='section05-speech-bubble-box-right'>
              <div className='section05-speech-bubble'>
                <div className='text20'>
                  <span>전원주택, 세컨 하우스</span>에 <br />
                  관심있는데 <br />
                  가격을 알아보고 싶어요!
                </div>
                <img
                  className='line-bubble'
                  src='/asset/images/promotion/line-bubble-big.svg'
                  alt='말풍선'
                />
              </div>
              <div className='people-img people-img-right'>
                <img
                  src='/asset/images/promotion/people_illust_04.svg'
                  alt='사람4'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='section section06 section06-1'>
          <div className='section06-title-box'>
            <div className='section06-title-1'>
              <p className='text27 text27-green'>가장 쉬운</p>
              <p className='text27 text-round-box'>농지테크</p>
            </div>

            <div className='text27'>트랜스파머에서</div>
            {/* <div className='section06-title-2'>
              <p className='text27 text-dot'>쉽고 편리하게</p>
              <p className='text27'>가격 분석하세요! </p>
            </div> */}
            <div className='flex-column flex-center relative'>
              <div className='text27'>쉽고편리하게</div>
              <div className='text27'>가격 분석하세요!</div>
              <div
                className='flex-row  absolute'
                style={{ bottom: '75px', left: '110px', gap: '17px' }}
              >
                {[1, 2, 3, 4].map(() => {
                  return (
                    <div
                      className='border-radius-999'
                      style={{
                        width: '9px',
                        height: '9px',
                        backgroundColor: '#00EA00',
                      }}
                    >
                      &nbsp;
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='section06-content-box'>
            <div className='section06-content-01'>
              <div className='content-01-title'>
                <div className='content-01-grey-line'></div>
                <p className='text27-white'>
                  <span className='text-bright-green'>AI 데이터로 분석</span>한
                  <br />
                  농지/전원주택{' '}
                  <span className='text27 text-bright-green'>가격 분석</span>
                </p>
              </div>
              <div className='content-01-contents'>
                <div className='content-01-content'>
                  <span className='text27'>쉽게</span>
                  <div className='content-white-box'>
                    <div className='white-box-content1'>
                      <span className='white-box-content1-1'>
                        <span className='text17-grey'>
                          내 농지{' '}
                          <img
                            src='/asset/images/promotion/red-check.svg'
                            alt=''
                          />
                        </span>
                        <span className='text17-grey'>
                          이웃 집 농지{' '}
                          <img
                            src='/asset/images/promotion/red-check.svg'
                            alt=''
                          />
                        </span>
                      </span>
                      <span className='white-box-content1-1'>
                        <span className='text17-grey'>
                          부모님 농지{' '}
                          <img
                            src='/asset/images/promotion/red-check.svg'
                            alt=''
                          />
                        </span>
                        <span className='text17-grey'>
                          사고 싶은 농지{' '}
                          <img
                            src='/asset/images/promotion/red-check.svg'
                            alt=''
                          />
                        </span>
                      </span>
                    </div>
                    <div className='white-box-content2'>
                      지도(주소)검색으로 가격분석
                    </div>
                  </div>
                </div>
                <div className='content-01-content'>
                  <span className='text27'>정확하게 </span>
                  <div className='content-white-box'>
                    <div>
                      <p className='text14'>특허출원</p>
                      <p className='text17'>
                        머신러닝 기반{' '}
                        <span className='text17-green'>
                          농지시세 예측시스템
                        </span>
                      </p>
                    </div>
                    <div>
                      <img src='/asset/images/promotion/z-model.svg' alt='z' />
                      <p className='text14'>Z모형 분석</p>
                      <p className='text17'>
                        <span className='text17-green'>95%정확도</span> AI
                        추정가 시스템
                      </p>
                    </div>
                  </div>
                </div>
                <img src='/asset/images/promotion/grey-arrow.svg' alt='arrow' />
                <div className='content-01-content-r'>
                  <div className='content-white-box-round'>
                    <span className='text17'>
                      "농지가격을 이렇게 검색해서 <br /> 알아 본 적이
                      처음이에요!"
                    </span>
                    <img src='/asset/images/promotion/farmer.svg' alt='' />
                  </div>
                  <p className='text14'>*실제 서비스 사용 농장주 인터뷰</p>
                </div>
                <div className='text27' style={{ color: '#fff' }}>
                  <p style={{ color: '#00EA00' }}>오직 트랜스파머에서</p>
                  <p>농촌생활 맞춤형</p>
                  <p>가격 검색이 가능합니다</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='section section08 section08-1'>
          <div className='section08-title-box'>
            <img src='/asset/images/promotion/Lightbulb.svg' alt='전구' />
            <p className='text32'>이렇게 이용하세요</p>
            <p className='text20'>
              쉽고,편하게 <br />
              <span>AI데이터를 활용</span>하여 <br />
              농업 및 농가경영을 계획하세요
            </p>
          </div>
          <div className='section08-content-box'>
            <UserGuideSlider />
          </div>
          <div
            className='w-100per flex-center padding-12 pointer'
            style={{ backgroundColor: '#171E26' }}
            onClick={() => {
              navigate(
                '/promotion/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8-%EB%A7%9E%EC%B6%A4-%ED%86%A0%EC%A7%80-%EC%BB%A8%EC%84%A4%ED%8C%85?utm_source=TF&utm_medium=banner&utm_campaign=consulting_banner&utm_id=promotion&utm_content=consulting_banner'
              );
            }}
          >
            <div
              className='flex-row space-between w-100per'
              style={{ maxWidth: '1200px' }}
            >
              <div className='flex-row gap-8 w-100per center-y'>
                <div
                  className='bg-primary-400 border-radius-2'
                  style={{ padding: '2px 6px' }}
                >
                  <div className='m-b2-14-b color-dark-primary'>
                    맞춤 컨설팅
                  </div>
                </div>
                <div className='m-b1-16-b color-FFFFFF'>
                  토지 매매, 이제 <span style={{ color: '#09CE7D' }}>안심</span>
                  하세요
                </div>
              </div>
              <img
                className='w-24'
                src='/asset/images/arrow/ArrowRightWhite20.svg'
              />
            </div>
          </div>
          <LandingBanner />
        </div>
        <div className='section section09'>
          <div className='section09-title-box'>
            <img src='/asset/images/promotion/Logo_Symbol.svg' alt='로고' />
            <p className='text32'>트랜스파머는</p>
            <p className='text20'>
              농지 검색에서 수익창출까지 <br />
              농지 위의 모든 것을 데이터화하여 <br />
              가치를 만들어 갑니다
            </p>
          </div>
          <div className='section09-content-box'>
            <CompanyInfoSlider />
          </div>
        </div>
        <Banner />
        <FooterBrowser />
      </div>
      <CTA
        text1='실거래 가격 이상의'
        text3='가치 분석을 무료로!'
        btnText1='AI 가격분석 시작'
        actionUpdate={actionUpdate}
        greenTextColor='#00EA00'
        actionBtnBackground='#00EA00'
      />
    </div>
  );
};

export default SignupPromotion;
