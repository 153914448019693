const GetLandShpaeImage = (shape: string) => {
  switch (shape) {
    case '가로장방':
      return 'https://back.transfarmer.kr/img/temp/1715043878631.png';
    case '변형사다리':
      return 'https://back.transfarmer.kr/img/temp/1715043897957.png';
    case '부정':
      return 'https://back.transfarmer.kr/img/temp/1715043917755.png';
    case '사다리':
      return 'https://back.transfarmer.kr/img/temp/1715043929536.png';
    case '삼각':
      return 'https://back.transfarmer.kr/img/temp/1715043942348.png';
    case '세로장방':
      return 'https://back.transfarmer.kr/img/temp/1715043952675.png';
    case '맹지':
      return 'https://back.transfarmer.kr/img/temp/1715043963449.png';
    case '역사다리':
      return 'https://back.transfarmer.kr/img/temp/1715043972788.png';
    case '자루':
      return 'https://back.transfarmer.kr/img/temp/1715043980806.png';
    case '정방':
      return 'https://back.transfarmer.kr/img/temp/1715043993996.png';
    default:
      return 'https://back.transfarmer.kr/img/temp/1715043878631.png';
  }
};
export default GetLandShpaeImage;
