import { useState } from "react";
import { RowSectionTitle } from "../../../../component/report/Row";
import { TextSectionTitle } from "../../../../component/report/Text";
import { isMobile } from "../../../../component/function/function";
import { Area, AreaChart, Tooltip, XAxis, YAxis } from "recharts";
import { BoxGreen1, BoxTable3 } from "../../../../component/report/Box";
// import { ITotalData } from "../../Main";

const ReportDetail5 = ({ data }: { data: any }) => {
  let arr = [];
  let arr2 = [];

  const [areaType, setAreaType] = useState("P");

  for (let key in data.officialPriceList) {
    arr.unshift([
      key + "년",
      areaType === "P"
        ? `${Math.round(
            data.officialPriceList[key as "2023"] * 3.3
          ).toLocaleString()}원`
        : `${data.officialPriceList[key as "2023"].toLocaleString()}원`,
    ]);
    arr2.push({
      key: key,
      price:
        areaType === "P"
          ? data.officialPriceList[key as "2023"] * 3.3
          : Math.round(data.officialPriceList[key as "2023"]),
    });
  }
  arr = arr.sort((a, b) => Number(b[0]) - Number(a[0]));
  const table = {
    th: [
      "기준연도",
      "개별공시지가 (단위: " + (areaType === "P" ? "평" : "m²") + ")",
    ],
    tb: arr,
  };

  return (
    <div className='padding-b-24 browser-bg-FFFFFF'>
      <RowSectionTitle>
        <TextSectionTitle>공시지가 추이</TextSectionTitle>
      </RowSectionTitle>

      <div className='end-x padding-x-16'>
        <div
          className='hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary'
          onClick={() => setAreaType((prev) => (prev !== "P" ? "P" : "M"))}
        >
          <img src='/asset/images/arrow/Refresh20.svg' alt='' />
          {areaType === "P" ? "m²" : "평"}
        </div>
      </div>
      <div className='padding-24-16 flex-column gap-16'>
        <AreaChart
          className='flex-medium f-size-11 center-x'
          width={
            isMobile(window.innerWidth) ? window.innerWidth - 32 : 360 - 32
          }
          height={181}
          data={arr2}
          margin={
            isMobile(window.innerWidth)
              ? {
                  top: 5,
                  right: 30,
                  left: 0,
                  bottom: 5,
                }
              : {
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }
          }
        >
          <defs>
            <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='0.6'>
              <stop offset='5%' stopColor='#53df89' stopOpacity={1} />
              <stop offset='95%' stopColor='#53df89' stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey='key' />
          <YAxis tickFormatter={(value) => value.toLocaleString()} />
          <Tooltip
            labelFormatter={(label) => `${label}년`}
            formatter={(value) => `${value.toLocaleString()}원`}
          />
          <Area
            type='monotone'
            dataKey='price'
            stroke='#53df89'
            fillOpacity={1}
            fill='url(#colorPv)'
            name='공시지가'
          />
        </AreaChart>
        <div className='flex-column of-y-auto' style={{ maxHeight: "168px" }}>
          <BoxTable3 table={table} right2 />
        </div>
      </div>
      <div className='padding-8-16 gap-10'>
        <BoxGreen1>
          <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
            최근 {Object.keys(data.officialPriceList).length}년(
            {Object.keys(data.officialPriceList)[0]}-
            {
              Object.keys(data.officialPriceList)[
                Object.keys(data.officialPriceList).length - 1
              ]
            }
            ) <br />
            공시지가 연평균 성장률은
            <br />
            {Math.floor(
              (Math.pow(
                data.officialPriceList[
                  Object.keys(data.officialPriceList)[
                    Object.keys(data.officialPriceList).length - 1
                  ]
                ] /
                  data.officialPriceList[
                    Object.keys(data.officialPriceList)[0]
                  ],
                1 /
                  (Number(
                    Object.keys(data.officialPriceList)[
                      Object.keys(data.officialPriceList).length - 1
                    ]
                  ) -
                    Number(Object.keys(data.officialPriceList)[0]))
              ) -
                1) *
                1000
            ) / 10}
            % 입니다.
          </div>
        </BoxGreen1>
      </div>
    </div>
  );
};

export default ReportDetail5;
