import Request from "../../@api/request";

const baseURL = `${process.env.REACT_APP_HOST}`;

export const request = new Request(baseURL);
export default class PolicyService {

  static list = async (type: string, bCategory: string, eCategory: string, termType: string, region: string, search: string, sort: string, limit: number, offset: number, year: any) => {
    const result = await request.get(`subsidy/list?type=${type}&bCategory=${bCategory}&eCategory=${eCategory}&termType=${termType}&region=${region}&search=${search}&sort=${sort}&limit=${limit}&offset=${offset}&year=${year}`,);
    return result.data;
  };

  static detail = async (subsidyIdx: number) => {
    const result = await request.get(`subsidy/${subsidyIdx}`);
    return result.data
  };
}
