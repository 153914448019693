const Area = ({
  number,
  area,
  setArea,
  // mArea,
  // setMArea,
  areaType,
  setAreaType,
  recommendCropApi,
}: any) => {
  return (
    <div className='flex-column w-100per report_result_box bg-white padding-b-16'>
      <div className='flex-column border-radius-8 bg-white'>
        <div className='flex-row gap-5  padding-16-16-8-16 space-between'>
          <div className='font-bold f-size-16 line-h-24 color-text-primary'>
            3. 재배 면적
          </div>
          <div
            className='hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary'
            onClick={() => {
              setAreaType((prev: any) => (prev !== "P" ? "P" : "M"));
            }}
          >
            <img src='/asset/images/arrow/Refresh20.svg' alt='' />
            {areaType === "P" ? "m²" : "평"}
          </div>
        </div>
        <div className='w-100per padding-x-16'>
          <div className='flex-row gap-5 border-171E26 w-100per border-radius-8 end-x center-y padding-6-8'>
            <input
              className='border-none color-text-secondary text-end font-medium f-size-14 line-h-24 '
              // value={Number(isMobile() ? mArea : area).toLocaleString()}
              value={Number(area).toLocaleString()}
              onChange={(e: any) => {
                setArea(Number(e.target.value.split(",").join("")));
                // if (isMobile()) {
                //   setMArea(Number(e.target.value.split(",").join("")));
                // } else {
                //   setArea(Number(e.target.value.split(",").join("")));
                // }
              }}
              placeholder='입력해주세요'
              onBlur={recommendCropApi}
            />
            <div className='font-medium f-size-14 line-h-20 color-text-primary'>
              {areaType === "P" ? "평" : "m²"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Area;
