import { el } from 'date-fns/locale';
import { transNum } from '../../../component/function/function';

const ReportInfo2 = ({
  data,
  type,
  list,
  handleNavigatePage,
}: {
  data: any;
  type: any;
  list: any;
  handleNavigatePage: any;
}) => {
  return (
    <div className='flex-column'>
      <div className='mobile-body-1-bold color-text-secondary padding-16-16-0'>
        소재지 목록
      </div>
      <div className='flex-column padding-16 gap-8'>
        <div>
          <div
            className='flex-row padding-y-8 center-y w-100per mobile-caption-1-medium color-text-tertiary'
            style={{ background: '#f7f8f9' }}
          >
            <div className='flex-row center-x' style={{ flex: 40 / 328 }}>
              NO
            </div>
            <div className='flex-row center-x' style={{ flex: 198 / 328 }}>
              소재지
            </div>
            <div className='flex-row center-x' style={{ flex: 90 / 328 }}>
              구분/면적
            </div>
          </div>
          <div className='w-100per flex-column'>
            {list.map((el: any, idx: number) => (
              <div
                className='flex-row w-100per padding-y-8 center-y w-100per mobile-caption-1-medium color-text-tertiary border-b-1-171E2610 pointer auction-list-item'
                onClick={(e) => handleNavigatePage(e, idx)}
              >
                <div className='flex-row center-x' style={{ flex: 40 / 328 }}>
                  {idx + 1}
                </div>
                <div
                  className='flex-row padding-x-8'
                  style={{ flex: 198 / 328 }}
                >
                  {el.address}
                </div>
                <div className='flex-row center-x' style={{ flex: 90 / 328 }}>
                  {el.isFarm === 'Y'
                    ? el.category && el.area
                      ? `${el.category} / ${Number(el.area).toLocaleString()}m²`
                      : ''
                    : '건물'}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportInfo2;
