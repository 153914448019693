import { Link } from 'react-router-dom';
import './InfoSlider.css';
import { useRef, useState, MouseEvent, TouchEvent } from 'react';

const CompanyInfoSlider = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [clickPoint, setClickPoint] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDownEvent = (e: MouseEvent<HTMLDivElement>) => {
    setDragging(true);
    if (containerRef.current) {
      setClickPoint(e.pageX); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };
  const handleTouchDownEvent = (e: TouchEvent<HTMLDivElement>) => {
    setDragging(true);
    e.stopPropagation();

    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'auto';
      setClickPoint(e.touches[0].pageX); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };
  const handleMouseMoveEvent = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음
    e.preventDefault();
    if (containerRef.current) {
      const walk = e.pageX - clickPoint;
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };
  const handleTouchMoveEvent = (e: TouchEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음
    e.stopPropagation();
    if (containerRef.current) {
      const walk = e.touches[0].pageX - clickPoint;
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const [dragging, setDragging] = useState(false);
  return (
    <div
      className='info-slider'
      ref={containerRef}
      onMouseDown={handleMouseDownEvent}
      onMouseLeave={() => setDragging(false)}
      onMouseUp={() => setDragging(false)}
      onMouseMove={handleMouseMoveEvent}
      onTouchStart={handleTouchDownEvent}
      onTouchMove={handleTouchMoveEvent}
    >
      <div className='info-item 1'>
        <div className='image-box image-box1'>
          <img className='nh-logo' src='/asset/images/promotion/image598.png' />
          <img
            className='sopoong-logo'
            src='/asset/images/promotion/sopoong-logo.png'
          />
        </div>
        <ul className='info-text-box'>
          <li className='font-medium f-size-14'>
            중소벤처부 초기창업패키지 선정
          </li>
          <li className='font-medium f-size-14'>
            NH농협은행 오픈이노베이션 파트너 기업 선정{' '}
          </li>
          <li className='font-medium f-size-14'>
            팁스운영사(소풍 벤처스) 투자 기업 선정
          </li>
          <li className='font-medium f-size-14'>
            농진원 벤처육성지원 사업 선정{' '}
          </li>
        </ul>
      </div>
      <div className='info-item 2'>
        <div className='image-box image-box2'></div>
        <ul className='info-text-box'>
          <li className='font-medium f-size-14'>
            농가대출 평가시스템 및 평가방법
          </li>
          <li className='font-medium f-size-14'>
            농작물 소득 예측시스템 및 예측방법
          </li>
          <li className='font-medium f-size-14'>
            머신러닝 기반 농지시세 예측시스템 및 방법
          </li>
          <li className='font-medium f-size-14'>
            농지특성에 적합한 농작물추천시스템 및 방법
          </li>
        </ul>
      </div>
      <div className='info-item'>
        <div className='image-box image-box3'></div>
        <ul className='info-text-box'>
          <li className='font-medium f-size-14'>
            <Link
              to='https://www.edaily.co.kr/news/read?newsId=03463686635778496&mediaCodeNo=257'
              target='_blank'
            >
              “AI로 농촌 소득 올리면 귀농인도 늘어날겁니다”...
              트랜스파머(이데일리 2023. 10. 23)
            </Link>
          </li>
          <li className='font-medium f-size-14'>
            <Link
              to='https://www.newsway.co.kr/news/view?ud=2023121316504354786'
              target='_blank'
            >
              김기현 트랜스파머 대표 “귀농•귀촌도 AI로... 감만 믿으면
              낭패”(뉴스웨이 2023. 12. 14)
            </Link>
          </li>
        </ul>
      </div>
      <div className='info-item'>
        <div className='image-box image-box4'></div>
        <ul className='info-text-box'>
          <li className='font-medium f-size-14'>
            "농업의 문제는 결국 토지에서 모든 게 시작됩니다. 트랜스파머는 확장
            가능성이 매우 높은 서비스입니다." (농업 전문가)
          </li>
        </ul>
      </div>
    </div>
  );
};
export default CompanyInfoSlider;
