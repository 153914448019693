import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TopNav2 } from '../../../component/mobile/Topnav';
import { Calendar } from '../calendar/Calendar';

import { useNavigate } from 'react-router';
import FooterBrowser from '../../../component/browser/Footer';
import FooterMobile from '../../../component/AIIntroduction/Footer';
import InfoTerms from '../../signUp/terms/Info';
import { ReportDetail } from '../report/ReportDetail';
import { PopUp2 } from '../../point/PopUp2';
import { PopUp3 } from '../../point/PopUp3';
import { isMobile } from '../../../component/function/function';
import UserService from '../../../service/checkAccount/CheckAccount';
import { parse } from 'path';

const FreePoint = ({
  setTypeSearch,
  setReportIdxSearch,
  setClose,
  search,
  temp,
  setOnDetail2,
  setReportIdx2,
  setType2,
  setPointDetail,
  setOnCalendar,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setAddress,
  setDes,
  des,
  setAuctionType,
  auctionType,
}: any) => {
  interface PointItem {
    pointIdx: number;
  }
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);

  const [onPointPopUp, setOnPointPopUp] = useState(false);
  const [reportIdx, setReportIdx] = useState(0);
  const [totalCnt, setTotalCnt] = useState(0);
  const [onCalendarMo, setOnCalendarMo] = useState(false);
  const tab1 = useRef<HTMLDivElement>(null);
  const tab2 = useRef<HTMLDivElement>(null);
  const tab3 = useRef<HTMLDivElement>(null);
  const [priceType, setPriceType] = useState('');
  const dateRef = useRef<HTMLDivElement>(null);

  const [tabWidth, setTabWidth] = useState(0);
  const [left, setLeft] = useState(0);
  const [menu, setMenu] = useState('전체');

  useLayoutEffect(() => {
    if (menu === '전체') {
      if (tab1?.current) {
        setTabWidth(tab1.current.offsetWidth);
        setLeft(tab1.current.offsetLeft);
      }
    } else if (menu === '적립') {
      if (tab2?.current) {
        setTabWidth(tab2.current.offsetWidth);
        setLeft(tab2.current.offsetLeft);
      }
    } else {
      if (tab3?.current) {
        setTabWidth(tab3.current.offsetWidth);
        setLeft(tab3.current.offsetLeft);
      }
    }
  }, [menu]);

  const date = new Date();
  // console.log(date.getMonth());
  const formattedStartDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, '0')}-01`;
  const formattedEndDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, '0')}-${new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate()}`;
  const [startDateMo, setStartDateMo] = useState(formattedStartDate);
  const [endDateMo, setEndDateMo] = useState(formattedEndDate);
  const [type, setType] = useState('');
  useEffect(() => {
    setPointList([]);
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  const [onDetail, setOnDetail] = useState(false);
  const [pointList, setPointList] = useState<PointItem[]>([]);
  const [point, setPoint] = useState(0);

  const limit = 10;
  const offset = 0;
  const getPointHistoryApi = async () => {
    try {
      const result = await UserService.getPointHistory(
        'F',
        isMobile(window.innerWidth) ? startDateMo : startDate,
        isMobile(window.innerWidth) ? endDateMo : endDate,
        menu === '전체' ? '' : menu === '적립' ? 'C' : 'U',

        tab === 0
          ? ''
          : tab === 1
          ? 'C'
          : tab === 2
          ? 'S'
          : tab === 3
          ? 'R'
          : tab === 4
          ? 'F'
          : tab === 5
          ? 'E'
          : tab === 6
          ? 'P'
          : ''
      );
      setPointList(result.data.result);
      setTotalCnt(Object.keys(result.data.result).length);
    } catch (error) {
      console.log(error);
    }
  };
  function changeTextType(item: string) {
    return reportType[item as keyof typeof reportType];
  }

  enum reportType {
    '매물 분석 - 지자체 매물' = 'S',
    '매물 분석 - 경매' = 'A',
    '매물 분석 - 공매' = 'P',
  }
  const checkPoint = async () => {
    try {
      const result = await UserService.checkPoint();
      setPoint(result.data.data.freePoint);
    } catch (error) {
      console.log(error);
    }
  };

  const currentDate = new Date(
    isMobile(window.innerWidth) ? startDateMo : startDate
  );
  const handleLeftArrowClick = () => {
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const newYear = currentMonth === 1 ? currentYear - 1 : currentYear;
    const newMonth = currentMonth === 1 ? 12 : currentMonth - 1;

    const newStartDate = formatDate(newYear, newMonth, 1);
    const newEndDate = getLastDayOfMonth(newYear, newMonth);

    if (isMobile(window.innerWidth)) {
      setStartDateMo(newStartDate);
      setEndDateMo(newEndDate);
    } else {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };
  const handleRightArrowClick = () => {
    const temp = new Date().getTime();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    if (isMobile(window.innerWidth)) {
      if (currentDate.getTime() + 2419200000 < temp) {
        const newYear = currentMonth === 12 ? currentYear + 1 : currentYear;
        const newMonth = currentMonth === 12 ? 1 : currentMonth + 1;
        const newStartDate = formatDate(newYear, newMonth, 1);
        const newEndDate = getLastDayOfMonth(newYear, newMonth);
        if (isMobile(window.innerWidth)) {
          setStartDateMo(newStartDate);
          setEndDateMo(newEndDate);
        } else {
          setStartDate(newStartDate);
          setEndDate(newEndDate);
        }
      }
    } else {
      if (currentDate.getTime() + 2419200000 < temp) {
        const newYear = currentMonth === 12 ? currentYear + 1 : currentYear;
        const newMonth = currentMonth === 12 ? 1 : currentMonth + 1;

        const newStartDate = formatDate(newYear, newMonth, 1);
        const newEndDate = getLastDayOfMonth(newYear, newMonth);
        if (isMobile(window.innerWidth)) {
          setStartDateMo(newStartDate);
          setEndDateMo(newEndDate);
        } else {
          setStartDate(newStartDate);
          setEndDate(newEndDate);
        }
      }
    }
  };
  const tabletClick = (idx: any, type: any, address: any, des: any) => {
    setOnDetail2(true);
    setReportIdx2(idx);
    setAddress(address);
    setType2(type);
    setDes(des);
  };
  const formatDate = (year: any, month: any, day: any) => {
    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = String(day).padStart(2, '0');
    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const getLastDayOfMonth = (year: any, month: any) => {
    // 일자를 0으로 설정하여 전월의 마지막 날짜를 가져옴
    const lastDay = new Date(year, month, 0).getDate();
    return formatDate(year, month, lastDay);
  };
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    if (search) {
      ref.current?.scrollTo(0, 0);
    } else {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  };
  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 500) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleShowButton);
    return () => {
      window.removeEventListener('scroll', handleShowButton);
    };
  }, []);
  const [position, setPosition] = useState<number>(0);
  useEffect(() => {
    const handleScroll = () => {
      setPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const SearchDetailHandler = (value: any, category: any) => {
    setReportIdxSearch(value);
    setTypeSearch(category);
    setPointDetail(true);
  };
  useEffect(() => {
    checkPoint();
  }, []);
  useEffect(() => {
    getPointHistoryApi();
  }, [startDate, endDate, menu]);
  useEffect(() => {
    getPointHistoryApi();
  }, [startDateMo, endDateMo, menu, tab]);

  return (
    <div
      ref={ref}
      className={`flex-column h-100per  ${
        search ? `w-360 of-scroll z-1 ` : `w-100per `
      }`}
    >
      {onDetail && (
        <ReportDetail
          open={onDetail}
          reportIdx={reportIdx}
          setClose={setOnDetail}
          type={type}
          des={des}
          auctionType={auctionType}
        />
      )}

      {onPointPopUp && <PopUp3 setOnPopUp2={setOnPointPopUp} />}
      {isMobile(window.innerWidth) && onCalendarMo && (
        <Calendar
          onCalendar={onCalendarMo}
          setOnCalendar={setOnCalendarMo}
          setStartDate={setStartDateMo}
          setEndDate={setEndDateMo}
        />
      )}
      <TopNav2 text='무료 포인트 결제 내역' back hamburger />
      {search && (
        <div className='flex-row space-between padding-12-16 center-y'>
          <div></div>
          <div className='m-b1-16-b color-dark-primary'>포인트 내역</div>
          <img
            src='/asset/images/close/CloseBlack24.svg'
            className='pointer'
            alt='close'
            onClick={() => {
              setClose(false);
            }}
          />
        </div>
      )}
      <div className=' padding-32-16-24-16 flex-column border-b-171E26 gap-8 bg-FFFFFF tablet-padding-32-40-24-40 relative'>
        <div className='flex-row space-between w-100per center-y'>
          <div className='m-b2-14-m color-dark-secondary'>
            무료 포인트 (우선 사용)
          </div>
          <div
            className='m-b2-14-m color-disabled underline pointer'
            onClick={() => {
              isMobile(window.innerWidth)
                ? navigate('/point')
                : setOnPointPopUp(true);
            }}
          >
            포인트 안내
          </div>
        </div>
        <div className='m-t1-20-b color-dark-primar'>
          {point.toLocaleString()}P
        </div>
      </div>
      <div
        className={`flex-column w-100per ${
          position > (dateRef.current?.getBoundingClientRect().top || 0) &&
          isMobile(window.innerWidth)
            ? `fixed`
            : ``
        }`}
      >
        <div
          ref={dateRef}
          className={` w-100per flex-row space-between center-y padding-12-16 bg-FFFFFF tablet-padding-12-40 `}
          style={{ top: 0 }}
        >
          <img
            src='/asset/images/arrow/ArrowLeftPage.svg'
            alt='arrow'
            className='pointer'
            onClick={() => {
              handleLeftArrowClick();
            }}
          />
          <div className='flex-row'>
            <div
              className='m-b1-16-m color-dark-primary pointer'
              onClick={() => {
                if (isMobile(window.innerWidth)) {
                  setOnCalendarMo(true);
                } else {
                  setOnCalendar(true);
                }
              }}
            >
              {isMobile(window.innerWidth)
                ? startDateMo.split('-')[0] +
                  '년' +
                  ' ' +
                  startDateMo.split('-')[1] +
                  '월'
                : startDate.split('-')[0] +
                  '년' +
                  ' ' +
                  startDate.split('-')[1] +
                  '월'}
            </div>
            {/* <img src='/asset/images/arrow/ArrowBottom16.svg' alt='arrow' /> */}
          </div>
          <img
            src='/asset/images/arrow/ArrowRightBlack.svg'
            alt='arrow'
            className='pointer'
            onClick={() => {
              handleRightArrowClick();
            }}
          />
        </div>
        <div className=' flex-row gap-8 bg-FFFFFF padding-8-16-0-16 border-b-171E26 tablet-padding-12-40 relative'>
          {/* {["전체", "충전", "사용"].map((item, index) => {
          return (
            <div
              className={`border-radius-100 padding-6-16 w-60 h-36 pointer ${
                tab === index ? `bg-171E26` : `bg-FFFFFF border-171E26`
              } `}
              onClick={() => {
                setTab(index);
              }}
            >
              <div
                className={`m-b1-16-m ${
                  index === tab ? `color-FFFFFF` : `color-text-tertiary`
                } `}
              >
                {item}
              </div>
            </div>
          );
        })} */}

          {['전체', '적립', '사용'].map((item: any, index: number) => {
            return (
              <div
                className='w-33per flex-center padding-y-8 pointer'
                ref={index === 0 ? tab1 : index === 1 ? tab2 : tab3}
                onClick={() => {
                  setMenu(item);
                  // window.scrollTo(0, 0);
                }}
              >
                <div
                  className={`m-b1-16-b ${
                    menu === item ? `color-dark-primary` : `color-171E2640`
                  }`}
                >
                  {item}
                </div>
              </div>
            );
          })}
          <div
            className='slider-black2'
            style={{ width: tabWidth, left: left, bottom: 0 }}
          />
        </div>
        {menu === '적립' && (
          <div
            className={`w-100per flex-row gap-8 padding-12-16`}
            style={{ overflowX: search && 'auto' }}
          >
            {[
              '전체',
              '출석도장',
              '회원가입',
              '친구추천',
              '친구가입',
              '이벤트 쿠폰',
              '선물 포인트',
            ].map((item, index) => {
              return (
                <div
                  className={`padding-6-16 border-radius-100 h-36 flex-center border-171E26 pointer no-wrap ${
                    tab === index ? `bg-171E26` : ``
                  }`}
                  onClick={() => {
                    setTab(index);
                  }}
                >
                  <div
                    className={`m-b1-16-m ${
                      tab === index ? `color-FFFFFF` : `color-text-tertiary`
                    }`}
                  >
                    {item}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {totalCnt > 0 && (
        <div className='bg-light-gray-100 padding-10-16 end-x w-100per  tablet-padding-10-40 relative'>
          <div className='m-b2-14-m color-000000'>
            총 <span className='color-primary-300'>{totalCnt}</span>건
          </div>
          <img
            className='fixed pointer'
            style={{
              bottom: '10px',
              right:
                window.innerWidth >= 1024 && window.innerWidth < 1920
                  ? '80px'
                  : window.innerWidth >= 1920
                  ? search
                    ? '1120px'
                    : `380px`
                  : ``,
              // right: "380px",
            }}
            src='/asset/images/arrow/ArrowTopWithCircle.svg'
            alt='top'
            onClick={() => {
              scrollToTop();
            }}
          />{' '}
        </div>
      )}
      <div className={`flex-column gap-32 bg-FFFFFF ${search && `h-100per`}`}>
        {totalCnt > 0 ? (
          Object.entries(pointList)
            .sort(
              (a, b) =>
                parseInt(b[0].split(' ')[0]) - parseInt(a[0].split(' ')[0])
            )
            .map((item: any) => {
              return item[1].map((item2: any, index: any) => {
                return item2.point > 0 ? (
                  <div
                    className='padding-b-40 flex-column gap-24 border-b-171E26  padding-24-16-48-16 tablet-padding-24-40-48-40'
                    key={index}
                  >
                    <div className='flex-row space-between w-100per center-y'>
                      <div className='m-b2-14-m color-text-tertiary'>
                        {item2.pointTime}
                      </div>
                      <div className='m-b2-14-m color-dark-secondary'>
                        {index === 0 && item[1].length + '건'}
                      </div>
                    </div>
                    <div className='flex-row gap-12'>
                      <img
                        src={
                          item2.category.includes('취소')
                            ? '/asset/images/mypage/cancel.svg'
                            : '/asset/images/mypage/charge.svg'
                        }
                        alt='point'
                      />
                      <div className='flex-column gap-4 w-100per'>
                        <div className='flex-row space-between center-y w-100per'>
                          <div className='m-b1-16-m color-dark-primary'>
                            {item2.category}
                          </div>
                          <div
                            className='m-b1-16-b color-primary-300'
                            style={{
                              textDecorationLine:
                                item2.category.includes('취소') &&
                                'line-through',
                            }}
                          >
                            {item2.point > 0
                              ? '+' + item2.point.toLocaleString()
                              : item2.point.toLocaleString()}
                            원(P)
                          </div>
                        </div>
                        <div className='m-b2-14-m color-text-tertiary'>
                          {item2.sub}
                        </div>
                        <div className='m-b2-14-m color-text-tertiary'>
                          {item2.pointTime2}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='padding-b-40 flex-column border-b-171E26  padding-24-16-48-16 tablet-padding-24-40-48-40'>
                    <div className='flex-column gap-40'>
                      <div className='flex-column gap-24'>
                        <div className='flex-row space-between w-100per center-y'>
                          <div className='m-b2-14-m color-text-tertiary'>
                            {item2.pointTime}
                          </div>
                          <div className='m-b2-14-m color-dark-secondary'>
                            {index === 0 && item[1].length + '건'}
                          </div>
                        </div>
                        <div className='flex-row gap-12'>
                          {item2.category && (
                            <img
                              src={
                                item2.category === '가격분석'
                                  ? '/asset/images/mypage/price.svg'
                                  : item2.category === '농지 찾기'
                                  ? '/asset/images/mypage/findland.svg'
                                  : item2.category === '내 집 짓기'
                                  ? '/asset/images/mypage/house.svg'
                                  : item2.category === '농지연금 분석'
                                  ? '/asset/images/mypage/pension.svg'
                                  : item2.category === '내 농장 진단'
                                  ? '/asset/images/mypage/aifarm.svg'
                                  : ''
                              }
                              alt='point'
                            />
                          )}
                          <div className='flex-column gap-4 w-100per'>
                            <div className='flex-row space-between center-y w-100per'>
                              <div className='m-b1-16-m color-dark-primary'>
                                {item2.category}
                              </div>
                              <div className='m-b1-16-b color-text-increase'>
                                {item2.point.toLocaleString()}원(P)
                              </div>
                            </div>
                            <div className='m-b2-14-m color-text-tertiary'>
                              {item2.sub}
                            </div>
                            <div className='m-b2-14-m color-text-tertiary'>
                              {item2.pointTime2}
                            </div>
                          </div>
                        </div>

                        <div
                          className='bg-light-gray-100 padding-8-16 flex-center border-radius-8 pointer'
                          onClick={() => {
                            setReportIdx(item2.value);
                            setType(item2.category);
                            setAuctionType(changeTextType(item2.sub));
                            search
                              ? SearchDetailHandler(item2.value, item2.category)
                              : window.innerWidth < 768
                              ? navigate(`/mypage/report/${item2.value}`, {
                                  state: {
                                    reportIdx: item2.value,
                                    category:
                                      item2.category === '가격 분석'
                                        ? '가격 분석'
                                        : item2.category === '농지 찾기'
                                        ? '농지가치 분석보고서'
                                        : item2.category === '내 집 짓기'
                                        ? '내 집 건축보고서'
                                        : item2.category === '내 농장 진단'
                                        ? '내농장 진단보고서'
                                        : '농지연금 분석보고서',
                                    address: item2.sub,
                                  },
                                })
                              : window.innerWidth >= 768 &&
                                window.innerWidth < 1024
                              ? setOnDetail(true)
                              : tabletClick(
                                  item2.value,
                                  item2.category,
                                  item2.sub,
                                  item2.description
                                );
                          }}
                        >
                          <div className='m-b2-14-m color-dark-primary'>
                            상세보기
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              });
            })
        ) : (
          <div
            className={`flex-column padding-56-16 bg-light-gray-100 gap-16 w-100per center-y  ${
              search ? `h-100per` : `tablet-h-448`
            }`}
          >
            <img
              className='w-48 h-48'
              src='/asset/images/warn/WarnWithGrayCircle.svg'
              alt='warn'
            />
            <div className='m-b2-14-m color-dark-secondary text-center'>
              {isMobile(window.innerWidth)
                ? startDateMo.split('-')[1]
                : startDate.split('-')[1]}
              월 무료 포인트{' '}
              {menu === '적립' ? '적립' : menu === '사용' ? '사용' : ''} 내역이
              없어요.
            </div>
            <div
              className='padding-8-16 border-radius-8 border-171E26 fit-content pointer'
              onClick={() => {
                if (isMobile(window.innerWidth)) {
                  setStartDateMo(
                    Number(startDateMo.split('-')[1]) - 1 === 0
                      ? String(Number(startDateMo.split('-')[0]) - 1) + '-12-01'
                      : startDateMo.split('-')[0] +
                          '-' +
                          // (Number(startDate.split("-")[1]) - 1)
                          String(
                            Number(startDateMo.split('-')[1]) - 1
                          ).padStart(2, '0') +
                          '-01'
                  );

                  setEndDateMo(
                    Number(endDateMo.split('-')[1]) - 1 === 0
                      ? String(Number(endDateMo.split('-')[0]) - 1) + '-12-31'
                      : endDateMo.split('-')[0] +
                          '-' +
                          // (Number(startDate.split("-")[1]) - 1)
                          String(Number(endDateMo.split('-')[1]) - 1).padStart(
                            2,
                            '0'
                          ) +
                          '-31'
                  );
                } else {
                  setStartDate(
                    Number(startDate.split('-')[1]) - 1 === 0
                      ? String(Number(startDate.split('-')[0]) - 1) + '-12-01'
                      : startDate.split('-')[0] +
                          '-' +
                          // (Number(startDate.split("-")[1]) - 1)
                          String(Number(startDate.split('-')[1]) - 1).padStart(
                            2,
                            '0'
                          ) +
                          '-01'
                  );

                  setEndDate(
                    Number(endDate.split('-')[1]) - 1 === 0
                      ? String(Number(endDate.split('-')[0]) - 1) + '-12-31'
                      : endDate.split('-')[0] +
                          '-' +
                          // (Number(startDate.split("-")[1]) - 1)
                          String(Number(endDate.split('-')[1]) - 1).padStart(
                            2,
                            '0'
                          ) +
                          '-31'
                  );
                }
              }}
            >
              <div className='m-c1-12-m color-dark-secondary'>
                {isMobile(window.innerWidth)
                  ? Number(startDateMo.split('-')[1]) - 1 === 0
                    ? '12'
                    : Number(startDateMo.split('-')[1]) - 1
                  : Number(startDate.split('-')[1]) - 1 === 0
                  ? '12'
                  : Number(startDate.split('-')[1]) - 1}
                월 내역 보기
              </div>
            </div>
          </div>
        )}
      </div>
      <FooterMobile />
      {!temp && !search && <FooterBrowser />}
    </div>
  );
};

export default FreePoint;
