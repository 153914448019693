import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserService from '../service/checkAccount/CheckAccount';

import { hackleClient, initializeHackleClientEvent } from '../hackler';

type AuthContextProp = {
  getToken: () => string;
  onLogin: (token: string) => void;
  setRedirect: (path: string) => void;
  onLogout: () => Promise<void>;
  isLogin: () => boolean;
};

let context: AuthContextProp = {
  getToken: () => {
    return '';
  },
  setRedirect: (path) => {},
  onLogin: (token) => {},
  onLogout: async () => {
    return;
  },
  isLogin: () => {
    return false;
  },
};

export const AuthContext = React.createContext(context);

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const GetUserInfo = async (type?: string) => {
  try {
    const result = await UserService.checkInfo();
    if (result.success) {
      localStorage.setItem('username', result.name);
      localStorage.setItem('email', result.email);
      if (localStorage.accessToken) {
        UserService.visit();
      }
      hackleClient.setUserId(result.email);
      initializeHackleClientEvent(result);
      type && window.location.reload();
    }
  } catch (error) {
    console.log(error);
  }
};
