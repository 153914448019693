import Request from '../../@api/request';
// const baseURL = "http://localhost:3333/";
const baseURL = `${process.env.REACT_APP_HOST}`;

export const request = new Request(baseURL);

interface AuctionReportDAta {
  type: string;
  value: string;
}
export default class AuctionService {
  static list = async (
    type: string,
    isFarm: string,
    region: string,
    search: string,
    sort: string,
    limit: number,
    offset: number
  ) => {
    const result = await request.get(
      `salesAnalysis/list?type=${type}&isFarm=${isFarm}&region=${region}&search=${search}&sort=${sort}&limit=${limit}&offset=${offset}`
    );
    return result.data;
  };

  static detail = async (type: string, aIdx: string) => {
    const result = await request.get(`salesAnalysis/${type}/${aIdx}`);
    return result.data;
  };

  static spaceInfo = async (sIdx: string) => {
    const result = await request.get(`salesAnalysis/${sIdx}`);
    return result.data;
  };
  static save = async (data: AuctionReportDAta) => {
    const result = await request.post('salesAnalysis/report/data/save', data);
    return result.data;
  };
  static report = async (reportIdx: number) => {
    const result = await request.get(`salesAnalysis/report/data/${reportIdx}`);
    return result.data;
  };
}
