import LandInfo from "../../../../component/report/LandInfo";
import { RowTable3 } from "../../../../component/report/Row";
import { IReport_desc } from "../../Main";

const ReportDetail3 = ({ data }: { data: IReport_desc }) => {
  return (
    <LandInfo.BuildingInfo
      data={data}
      title="3.주택정보"
      RowTable={RowTable3}
    />
  );
};

export default ReportDetail3;
