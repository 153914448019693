import { useEffect, useMemo, useRef, useState } from "react";
import { TopNav2, TopNavNewBrowser } from "../../component/mobile/Topnav";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider2 from "@mui/material/Slider";
import _ from "lodash";
import { History } from "./History";
import Footer from "../../component/AIIntroduction/Footer";
import FooterBrowser from "../../component/browser/Footer";
import useDidMountEffect from "../../hooks/UseDidMountEffect";
import { Contents } from "./Contents";
import { Carousel } from "./Carousel";
import { Carousel2 } from "./Carousel2";
import { Carousel2Browser } from "./Carousel2_2";
import { Helmet } from "react-helmet";

const Profile = () => {
  const [initialWidth, setInitialWidth] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const ref2 = useRef<HTMLDivElement | null>(null);

  const ref3 = useRef<HTMLDivElement | null>(null);
  const ref4 = useRef<HTMLDivElement | null>(null);
  const [tempHeight, setTempHeight] = useState(0);

  const [imgWidth, setImgWidth] = useState(328);
  const [imgHeight, setImgHeight] = useState(180);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [myScroll, setMyScroll] = useState(0);
  const imageList = [
    "/asset/images/profile/carousel_img_1.png",
    "/asset/images/profile/carousel_img_2.png",
    "/asset/images/profile/carousel_img_3.png",
  ];
  const imageList2 = [
    "/asset/images/profile/leader1.svg",
    "/asset/images/profile/leader2.svg",
    "/asset/images/profile/leader3.svg",
    "/asset/images/profile/leader4.svg",
    "/asset/images/profile/leader5.svg",
    "/asset/images/profile/leader6.svg",
    "/asset/images/profile/leader7.svg",
    "/asset/images/profile/leader8.svg",
  ];
  const [isAnimated, setIsAnimated] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentSection2, setCurrentSection2] = useState(0);
  const sliderRef = useRef<Slider | null>(null);
  const sliderRef2 = useRef<Slider | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      setMyScroll(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    setInitialHeight(window.innerHeight);
    setInitialWidth(window.innerWidth);
    console.log(
      "innerwidth",
      window.innerWidth,
      "innerHeight",
      window.innerHeight
    );
  }, []);

  const [hasScrolled, setHasScrolled] = useState(false);
  const imageStyle = useMemo(() => {
    return {
      filter: hasScrolled ? "brightness(60%)" : "brightness(100%)",
      borderRadius: hasScrolled ? "0" : "16px",
      width: hasScrolled ? "100%" : "100%",
      padding: hasScrolled ? "0px" : "0px 16px",
      height: hasScrolled ? "100vh" : "",
      transform: hasScrolled ? "scale(1.2)" : "scale(1)",
      transition: hasScrolled
        ? "filter 1s, border-radius 1s, transform 1s"
        : "",
    };
  }, [hasScrolled]);
  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolled) {
        // 첫 스크롤 이벤트가 발생했을 때 실행할 코드
        console.log("첫 스크롤 이벤트 발생!");
        const scrollPosition = window.scrollY;
        if (!isAnimated && scrollPosition > 1079) {
          setIsAnimated(true);
        }
        // 필요한 처리를 추가하고, hasScrolled를 업데이트하여 더 이상 이벤트가 실행되지 않도록 합니다.
        setHasScrolled(true);

        // 1초 동안 다른 스크롤 이벤트를 무시하도록 setTimeout 사용
      }
    };

    window.addEventListener("scroll", handleScroll);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);

  useEffect(() => {
    console.log("myscroll", myScroll);
  });
  return (
    <div className="w-100per flex-column" style={{ overflowX: "hidden" }}>
      <TopNav2 text="회사소개" hamburger />
      <TopNavNewBrowser />
      <Helmet>
        <title>회사 소개</title>
      </Helmet>
      <div
        className={`flex-column  center-y gap-48 relative of-hidden ${
          hasScrolled ? `` : `padding-130-16 tablet-padding-240-40`
        }`}
        ref={ref2}
      >
        <div
          className={`flex-column gap-16 center-y ${
            hasScrolled ? `absolute z-10 ` : `relative`
          }`}
          style={{
            top: hasScrolled ? `${window.innerHeight / 2 - 100}px` : "",
            // transition: "top 1s",
            transform: myScroll ? `translateY(50px)` : "translateY(0)",
            // opacity: myScroll ? 1 : 0,
            transition: "opacity 1s ease-out, transform 1s ease-out",
          }}
        >
          <div
            className={`font-bold f-size-20 line-h-29 browser-f-size-40 browser-line-h-58 ${
              hasScrolled ? `color-FFFFFF` : `color-dark-primary`
            }`}
          >
            트랜스파머는
          </div>
          <div
            className={`font-medium f-size-18 line-h-26 browser-f-size-32 browser-line-h-46 ${
              hasScrolled ? `color-FFFFFF` : `color-dark-primary`
            } text-center`}
          >
            AI 가이드로 농업의 문턱을 낮추고 <br /> 누구나 참여할 수 있는 농촌을
            만들어갑니다.
          </div>
        </div>
        <img style={imageStyle} src="/asset/images/profile/profile_img_1.png" />
      </div>
      <div className="flex-column browser-gap-140 browser-padding-y-176">
        <div className="padding-56-16 flex-column gap-48 flex-center tablet-padding-56-40">
          {myScroll > 167 ? (
            <div
              className={`font-bold f-size-18 line-h-26 color-dark-primary browser-f-size-32 browser-line-h-46 ${
                myScroll > 167 && `fade-in-up`
              }`}
              ref={ref3}
            >
              농촌경제 DX 플랫폼 트랜스파머
            </div>
          ) : (
            <div
              style={{
                height: "300px",
              }}
            >
              &nbsp;
            </div>
          )}
          {myScroll > 312 ? (
            <Carousel scroll={myScroll} />
          ) : (
            <div
              style={{
                height: "400px",
              }}
            >
              &nbsp;
            </div>
          )}
        </div>
        <div className="flex-column bg-light-gray-100 padding-y-56 gap-48 flex-center w-100per ">
          {window.innerWidth >= 1920
            ? myScroll > 1010 && (
                <div className="font-bold f-size-18 line-h-26 color-dark-primary fade-in-up browser-f-size-32 browser-line-h-46">
                  똑똑한 농촌생활을 위한 AI 활용 가이드
                </div>
              )
            : window.innerWidth >= 1024
            ? myScroll > 827 && (
                <div className="font-bold f-size-18 line-h-26 color-dark-primary fade-in-up browser-f-size-32 browser-line-h-46">
                  똑똑한 농촌생활을 위한 AI 활용 가이드
                </div>
              )
            : myScroll > 811 && (
                <div className="font-bold f-size-18 line-h-26 color-dark-primary fade-in-up browser-f-size-32 browser-line-h-46">
                  똑똑한 농촌생활을 위한 AI 활용 가이드
                </div>
              )}
          <Contents myScroll={myScroll} />
        </div>
      </div>
      {/* {myScroll > (window.innerWidth > 1024 ? 2849 : 3046) ? (
        <div
          className={`flex-column flex-center w-100per padding-56-16 tablet-padding-56-40 ${
            myScroll > (window.innerWidth > 1024 ? 2849 : 3046) && `fade-in-up `
          }`}
        >
          <div className="font-bold f-size-18 line-h-26 color-dark-primary">
            Team Leader
          </div>
          <div className="flex-column w-100per center-y ">
            {window.innerWidth >= 1024 ? <Carousel2Browser /> : <Carousel2 />}
          </div>
        </div>
      ) : (
        <div style={{ height: "500px" }} />
      )} */}
      <div
        className={`flex-column flex-center w-100per padding-56-16 tablet-padding-56-40`}
      >
        <div className="font-bold f-size-18 line-h-26 color-dark-primary">
          Team Leader
        </div>
        <div className="flex-column w-100per center-y ">
          {window.innerWidth >= 1920
            ? myScroll > 3803 && <Carousel2Browser scroll={myScroll} />
            : window.innerWidth >= 1024
            ? myScroll > 3409 && <Carousel2Browser scroll={myScroll} />
            : myScroll > 3439 && <Carousel2 scroll={myScroll} />}
        </div>
      </div>
      {myScroll >
      (window.innerWidth >= 1920
        ? 4420
        : window.innerWidth >= 1024
        ? 4047
        : 4172) ? (
        <History scroll={myScroll} />
      ) : (
        <div style={{ height: "500px" }} />
      )}

      <Footer />
      <FooterBrowser />
    </div>
  );
};

export default Profile;
