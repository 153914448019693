import { createSlice } from "@reduxjs/toolkit";

const initialTopnav = {
  menu: "farm",
};

const topnavSlice = createSlice({
  name: "menu",
  initialState: initialTopnav,
  reducers: {
    ChangeMenu(state, action) {
      state.menu = action.payload;
    },
  },
});

export const { ChangeMenu } = topnavSlice.actions;
export default topnavSlice.reducer;
