const SelectBox = ({ type, setType, title, arr, num }: any) => {
  return (
    <div className="flex-column gap-8 w-100per">
      <div className="flex-row gap-4 start-y">
        <div className="font-bold f-size-14 line-h-20 color-text-primary ">
          {num}
        </div>
        <div className="font-bold f-size-14 line-h-20 color-text-primary ">
          {title}
        </div>
      </div>
      <div className="flex-row gap-12">
        {arr.map((el: any) => (
          <div
            className={
              (type === el.type ? "bg-primary-600" : "bg-light-gray-200") +
              " flex-1 flex-center padding-12-12-12-8 border-radius-8 hover"
            }
            onClick={() => {
              setType(el.type);
            }}
          >
            <div
              className={`f-size-14 line-h-20 ${
                type === el.type
                  ? "color-white font-bold"
                  : "font-medium color-disabled"
              }`}
            >
              {type === el.type ? "✓ " : ""}
              {el.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectBox;
