import { Navigate, useNavigate } from "react-router-dom";
import { Font } from "../../asset/styles/browser/font/font";
import { FullContainer } from "../../asset/styles/mobile/common/Common";
import {
  CheckBoxContainer,
  SurveyContainer,
} from "../../asset/styles/mobile/survey/survey";
import SignUpModal from "../../component/mobile/SignupModal";
import { surveyCategory } from "../../dummy/dummy";
import { useDispatch, useSelector } from "react-redux";
import { ModalOn } from "../../store/signUpInfoSlice";

const Survey = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => {
    return state.signUpInfo;
  });
  return (
    <FullContainer>
      <SignUpModal />
      <SurveyContainer>
        <Font fontWeight="700" fontSize="30" lineHeight="30" color="black">
          Transfarmer
        </Font>
        <Font fontWeight="700" fontSize="20" lineHeight="30" color="black">
          현재 AI 분석 서비스 무료 이용권 3회
        </Font>
        <Font fontWeight="700" fontSize="20" lineHeight="30" color="black">
          가입 경로에 응답해 주시면, AI 분석 서비스 무료 이용권 1회를 더
          드립니다.
        </Font>
        <CheckBoxContainer>
          {surveyCategory.map((item) => {
            return (
              <Font
                fontWeight="700"
                fontSize="21"
                lineHeight="40"
                color="black"
                onClick={() => {
                  dispatch(ModalOn(true));
                }}
              >
                {item}
              </Font>
            );
          })}
          <Font
            fontWeight="700"
            fontSize="25"
            lineHeight="40"
            color="red"
            marginTop="30"
            onClick={() => {
              navigate("/");
            }}
          >
            ○ 다음에 하겠습니다.
          </Font>
        </CheckBoxContainer>
      </SurveyContainer>
    </FullContainer>
  );
};

export default Survey;
