import { createSlice } from "@reduxjs/toolkit";
import { truncate } from "fs";

const InitialOnOff = {
  PopUp: undefined,
};

const OnOffSlice = createSlice({
  name: "type",
  initialState: InitialOnOff,
  reducers: {
    SetPopUpOn(state, action) {
      state.PopUp = action.payload;
    },
  },
});

export const { SetPopUpOn } = OnOffSlice.actions;
export default OnOffSlice.reducer;
