import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../component/mobile/Button";
import { isMobile } from "../../component/function/function";
import { useState } from "react";
import { PopUp3 } from "./PopUp3";

const Complete = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [onPopup, setOnPopUp] = useState(false);
  return (
    <div className='w-100per flex-column browser-center-y browser-h-100vh bg-F0F0F0'>
      <div className='flex-column w-1024-max-w-768 browser-w-100per browser-h-100per bg-FFFFFF browser-of-scroll'>
        {onPopup && <PopUp3 setOnPopUp2={setOnPopUp} />}
        <div className='flex padding-13-16 w-1024-padding-13-40 w-100per end-x'>
          {/* <div className="padding-4 flex-center border-radius-4 border-171E26">
            <div className="font-medium f-size-12 line-h-14 color-disabled">
              문의하기
            </div>
          </div> */}
        </div>
        <div
          className='flex-column  browser-h-100per'
          style={{ justifyContent: "space-between" }}
        >
          <div className='flex-column'>
            <div className='flex-column gap-24 padding-x-16 browser-flex-center'>
              <div className='flex-column center-y gap-16'>
                <div className='flex-center w-56 h-56 bg-primary-300 border-radius-999'>
                  <img
                    alt='포인트충전완료'
                    src='/asset/images/check/Check24White.svg'
                    className='w-30 '
                  />
                </div>
                <div className='font-bold f-size-18 line-h-26 color-dark-primary text-center'>
                  {location.state.type === 1
                    ? "포인트를 충전해"
                    : "이용권을 구매해"}
                  주셔서 감사합니다
                </div>
                <div className='font-medium f-size-14 line-h-20 color-dark-primary text-center'>
                  {location.state.type === 1
                    ? "충전하신 포인트는"
                    : "구매하신 이용권은"}{" "}
                  입금이 확인되는대로
                  <br />
                  회원님의 연락처로 확인 안내 문자를 보내드리겠습니다.
                </div>
              </div>
              <img
                alt='포인트충전완료'
                src='/asset/images/point/process_point.svg'
                className='w-100per w-1024-max-w-310'
              />
            </div>
            <div className='flex-column gap-16  mt-42  padding-x-16 w-1024-padding-x-40 gap-16'>
              <div className='flex-column'>
                <div className='flex-row space-between border-b-171E26 border-t-171E2640 padding-y-16'>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary'>
                    {location.state.type === 1 ? "현재 포인트" : "결제 이용권"}
                  </div>
                  <div className='font-medium f-size-14 line-h-20 color-dark-primary'>
                    {location.state.type === 1
                      ? Number(location.state.currentPoint).toLocaleString() +
                        "P"
                      : "+" + location.state.month + "개월"}
                  </div>
                </div>
                <div className='flex-row space-between border-b-171E26 padding-y-16'>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary'>
                    {location.state.type === 1
                      ? "충전 포인트"
                      : "결제 후 이용기간"}
                  </div>
                  <div className='font-medium f-size-14 line-h-20 color-dark-primary'>
                    {location.state.type === 1
                      ? "+" +
                        Number(location.state.chargePoint).toLocaleString() +
                        "P"
                      : "~" + location.state.period + "까지"}
                  </div>
                </div>
                <div className='flex-row space-between padding-y-16'>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary'>
                    {location.state.type === 1
                      ? "충전 후 포인트"
                      : "이용권 금액"}
                  </div>
                  <div className='font-bold f-size-14 line-h-20 color-dark-primary'>
                    {location.state.type === 1
                      ? Number(
                          location.state.currentPoint +
                            location.state.chargePoint
                        ).toLocaleString() + "P"
                      : Number(location.state.price).toLocaleString() + "원"}
                  </div>
                </div>
              </div>
              <div
                className='padding-16 border-radius-8 bg-light-gray-100 flex-row space-between pointer center-y'
                onClick={() => {
                  navigate("/mypage");
                }}
              >
                <div className='mobile-body-2-medium color-dark-primary'>
                  마이페이지에서 충전 포인트 확인하기
                </div>
                <img src='/asset/images/arrow/ArrowGray.svg' alt='화살표' />
              </div>
            </div>
          </div>
          <div className='flex-column'>
            <div className='mt-16 flex-center flex-row gap-8'>
              {/* <div className='font-bold f-size-12 line-h-14 color-dark-secondary'>
                실시간 누적 검색 농지 면적
              </div>
              <div className='font-bold f-size-12 line-h-14 color-primary-300'>
                00,000,000,000 ㎡
              </div> */}
            </div>
            <div className='flex-center padding-16 w-100per '>
              <div className='w-100per flex-center bg-DCF4E9 border-radius-8 w-1024-max-w-688'>
                <img
                  className='border-radius-8 w-1024-max-w-688 pointer'
                  src={
                    isMobile(window.innerWidth)
                      ? "/asset/images/banner/point_banner3.svg"
                      : "/asset/images/banner/point_banner3_web.svg"
                  }
                  alt='배너'
                  onClick={() => {
                    isMobile(window.innerWidth)
                      ? navigate("/point")
                      : setOnPopUp(true);
                  }}
                />
              </div>
            </div>

            <div className='padding-16-16-48-16 flex w-1024-padding-16-40-48-40'>
              <Button
                on={true}
                description='확인'
                onClick={() => {
                  if (sessionStorage.getItem("searchPointRecharge") === "Y") {
                    navigate(
                      `/${sessionStorage.getItem(
                        "tab"
                      )}/map?area=${sessionStorage.getItem("pointAddress")}`
                    );
                  } else {
                    navigate("/");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Complete;
