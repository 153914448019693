export const RechargeFail = () => {
  return (
    <div className='w-100per h-100vh bg-F0F0F0 flex-center'>
      <div
        className='center-y flex-column space-between'
        style={{ width: '768px', height: '100%', backgroundColor: '#FFFFFF' }}
      >
        <div className='flex-column w-100per'>
          <div className='end-x w-100per' style={{ padding: '13px 40px' }}>
            <div className='border-radius-4 padding-4 flex-center border-171E26 fit-content'>
              <div>문의하기</div>
            </div>
          </div>
          <div className='flex-center flex-column gap-16 padding-24'>
            <img
              className='w-56'
              src='/asset/images/warn/WarnWithGrayCircle.svg'
            />
            <div className='flex-column gap-8'>
              <div className='m-h2-24-b color-dark-primary text-center'>
                10,000P
              </div>
              <div className='m-t2-18-b color-dark-primary text-center'>
                충전 실패
              </div>
            </div>
          </div>
          <div className='w-100per padding-x-16 flex-column '>
            <div
              className='flex-column w-100per gap-48 padding-32 flex-center'
              style={{ borderTop: '1px solid #171E2640' }}
            >
              <div className='m-b2-14-m color-dark-secondary'>
                이용에 불편을 드려 죄송합니다. <br />
                결제 과정이 정상 처리되지 않았습니다. <br />
                확인 후 다시 시도해 주시기 바랍니다.
              </div>
              <div className='bg-F7F8F9 border-radius-8 padding-16 flex-center w-100per'>
                <div className='m-b2-14-m color-text-tertiary'>
                  코드 : <span className='color-dark-primary'>999</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='border-t-171E26 padding-16-16-48-16 flex-center w-100per'>
          <div className='flex-center w-100per bg-primary border-radius-8 padding-14-16 pointer'>
            <div className='m-42-18-b color-FFFFFF'>확인</div>
          </div>
        </div>
      </div>
    </div>
  );
};
