import LandService from '../../service/land/landApi';

export default class LandController {
  static coordinate = async (
    panToAndMarker: Function,
    drawCircle: Function,
    query: string,
    isSearch: boolean
  ) => {
    try {
      if (query) {
        const res = await LandService.coordinate(query);
        drawCircle({
          La: Number(res.coordinate.xpos),
          Ma: Number(res.coordinate.ypos),
        });
        panToAndMarker(
          {
            La: Number(res.coordinate.xpos),
            Ma: Number(res.coordinate.ypos),
          },
          isSearch
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  static coordinate2 = async (
    panToAndMarker: Function,

    query: string,
    isSearch: boolean
  ) => {
    try {
      if (query) {
        const res = await LandService.coordinate(query);

        panToAndMarker(
          {
            La: Number(res.coordinate.xpos),
            Ma: Number(res.coordinate.ypos),
          },
          isSearch
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
}
