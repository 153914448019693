import Swal from "sweetalert2";
import html2canvas from "html2canvas";
import JsPdf from "jspdf";
export const TypeHandler = (value: string) => {
  if (value === "R") {
    return "역경매";
  } else if (value === "E") {
    return "기타";
  } else if (value === "G") {
    return "일반";
  } else if (value === "C") {
    return "시공";
  } else if (value === "F") {
    return "농지";
  }
};

export const StatusHandler = (value: string) => {
  if (value === "B") {
    return "답변전";
  } else if (value === "A") {
    return "답변중";
  } else if (value === "S") {
    return "답변완료";
  } else if (value === "D") {
    return "삭제됨";
  }
};

export const transNum = (num: number, trim?: any, price?: any) => {
  if (num === null) {
    return;
  }
  if (typeof num !== "number") {
    return 0;
  }

  if (num >= 100000000) {
    return `${(Math.round(num / 10000000) / 10).toLocaleString()}${
      trim ? "" : " "
    }억`;
  } else if (num >= 10000) {
    return price
      ? `${(Math.round(num / 1000) / 10).toLocaleString()}${trim ? "" : " "}만`
      : `${Math.round(num / 10000).toLocaleString()}${trim ? "" : " "}만`;
  } else if (num <= -100000000) {
    return `${(
      -1 *
      (Math.round((num * -1) / 10000000) / 10)
    ).toLocaleString()}${trim ? "" : " "}억`;
  } else if (num <= -10000) {
    return price
      ? `${(Math.round(num / 1000) / 10).toLocaleString()}${trim ? "" : " "}만`
      : `${Math.round(num / 10000).toLocaleString()}${trim ? "" : " "}만`;
  } else {
    return price
      ? `${(num / 10000).toFixed(1).toLocaleString()}${trim ? "" : " "}만`
      : `${Math.round(num).toLocaleString()}${trim ? "" : " "}`;
  }
};

// export const isMobile = () => {
//   return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//     navigator.userAgent
//   );
// };

export const isMobile = (innerWidth: any) => {
  // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //   navigator.userAgent
  // );
  if (innerWidth < 1024) {
    return true;
  } else {
    return false;
  }
};

export const alertCustom = (text: string, type: string) => {
  Swal.fire({
    confirmButtonColor: type === "1" ? "#1E9B6B" : "#18181B",
    text: text,
    confirmButtonText: "확인",
    customClass: {
      popup: "browser-alert-font alert-font",
      confirmButton: "browser-alert-btn-font alert-btn-font",
    },
  });
};

export const handleKeyDown = (event: any) => {
  if (event.keyCode === 32) {
    event.preventDefault();
  }
};

// 현재시간
export const formatDateTime = (date: any) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

//파라미터로 documentID 와 파일명을 넣어주면된다.
async function printPDF(id: string, name: string) {
  html2canvas(document.getElementById(id)!, {
    onclone: (document: any) => {
      document.getElementById("print").style.visibility = "hidden";
    },
  })
    .then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new JsPdf("p", "mm");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var position = 0;
      var pageHeight = 295;
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      //화면이 넘칠경우 다음페이지 생성
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${name}.pdf`);
    })
    .catch((error) => console.log(error));
}
export default printPDF;
