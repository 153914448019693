import { RowSectionTitle } from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import { transNum } from '../../../../component/function/function';
// import { ITotalData } from "../../Main";
import { useEffect, useState } from 'react';
import { Tooltip2 } from '../../../../component/common/tooltip';
import UserService from '../../../../service/checkAccount/CheckAccount';
import { useSelector } from 'react-redux';
import { BoxCard1, BoxGreen1 } from '../../../../component/report/Box';
import SignController from '../../../../controller/sign/SignController';

const Summary1 = ({ data }: { data: any }) => {
  const [name, setName] = useState('');

  const state2 = useSelector((state2: any) => {
    return state2.type.priceType === 'land';
  });

  useEffect(() => {
    SignController.getName(setName);
  }, []);

  const Column2 = ({ title, content, index }: any) => (
    <div
      className={`flex-row  ${
        index !== '3' ? `padding-b-10 border-b-171E26` : ``
      }`}
    >
      <div className='w-50per font-medium f-size-16 line-h-26 color-text-tertiary'>
        {title}
      </div>

      <div className={`font-bold f-size-16 line-h-26 color-text-secondary`}>
        {content}
        {index !== '1' && '평'}
      </div>
    </div>
  );
  const [areaType, setAreaType] = useState('P');
  const [tooltip, setTooltip] = useState(false);

  return (
    <div className='browser-bg-FFFFFF'>
      <RowSectionTitle>
        <TextSectionTitle>
          {data && data.isFarm === 'Y' ? 'AI 농지 분석' : 'AI 주택 분석'}
        </TextSectionTitle>
      </RowSectionTitle>
      <div className='padding-16'>
        <div className='flex-column padding-16 bg-blue-gray-100 border-radius-8 gap-8'>
          {/* {data && data.isFarm === "Y" ? (
            <Column2
              title="AI 농지점수"
              content={`${
                Math.floor(Number(data && data.avgScore) * 10) / 10
              }점`}
              index="1"
            />
          ) : (
            ""
          )} */}
          <div className='flex-row padding-b-10 border-b-171E26'>
            <div className='w-50per font-medium f-size-16 line-h-26 color-text-tertiary'>
              {data && data.isFarm === 'Y' ? 'AI 농지추정가' : 'AI 추정가'}
            </div>
            {Number(data.estimated) !== 0 ? (
              <div className='flex-column'>
                <div
                  className={`font-bold f-size-20 line-h-33 color-primary-500`}
                >
                  {transNum(data && data.estimated, true)}원
                </div>
                <div className='font-medium f-size-14 line-h-20 color-text-tertiary'>
                  {transNum(
                    data &&
                      data.estimated /
                        (areaType !== 'P' ? data.area : data.pyeong)
                  )}
                  {areaType !== 'P' ? '원/m²' : '원/평'}
                </div>
              </div>
            ) : (
              <div className='mobile-body-1-bold color-disabled'>
                심층분석 문의 필요
              </div>
            )}
          </div>
          <div
            className='flex-row padding-b-10 border-b-171E26'
            onClick={() => console.log(2)}
          >
            <div className='w-50per font-medium f-size-16 line-h-26 color-text-tertiary'>
              실거래가
            </div>
            <div className='flex-column center-x'>
              <div
                className={
                  data && data.tradePrice
                    ? `font-bold f-size-20 line-h-33 color-primary-500`
                    : 'mobile-body-1-bold color-disabled'
                }
              >
                {data && data.tradePrice
                  ? transNum(data && data.tradePrice) + '원'
                  : '거래 이력 없음'}
              </div>
              <div className='font-medium f-size-14 line-h-20 color-text-tertiary'>
                {data &&
                  data.tradeDate &&
                  `${data && data.tradeDate.substring(0, 4)}.${
                    data && data.tradeDate.substring(4, 6)
                  }.${data && data.tradeDate.substring(6, 8)}`}
              </div>
            </div>
          </div>{' '}
          <Column2 title='용도지역' content={data && data.zoning} index='1' />
          <div
            onClick={() => console.log(2)}
            className={`flex-row  ${`padding-b-10 border-b-171E26`}`}
          >
            <div className='w-50per font-medium f-size-16 line-h-26 color-text-tertiary'>
              토지 면적
            </div>

            <div
              className={`font-bold f-size-16 line-h-26 color-text-secondary`}
            >
              <div className='flex-row gap-16'>
                {data && areaType !== 'P' ? data.area : data.pyeong}{' '}
                {areaType !== 'P' ? 'm²' : '평'}
                <div
                  className='hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary'
                  onClick={() => {
                    console.log(areaType);
                    setAreaType((prev) => (prev !== 'P' ? 'P' : 'M'));
                  }}
                >
                  <img src='/asset/images/arrow/Refresh20.svg' alt='' />
                  {areaType === 'P' ? 'm²' : '평'}
                </div>
              </div>
              {'1' !== '1' && '평'}
            </div>
          </div>
          {/* <Column2
            title="토지 면적"
            content={
              <div className="flex-row gap-16">
                {data && areaType !== "P" ? data.area : data.pyeong}{" "}
                {areaType !== "P" ? "m²" : "평"}
                <div
                  className="hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
                  onClick={() => {
                    console.log(areaType);
                    setAreaType((prev) => (prev !== "P" ? "P" : "M"));
                  }}
                >
                  <img src="/asset/images/arrow/Refresh20.svg" alt="" />
                  {areaType === "P" ? "m²" : "평"}
                </div>
              </div>
            }
            index="1"
          /> */}
          {data && data.isFarm === 'Y' ? (
            ''
          ) : (
            <Column2
              title='주택 면적'
              content={
                data && data.buildingPyeong ? (
                  `${data.buildingPyeong}평`
                ) : (
                  <div className='mobile-body-1-bold color-disabled'>
                    주택 면적 없음
                  </div>
                )
              }
              index='1'
            />
          )}
          <div className='flex-column'>
            <div className='relative'></div>
            <div className='flex-row gap-4 end-x center-y  relative'>
              <div style={{ position: 'absolute', zIndex: 10 }}>
                <Tooltip2
                  text={
                    <>
                      <p className='line-h-14 text-left'>
                        추정가는 트랜스파머가 약 17억개 데이터 분석을 기반으로
                        실거래와 인근 유사 거래 사례를 고려하여 해당 농지(주택)
                        가치를 계산한 값입니다.
                      </p>
                    </>
                  }
                  text2={
                    <div>
                      <br />
                      <p className='line-h-14'>{'<유의사항>'}</p>
                      <br />
                      <ol className='line-h-14 mt-l-10'>
                        <li>
                          추정가는 실제 시장에서 거래되는 가격과는 다소 차이가
                          있을 수 있습니다. 참고용으로만 사용하시길 권장하며,
                          이를 기반으로 행해진 거래 손실 등에 대해 당사는
                          책임지지 않습니다
                        </li>
                        <br />
                        <li>
                          추정가를 무단 크롤링하거나 사용할 경우, 법적 책임을
                          물을 수 있습니다. 사용 관련 문의는{' '}
                          <a
                            href='mailto:biz@transfarmer.co.kr'
                            className='color-FFFFFF'
                          >
                            biz@transfarmer.co.kr
                          </a>
                          로 연락 바랍니다.
                        </li>
                      </ol>
                    </div>
                  }
                  top='23'
                  left='-236'
                  width
                  on={tooltip}
                  off={() => {
                    setTooltip(false);
                  }}
                />
              </div>
              <img
                className='hover'
                src='/asset/images/warn/Warn20.svg'
                alt=''
                onClick={() => {
                  setTooltip(true);
                }}
              />
              <div className='font-medium f-size-12 line-h-14 color-text-tertiary'>
                법적한계 안내 보기
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <div className='padding-8-16 gap-10'>
          <BoxGreen1>
            {data && data.estimated > 0 ? (
              <>
                <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
                  해당 {data.isFarm === 'Y' ? '농지' : '주택'}의 추정가는{' '}
                  {` ${transNum(data && data.estimated)}원`}
                  으로
                </div>
                <div
                  className='font-bold f-size-16 line-h-24 '
                  style={{
                    color: data.officialDiff > 1 ? '#1E9B6B' : '#d62554',
                  }}
                >
                  공시지가
                  {data.officialDiff > 1 ? (
                    <span>
                      보다 <br /> {Math.round(data.officialDiff * 10000) / 100}%
                      높습니다.
                    </span>
                  ) : data.officialDiff < 1 ? (
                    <span>
                      보다 <br /> {Math.round(data.officialDiff * 10000) / 100}%
                      낮습니다.
                    </span>
                  ) : (
                    '와 같습니다.'
                  )}
                </div>
              </>
            ) : (
              <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
                해당 {data.isFarm === 'Y' ? '농지' : '주택'}의 추정가는 산출할
                수 없습니다.
              </div>
            )}
          </BoxGreen1>
        </div>

        <div className='padding-8-16 gap-10'>
          <BoxGreen1>
            {data && data.estimated > 0 && data.avgValue > 0 ? (
              <>
                <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
                  해당 {data.isFarm === 'Y' ? '농지' : '주택'}의 평당 추정가는{' '}
                  {Math.round(
                    Number(data && data.estimated / data.pyeong)
                  ).toLocaleString()}
                  원으로
                </div>
                <div
                  className='font-bold f-size-16 line-h-24 color-primary-500'
                  style={{
                    color:
                      Number(
                        data && Number(data && data.estimated / data.pyeong)
                      ) < Number(data && data.avgValue)
                        ? '#d62554'
                        : '#1E9B6B',
                  }}
                >
                  인근 농지 평당 가격{' '}
                  {Math.round(Number(data && data.avgValue)).toLocaleString()}원
                  {Number(
                    data && Number(data && data.estimated / data.pyeong)
                  ) > Number(data && data.avgValue) ? (
                    <span>
                      의 <br />
                      {data && Math.round(data.difference * 100 * 100) / 100}%
                      입니다.
                    </span>
                  ) : Number(
                      data && Number(data && data.estimated / data.pyeong)
                    ) < Number(data && data.avgValue) ? (
                    <span>
                      의 <br />
                      {data && Math.round(data.difference * 100 * 100) / 100}%
                      입니다.
                    </span>
                  ) : (
                    '과 같습니다.'
                  )}
                </div>
              </>
            ) : data.estimated > 0 ? (
              <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
                주변 거래 사례가 없습니다
              </div>
            ) : (
              <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
                해당 {data.isFarm === 'Y' ? '농지' : '주택'}의 평당 추정가는
                산출할 수 없어 인근 농지 평당가와 비교할 수 없습니다.
              </div>
            )}
          </BoxGreen1>
        </div>
      </>
    </div>
  );
};

export default Summary1;
