const Pagination = ({
    currentPage,
    setCurrentPage,
    pageList,
    totalPages,
}: {
    currentPage: number;
    setCurrentPage: any;
    pageList: (string | number)[];
    totalPages: number;
}) => {
    return (
        <div className="flex-row w-100per center-x gap-24 center-y">
            {currentPage > 1 ? (
                <div
                    className="bg-light-gray-200 border-radius-999 center-x center-y hover"
                    style={{ width: '32px', height: '32px' }}
                    onClick={() => setCurrentPage((prev: any) => prev - 1)}
                >
                    <img src="/asset/images/arrow/ArrowLeftPage.svg" alt="" />
                </div>
            ) : (
                <div
                    className="bg-light-gray-200 border-radius-999 center-x center-y hover"
                    style={{
                        width: '32px',
                        height: '32px',
                        opacity: '30%',
                    }}
                >
                    <img src="/asset/images/arrow/ArrowLeftPage.svg" alt="" />
                </div>
            )}
            {pageList.map((el, key) => (
                <div
                    key={key}
                    className={
                        'font-bold f-size-16 line-h-26 ' +
                        (typeof el === 'number' ? 'hover ' : '') +
                        (el === currentPage ? ' color-text-primary' : ' color-text-tertiary')
                    }
                    onClick={() => {
                        if (typeof el === 'number') setCurrentPage(el);
                    }}
                >
                    {el}
                </div>
            ))}

            {totalPages > currentPage ? (
                <div
                    className="bg-light-gray-200 border-radius-999 center-x center-y hover"
                    style={{
                        width: '32px',
                        height: '32px',
                        transform: 'rotate(180deg)',
                    }}
                    onClick={() => setCurrentPage((prev: any) => prev + 1)}
                >
                    <img src="/asset/images/arrow/ArrowLeftPage.svg" alt="" />
                </div>
            ) : (
                <div
                    className="bg-light-gray-200 border-radius-999 center-x center-y hover"
                    style={{
                        width: '32px',
                        height: '32px',
                        transform: 'rotate(180deg)',
                        opacity: '30%',
                    }}
                >
                    <img src="/asset/images/arrow/ArrowLeftPage.svg" alt="" />
                </div>
            )}
        </div>
    );
};

export default Pagination;
