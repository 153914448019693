import Search from './../../../asset/images/search/search.svg';
import Close from './../../../asset/images/close/close.svg';
import locationImg from './../../../asset/images/main/location.svg';
import locationNoImg from './../../../asset/images/main/locationNoImg.svg';
import { useEffect, useRef, useState } from 'react';
import useDidMountEffect from '../../../hooks/UseDidMountEffect';
import LandService from '../../../service/land/landApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeSideType } from '../../../store/typeSlice';
import { SideTopnav } from '../SideTopnav';
import Mypage from '../../../pages/mypage/Mypage';
import { isMobile } from '../../function/function';
import TopBanner from '../../banner/TopBanner';
import AuctionService from '../../../service/auction/Auction';
import GuideBannerWeb from '../../banner/GuideBannerWeb';
interface ListType {
  address: string;
  gugun: string;
  lat: null;
  lon: null;
  isFarm: string;
  jibunAddr?: string;
  detail?: string;
}
interface PropsType {
  location: any;
}
const SideSearch = ({
  location,
  on,
  recycle,
  noList,
  isFarm,
  banner,
  errorMessage,
}: any) => {
  const navigate = useNavigate();

  const ref = useRef<HTMLInputElement>(null);
  const temp = useRef<HTMLDivElement>(null);
  const locationHook = useLocation();
  const [address, setAddress] = useState('');
  const [onList, setOnList] = useState(false);
  const [list, setList] = useState<ListType[]>([]);
  const [hover, setHover] = useState(0);
  const [onGuideBanner, setOnGuideBanner] = useState(true);
  const [onNoLand, setOnNoLand] = useState(true);
  const [recentSearch, setRecentSearch] = useState(false); // input에 focus되면 true blur되면 false
  const state = useSelector((state: any) => {
    return state.loading;
  });
  const state2 = useSelector((state: any) => {
    return state.type;
  });

  // console.log(state2);
  const searchApi = async () => {
    try {
      let result: { searchList: Array<ListType> | Array<never> } | any;
      if (locationHook.pathname.startsWith('/auction/map')) {
        result = await LandService.search2(address);
      } else {
        if (
          locationHook.pathname.startsWith('/house/map') ||
          state2.priceType === 'house'
        ) {
          result = await LandService.searchRoad1st(address);
        } else {
          result = await LandService.search(address);
        }
      }
      setList(result.searchList);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteSearch = async (address: string, service: string) => {
    const data = {
      address: address,
      service: service,
    };
    try {
      const result = await LandService.deleteSearchList(data);
      console.log('delete', result);
      searchApi();
    } catch (error) {
      console.log(error);
    }
  };
  const deleteSearchAll = async () => {
    try {
      const result = await LandService.deleteSearchListAll();
      searchApi();
      console.log('deleteAll', result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      sessionStorage.setItem('type', 'landinfo');
      navigate(
        `${
          locationHook.pathname.slice(-1) === '/'
            ? locationHook.pathname.slice(0, -1)
            : locationHook.pathname
        }?area=${address}`
      );
    }
  };
  useEffect(() => {
    searchApi();
  }, [address]);

  useEffect(() => {
    console.log('onGuideBanner', onGuideBanner);
  }, [onGuideBanner]);
  useEffect(() => {
    setAddress(location);
    // if (locationHook.pathname !== "/auction/map") {
    // }
    setOnNoLand(true);
  }, [location]);

  useEffect(() => {
    // 컴포넌트가 마운트되면 input 요소에 포커스를 설정
    isMobile(window.innerWidth) && ref.current?.focus();
  }, []); // 빈 배열은 한 번만 실행하도록 합니다.

  return (
    <div
      className={`flex-column  h-100per browser ${
        recycle ? `` : ` sidebar-box-shadow`
      }`}
      // browser-w-500
      style={{
        height: recycle ? 'fit-content' : '100%',
      }}
    >
      <div className='flex-row h-100per'>
        {on && <SideTopnav />}

        <div className='flex-column ' style={{ width: '375px' }}>
          {/* <div className="sidebar-bg  padding-x-18 flex-center min-h-106">
            <div className="flex-row h-48 border-radius-8 bg-white padding-14 space-between w-100per"> */}
          {/* // w-326 */}
          <div
            className={`padding-x-18 flex-center min-h-80 w-100per ${
              recycle ? `bg-FFFFFF` : `sidebar-bg`
            }`}
          >
            <div
              ref={temp}
              className={`flex-row h-48 border-radius-8 bg-white padding-14 space-between  w-100per ${
                recycle ? `border-1E9B6B-2px` : ``
              }`}
            >
              <div className={`flex-row w-100per ${recycle ? `` : ``}`}>
                <img className='w-20' src={Search} />
                <input
                  ref={ref}
                  className='myCustomInput border-none font-medium f-size-16 line-h-26 color-text-171E2656 w-100per '
                  placeholder={
                    (locationHook.pathname.slice(-1) === '/'
                      ? locationHook.pathname.slice(0, -1)
                      : locationHook.pathname) === '/house/map'
                      ? '주소를 입력하세요'
                      : (locationHook.pathname.slice(-1) === '/'
                          ? locationHook.pathname.slice(0, -1)
                          : locationHook.pathname) === '/auction/map'
                      ? isFarm === 'Y'
                        ? '농지 주소를 입력하세요'
                        : '주소를 입력하세요'
                      : (locationHook.pathname.slice(-1) === '/'
                          ? locationHook.pathname.slice(0, -1)
                          : locationHook.pathname) === '/price/map'
                      ? state2.priceType === 'land'
                        ? '농지 주소를 입력하세요'
                        : '주소를 입력하세요'
                      : '농지 주소를 입력하세요.'
                  }
                  onFocus={() => {
                    setOnGuideBanner(false);
                    setRecentSearch(true);
                  }}
                  onBlur={() => {
                    setOnGuideBanner(true);
                    setTimeout(() => {
                      setRecentSearch(false);
                    }, 300);
                  }}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    // if (e.target.value !== "") {
                    //   setAddress(e.target.value);
                    // }
                    setAddress(e.target.value);
                    setOnNoLand(false);
                    // console.log("target", e.target.value, "address", address);
                  }}
                  value={address}
                />
              </div>
              <img
                className='w-15 hover'
                src={Close}
                onClick={() => {
                  setAddress('');
                  if (
                    (locationHook.pathname.slice(-1) === '/'
                      ? locationHook.pathname.slice(0, -1)
                      : locationHook.pathname) === '/auction/map'
                  ) {
                    navigate('/auction/map?area=');
                  }
                }}
                alt=''
              />
            </div>
          </div>

          <div
            className={`flex-column w-375 ${
              isMobile(window.innerWidth) ? `of-scroll` : `browser-of-scroll`
            } ${recycle ? `max-h-304  fixed z-20 mt-8` : `h-100per `}`}
            style={
              recycle
                ? {
                    height: 'fit-content',
                    maxHeight: '304px',
                    top:
                      (temp.current?.getBoundingClientRect().top || 0) +
                      (temp.current?.offsetHeight || 0),
                  }
                : {
                    height: window.innerHeight - 106,
                  }
            }
          >
            <div
              className={
                (locationHook.pathname.slice(-1) === '/'
                  ? locationHook.pathname.slice(0, -1)
                  : locationHook.pathname) === '/auction/map'
                  ? 'flex-column space-between h-100per'
                  : 'flex-column space-between h-100per padding-b-40'
              }
            >
              <div className='flex-column relative'>
                {!(
                  (locationHook.pathname.slice(-1) === '/'
                    ? locationHook.pathname.slice(0, -1)
                    : locationHook.pathname) === '/auction/map'
                ) && <TopBanner search />}
                {true ? (
                  recycle && recentSearch ? (
                    address !== location ? (
                      <div
                        className='padding-x-18  fixed'
                        // style={{ paddingTop: '20px', paddingBottom: '20px' }}
                        style={{
                          top: '70px',
                          maxHeight: '700px',
                          overflowY: 'auto',
                        }}
                      >
                        <div
                          className='relative'
                          style={{
                            maxHeight: '700px',
                            overflowY: 'auto',

                            borderRadius: '8px',
                            // border: "2px solid black",
                            border:
                              '1px solid var(--alias-color-background-screen, #F0F0F0)',

                            background: '#FFF',
                            width: temp.current?.offsetWidth,
                          }}
                        >
                          {list.map((item, index) => {
                            return address === '' ? (
                              <div
                                className='flex-center padding-x-18 fixed'
                                style={{ width: '375px' }}
                              >
                                <div
                                  className='flex-column w-100per absolute border-171E26 bg-FFFFFF '
                                  style={{
                                    top: '0px',
                                    left: '0px',
                                    width: '325px',
                                    maxHeight: '700px',
                                    overflowY: 'auto',
                                  }}
                                >
                                  <div className='w-100per padding-8-16 flex-row space-between center-y border-b-171E26'>
                                    <div
                                      style={{
                                        padding: '4px 12px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: '#171E26C7',
                                        border: '1px solid #171E2610',
                                        borderRadius: '999px',
                                      }}
                                    >
                                      <div className='m-b2-14-m color-FFFFFF'>
                                        최근검색
                                      </div>
                                    </div>
                                    <div
                                      className='m-b2-14-m color-dark-secondary pointer'
                                      onClick={() => {
                                        deleteSearchAll();
                                      }}
                                    >
                                      전체삭제
                                    </div>
                                  </div>
                                  {list.map((item: any, index: number) => {
                                    return (
                                      <div className='padding-16 flex-row space-between '>
                                        <div
                                          className='flex-row w-100per gap-8 center-y hover'
                                          onClick={() => {
                                            sessionStorage.setItem(
                                              'type',
                                              'landinfo'
                                            );
                                            navigate(
                                              `${
                                                locationHook.pathname.slice(
                                                  -1
                                                ) === '/'
                                                  ? locationHook.pathname.slice(
                                                      0,
                                                      -1
                                                    )
                                                  : locationHook.pathname
                                              }?area=${
                                                item.jibunAddr
                                                  ? item.jibunAddr
                                                  : item.address
                                              }`
                                            );
                                          }}
                                        >
                                          <img
                                            alt=''
                                            className='w-24'
                                            src='/asset/images/location/location.svg'
                                          />
                                          <div className='flex-column'>
                                            <div className='font-medium f-size-16 line-h-24 color-text-secondary'>
                                              {item.address}
                                            </div>
                                            <div className='m-b2-14-m color-text-tertiary'>
                                              {item.service &&
                                              item.service.includes('-')
                                                ? item.service.split('-')[0] +
                                                  ' / ' +
                                                  item.service.split('-')[1]
                                                : item.service}
                                            </div>
                                          </div>
                                        </div>
                                        <img
                                          className='w-24 pointer'
                                          src='/asset/images/close/CloseGray20.svg'
                                          onClick={() => {
                                            // console.log('awef');
                                            deleteSearch(
                                              item.address,
                                              item.service
                                            );
                                          }}
                                          alt=''
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : (
                              <div className='flex-column' key={index}>
                                <div
                                  className={
                                    hover === index
                                      ? 'flex padding-x-16 bg-primary-100 hover'
                                      : 'flex padding-x-16 bg-white hover'
                                  }
                                  onClick={() => {
                                    // setMenu("landinfo");
                                    sessionStorage.setItem('type', 'landinfo');
                                    navigate(
                                      `${
                                        locationHook.pathname.slice(-1) === '/'
                                          ? locationHook.pathname.slice(0, -1)
                                          : locationHook.pathname
                                      }?area=${
                                        item.jibunAddr
                                          ? item.jibunAddr
                                          : item.address
                                      }`
                                    );
                                  }}
                                  onMouseOver={() => {
                                    setHover(index);
                                  }}
                                  onMouseOut={() => {
                                    setHover(10000);
                                  }}
                                >
                                  <div className='flex-row w-100per gap-8 center-y  padding-y-16 hover  border-b-171E26'>
                                    <img
                                      alt=''
                                      className='w-24'
                                      src='/asset/images/location/location.svg'
                                    />
                                    <div className='flex-column'>
                                      <div className='font-medium f-size-16 line-h-24 color-text-secondary'>
                                        {item.address}
                                      </div>
                                      <div className='font-medium f-size-14 line-h-20 color-text-tertiary'>
                                        {item.jibunAddr || item.detail}
                                      </div>
                                    </div>
                                  </div>
                                  {/*
                                <div className="flex-row w-100per gap-8 center-y  padding-y-16 hover  border-b-171E26">
                                  <img
                                    alt=""
                                    className="w-24"
                                    src="/asset/images/location/location.svg"
                                  />
                                  <div className="font-medium f-size-14 line-h-20 color-text-secondary">
                                    {item.address}
                                  </div>
                                </div>
                                */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      ''
                    )
                  ) : recentSearch ? (
                    address ? (
                      <div
                        className='padding-x-18  fixed'
                        // style={{ paddingTop: '20px', paddingBottom: '20px' }}
                        style={{
                          top: '70px',
                          maxHeight: '700px',
                          overflowY: 'auto',
                        }}
                      >
                        <div
                          style={{
                            maxHeight: '700px',
                            overflowY: 'auto',

                            borderRadius: '8px',
                            // border: "2px solid black",
                            border:
                              '1px solid var(--alias-color-background-screen, #F0F0F0)',

                            background: '#FFF',
                            width: temp.current?.offsetWidth,
                          }}
                        >
                          {list.map((item, index) => {
                            return (
                              <div className='flex-column' key={index}>
                                <div
                                  className={
                                    hover === index
                                      ? 'flex padding-x-16 bg-primary-100 hover'
                                      : 'flex padding-x-16 bg-white hover'
                                  }
                                  onClick={() => {
                                    // setMenu("landinfo");
                                    sessionStorage.setItem('type', 'landinfo');
                                    navigate(
                                      `${
                                        locationHook.pathname.slice(-1) === '/'
                                          ? locationHook.pathname.slice(0, -1)
                                          : locationHook.pathname
                                      }?area=${
                                        item.jibunAddr
                                          ? item.jibunAddr
                                          : item.address
                                      }`
                                    );
                                  }}
                                  onMouseOver={() => {
                                    setHover(index);
                                  }}
                                  onMouseOut={() => {
                                    setHover(10000);
                                  }}
                                >
                                  <div className='flex-row w-100per gap-8 center-y  padding-y-16 hover  border-b-171E26'>
                                    <img
                                      alt=''
                                      className='w-24'
                                      src='/asset/images/location/location.svg'
                                    />
                                    <div className='flex-column'>
                                      <div className='font-medium f-size-16 line-h-24 color-text-secondary'>
                                        {item.address}
                                      </div>
                                      <div className='font-medium f-size-14 line-h-20 color-text-tertiary'>
                                        {item.jibunAddr || item.detail}
                                      </div>
                                    </div>
                                  </div>
                                  {/*
                              <div className="flex-row w-100per gap-8 center-y  padding-y-16 hover  border-b-171E26">
                                <img
                                  alt=""
                                  className="w-24"
                                  src="/asset/images/location/location.svg"
                                />
                                <div className="font-medium f-size-14 line-h-20 color-text-secondary">
                                  {item.address}
                                </div>
                              </div>
                              */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      // 최신검색 코드
                      <div
                        className='flex-center padding-x-18 relative'
                        style={{ width: '375px' }}
                      >
                        <div
                          className='flex-column w-100per fixed border-171E26 bg-FFFFFF '
                          style={{
                            top: '65px',
                            width: '339px',
                            maxHeight: '700px',
                            overflowY: 'auto',
                          }}
                        >
                          <div className='w-100per padding-8-16 flex-row space-between center-y border-b-171E26'>
                            <div
                              style={{
                                padding: '4px 12px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#171E26C7',
                                border: '1px solid #171E2610',
                                borderRadius: '999px',
                              }}
                            >
                              <div className='m-b2-14-m color-FFFFFF'>
                                최근검색
                              </div>
                            </div>
                            <div
                              className='m-b2-14-m color-dark-secondary pointer'
                              onClick={() => {
                                deleteSearchAll();
                              }}
                            >
                              전체삭제
                            </div>
                          </div>
                          {list.map((item: any, index: number) => {
                            return (
                              <div className='padding-16 flex-row space-between '>
                                <div
                                  className='flex-row w-100per gap-8 center-y hover'
                                  onClick={() => {
                                    sessionStorage.setItem('type', 'landinfo');
                                    navigate(
                                      `${
                                        locationHook.pathname.slice(-1) === '/'
                                          ? locationHook.pathname.slice(0, -1)
                                          : locationHook.pathname
                                      }?area=${
                                        item.jibunAddr
                                          ? item.jibunAddr
                                          : item.address
                                      }`
                                    );
                                  }}
                                >
                                  <img
                                    alt=''
                                    className='w-24'
                                    src='/asset/images/location/location.svg'
                                  />
                                  <div className='flex-column'>
                                    <div className='font-medium f-size-16 line-h-24 color-text-secondary'>
                                      {item.address}
                                    </div>
                                    <div className='m-b2-14-m color-text-tertiary'>
                                      {item.service &&
                                      item.service.includes('-')
                                        ? item.service.split('-')[0] +
                                          ' / ' +
                                          item.service.split('-')[1]
                                        : item.service}
                                    </div>
                                  </div>
                                </div>
                                <img
                                  className='w-24 pointer'
                                  src='/asset/images/close/CloseGray20.svg'
                                  onClick={() => {
                                    // console.log('awef');
                                    deleteSearch(item.address, item.service);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {/* house가 아니고  인풋에 주소 입력이 되었을때  */}
                {/* onNoLand */}
                {noList && onNoLand && (
                  <div className='flex-column h-100per center-y bg-light-gray padding-t-50'>
                    <img
                      src={state.loading === 'N' ? locationNoImg : locationImg}
                      className='w-40 h-40'
                    />
                    <div className='w-100per padding-24 center-x font-bold f-size-16 color-text-tertiary text-center line-h-24'>
                      {(locationHook.pathname.slice(-1) === '/'
                        ? locationHook.pathname.slice(0, -1)
                        : locationHook.pathname) !== '/house/map' &&
                      !(
                        (locationHook.pathname.slice(-1) === '/'
                          ? locationHook.pathname.slice(0, -1)
                          : locationHook.pathname) === '/price/map' &&
                        state2.priceType !== 'land'
                      ) ? (
                        state.loading === 'N' ? (
                          sessionStorage.getItem('errMsg2') ? (
                            <div className='flex-column'>
                              <div>{sessionStorage.getItem('errMsg2')}</div>
                              <div>또는 지도를 움직여 위치를 찾아보세요 </div>
                            </div>
                          ) : (
                            <div>{sessionStorage.getItem('errMsg')}</div>
                          )
                        ) : (
                          <>
                            잠시만 기다려주세요.
                            <br /> 해당 토지를 분석중입니다
                          </>
                        )
                      ) : state.loading === 'N' ? (
                        sessionStorage.getItem('errMsg2') ? (
                          <div className='flex-column'>
                            <div>{sessionStorage.getItem('errMsg2')}</div>
                            <div>또는 지도를 움직여 위치를 찾아보세요 </div>
                          </div>
                        ) : (
                          <div>{sessionStorage.getItem('errMsg')}</div>
                        )
                      ) : (
                        <>
                          잠시만 기다려주세요.
                          <br /> 해당 주택을 분석중입니다
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className='flex-center flex-column gap-24'>
                {!isMobile(window.innerWidth) && onGuideBanner && banner && (
                  <div
                    className='w-328 padding-12-16 bg-light-gray-100 flex-row space-between border-radius-8 center-y pointer'
                    onClick={() => {
                      window.open(
                        'https://transfarmer.notion.site/20eba2a06fd246d1a1fe45b2e9d9d835?pvs=4'
                      );
                    }}
                  >
                    <div className='w-c2-12-r color-text-tertiary'>
                      서비스 이용에 어려움을 겪고 있나요?
                    </div>
                    <div className='flex-row'>
                      <div
                        className='color-dark-secondary'
                        style={{
                          fontSize: '14px',
                          fontWeight: '500',
                          lineHeight: '20px',
                        }}
                      >
                        자주 묻는 질문
                      </div>
                      <img src='/asset/images/arrow/ArrowRight16.svg' />
                    </div>
                  </div>
                )}

                {!isMobile(window.innerWidth) &&
                  onGuideBanner &&
                  banner &&
                  (locationHook.pathname === '/aiFarming/map' ||
                    locationHook.pathname === '/findland/map' ||
                    locationHook.pathname === '/annuity/map' ||
                    locationHook.pathname === '/price/map' ||
                    locationHook.pathname === '/house/map') && (
                    <GuideBannerWeb />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SideSearch;
