import { createSlice } from "@reduxjs/toolkit";

const InitialMoney = {
  loan: 0,
};

const moneySlice = createSlice({
  name: "type",
  initialState: InitialMoney,
  reducers: {
    Loan(state, action) {
      state.loan = action.payload;
    },
  },
});

export const { Loan } = moneySlice.actions;
export default moneySlice.reducer;
