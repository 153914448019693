import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import logo from './../../asset/images/logo/logo.svg';
import SignUp from '../../asset/images/navIcon/signup.svg';
import Login from '../../asset/images/navIcon/login.svg';
import Logout from '../../asset/images/navIcon/logout.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeType } from '../../store/typeSlice';
import { useAuth } from '../../utilities/auth';
import { SetPopUpOn } from '../../store/onOffSlice';
import { clickAnnuityMenu } from '../../hackler';
export const SideTopnav = () => {
  const state = useSelector((state: any) => {
    return state.type;
  });
  const locationHook = useLocation();
  const [number, setNumber] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLogin, onLogout } = useAuth();
  const MypageClickHandler = () => {
    sessionStorage.setItem('tab', 'mypage');
    // dispatch(ChangeType("aifarm"));
    navigate('/mypage/map');
  };
  const IconButtonBox = (props: any) => (
    <div
      className={`flex-1 flex-column center-y center-x hover padding-20-0  gap-4 ${
        props.on ? `bg-primary` : `bg=white`
      }`}
      // onClick={props.on ? () => {} : props.onClick}
      onClick={props.onClick}
    >
      {props.on ? (
        <img className='w-24' src={props.iconOn} alt='' />
      ) : (
        <img className='w-24' src={props.iconOff} alt='' />
      )}
      {props.description === '숨은 정책 지원금' ? (
        <div
          className={
            'f-size-11 line-h-12 text-center ' +
            (props.on ? 'font-bold' : 'font-medium')
          }
          style={{ color: props.on ? '#FFFFFF' : '#171E2640' }}
        >
          숨은 정책 <br /> 지원금
        </div>
      ) : (
        <div
          className={
            'f-size-11 line-h-12 no-wrap ' +
            (props.on ? 'font-bold' : 'font-medium')
          }
          style={{ color: props.on ? '#FFFFFF' : '#171E2640' }}
        >
          {props.description}
        </div>
      )}
    </div>
  );

  return (
    <div className='browser browser-flex w-74 h-100vh'>
      <div className='side-nav-inner bg-white w-74 flex-column space-between padding-b-24 border-r-171E2610 center-y h-100per  overflow-y-auto'>
        {/* 합필 */}
        {/* <div
        className="fixed left-90per top-90per z-20 bg-primary padding-16-24 border-radius-100 hover"
        onClick={() => {
          setListPopUp(true);
        }}
      >
        <div className="font-bold f-size-18 line-h-20 color-white">
          내 농지목록
        </div>
      </div> */}
        <div className='flex-column w-100per'>
          <div className='flex-column flex-center gap-5 h-80 border-b-171E26'>
            <img
              className='w-28 h-31 hover'
              src={logo}
              onClick={() => {
                sessionStorage.removeItem('tab');
                navigate('/');
              }}
            />
          </div>

          <div className='flex-column w-100per  border-b-171E26'>
            <IconButtonBox
              description='가격 분석'
              iconOn='/asset/images/icon/price_on.svg'
              iconOff='/asset/images/icon/price_off.svg'
              on={
                (locationHook.pathname.slice(-1) === '/'
                  ? locationHook.pathname.slice(0, -1)
                  : locationHook.pathname) === '/price/map'
              }
              onClick={() => {
                // dispatch(ChangeType("findland"));
                dispatch(SetPopUpOn(true));
                navigate('/price/map');
                sessionStorage.setItem('tab', 'price');
              }}
            />
            <IconButtonBox
              description='매물 분석'
              iconOn='/asset/images/icon/auction_on.svg'
              iconOff='/asset/images/icon/auction_off.svg'
              on={
                (locationHook.pathname.slice(-1) === '/'
                  ? locationHook.pathname.slice(0, -1)
                  : locationHook.pathname) === '/auction/map'
              }
              onClick={() => {
                // dispatch(ChangeType("findland"));
                navigate('/auction/map');
                sessionStorage.setItem('tab', 'auction');
              }}
            />
            <IconButtonBox
              description='농지연금 분석'
              iconOn='/asset/images/icon/pension_on.svg'
              iconOff='/asset/images/icon/pension_off.svg'
              on={
                (locationHook.pathname.slice(-1) === '/'
                  ? locationHook.pathname.slice(0, -1)
                  : locationHook.pathname) === '/annuity/map'
              }
              onClick={() => {
                dispatch(SetPopUpOn(true));
                navigate('/annuity/map');
                sessionStorage.setItem('tab', 'annuity');
                clickAnnuityMenu();
              }}
            />
            <IconButtonBox
              description='농지 찾기'
              iconOn='/asset/images/icon/findland_on.svg'
              iconOff='/asset/images/icon/findland_off.svg'
              on={
                (locationHook.pathname.slice(-1) === '/'
                  ? locationHook.pathname.slice(0, -1)
                  : locationHook.pathname) === '/findland/map'
              }
              onClick={() => {
                // dispatch(ChangeType("findland"));

                navigate('/findland/map');
                sessionStorage.setItem('tab', 'findland');
              }}
            />

            <IconButtonBox
              description='내 농장 진단'
              iconOn='/asset/images/icon/aifarm_on.svg'
              iconOff='/asset/images/icon/aifarm_off.svg'
              on={
                (locationHook.pathname.slice(-1) === '/'
                  ? locationHook.pathname.slice(0, -1)
                  : locationHook.pathname) === '/aiFarming/map'
              }
              onClick={() => {
                sessionStorage.setItem('tab', 'aifarm');
                // dispatch(ChangeType("aifarm"));
                navigate('/aiFarming/map');
              }}
            />
            <IconButtonBox
              description='내 집 짓기'
              iconOn='/asset/images/icon/house_on.svg'
              iconOff='/asset/images/icon/house_off.svg'
              on={
                (locationHook.pathname.slice(-1) === '/'
                  ? locationHook.pathname.slice(0, -1)
                  : locationHook.pathname) === '/house/map'
              }
              onClick={() => {
                sessionStorage.setItem('tab', 'house');
                navigate('/house/map');
                setNumber(3);
              }}
            />
            <IconButtonBox
              description='숨은 정책 지원금'
              iconOn='/asset/images/icon/policy_on.svg'
              iconOff='/asset/images/icon/policy_off.svg'
              on={
                (locationHook.pathname.slice(-1) === '/'
                  ? locationHook.pathname.slice(0, -1)
                  : locationHook.pathname) === '/hidden/policy'
              }
              onClick={() => {
                navigate('/hidden/policy');
                sessionStorage.setItem('tab', 'policy');
              }}
            />
          </div>
        </div>
        <div className='flex-column gap-40 mt-16'>
          <div
            className='flex-column flex-center hover'
            onClick={() => {
              isLogin() ? MypageClickHandler() : navigate('/signup');
            }}
          >
            <img src={SignUp} className='w-24' />
            <div className='font-medium f-size-14 line-h-20 color-00000056'>
              {localStorage.getItem('username')
                ? localStorage.getItem('username')
                : '회원가입'}
            </div>
          </div>
          <div
            className='flex-column flex-center hover'
            onClick={() => {
              localStorage.getItem('username')
                ? onLogout()
                : navigate('/login');
            }}
          >
            <img
              src={localStorage.getItem('username') ? Logout : Login}
              className='w-24'
            />
            <div className='font-medium f-size-14 line-h-20 color-00000056'>
              {localStorage.getItem('username') ? '로그아웃' : '로그인'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
