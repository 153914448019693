import './SignUp.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import {
  BirthDate,
  Category,
  CertificationNumber,
  Id,
  IdCheck,
  IdEqualCheck,
  Name,
  Password,
  Password2,
  PasswordCheck,
  PasswordEqual,
  PhoneNumber,
  PhoneNumber2,
  SignUpBtnCheck,
  SpinnerOn,
  Agriculture,
  SignUpType,
  Category2,
} from '../../store/signUpInfoSlice';
import { Inputbox } from '../../asset/styles/mobile/common/Common';
import { WarningWrapper } from '../../asset/styles/browser/signUp/SignUp';
import { RegId, RegPassword } from '../../utilities/reg';
import { jobOptions, yearOptions } from '../../dummy/dummy';
import Button from '../../component/mobile/Button';
import Footer from '../../component/browser/Footer';
import SignUpService from '../../service/signUp/SignUp';
import { TopNav2, TopNavNewBrowser } from '../../component/mobile/Topnav';

import { TextBox, TelBox } from './component';
import CouponService from '../../service/coupon/coupon';
import { alertCustom, handleKeyDown } from '../../component/function/function';
import { Helmet } from 'react-helmet';

import Terms from './Terms';
import { SignUpPopUp } from './PopUp';
import TopBanner from '../../component/banner/TopBanner';
import { TermsSns } from './TermsSns';
import Banner from '../../component/AIIntroduction/Footer';
import { useNavigate } from 'react-router-dom';
import { hackleSignUptrack, hackleClient } from '../../hackler';
import { GetUserInfo } from '../../utilities/auth';
interface Option {
  value: string;
  label: string;
}

const SignUpEmail = () => {
  const [termsOpen, setTermsOpen] = useState(false);
  const [type, setType] = useState('');
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [couponCode, setCouponCode] = useState('');
  const [onPopUp, setOnPopUp] = useState(false);
  const [phoneEmailCheck, setPhoneEmailCheck] = useState(false);
  const state = useSelector((state: any) => {
    return state.signUpInfo;
  });
  const navigate = useNavigate();
  const state2 = useSelector((state: any) => {
    return state.check;
  });

  console.log(state);
  // 회원가입
  const SignUpApi = async () => {
    if (!state.name) {
      alert('이름을 입력해주세요.');
    } else if (!state.phoneNumber || !state.phoneNumber2) {
      alert('휴대폰 번호를 확인해주세요.');
    } else if (!state.idCheck) {
      alert('아이디를 확인해주세요.');
    } else if (!state.passwordCheck) {
      alert('패스워드를 확인해주세요.');
    } else if (!state.passwordEqual) {
      alert('패스워드가 서로 일치하지 않습니다.');
    } else if (!state.idEqualCheck) {
      alert('중복된 아이디가 있습니다.');
    } else if (state2.privacy === false) {
      alert('개인정보 처리방침에 동의해주세요.');
    } else if (state2.transfarmerService === false) {
      alert('트랜스파머 서비스 이용약관에 동의해주세요.');
    } else if (state2.location === false) {
      alert('위치기반서비스 이용약관에 동의해주세요.');
    } else {
      dispatch(SpinnerOn(true));
      try {
        const data = {
          loginType: 'C',
          email: state.id,
          password: state.password,
          name: state.name,
          phone_1st: state.phoneNumber,
          phone_2nd: state.phoneNumber2,
          category: state.category,
          agriculturalNum: state.AgricultureNumber,
          // birthDate: state.birthdate,
          // gender: state.gender,
          couponCode: couponCode,
          landing: sessionStorage.getItem('landingType'),
          referer: sessionStorage.getItem('beforeUrl'),
          isNotification: state2.notification === true ? 'Y' : 'N',
        };
        const result = await SignUpService.SignUp(data);
        localStorage.setItem('accessToken', result.data.accessToken);
        dispatch(SignUpType('C'));
        dispatch(SpinnerOn(false));
        // setOnPopUp(true);
        hackleClient.setUserId(state.id);
        GetUserInfo();
        hackleSignUptrack(state.signUpType, state.category, state.id);
        navigate('/signup/complete', { state: { name: state.name } });
        const _nasa: { [key: string]: any } = {};
        if ((window as any).wcs) {
          _nasa['cnv'] = (window as any).wcs.cnv('2', '1');
        }
      } catch (error: any) {
        console.log(error.response.data.message);
        dispatch(SpinnerOn(false));
        if (
          error.response.data.message ===
          'Phone verification has not been verified'
        ) {
          alert('휴대폰을 인증해주세요.');
        } else if (
          error.response.data.message === '유효하지 않은 쿠폰 코드입니다'
        ) {
          alert('유효하지 않은 쿠폰 코드입니다');
        }
      }
    }
  };

  // 인증번호 발송
  const SendSmsApi = async () => {
    dispatch(SpinnerOn(true));
    const phone = `010${state.phoneNumber}${state.phoneNumber2}`;
    const data = {
      userId: state.id,
      phone: phone,
    };
    try {
      if (!RegId.test(state.id)) {
        alert('이메일을 입력해주세요.');
        dispatch(SpinnerOn(false));
        return;
      }
      if (!state.phoneNumber || !state.phoneNumber2) {
        alert('휴대폰 번호를 입력해주세요.');
        dispatch(SpinnerOn(false));
        return;
      }
      const result = await SignUpService.SendSms(data);
      alert('인증번호를 발송했습니다.');
      dispatch(SpinnerOn(false));
    } catch (error) {
      alert(error);
      console.log(error);
      dispatch(SpinnerOn(false));
    }
  };
  // 인증번호
  const CheckSmsApi = async (certificationNumber: any) => {
    const data = {
      userId: state.id,
      code: certificationNumber,
      phone: `010${state.phoneNumber}${state.phoneNumber2}`,
    };
    try {
      const result = await SignUpService.CheckSMS(data);
      if (result.data.message === 'AuthCode matches') {
        alert('인증되었습니다.');
        setPhoneEmailCheck(true);
      }
      console.log(result);
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message === 'This authCode does not match') {
        alert('인증번호를 확인해주세요.');
      }
    }
  };

  //   Id,Password 체크
  const CheckHandler = (key: string, value: string) => {
    if (key === 'ID') {
      if (RegId.test(value) === true) {
        dispatch(IdCheck(true));
      } else {
        dispatch(IdCheck(false));
      }
    } else if (key === 'Pwd') {
      if (RegPassword.test(value)) {
        dispatch(PasswordCheck(true));
      } else {
        dispatch(PasswordCheck(false));
      }
    } else if (key === 'Pwd2') {
      if (state.password === value) {
        dispatch(PasswordEqual(true));
      } else {
        dispatch(PasswordEqual(false));
      }
    }
  };
  // ID 중복 검사 API
  const IdEqualCheckApi = async () => {
    dispatch(SpinnerOn(true));
    try {
      const result = await SignUpService.IdEqualCheck(state.id);
      dispatch(SpinnerOn(false));
      if (!result.data.isValid) {
        alert('동일한 아이디가 있습니다.');
        dispatch(IdEqualCheck(false));
      } else {
        dispatch(IdEqualCheck(true));
      }
    } catch (error) {
      console.log(error);
      dispatch(SpinnerOn(false));
    }
  };
  // Btn 활용
  useEffect(() => {
    if (
      state.name !== '' &&
      state.phoneNumber !== '' &&
      state.phoneNumber2 !== '' &&
      // state.category !== '' &&
      state.id !== '' &&
      state.password !== '' &&
      state.password2 !== '' &&
      // state.gender !== "" &&
      // state.birthdate !== "" &&
      state.certificationNumber !== '' &&
      state2.privacy &&
      state2.transfarmerService &&
      state2.location
    ) {
      dispatch(SignUpBtnCheck(true));
    } else {
      dispatch(SignUpBtnCheck(false));
    }
  }, [state, state2]);
  // 쿠폰체크
  const checkCoupon = async () => {
    try {
      const result = await CouponService.check(couponCode);
      console.log('checkcoupon', result);
      alertCustom('쿠폰이 확인되었습니다', '1');
    } catch (error: any) {
      console.log(error);
      if (
        error.response.data.message === 'The couponCode is empty' ||
        '유효하지 않은 쿠폰 코드입니다'
      ) {
        // alert("쿠폰 번호를 다시 확인해 주세요");
        alertCustom('쿠폰 번호를 다시 확인해주세요', '2');
      } else {
        // alert(error.response.data.message);
      }
    }
  };
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const handleInputChange = (inputValue: any) => {
    // 입력값이 4자리이면 자동으로 해당 값을 선택
    if (inputValue.length === 4) {
      const option = yearOptions.find(
        (opt: any) => opt.value.toString() === inputValue
      );
      console.log('option', option);
      if (option) {
        dispatch(BirthDate(option.value));
        setSelectedOption(option);
      }
    }
  };
  const handleMenuOpen = () => {
    setTimeout(() => {
      if (inputRef.current) {
        console.log('aaee');
        inputRef.current.focus();
      }
    }, 500);
  };

  useEffect(() => {
    dispatch(Id(''));
    dispatch(Password(''));
    dispatch(Password2(''));
    dispatch(Name(''));
    dispatch(PhoneNumber(''));
    dispatch(PhoneNumber2(''));
    dispatch(CertificationNumber(''));
    dispatch(Category(''));
    dispatch(SignUpType('C'));
  }, []);
  return (
    <div className='email-signup-wrap'>
      <Helmet>
        <title>회원가입</title>
      </Helmet>
      {termsOpen && (
        <TermsSns
          setTermsOpen={setTermsOpen}
          termsOpen={termsOpen}
          type={type}
          setType={setType}
        />
      )}
      <TopBanner />
      <TopNav2 back hamburger text='회원가입' />
      {onPopUp && <SignUpPopUp setOnPopUp={setOnPopUp} name={state.name} />}
      <TopNavNewBrowser showMenu={false} />
      <div className='email-signup-inner-box'>
        <div className='email-signup-title'>이메일로 가입하기</div>
        <div className='email-input-box'>
          <div className='email-input'>
            <div className='email-input-title'>아이디</div>
            <div className='email-input-text'>
              <Inputbox
                disabled={phoneEmailCheck}
                value={state.id}
                onKeyDown={handleKeyDown}
                placeholder='이메일 입력'
                onChange={(e: any) => {
                  dispatch(Id(e.target.value));
                  CheckHandler('ID', e.target.value);
                }}
                onBlur={(e: any) => {
                  e.target.value !== '' && IdEqualCheckApi();
                }}
              />
              {state.id.length > 0 && !phoneEmailCheck && (
                <img
                  alt=''
                  src='/asset/images/close/close_with_circle.svg'
                  className='signup-input-img'
                  onClick={() => {
                    dispatch(Id(''));
                  }}
                />
              )}
            </div>
          </div>
          {!state.idCheck && state.id !== '' ? (
            <WarningWrapper>
              <p className='warning-font'>이메일 형식이 아닙니다.</p>
            </WarningWrapper>
          ) : null}
          <div className='email-input'>
            <div className='email-input-title'>비밀번호</div>
            <div className='email-input-text'>
              <Inputbox
                value={state.password}
                onKeyDown={handleKeyDown}
                placeholder='비밀번호 입력'
                onChange={(e: any) => {
                  dispatch(Password(e.target.value));
                  CheckHandler('Pwd', e.target.value);
                }}
                type='password'
              />
              {state.password.length > 0 && (
                <img
                  alt=''
                  src='/asset/images/close/close_with_circle.svg'
                  className='signup-input-img'
                  onClick={() => {
                    dispatch(Password(''));
                  }}
                />
              )}
            </div>
          </div>
          {!state.passwordCheck && state.password !== '' ? (
            <WarningWrapper>
              <p className='warning-font'>
                영문/숫자/특수문자를 사용하여 8자~16자로 입력
              </p>
            </WarningWrapper>
          ) : null}
          <div className='email-input'>
            <div className='email-input-title'>비밀번호 확인</div>
            <div className='email-input-text'>
              <Inputbox
                value={state.password2}
                onKeyDown={handleKeyDown}
                placeholder='비밀번호 확인'
                onChange={(e: any) => {
                  dispatch(Password2(e.target.value));
                  CheckHandler('Pwd2', e.target.value);
                }}
                type='password'
              />
              {state.password2.length > 0 && (
                <img
                  alt=''
                  src='/asset/images/close/close_with_circle.svg'
                  className='signup-input-img'
                  onClick={() => {
                    dispatch(Password2(''));
                  }}
                />
              )}
            </div>
          </div>
          {!state.passwordEqual && state.password2 !== '' ? (
            <WarningWrapper>
              <p className='warning-font'>패스워드가 일치하지 않습니다.</p>
            </WarningWrapper>
          ) : null}
          <TextBox
            value={state.name}
            placeholder='이름 입력'
            dispatch={Name}
            text='이름'
          ></TextBox>
          <TelBox
            disabled={phoneEmailCheck}
            text='휴대폰 번호'
            PhoneNumber={PhoneNumber}
            PhoneNumber2={PhoneNumber2}
            SendSmsApi={SendSmsApi}
          ></TelBox>
          <TextBox
            disabled={phoneEmailCheck}
            value={state.certificationNumber}
            placeholder='인증번호 6자리 입력'
            dispatch={CertificationNumber}
            checkPhone={CheckSmsApi}
            text='인증번호'
          ></TextBox>
          <div className='email-input'>
            <div className='email-input-title'>
              소속 혹은 농촌에 대한 관심사 (선택)
              <Select
                className='email-input-category'
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    padding: 0,
                    border: 'none',
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    height: '30px',
                    fontFamily: 'Pretendard-Regular',
                    fontSize: '17px',
                    color: '#888b90',
                  }),
                }}
                options={jobOptions}
                placeholder='어떤 일을 하시나요?'
                onChange={(e: any) => {
                  dispatch(Category(e.value));

                  handleMenuOpen();
                }}
                onInputChange={handleInputChange}
              />
            </div>
            {state.category === 'G' && (
              <input
                className='email-input-category-text'
                ref={inputRef}
                placeholder='하는 일 혹은 관심사를 기재해주세요'
                onChange={(e) => {
                  dispatch(Category2(e.target.value));
                }}
                value={state.category2}
              />
            )}
          </div>
          {state.category === 'W' ? (
            <TextBox
              placeholder='농업 경영체 등록 번호를 입력해주세요.'
              dispatch={Agriculture}
              text='농업 경영체 등록 번호'
            ></TextBox>
          ) : (
            <></>
          )}
        </div>
        <div className='signup-tems'>
          <Terms SNS setTermsOpen={setTermsOpen} setType={setType} />
        </div>
        <div className='sns-signup-btn'>
          <Button
            on={state.btnCheck}
            onClick={() => {
              SignUpApi();
            }}
            description='가입하기'
          />
        </div>
      </div>
      <Banner />
      <Footer />
    </div>
  );
};
export default SignUpEmail;
