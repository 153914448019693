import { createSlice } from "@reduxjs/toolkit";

const InitialData = {
  calData: {
    SGARate: 0,
    avgInfraCost: 0,
    avgOperationCost: 0,
    daySales: 0,
    dsr: 0,
    facilityCostRate: 0,
    farmEquipmentCostRate: 0,
    farmMachineryCostRate: 0,
    inRate: 0,
    infraCost: 0,
    infraCostRate: 0,
    irr: 0,
    laborCostRate: 0,
    landPurchaseCostRate: 0,
    landRentalCostRate: 0,
    loanFund: 0,
    loanFundRate: 0,
    loanInfo: {},
    materialCostRate: 0,
    monthSales: 0,
    operationCost: 0,
    operationCostRate: 0,
    orchardCostRate: 0,
    ownMoney: 0,
    ownMoneyRate: 0,
    realIncome: 0,
    realIncomeRate: 0,
    totalInterate: 0,
    waterHeatingCostRate: 0,
    yearSales: 0,
  },
};

const apiDataSlice = createSlice({
  name: "api",
  initialState: InitialData,
  reducers: {
    CalData(state, action) {
      state.calData = action.payload;
    },
  },
});

export const { CalData } = apiDataSlice.actions;
export default apiDataSlice.reducer;
