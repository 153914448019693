export const SaveReportPopUp = ({ setSavePopUp }: any) => {
  return (
    <div
      className='fixed z-999 center-x center-y padding-24'
      style={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#00000075',
      }}
    >
      <div
        className='bg-FFFFFF border-radius-16 flex-column w-312 flex-center gap-12'
        style={{ padding: '24px 16px 16px 16px' }}
      >
        <img
          className='w-56'
          src='/asset/images/check/Check24GreenCircle4.svg'
        />
        <div className='flex-column gap-24'>
          <div className='flex-column gap-8 flex-center'>
            <div className='m-b1-16-b color-dark-primary text-center'>
              보고서 저장 완료
            </div>
            <div className='m-b2-14-m color-disabled text-center'>
              마이페이지 &gt; 포인트 내역에서 <br />
              보고서를 확인할 수 있습니다.
            </div>
          </div>
          <div
            className='padding-12 bg-primary-500 flex-center border-radius-8 pointer'
            onClick={() => {
              setSavePopUp(false);
            }}
            style={{ width: '280px' }}
          >
            <div className='m-b2-14-m color-FFFFFF'>확인</div>
          </div>
        </div>
      </div>
    </div>
  );
};
