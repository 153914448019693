export const History = ({ scroll }: any) => (
  <div
    className={`flex-column padding-56-16 gap-40 bg-light-gray-100 tablet-padding-56-40 browser-flex-row browser-space-between w-1024-padding-176-60 w-1920-padding-176-360  ${
      scroll >
        (window.innerWidth >= 1920
          ? 4420
          : window.innerWidth >= 1024
            ? 4047
            : 4172) && `fade-in-up`
    }`}
  >
    <div className='flex-column browser-flex-row browser-1920-w-1200 tablet-w-904-2 flex-row browser-space-between'>
      <div className='flex-column gap-48 browser-space-between'>
        <div className='m-t2-18-b color-dark-primary w-t1-32-b tablet-text-center text-left '>
          History
        </div>
        <div className='w-100per w-t1-32-b tablet-pb-40-flex-end pb-0'>
          <img
            className='flex tablet-img-flex-end'
            src='/asset/images/profile/bottom_img.svg'
          />
        </div>
      </div>
      <div className='flex-column  '>
        <div className='flex-column gap-16 w-1024-w-553 browser-flex-row  browser-w-100per '>
          <div className='flex-column gap-16'>
            <div className='flex-column gap-16'>
              <div className='m-b1-16-b color-dark-primary'>2024</div>
              <div className='flex-row gap-16'>
                <div className='m-b1-16-b color-dark-secondary'>04</div>
                <div className='flex-column gap-8'>
                  <div className='m-b2-14-m color-dark-secondary web-body-2-medium'>
                    소셜벤처기업 인증
                  </div>
                  <div className='m-b2-14-m color-dark-secondary web-body-2-medium'>
                    창업진흥원 '초기창업패키지' 사업 선정
                  </div>
                  <div className='m-b2-14-m color-dark-secondary web-body-2-medium'>
                    한국농업기술진흥원 '농식품 벤처육성지원사업' 선정
                  </div>
                  <div className='m-b2-14-m color-dark-secondary  web-body-2-medium'>
                    정보통신산업진흥원 '클라우드 기반 SW 개발환경' 수요기업 선정
                  </div>
                  <div className='m-b2-14-m color-dark-secondary  web-body-2-medium'>
                    한국사회투자 민간 우수기술 사업화 지원 사업 'Agri-Future'
                    선정
                  </div>
                  <div className='m-b2-14-m color-dark-secondary  web-body-2-medium'>
                    한국농업기술진흥원 농식품 엑셀러레이팅 'Fast Track' 참여기업
                    선정
                  </div>
                  <div className='m-b2-14-m color-dark-secondary  web-body-2-medium'>
                    신용보증기금 '스타트업 네스트' 제 15기 선정
                  </div>
                </div>
              </div>
              <div className='flex-row gap-16'>
                <div className='m-b1-16-b color-dark-secondary'>03</div>
                <div className='flex-column gap-8'>
                  <div className='m-b2-14-m color-dark-secondary  web-body-2-medium'>
                    벤처기업 인증
                  </div>
                </div>
              </div>
              <div className='flex-row gap-16'>
                <div className='m-b1-16-b color-dark-secondary'>02</div>
                <div className='flex-column gap-8'>
                  <div className='m-b2-14-m color-dark-secondary  web-body-2-medium'>
                    소풍벤처스 Seed 투자 유치
                  </div>
                  <div className='m-b2-14-m color-dark-secondary  web-body-2-medium'>
                    고용노동부 일경험 프로그램 참여기업 선정
                  </div>
                </div>
              </div>
              <div className='flex-row gap-16'>
                <div className='m-b1-16-b color-dark-secondary'>01</div>
                <div className='flex-column gap-8'>
                  <div className='m-b2-14-m color-dark-secondary  web-body-2-medium'>
                    AI 바우처 공급기업 선정
                  </div>
                  <div className='m-b2-14-m color-dark-secondary  web-body-2-medium'>
                    NH농협 디지털혁신캠퍼스 입주
                  </div>
                  <div className='m-b2-14-m color-dark-secondary  web-body-2-medium'>
                    비대면 서비스 바우처 수요기업 선정
                  </div>
                </div>
              </div>
            </div>
            <div className='flex-column browser-space-between  browser-w-508 '>
              <div className='font-bold f-size-16 line-h-24 color-dark-primary'>
                2023
              </div>
              <div className='flex w-100per end-x browser-start-x'></div>
            </div>
            <div className='flex-row gap-16 center-y'>
              <div className='font-bold f-size-16 line-h-24 color-dark-primary'>
                12
              </div>
              <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                NH농협은행 오픈이노베이션 후속연계기업 선정
              </div>
            </div>
            <div className='flex-column gap-16'>
              <div className='flex-row gap-16 center-y'>
                <div className='font-bold f-size-16 line-h-24 color-dark-primary start-x'>
                  11
                </div>
                <div className='flex-column gap-8'>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                    서울창조경제혁신센터 보육기업 협약
                  </div>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                    대한상공회의소 유연근무제 인프라 구축비 지원사업 선정 (AWS
                    클라우드)
                  </div>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                    데이터사업자 신고
                  </div>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                    세종대학교 스마트팜학과 MOU (수업 교보재 계약)
                  </div>
                  <div className='flex-row space-between gap-10'>
                    <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                      이데일리 “AI로 농촌 소득 올리면 귀농인도 늘어날 겁니다"
                    </div>
                    <img
                      className='pointer'
                      src='/asset/images/arrow/ArrowRightBlack.svg'
                      onClick={() => {
                        window.open(
                          'https://www.edaily.co.kr/news/read?newsId=03463686635778496&mediaCodeNo=257'
                        );
                      }}
                    />
                  </div>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                    TransFarmer 플랫폼 런칭(v2.0)
                  </div>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                    국제 Smart 농업 엑스포 발제
                  </div>
                </div>
              </div>
            </div>
            <div className='flex-column gap-16'>
              <div className='flex-row gap-16 start-y'>
                <div className='font-bold f-size-16 line-h-24 color-dark-primary start-x'>
                  09
                </div>
                <div className='flex-column gap-8'>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                    서울시 캠퍼스 타운 인재양성 사업 인턴십 참여 기업 선정
                    (중앙대학교)
                  </div>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                    한국지능정보사회진흥원 초거대AI 활용 지원사업 선정 (네이버
                    농업 챗봇 PoC)
                  </div>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                    특허/상표권 5건 출원
                  </div>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                    한국부동산원 빈집추정시스템 특허 무상사용 실시권 MOU
                  </div>
                </div>
              </div>
            </div>
            <div className='flex-column gap-16'>
              <div className='flex-row gap-16 start-y'>
                <div className='font-bold f-size-16 line-h-24 color-dark-primary start-x'>
                  08
                </div>
                <div className='flex-column gap-8'>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                    KOITA 기업부설연구소 설립
                  </div>
                  <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                    정책평가연구원 정책플랫폼 용역 수주
                  </div>
                </div>
              </div>
            </div>
            <div className='flex-row gap-16 start-y'>
              <div className='font-bold f-size-16 line-h-24 color-dark-primary start-x'>
                05
              </div>

              <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                전남 나주시 TransFarmer 시범사업 진행
              </div>
            </div>
            <div className='flex-row gap-16 start-y'>
              <div className='font-bold f-size-16 line-h-24 color-dark-primary start-x'>
                04
              </div>

              <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                한국지능정보사회진흥원 2023 빅데이터 플랫폼 기반 분석서비스
                지원사업 선정
              </div>
            </div>
            <div className='flex-row gap-16 start-y'>
              <div className='font-bold f-size-16 line-h-24 color-dark-primary start-x'>
                03
              </div>
              <div className='flex-column gap-8'>
                <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                  HCOMBINED Seed 투자유치 (민간기업)
                </div>
                <div className='font-medium f-size-14 line-h-20 color-dark-secondary  web-body-2-medium'>
                  법인 설립
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
