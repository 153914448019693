import './banner.css';
import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../utilities/auth';
import { isMobile } from '../function/function';

const TopBanner = ({ search, setTopBanner }: any) => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const { isLogin } = useAuth();

  useEffect(() => {
    if (isLogin()) {
      sessionStorage.setItem('topBannerOpen', 'false');
    }

    const bannerState = sessionStorage.getItem('topBannerOpen');
    if (bannerState === 'false') {
      setIsBannerVisible(false);
    }
  }, []);

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
    sessionStorage.setItem('topBannerOpen', 'false');
    if (setTopBanner) {
      setTopBanner(false);
    }
  };

  if (window.navigator.userAgent === 'ReactSnap') {
    return <></>;
  }

  if (!isBannerVisible) {
    return null;
  }

  return (
    <div className='banner-wrap bg-000000'>
      <div
        className='banner-inner'
        style={{
          padding: search ? '0 16px' : '',
        }}
      >
        <div className='banner-content'>
          <img src='/asset/images/banner/banner_money.svg' alt='' />
          {isMobile(window.innerWidth) || search ? (
            <div className='banner-content-left'>
              <Link to='/signup' className='to-signup'>
                <span className='font-bold f-size-16'>
                  지금 가입하고 <span className='font-yellow'>0원 </span>
                  검색 시작하기
                </span>
                <img src='/asset/images/arrow/ArrowYellow.svg' alt='화살표' />
              </Link>
            </div>
          ) : (
            <>
              <div className='banner-content-left'>
                <Link to='/signup' className='to-signup'>
                  <span className='font-bold f-size-16'>
                    가입하면 전국{' '}
                    <span className='font-yellow'>
                      토지·주택가격 무료 검색{' '}
                    </span>
                  </span>
                </Link>
              </div>
              <Link
                to='/signup'
                className='to-signup-button h-32 flex-center hover '
              >
                <span className='m-b1-16-b color-dark-primary'>
                  0원 검색 시작하기
                </span>
                <img src='/asset/images/arrow/Right24.svg' alt='화살표' />
              </Link>
            </>
          )}
        </div>
        <img
          className='banner-close-btn'
          src='/asset/images/close/CloseWhite24.svg'
          alt='닫기'
          onClick={handleCloseBanner}
        />
      </div>
    </div>
  );
};

export default TopBanner;
