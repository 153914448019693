import PredictScore from "./PredictScore";
import { Slider } from "@mui/material";
import Select from "react-select";
import { useEffect, useState } from "react";
import { Tooltip2, TooltipMobile } from "../../../../component/common/tooltip";
import month from "./../../../../asset/images/new/month.svg";
const CropSkill = ({
  score,
  setScore,
  age,
  setAge,
  live,
  setLive,
  experience,
  setExperience,
  graduate,
  setGraduate,
  setYear,
  year,
  setLiveDate,
  setFarmDate,
  setOnlineHours,
  setActualHours,
}: any) => {
  const today = new Date();

  const [education, setEducation] = useState(true);
  const [tooltip, setTooltip] = useState(false);
  const [tooltip2, setTooltip2] = useState(false);
  const dummy = [
    { value: "1", label: "1년" },
    { value: "2", label: "2년" },
    { value: "3", label: "3년" },
    { value: "4", label: "4년" },
    { value: "5", label: "5년" },
  ];

  const dummy2 = [
    {
      title: "1) 나이",
      option1: "만 40세 미만",
      option2: "만 40세~ 65세 이하",
      state: age,
      setState: setAge,
      date: false,
      input: false,
      category: "age",
    },
    {
      title: "2) 농촌 거주 여부",
      option1: "해당",
      option2: "해당사항 없음",
      state: live,
      setState: setLive,
      date: true,
      input: false,
      category: "live",
      title2: "전입일",
    },
    {
      title: "3) 영농 경력 유무",
      option1: "있음",
      option2: "없음",
      state: experience,
      setState: setExperience,
      date: true,
      input: false,
      category: "experience",
      title2: "영농 시작일",
    },
  ];
  const Title = ({ text }: any) => {
    return (
      <div className='padding-0-16-8-16'>
        <div className='font-bold f-size-14 line-h-20 color-text-primary'>
          {text}
        </div>
      </div>
    );
  };

  return (
    <div className='flex-column w-100per report_result_box bg-white padding-b-16 relative'>
      <div className='flex-column relative padding-16-16-8-16'>
        <div className='flex-row center-y gap-4 relative'>
          <div className='font-bold f-size-16 line-h-24 color-text-primary relative '>
            7. 나의 경작 능력
          </div>

          <img
            className='w-20 pointer '
            src='/asset/images/warn/Warn20.svg'
            alt=''
            onClick={() => {
              setTooltip(!tooltip);
            }}
          />

          <div style={{ position: "absolute", zIndex: "1" }}>
            <Tooltip2
              text={
                <div className='no-wrap text-left'>
                  입력하신 재배 능력 항목은 대출 자격 여부
                  <br />
                  진단 및 영농 매출 예측에 활용됩니다.
                </div>
              }
              on={tooltip}
              left='0'
              top='33'
              width
              off={() => {
                setTooltip(false);
              }}
            />
          </div>
        </div>
      </div>
      <div className='flex-column no-wrap'>
        {dummy2.map((item, index) => {
          const NoLive = () => {
            item.setState("2");
            setLiveDate(null);
          };
          const NoExp = () => {
            item.setState("2");
            setFarmDate(null);
          };
          return (
            <>
              <div
                className={`flex-column w-100per  gap-8 ${
                  index === 0 ? `` : `padding-t-24`
                }`}
              >
                <div className='flex-row relative padding-0-16-8-16  gap-4'>
                  <div className='font-bold relative f-size-14 line-h-20 color-text-primary'>
                    {item.title}
                  </div>

                  {item.category === "live" && (
                    <div className='flex-row relative no-wrap'>
                      <img
                        className='w-20 hover '
                        src='/asset/images/warn/Warn20.svg'
                        alt=''
                        onClick={() => {
                          setTooltip2(!tooltip2);
                        }}
                      />
                      <div style={{ position: "absolute", zIndex: "1" }}>
                        <Tooltip2
                          left='-100'
                          top='20'
                          off={() => {
                            setTooltip2(false);
                          }}
                          width
                          text={
                            <div className='text-left'>
                              농촌이란 다음의 어느 하나에 해당 하는 지역을 말함.
                              <br />
                              <br />
                              <br />
                            </div>
                          }
                          text2={
                            <>
                              <ol className='line-h-14 mt-l-10'>
                                <li> 읍･면의 지역</li>
                                <br />
                                <li>(지방자치법)</li>
                                주거･상업･공업지역 외의 용도지역
                                <br />
                                <br />
                                <li>(지방자치법)</li>
                                가. 도시지역의 녹지지역 중 생산･보전 <br></br>
                                녹지지역
                                <br />
                                나. 관리지역 중 생산･보전관리지역
                                <br />
                                다. 농림･자연환경보전지역
                              </ol>
                            </>
                          }
                          on={tooltip2}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className='flex-row gap-12 padding-x-16'>
                  <div
                    className={`padding-12-12-12-8 border-radius-8 w-50per flex-center pointer ${
                      item.state === "1"
                        ? `bg-primary-600`
                        : `bg-light-gray-200`
                    }`}
                    onClick={() => {
                      item.setState("1");
                    }}
                  >
                    <div
                      className={`font-bold f-size-14 line-h-20 ${
                        item.state === "1" ? `color-FFFFFF` : `color-disabled`
                      }`}
                    >
                      {item.option1}
                    </div>
                  </div>
                  <div
                    className={`padding-12-12-12-8 border-radius-8 w-50per flex-center pointer ${
                      item.state === "2"
                        ? `bg-primary-600`
                        : `bg-light-gray-200`
                    }`}
                    onClick={() => {
                      item.category === "live"
                        ? NoLive()
                        : item.category === "experience"
                        ? NoExp()
                        : item.setState("2");
                    }}
                  >
                    <div
                      className={`font-medium f-size-14 line-h-20 ${
                        item.state === "2" ? `color-white` : `color-disabled`
                      }`}
                    >
                      {item.option2}
                    </div>
                  </div>
                </div>
                {item.date && item.state === "1" && (
                  <div className='flex-row space-between center-y padding-x-16'>
                    <div className='font-medium f-size-14 line-h-20 color-text-secondary'>
                      {item.title2}
                    </div>
                    <input
                      className='w-50per border-171E26 border-radius-8 padding-6-14 font-medium f-size-14 line-h-24 color-text-secondary'
                      type='month'
                      onChange={(e: any) => {
                        item.category === "live"
                          ? setLiveDate(e.target.value)
                          : setFarmDate(e.target.value);
                      }}
                      defaultValue={
                        today.getFullYear() + "-" + (today.getMonth() + 1)
                      }
                    />
                    {/* <div
                      className="flex-row w-50per border-171E26 border-radius-8 padding-6-14 gap-8 flex-center pointer"
                      onClick={() => {}}
                    >
                      <img src={month} />
                      <div className="font-medium f-size-14 line-h-24 color-text-secondary">
                        2023-08
                      </div>
                    </div> */}
                  </div>
                )}
              </div>

              <div className='padding-x-16'>
                <div className='h-24 border-b-171E26'>&nbsp;</div>
              </div>
            </>
          );
        })}
      </div>
      <div className='flex-column w-100per padding-t-24 gap-8'>
        <Title text='4) 농업계 학교 졸업 여부' />
        <div className='flex-column gap-10 padding-x-16'>
          <div className='flex-row gap-8'>
            <div
              className={`border-radius-8 flex-center w-33per padding-y-14 flex-center pointer ${
                graduate === "1" ? `bg-primary-600` : `bg-light-gray-200`
              }`}
              onClick={() => {
                setGraduate("1");
              }}
            >
              <div
                className={`font-bold f-size-12 line-h-14 ${
                  graduate === "1" ? `color-FFFFFF` : `color-disabled`
                }`}
              >
                농업고등학교
              </div>
            </div>
            <div
              className={`border-radius-8 flex-center w-33per padding-y-14 flex-center pointer ${
                graduate === "2" ? `bg-primary-600` : `bg-light-gray-200`
              }`}
              onClick={() => {
                setGraduate("2");
              }}
            >
              <div
                className={`font-bold f-size-12 line-h-14 ${
                  graduate === "2" ? `color-FFFFFF` : `color-disabled`
                }`}
              >
                농업대학교
              </div>
            </div>
            <div
              className={`border-radius-8 flex-center w-33per padding-y-14 flex-center pointer ${
                graduate === "3" ? `bg-primary-600` : `bg-light-gray-200`
              }`}
              onClick={() => {
                setGraduate("3");
              }}
            >
              <div
                className={`font-bold f-size-12 line-h-14 ${
                  graduate === "3" ? `color-FFFFFF` : `color-disabled`
                }`}
              >
                영농 보육센터
              </div>
            </div>
          </div>
          <div className='flex-row gap-10'>
            <div
              className={`border-radius-8 flex-center w-33per padding-y-14 flex-center pointer ${
                graduate === "4" ? `bg-primary-600` : `bg-light-gray-200`
              }`}
              onClick={() => {
                setGraduate("4");
              }}
            >
              <div
                className={`font-bold f-size-12 line-h-14 ${
                  graduate === "4" ? `color-FFFFFF` : `color-disabled`
                }`}
              >
                스마트팜 보육센터
              </div>
            </div>
            <div
              className={`border-radius-8 flex-center w-33per padding-y-14 flex-center pointer ${
                graduate === "5" ? `bg-primary-600` : `bg-light-gray-200`
              }`}
              onClick={() => {
                setGraduate("5");
              }}
            >
              <div
                className={`font-bold f-size-12 line-h-14 ${
                  graduate === "5" ? `color-FFFFFF` : `color-disabled`
                }`}
              >
                해당사항 없음
              </div>
            </div>
            <div className='bg-white  border-radius-8  flex-center w-33per padding-y-14 flex-center pointer'>
              <div className='font-medium f-size-12 line-h-14 color-disabled'>
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div className='padding-x-16'>
          <div className='h-24 border-b-171E26'>&nbsp;</div>
        </div>
      </div>
      <div className='flex-column w-100per gap-8 padding-t-24'>
        <Title text='5) 영농 기술교육 이수현황' />
        <div className='flex-row gap-12 padding-x-16'>
          <div
            className={`padding-12-12-12-8 border-radius-8 w-50per flex-center pointer ${
              education ? `bg-primary-600` : `bg-light-gray-200`
            }`}
            onClick={() => {
              setEducation(true);
            }}
          >
            <div
              className={`font-bold f-size-14 line-h-20 ${
                education ? `color-FFFFFF` : `color-disabled`
              }`}
            >
              있음
            </div>
          </div>
          <div
            className={`padding-12-12-12-8 border-radius-8 w-50per flex-center pointer ${
              !education ? `bg-primary-600` : `bg-light-gray-200`
            }`}
            onClick={() => {
              setEducation(false);
              setActualHours(null);
              setOnlineHours(null);
            }}
          >
            <div
              className={`font-medium f-size-14 line-h-20 ${
                !education ? `color-white` : `color-disabled`
              }`}
            >
              없음
            </div>
          </div>
        </div>

        {education && (
          <div className='flex-column gap-8 w-100per'>
            <div className='flex-row center-y space-between center-y w-100per padding-x-16'>
              <div className='font-medium f-size-14 line-h-20 color-secondary'>
                현장 교육 시간
              </div>
              <div className='flex-row center-y border-171E26 border-radius-8 w-50per end-x center-y gap-5  padding-6-8'>
                <input
                  className='w-70per border-none font-medium f-size-14 line-h-24 text-end'
                  placeholder='0'
                  onChange={(e) => {
                    setActualHours(e.target.value);
                  }}
                />
                <div className='font-medium f-size-14 line-h-20 color-text-primary'>
                  시간
                </div>
              </div>
            </div>
            <div className='flex-row center-y space-between center-y w-100per padding-x-16'>
              <div className='font-medium f-size-14 line-h-20 color-secondary'>
                온라인 교육 시간
              </div>
              <div className='flex-row center-y border-171E26  border-radius-8 w-50per end-x center-y gap-5 padding-6-8'>
                <input
                  className='w-70per border-none font-medium f-size-14 line-h-24 text-end'
                  placeholder='0'
                  onChange={(e) => {
                    setOnlineHours(e.target.value);
                  }}
                />
                <div className='font-medium f-size-14 line-h-20 color-text-primary'>
                  시간
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='flex-column gap-20 padding-t-24'>
        <div className='flex-column gap-10'>
          <Title text='6) 나의 예상 경작 능력' />
          <div className='padding-x-16'>
            <div className='fo  nt-medium f-size-14 line-h-20 color-text-tertiary'>
              경작 능력의 지역평균을 3이라고 가정할 때, <br />
              나의 능력은 어디에 위치할지 선택해보세요.
            </div>
          </div>
        </div>
        <div className='flex-column'>
          <div className='text-center font-bold f-size-20 line-h-29 color-text-primary'>
            {score}
          </div>
          <div className='padding-x-16'>
            <Slider
              value={score}
              onChange={(e: any) => {
                setScore(e.target.value);
              }}
              // valueLabelDisplay="auto"
              // getAriaValueText={valuetext}
              sx={{ color: "green" }}
              step={0.1}
              max={5}
              min={1}
              // marks={true}
            />

            <div className='flex-row w-100per '>
              {[1, 2, 3, 4, 5].map((item) => {
                return (
                  <div
                    className={`font-medium f-size-12 line-h-14 color-text-secondary ${
                      item === 5 ? `` : `w-25per`
                    }`}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className='flex-row w-100per padding-x-16'>
          <div className='flex-half font-medium f-size-12 line-h-14 color-text-tertiary'>
            낮음
          </div>
          <div className='flex-half font-medium f-size-12 line-h-14 color-text-tertiary'>
            지역평균
          </div>
          <div className='no-wrap font-medium f-size-12 line-h-14 color-text-tertiary'>
            높음
          </div>
        </div>
        {score < 3 && (
          <div className='flex-column gap-10 padding-x-16'>
            <div className='font-bold f-size-14 line-h-20 color-text-primary'>
              나의 경작 능력이 지역평균(3)에 도달하는데 얼마나 시간이 걸릴 것
              같나요?
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Select
                menuPlacement='auto'
                className='w-50per font-medium f-size-14 line-h-20 color-text-primary'
                options={dummy}
                placeholder='2년'
                onChange={(e) => {
                  setYear(e.value);
                }}
                defaultValue={year}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CropSkill;
