import { RowTableGreenBold, RowTableValueBold, RowTable1 } from './Row';
import { useState } from 'react';
import { isMobile, transNum } from '../function/function';
import { Tooltip2 } from '../common/tooltip';
import { LineChart, Tooltip, XAxis, YAxis, Line } from 'recharts';
import { ButtonSelect1M } from './Button';
import { BoxTable3, BoxGreen1 } from './Box';
export default class LandPriceCom {
  static LandPrice1 = ({
    data,
    name,
    setTab2,
    tab2,
    data2,
    onToolTip,
    setOnToolTip,
    mypage,
  }: {
    data: any;
    name: string;
    setTab2: any;
    tab2: number;
    data2: any;
    onToolTip: boolean;
    setOnToolTip: any;
    mypage?: boolean;
  }) => {
    const [onToolTip1, setOnToolTip1] = useState(false);
    const [onToolTip2, setOnToolTip2] = useState(false);

    return (
      <div className='flex-column'>
        <div className='padding-16-16-0 flex-column end-y'>
          <RowTableGreenBold
            title='AI 추정가'
            value={`${transNum(data.estimatedPrice)}원`}
          />
          <div className='font-medium f-size-14 line-h-20 color-text-tertiary'>
            {true
              ? transNum(data.perPyeongEstimatedPrice)
              : transNum(data.perMeterEstimatedPrice)}
            원/평
          </div>
        </div>
        <div className='padding-24-16 gap-16 flex-column'>
          <div className='font-bold f-size-16 line-h-24 color-text-secondary'>
            농지현황
          </div>
          <div className='flex-row gap-24'>
            <RowTable1 title='이용상황' value={data.useStatus} />
            <RowTable1 title='고저' value={data.height} />
          </div>
          <div className='flex-row gap-24'>
            <RowTable1
              onClick={() => setOnToolTip1(true)}
              warn
              title='형상'
              value={data.shape}
            />
            <RowTable1
              onClick={() => setOnToolTip2(true)}
              warn
              title='접면'
              value={data.roadSurface}
            />
          </div>
          <div className='relative'>
            {onToolTip1 && (
              <div
                className='flex-row bg-blue-gray-700 border-radius-8 padding-12-16 tool-tip start-y center-x w-218 gap-10 z-10 absolute'
                style={{
                  top: `${0}px`,
                  left: '0px',
                }}
              >
                <div className='flex-column gap-8'>
                  <div className='font-medium f-size-11 line-h-12 color-EFF0F6'>
                    • 토지의 모양(지형)으로, 8가지로 분류합니다.
                  </div>
                  <div className='font-medium f-size-11 line-h-12 color-EFF0F6'>
                    • 정방형, 가로장방형, 세로장방형, 사다리형, 삼각형,
                    역삼각형, 부정형, 자루형
                  </div>
                </div>
                <img
                  alt=''
                  className='hover'
                  src='/asset/images/close/CloseWhite12.svg'
                  onClick={() => setOnToolTip1(false)}
                />
              </div>
            )}
          </div>
          <div className='relative'>
            {onToolTip2 && (
              <div
                className='flex-row bg-blue-gray-700 border-radius-8 padding-12-16 tool-tip start-y center-x w-218 gap-10 z-10 absolute'
                style={{
                  top: `${0}px`,
                  right: '0px',
                }}
              >
                <div className='flex-column gap-8'>
                  <div className='font-medium f-size-11 line-h-12 color-EFF0F6'>
                    • 도로조건(도로접면)으로, 토지의 가격형성에 있어 중요한
                    요인입니다. 12가지로 분류합니다.
                  </div>
                  <div className='font-medium f-size-11 line-h-12 color-EFF0F6'>
                    • 광대한면, 광대소각, 광대세각, 중로한면, 중로각지,
                    소로한면, 소로각지, 세로(가), 세각(가), 세로(불), 세각(불),
                    맹지
                  </div>
                </div>
                <img
                  alt=''
                  className='hover'
                  src='/asset/images/close/CloseWhite12.svg'
                  onClick={() => setOnToolTip2(false)}
                />
              </div>
            )}
          </div>
        </div>
        <div className='padding-8-16 gap-10'>
          <BoxGreen1>
            <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
              해당 농지의 AI 추정가는{' '}
              {`${transNum(data.estimatedPrice, true)}원`}
              으로
            </div>
            <div
              className='font-bold f-size-16 line-h-24 '
              style={{
                color:
                  data.estimatedPrice >=
                  data.officialPriceList[2023] * data2.landArea
                    ? '#1E9B6B'
                    : '#d62554',
              }}
            >
              공시지가
              {/* {(data.difference || 0) > 0
                ? ` 보다 ${Math.round((data.difference || 0) * 100)}% 높습니다.`
                : (data.difference || 0) < 0
                ? ` 보다 ${
                    Math.round((data.difference || 0) * -1 * 100) / 100
                  }% 낮습니다.`
                : "와 같습니다."} */}
              {data.estimatedPrice &&
              data.officialPriceList[2023] &&
              data.estimatedPrice >=
                data.officialPriceList[2023] * data2.landArea
                ? `보다 높습니다(${(
                    Math.round(
                      (data.estimatedPrice /
                        (data.officialPriceList[2023] * data2.landArea)) *
                        100 *
                        10
                    ) / 10
                  ).toFixed(1)}%)`
                : `보다 낮습니다(${(
                    Math.round(
                      (data.estimatedPrice /
                        (data.officialPriceList[2023] * data2.landArea)) *
                        100 *
                        10
                    ) / 10
                  ).toFixed(1)}%)`}
            </div>
          </BoxGreen1>
        </div>
        <div className='padding-8-16 gap-10'>
          <BoxGreen1>
            {data.surroundingsAvgPrice ? (
              <>
                <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
                  해당 농지의 평당 AI 추정가는{' '}
                  {Math.round(
                    Number(data.perPyeongEstimatedPrice)
                  ).toLocaleString()}
                  원으로
                </div>
                <div
                  className='font-bold f-size-16 line-h-24 color-primary-500'
                  style={{
                    color:
                      Number(data.perPyeongEstimatedPrice) <
                      Number(data.surroundingsAvgPrice)
                        ? '#d62554'
                        : '#1E9B6B',
                  }}
                >
                  인근 농지 평당 가격 {''}
                  {Math.round(
                    Number(data.surroundingsAvgPrice)
                  ).toLocaleString()}
                  원
                  {Number(data.perPyeongEstimatedPrice) >
                  Number(data.surroundingsAvgPrice) ? (
                    (Number(data.perPyeongEstimatedPrice) -
                      Number(data.surroundingsAvgPrice)) /
                      Number(data.surroundingsAvgPrice) <
                    0.05 ? (
                      <span>과 유사</span>
                    ) : (
                      <span>
                        보다 <br /> 높습니다(
                        {Math.abs(
                          Math.round(
                            (data.perPyeongEstimatedPrice /
                              data.surroundingsAvgPrice) *
                              1000
                          ) / 10
                        ).toFixed(1)}
                        %)
                      </span>
                    )
                  ) : Number(data.perPyeongEstimatedPrice) <
                    Number(data.surroundingsAvgPrice) ? (
                    (Number(data.surroundingsAvgPrice) -
                      Number(data.perPyeongEstimatedPrice)) /
                      Number(data.surroundingsAvgPrice) <
                    0.05 ? (
                      <span> 과 유사</span>
                    ) : (
                      <span>
                        {' '}
                        보다 <br /> 낮습니다(
                        {Math.abs(
                          Math.round(
                            (data.perPyeongEstimatedPrice /
                              data.surroundingsAvgPrice) *
                              1000
                          ) / 10
                        ).toFixed(1)}
                        %)
                      </span>
                    )
                  ) : (
                    '과 같음'
                  )}
                </div>
              </>
            ) : (
              <div className='font-bold f-size-14 line-h-20 color-dark-primary'>
                최근 5년 동안 해당 물건 주변 실거래 사례는 없습니다.
              </div>
            )}
          </BoxGreen1>
        </div>

        <div className='padding-8-16 flex-row gap-8'>
          <ButtonSelect1M
            onClick={() => {
              setTab2(1);
            }}
            selected={tab2 === 1}
          >
            거래 이력
          </ButtonSelect1M>
          <ButtonSelect1M
            onClick={() => {
              setTab2(2);
            }}
            selected={tab2 === 2}
          >
            주변 거래사례
          </ButtonSelect1M>
        </div>

        {tab2 === 1 ? (
          <div className='padding-16'>
            {data.landTradePrice === null ? (
              <RowTableValueBold
                title=''
                value='최근 5년 내 거래 이력이 없습니다'
              />
            ) : (
              <RowTableValueBold
                title=''
                value={
                  <div className='flex-column end-y'>
                    <div className='font-medium f-size-16 line-h-24 color-secondary'>
                      {transNum(data.landTradePrice)}원
                    </div>
                    <div className='font-medium f-size-14 line-h-20 color-text-tertiary'>
                      {Math.round(
                        data.landTradePrice / (data2.farmArea * 0.3025)
                      ).toLocaleString()}
                      원/평
                    </div>
                    <div className='font-medium f-size-14 line-h-20 color-text-tertiary'>
                      {data.landTradeDate &&
                        `${data.landTradeDate.slice(
                          0,
                          4
                        )}-${data.landTradeDate.slice(
                          4,
                          6
                        )}-${data.landTradeDate.slice(6, 8)}`}
                    </div>
                  </div>
                }
              />
            )}
          </div>
        ) : (
          <div className='flex-column'>
            {/* <div className="padding-16-16-0 flex-row ">
                        <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                          주변 거래사례
                        </div>
                        <img
                          src="/asset/images/warn/Warn24.svg"
                          className="hover"
                          onClick={() => setOnToolTip(true)}
                          alt=""
                        />
                      </div> */}
            <Tooltip2
              bottom='0'
              on={onToolTip}
              off={() => setOnToolTip(false)}
              text='더 많은 주변 거래사례는 고객센터에 문의 바랍니다.'
            />
            <div className='padding-16 flex-column gap-16'>
              {data.surroundings &&
                data.surroundings.length > 0 &&
                data.surroundings.map((el: any) => {
                  let arr = el.address.split(' ');
                  let num = arr.pop();
                  let num2 = '';
                  for (let i = 1; i < num.length; i++) {
                    num2 = num2 + '*';
                  }
                  const address = arr.join(' ') + ' ' + num.slice(0, 1) + num2;
                  return (
                    <div className='flex-row space-between'>
                      <div className='font-medium f-size-16 line-h-24 color-text-tertiary'>
                        반경 {Math.round(el.distance)}m
                      </div>
                      <div className='flex-column end-y'>
                        <div className='font-medium f-size-16 line-h-24 color-secondary'>
                          {address}
                        </div>
                        <div className='font-medium f-size-14 line-h-20 color-text-tertiary'>
                          {Math.round(el.price_per_pyeong).toLocaleString()}
                          원/평
                        </div>
                        <div className='font-medium f-size-14 line-h-20 color-text-tertiary'>
                          {el.landTradeDate.slice(0, 4)}-
                          {el.landTradeDate.slice(4, 6)}-
                          {el.landTradeDate.slice(6, 8)}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    );
  };

  static LandPrice2 = ({
    arr2,
    table,
    data,
    mypage,
  }: {
    arr2: { key: string; price: any }[];
    table: { th: string[]; tb: string[][] };
    data: any;
    mypage?: boolean;
  }) => {
    const CustomTooltip = ({ active, payload, label }: any) => {
      if (active && payload && payload.length) {
        return (
          <div
            className='custom-tooltip'
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              padding: '16px',
            }}
          >
            <p className='label'>{`${label}년`}</p>
            <p
              className='price'
              style={{ color: '#09CE7D' }}
            >{`공시지가 : ${payload[0].value.toLocaleString()}원`}</p>
          </div>
        );
      }

      return null;
    };

    return (
      <div className='flex-column'>
        <div className='padding-16-16-0'>
          <div className='font-bold f-size-16 line-h-24 color-text-secondary'>
            공시지가 추이
          </div>
        </div>
        <div className='padding-24-16 flex-column gap-16'>
          <LineChart
            className='flex-medium f-size-11 center-x'
            width={
              isMobile(window.innerWidth) ? window.innerWidth - 32 : 360 - 16
            }
            height={181}
            data={arr2}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <Line type='monotone' dataKey='price' stroke='#53df89' />
            <XAxis dataKey='key' />
            <YAxis tickFormatter={(value) => value.toLocaleString()} />
            <Tooltip content={<CustomTooltip />} />
          </LineChart>
          <div
            className={`flex-column of-y-auto`}
            style={{ maxHeight: '168px' }}
          >
            <BoxTable3 table={table} />
          </div>
        </div>
        <div className='padding-8-16 gap-10'>
          <BoxGreen1>
            <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
              최근 {Object.keys(data.officialPriceList).length}년(
              {Object.keys(data.officialPriceList)[0]}-
              {
                Object.keys(data.officialPriceList)[
                  Object.keys(data.officialPriceList).length - 1
                ]
              }
              ) <br />
              공시지가 연평균 성장률은
              <br />
              {Math.round(data.CAGR * 1000) / 10}% 입니다.
            </div>
          </BoxGreen1>
        </div>
      </div>
    );
  };
}
