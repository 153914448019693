import arrow from "./../../asset/images/arrow/arrowRight.svg";
import "../../asset/styles/SaleInfo.css";
import "../../Main.css";
import { Topnav } from "../../component/mobile/Topnav";
import { BottomNav } from "../../component/mobile/BottomNav";
import { LinkText } from "../../dummy/dummy";
import { useNavigate } from "react-router-dom";

const CS = () => {
  const navigate = useNavigate();
  return (
    <div
      className="flex-column center-x w-100per"
      style={{ paddingBottom: "70px", paddingTop: "50px" }}
    >
      <Topnav text="마이페이지" />
      <div className="flex-column flex-center w-100per bg-F2F3F6 gap-10">
        <div className="flex-column flex-center w-100per padding-0-20  bg-FFFFFF">
          <div className="flex-column w-100per flex-center">
            {LinkText.map((menu: any) => {
              return (
                <div
                  className="flex-row space-between center-y pointer w-100per padding-y-20"
                  onClick={() => {
                    {
                      menu === "문의하기" && navigate("/inquiry");
                    }
                  }}
                >
                  <div className="font-medium f-size-16 line-h-22 color-text-primary">
                    {menu}
                  </div>
                  <img src={arrow} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CS;
