import { useState } from 'react';
import { Tooltip2, TooltipMobile } from '../../../../component/common/tooltip';
const CultivateCount = ({ count, setCount }: any) => {
  const [tooltip, setTooltip] = useState(false);
  const UpDownBtn = ({ btn }: { btn: string }) => (
    <div
      className='padding-4-8 w-32 h-32 bg-blue-gray-500 flex-center border-radius-8'
      onClick={() =>
        btn === '+'
          ? setCount((prev: any) => prev + 1)
          : setCount((prev: any) => prev - 1)
      }
    >
      <div className='font-medium f-size-30 line-h-24 color-white'>{btn}</div>
    </div>
  );

  return (
    <div className='flex-row center-y space-between w-100per report_result_box bg-white padding-22-16 '>
      <div className='flex-column relative'>
        <div className='flex-row gap-4'>
          <div className='font-bold f-size-16 line-h-24 color-text-primary'>
            4. 1년 재배 횟수
          </div>
          <img
            className='w-20 hover'
            src='/asset/images/warn/Warn20.svg'
            alt=''
            onClick={() => {
              setTooltip(!tooltip);
            }}
          />
        </div>
        <div style={{ position: 'absolute', zIndex: '1' }}>
          <Tooltip2
            width
            text={
              <div className='text-left'>
                재배 횟수를 예측하기 어렵다면 1기작으로 설정하시기를 바랍니다.
              </div>
            }
            left='0'
            top='22'
            on={tooltip}
            off={() => setTooltip(false)}
          />
        </div>
      </div>

      <div className='flex-row gap-5'>
        <UpDownBtn btn='-' />
        <div className='padding-4-8 border-1-171E26 flex-center'>
          <div className='font-medium f-size-14 line-h-24'>{count}기작</div>
        </div>
        <UpDownBtn btn='+' />
      </div>
    </div>
  );
};

export default CultivateCount;
