import {
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  LabelList,
  Legend,
} from 'recharts';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isMobile } from '../../component/function/function';
import PriceService from '../../service/price/priceApi';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingDot } from '../../store/loadingSlice';
import { SaveBtn } from '../../component/common/saveBtn';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Temp from './components/Temp';

import ReportDetail8 from './components/detail/ReportDetail8';
import { Footer, ReportTitle } from './component';
import MapContainerReport from '../aiFarming/map/MapContainerReport';
import LandController from '../../controller/land/LandController';
import MapContainerReport2 from '../aiFarming/map/MapContainerReport2';
import { UsageArea } from './reportComponents/UsageArea';
import ReportDetail4 from '../reportResultSale/components/detail/ReportDetail4';
import { LandRank } from './reportComponents/LandRank';
import { LandRank2 } from './reportComponents/LandRank2';
import { SoilRank } from './reportComponents/SoilRank';
import { OfficialPrice } from './reportComponents/OfficialPirce';
import { Surroundings } from './reportComponents/Surrounding';
import { Surroundings2 } from './reportComponents/Surroundings2';
import { Estimated } from './reportComponents/Estimated';
import { AskPrice } from './reportComponents/AskPrice';
import { Estimated2 } from './reportComponents/Estimated2';
import { Cover } from './reportComponents/Cover';
import { Index } from './reportComponents/Index';
import { Location } from './reportComponents/Location';
import { Summary } from './reportComponents/Summary';

const PriceReportMain2 = () => {
  const [loading, setLoading] = useState(true);
  const divRef = useRef<HTMLDivElement>(null);
  const divRef2 = useRef<HTMLDivElement>(null);
  const divRef3 = useRef<HTMLDivElement>(null);
  const divRef4 = useRef<HTMLDivElement>(null);
  const divRef5 = useRef<HTMLDivElement>(null);
  const divRef6 = useRef<HTMLDivElement>(null);
  const divRef7 = useRef<HTMLDivElement>(null);
  const [address, setAddress] = useState('전북특별자치도 김제시 순동 445-19');
  const [stickPolygon, setStickPolygon]: any = useState(() => {});
  const [currentMarker, setCurrentMarker]: any = useState();
  const [areaList, setAreaList]: any = useState([]);
  const [locationData, setLocationData] = useState({});
  const [areaData, setAreaData] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [landRankData, setLandRankData] = useState({});
  const [soilRankData, setSoilRankData] = useState({});
  const [OfficialPirceData, setOfficialPirceData] = useState({});
  const convertElementToImage = async (
    element: HTMLElement | null
  ): Promise<string | null> => {
    if (element) {
      let canvas = await html2canvas(element, { scale: 3, useCORS: true });
      return canvas.toDataURL('image/jpeg');
    }
    return null;
  };

  const convertHTMLToImages = async (): Promise<string[]> => {
    const elements: (HTMLElement | null)[] = [
      divRef.current,
      divRef2.current,
      divRef3.current,
      divRef4.current,
    ];
    const imageDataArray = await Promise.all(
      elements.map(async (element) => {
        return await convertElementToImage(element);
      })
    );
    // null 값 필터링
    return imageDataArray.filter(
      (imageData): imageData is string => imageData !== null
    );
  };
  const func1 = () => {
    window.print();
  };
  const func2 = () => {
    // Basic.html2pdf()
  };
  const handleButtonClick = async () => {
    let imgDataArray = await convertHTMLToImages();

    if (imgDataArray.length > 0) {
      // 'landscape' 옵션을 사용하여 가로 방향으로 PDF 생성
      let doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4',
      });

      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();

      for (let index = 0; index < imgDataArray.length; index++) {
        const imgData = imgDataArray[index];
        if (imgData !== null) {
          const img = new Image();
          img.src = imgData;
          await new Promise((resolve) => {
            img.onload = () => {
              const imgWidth = img.width;
              const imgHeight = img.height;

              // 이미지의 비율을 유지하면서 PDF 크기에 맞추기
              const ratio = Math.min(
                pdfWidth / imgWidth,
                pdfHeight / imgHeight
              );

              const imgPDFWidth = imgWidth * ratio;
              const imgPDFHeight = imgHeight * ratio;

              doc.addImage(imgData, 'JPEG', 0, 0, imgPDFWidth, imgPDFHeight);
              if (index < imgDataArray.length - 1) {
                doc.addPage();
              }
              resolve(null);
            };
          });
        }
      }

      doc.save('a4_landscape.pdf');
    }
  };
  const Block = () => (
    <div
      className='mt-24 noprint'
      style={{ height: '16px', backgroundColor: '#F7F8F9', width: '100%' }}
    >
      &nbsp;
    </div>
  );

  const handleDownload = () => {
    // 파일의 경로 설정
    const filePath = `http://localhost:3000/plan.hwp`;

    // 다운로드 링크 생성 및 클릭
    const link = document.createElement('a');
    link.href = filePath;
    link.download = '무료서식.hwp'; // 사용자에게 표시될 파일 이름
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const getData = async () => {
    const type = 'L';

    try {
      setLoading(true);
      const result = await PriceService.getPdfData(type, address);
      console.log('pdf', result);
      setLocationData(result.result.sheet3);
      setAreaData(result.result.sheet4);
      setSummaryData(result.result.sheet5);
      setLandRankData(result.result.sheet6);
      setSoilRankData(result.result.sheet7);
      setOfficialPirceData(result.result.sheet8);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log('로딩', loading);
  }, [loading]);
  useEffect(() => {
    getData();
  }, []);
  return (
    <div
      id='pdf-download'
      className={`w-100per h-100per`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <button
        onClick={async () => {
          // setOn(true);
          // handleClick();
          handleButtonClick();
        }}
        className='noprint'
      >
        버튼
      </button>
      <button
        className='noprint'
        onClick={() => {
          func1();
        }}
      >
        버튼2
      </button>
      <button
        className='noprint'
        onClick={() => {
          handleDownload();
        }}
      >
        버튼3
      </button>
      {/* page1 cover */}
      <div className='page'>{!loading && <Cover address={address} />}</div>
      <div className='break noprint'></div>
      <Block />
      {/* page2 목차 */}
      <div className='flex-column w-100per page'>{!loading && <Index />}</div>
      <div className='break noprint'></div>
      <Block />
      {/* page3 물건 위치*/}
      <div className='flex-column w-100per page'>
        {!loading && <Location address={address} data={locationData} />}
      </div>

      <div className='break noprint'></div>
      <Block />
      {/* page4 물건 사진 및 용도 지역 */}
      <div className='flex-column w-100per page'>
        {!loading && <UsageArea data={areaData} address={address} />}
      </div>

      <div className='break noprint'></div>
      <Block />
      {/* page5 물건 개요 */}
      <div className='flex-column w-100per page'>
        {!loading && <Summary data={summaryData} address={address} />}
      </div>
      <div className='break noprint'></div>
      <Block />
      {/* page6 토지 등급 */}
      <div className='flex-column w-100per page'>
        {!loading && <LandRank data={landRankData} address={address} />}
      </div>
      <div className='break noprint'></div>
      <Block />

      {/* page8 토양 등급*/}
      <div className='flex-column w-100per page'>
        {!loading && <SoilRank data={soilRankData} address={address} />}
      </div>
      <div className='break noprint'></div>
      <Block />
      {/* page9 공시지가 추이 */}
      <div className='flex-column w-100per page'>
        {!loading && (
          <OfficialPrice data={OfficialPirceData} address={address} />
        )}
      </div>
      <div className='break noprint'></div>
      <Block />
      {/* page10 주변 실거래 가격 */}
      <div className='flex-column w-100per page'>
        <Surroundings />
      </div>
      <div className='break noprint'></div>
      <Block />
      {/* page11 주변 실거래 가격 추이 */}
      <div className='flex-column w-100per page'>
        <Surroundings2 />
      </div>
      <div className='break noprint'></div>
      <Block />
      {/* page12 추정 근거 및 1차 추정가 */}
      <div className='flex-column w-100per page'>
        <Estimated />
      </div>
      <div className='break noprint'></div>
      <Block />
      <div className='flex-column w-100per page'>
        <AskPrice />
      </div>
      <div className='break noprint'></div>
      <Block />
      <div className='flex-column w-100per page'>
        <Estimated2 />
      </div>
      <div className='break noprint'></div>
      <Block />
    </div>
  );
};

export default PriceReportMain2;
