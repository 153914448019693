import { MouseEventHandler, useState } from "react";

export const ButtonSelect2 = ({
  children,
  selected,
  width,
  onClick,
}: {
  children: string;
  selected: boolean;
  width?: boolean | undefined;
  onClick: MouseEventHandler;
}) => (
  <div
    onClick={onClick}
    className="padding-8 border-radius-8 center-x hover"
    style={{
      border: selected ? "1px solid #1E9B6B" : "none",
      background: "#FFFFFF",
      width: width ? "100%" : "auto",
      filter: selected ? "drop-shadow(2px 4px 0 #1e986b10)" : "none",
    }}
  >
    <div
      className={`${
        selected ? "font-bold" : "font-medium "
      } f-size-16 line-h-16 browser-line-h-29 browser-f-size-18`}
      style={{ color: selected ? "#1E9B6B" : "#171E268F" }}
    >
      {children}
    </div>
  </div>
);

export const ButtonSelectorBox = ({
  btnArr,
  tab,
  setTab,
}: {
  btnArr: Array<{ children: string; selected: number } | string>;
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <div className="gap-10 w-100per">
      <div className="border-1-f0f0f0 bg-light-gray-200 padding-2 border-radius-8 flex-row w-100per browser-max-w-1200">
        {btnArr.map((el, i) => {
          if (i % 2 === 0 && typeof el !== "string") {
            return (
              <ButtonSelect2
                onClick={() => {
                  setTab(el.selected);
                }}
                children={el.children}
                selected={el.selected === tab}
                width
              />
            );
          } else {
            let acc = btnArr[i - 1];
            let nxt = btnArr[i + 1];
            if (
              typeof acc !== "string" &&
              !(acc.selected === tab) &&
              nxt &&
              typeof nxt !== "string" &&
              !(nxt.selected === tab)
            ) {
              return (
                <div className="padding-y-8">
                  <div
                    style={{
                      height: "100%",
                      width: "0.62px",
                      background: "#757575",
                    }}
                  />
                </div>
              );
            } else {
              <div className="padding-y-8">
                <div
                  style={{
                    height: "100%",
                    width: "0.62px",
                    background: "#757575",
                  }}
                />
              </div>;
            }
          }
        })}
      </div>
    </div>
  );
};
