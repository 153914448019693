import BoardService from "../../service/board/Board";

type NewCommentProp = {    
    setComment : any,    
    data : any,
    onSuccess? : any;
    ref?:any;
}


const NewComment = (prop : NewCommentProp) => {

    const writeCommentApi = async () => {        
        if (prop.data.comment === "") {
          alert("내용을 입력해주세요.");
        } else {
          const data = {
            content: prop.data.comment,
            articleIdx: prop.data.idx,
          };
          try {
            const result = await BoardService.WriteComment(data);
            if (result.success) {                
                if(prop.onSuccess){
                    prop.onSuccess();
                    //getCommentApi();
                }              
            }
          } catch (error) {
            console.log(error);            
          }
        }
      };

    return (
        <div
            style={{
                position: "fixed",
                bottom: "71px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",

                justifyContent: "space-between",
            }}
        >
            <div
                className="flex-row space-between w-100per"
                style={{
                    border: "1px solid black",
                    padding: "10px 20px",
                    backgroundColor: "white",
                }}
            >
                <input
                    ref={prop.ref}
                    className="font-normal"
                    placeholder="댓글을 작성해보세요"
                    style={{
                        width: "100%",
                        border: "none",
                    }}
                    onBlur={(e) => {
                        prop.setComment(e.target.value);
                    }}
                />
                <div className="flex-center">
                    <div
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => {
                            writeCommentApi();
                        }}
                        className="font-normal"
                    >
                        등록
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewComment;
