import { FullContainer } from "../../../asset/styles/mobile/common/Common";
import { Topnav } from "../../../component/mobile/Topnav";
import defaultImg from "../../../asset/images/mypage/defaultImg_mobile.svg";
import resetImg from "./../../../asset/images/mypage/resetIcon.png";
import "../../../asset/styles/SaleInfo.css";

import { useState } from "react";
import UserService from "../../../service/checkAccount/CheckAccount";
const Modify = () => {
  const [nick, setNick] = useState("");
  const [duplicated, setDuplicated] = useState(false);
  const checkNickApi = async () => {
    const data = { nick: nick };
    try {
      const result = await UserService.checkNick(data);
      setDuplicated(result.isDuplicate);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="flex-column w-100per">
      <Topnav text="프로필 수정" />
      <div
        className="flex-column w-100per gap-20 flex-center padding-0-20"
        style={{ marginTop: "50px" }}
      >
        <img src={defaultImg} style={{ height: "200px" }} />
        <div className="font-bold">홍길동</div>
        <div
          className="greybox flex-column w-100per"
          style={{
            backgroundColor: "#d8d9d7",
            padding: "20px 20px",
            gap: "5px",
          }}
        >
          <div className="font-small">닉네임</div>
          <div className="flex-row space-between center-y">
            <input
              style={{ backgroundColor: "#d8d9d7", border: "none" }}
              placeholder="닉네임을 입력해주세요."
              value={nick}
              onChange={(e) => {
                setNick(e.target.value);
              }}
              onBlur={() => {
                checkNickApi();
              }}
            />
            <img
              onClick={() => {
                setNick("");
              }}
              src={resetImg}
            />
          </div>
        </div>
        {duplicated ? (
          <div
            className="font-normal"
            style={{
              color: "red",
              textAlign: "start",
              width: "100%",
              paddingLeft: "10px",
            }}
          >
            *이미 사용 중인 닉네임입니다.
          </div>
        ) : null}
        <div
          className="flex-center padding-20-0 w-100per mt-20"
          style={{
            backgroundColor: "rgba(0, 175, 135, 1)",
            borderRadius: "10px",
          }}
        >
          <div className="font-bold" style={{ color: "white" }}>
            저장
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modify;
