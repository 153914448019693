import Button from '../../component/mobile/Button';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { SetPopUpOn } from '../../store/onOffSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import SignUpService from '../../service/signUp/SignUp';
import { Inputbox } from '../../asset/styles/mobile/common/Common';
import { alertCustom, handleKeyDown } from '../../component/function/function';
import { Password } from '../../store/signUpInfoSlice';
import { DeletePopUp2 } from './DeletePopUp2';
import './delete.css';
import { VerifyBtn } from '../../asset/styles/mobile/signUp/SIgnUp';
import UserService from '../../service/checkAccount/CheckAccount';
import { TextBox } from './component';

export const DeletePopUp = ({
  selectedReasons,
}: {
  setOnPopUp: any;
  selectedReasons: string[];
}) => {
  const navigate = useNavigate();

  const [showDeletePopUp2, setShowDeletePopUp2] = useState(false);
  const [isPasswordEntered, setIsPasswordEntered] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [changePhone, setChangePhone] = useState(false);
  const [certificationNumber, setCertificationNumber] = useState('');
  const [certificationNumberEmail, setCertificationNumberEmail] = useState('');
  const [showCertificationNumber, setShowCertificationNumber] = useState(false);
  const [showCertificationNumberEmail, setShowCertificationNumberEmail] =
    useState(false);
  const [email, setEmail] = useState('');
  const state = useSelector((state: any) => {
    return state.signUpInfo;
  });

  function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
  }

  const AuthenticationApi = async (type: string) => {
    const data = {
      type: type,
      value: type === 'E' ? email : phoneNumber,
    };
    try {
      if (type === 'P') {
        setShowCertificationNumber(true);
      } else if (type === 'E') {
        setShowCertificationNumberEmail(true);
      }
      const result = await SignUpService.Authentication(data);
      alert('인증번호가 전송되었습니다.');
    } catch (error) {
      console.log(error);
    }
  };

  const checkMyInfo = async () => {
    try {
      const result = await UserService.checkInfo();
      setPhoneNumber(result.phone);
      setEmail(result.email);
    } catch (error) {
      console.log(error);
    }
  };

  const withDrawal = async () => {
    const data = {
      reason: selectedReasons,
      authCode: showCertificationNumberEmail
        ? certificationNumberEmail
        : certificationNumber,
    };
    try {
      const result = await SignUpService.Withdrawal(data);
      console.log(result);
      setShowDeletePopUp2(true);
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message === 'This authCode does not match') {
        alert('인증번호가 일치하지 않습니다.');
      }
    }
  };

  useEffect(() => {
    checkMyInfo();
  }, []);
  return (
    <div
      id='post-meta-col'
      className='flex-column fixed h-100per z-9999 browser-flex-center w-100per flex-center'
      style={{
        backgroundColor: '#00000060',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div className='bg-FFFFFF padding-24-16-16-16 border-radius-16 gap-12 w-312 flex-center flex-column'>
        <div className='flex-column gap-24 w-100per'>
          <div className='flex-column gap-8 flex-center'>
            <div className='m-b1-16-b'>핸드폰 번호 인증</div>
            <div className='m-b2-14-m color-disabled'>
              안전한 회원탈퇴를 위해 핸드폰 번호를 입력해 주세요.
            </div>
          </div>
          <div className='flex-column bg-FAFAFA border-radius-13 w-100per padding-12-16'>
            <div className='font-regular f-size-12 line-h-18 color-888B90 text-start'>
              핸드폰 번호
            </div>
            <div className='flex-row space-between w-100per'>
              <Inputbox
                width={150}
                disabled
                className=''
                value={phoneNumber}
                onKeyDown={handleKeyDown}
                placeholder='핸드폰 번호 입력'
                onChange={() => {}}
              />
              <VerifyBtn
                onClick={() => {
                  AuthenticationApi('P');
                }}
              >
                인증번호 받기
              </VerifyBtn>
            </div>
          </div>
          {showCertificationNumber && (
            <div className='flex-column bg-FAFAFA border-radius-13 w-100per padding-12-16'>
              <div className='font-regular f-size-12 line-h-18 color-888B90 text-start'>
                인증 번호
              </div>
              <div className='flex-row space-between w-100per'>
                <Inputbox
                  width={150}
                  className=''
                  value={certificationNumber}
                  onKeyDown={handleKeyDown}
                  placeholder='인증번호 입력'
                  onChange={(e) => {
                    setCertificationNumber(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
          <div
            className='pointer m-c2-11-m'
            onClick={() => {
              setChangePhone(true);
            }}
          >
            * 핸드폰이 변경되셨나요?
          </div>
          {changePhone && (
            <div className='flex-column gap-24'>
              <div className='flex-column bg-FAFAFA border-radius-13 w-100per padding-12-16'>
                <div className='font-regular f-size-12 line-h-18 color-888B90 text-start'>
                  이메일
                </div>
                <div className='flex-row space-between w-100per center-y'>
                  <div
                    style={{
                      maxWidth: '15ch',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {email}
                  </div>
                  <VerifyBtn
                    onClick={() => {
                      AuthenticationApi('E');
                    }}
                  >
                    인증번호 받기
                  </VerifyBtn>
                </div>
              </div>
              {showCertificationNumberEmail && (
                <div className='flex-column bg-FAFAFA border-radius-13 w-100per padding-12-16'>
                  <div className='font-regular f-size-12 line-h-18 color-888B90 text-start'>
                    인증 번호
                  </div>
                  <div className='flex-row space-between w-100per'>
                    <Inputbox
                      width={150}
                      className=''
                      value={certificationNumberEmail}
                      onKeyDown={handleKeyDown}
                      placeholder='인증번호 입력'
                      onChange={(e) => {
                        setCertificationNumberEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          <div className='p flex w-100per gap-8'>
            <Button
              on={
                showCertificationNumberEmail
                  ? certificationNumberEmail.length > 0
                    ? true
                    : false
                  : showCertificationNumber
                  ? certificationNumber.length > 0
                    ? true
                    : false
                  : false
              }
              description='탈퇴하기'
              onClick={() => {
                withDrawal();
              }}
            />
            <Button
              on={false}
              description='취소'
              onClick={() => {
                navigate('/mypage', { replace: true });
              }}
            />
          </div>
        </div>
      </div>
      {showDeletePopUp2 && <DeletePopUp2 />}
    </div>
  );
};
