import {
  TopNavNew,
  TopNavNewBrowser,
  Topnav,
} from '../../component/mobile/Topnav';
import InquiryList from '../mypage/inquiry/InquiryList';
import '../../asset/styles/SaleInfo.css';
import { useState } from 'react';
import InquiryWrite from './InquiryWrite';
import Select from 'react-select';
import Pagination from 'react-js-pagination';

const Inquiry = () => {
  const [menu, setMenu] = useState(0);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dummy = [
    {
      category: '전체',
      status: '답변 대기',
      title: '제목',
      writer: '임준영',
      date: '2024-07-02',
    },
    {
      category: '서비스이용',
      status: '답변 완료',
      title:
        '제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2',
      writer: '이송이',
      date: '2024-07-02',
    },
    {
      category: '회원',
      status: '답변 대기',
      title: '제목3',
      writer: '이태원',
      date: '2024-07-02',
    },
    {
      category: '결제관련',
      status: '답변 완료',
      title: '제목4',
      writer: '전근호',
      date: '2024-07-02',
    },
    {
      category: '건의사항',
      status: '답변 대기',
      title: '제목5',
      writer: '이혜진',
      date: '2024-07-02',
    },
    {
      category: '전체',
      status: '답변 대기',
      title: '제목',
      writer: '임준영',
      date: '2024-07-02',
    },
    {
      category: '서비스이용',
      status: '답변 완료',
      title:
        '제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2제목2',
      writer: '이송이',
      date: '2024-07-02',
    },
    {
      category: '회원',
      status: '답변 대기',
      title: '제목3',
      writer: '이태원',
      date: '2024-07-02',
    },
    {
      category: '결제관련',
      status: '답변 완료',
      title: '제목4',
      writer: '전근호',
      date: '2024-07-02',
    },
    {
      category: '건의사항',
      status: '답변 대기',
      title: '제목5',
      writer: '이혜진',
      date: '2024-07-02',
    },
  ];
  const option1 = [
    { value: 'A', label: '답변 대기' },
    { value: 'B', label: '답변 완료' },
  ];
  const option2 = [
    { value: 'A', label: '제목 + 내용' },
    { value: 'B', label: '작성자' },
  ];
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      minHeight: '48px', // 최소 높이 설정
      height: '48px', // 고정 높이 설정
      border: '2px solid #171E26',
    }),
  };
  return (
    <div className='flex-column w-100per flex-center'>
      <TopNavNew setOpen={setOpen} open={open} />

      <TopNavNewBrowser />
      <div className='flex-column max-w-1200 w-100per mt-32'>
        <div className='flex-column gap-24'>
          <div
            style={{
              fontFamily: 'Pretendard-Bold',
              fontSize: '26px',
              lineHeight: '38px',
              color: '#171E26',
            }}
          >
            고객문의
          </div>
          <div className='flex-row gap-24'>
            <Select
              className='inquiry-content-box-select font-medium f-size-14 line-h-24 color-text-primary border-radius-5 pointer '
              placeholder='답변 상태'
              options={option1}
              onChange={() => {}}
              styles={customStyles}
            />
            <Select
              className='inquiry-content-box-select font-medium f-size-14 line-h-24 color-text-primary border-radius-5  pointer h-48'
              placeholder='제목 + 내용'
              options={option2}
              onChange={() => {}}
              styles={customStyles}
            />
            <div
              className='flex-row flex-1 gap-4 border-radius-8'
              style={{ border: '2px solid #171E26', padding: '11px 34px' }}
            >
              <img
                style={{ width: '20px' }}
                src='/asset/images/search/SearchGray20.svg'
              />
              <input
                placeholder='검색어를 입력해주세요'
                style={{ border: 'none' }}
              />
            </div>
          </div>
          <div className='flex-row gap-16'>
            {[
              '전체',
              '서비스이용',
              '회원',
              '결제관련',
              '건의사항',
              '사업문의',
              '기타',
            ].map((item, index) => {
              return (
                <div
                  className={` pointer ${
                    menu === index
                      ? `m-h2-24-b color-dark-primary`
                      : `m-h2-24-m color-disabled`
                  }`}
                  onClick={() => {
                    setMenu(index);
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
          <div className='flex-column gap-8'>
            <div className='m-b2-14-b color-dark-primary'>총 1,500건</div>
            <div className='flex-column w-100per'>
              <div
                className='flex-row w-100per padding-16 bg-light-gray-100'
                style={{
                  borderTop: '1px solid #171E26',
                  borderBottom: '1px solid #171E2610',
                  // backgroundColor: '#171E261A',
                }}
              >
                <div className='w-10per flex-center '>
                  <div className='m-b1-16-b color-dark-primary'>상담유형</div>
                </div>
                <div className='w-10per flex-center '>
                  <div className='m-b1-16-b color-dark-primary'>답변상태</div>
                </div>
                <div className=' flex-center ' style={{ width: '60%' }}>
                  <div className='m-b1-16-b color-dark-primary'>제목</div>
                </div>
                <div className='w-10per flex-center '>
                  <div className='m-b1-16-b color-dark-primary'>작성자</div>
                </div>
                <div className='w-10per flex-center '>
                  <div className='m-b1-16-b color-dark-primary'>작성일시</div>
                </div>
              </div>

              {dummy.map((item: any, index: number) => {
                let writer: string = '';

                for (let i = 0; i < item.writer.length; i++) {
                  if (i === 0 || i === item.writer.length - 1) {
                    writer += item.writer[i];
                  } else {
                    writer += '*';
                  }
                }
                return (
                  <div
                    className='flex-row w-100per padding-16 '
                    style={{
                      borderBottom: '1px solid #171E2610',
                      // backgroundColor: '#171E261A',
                    }}
                  >
                    <div className='w-10per flex-center '>
                      <div className='m-b1-16-m color-dark-primary'>
                        {item.category}
                      </div>
                    </div>
                    <div className='w-10per flex-center '>
                      <div
                        className={`m-b1-16-m ${
                          item.status === '답변 완료'
                            ? `color-primary-500`
                            : `color-text-tertiary`
                        }`}
                      >
                        {item.status}
                      </div>
                    </div>

                    <div
                      className='flex-row gap-4 pointer'
                      style={{ width: '60%' }}
                    >
                      <div
                        className='m-b1-16-m color-dark-primary'
                        style={{
                          maxWidth: '40ch',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {item.title}
                      </div>
                      <img
                        style={{ width: '18px' }}
                        src='/asset/images/icon/lock.svg'
                      />
                    </div>
                    <div className='w-10per flex-center '>
                      <div className='m-b1-16-m color-dark-primary'>
                        {writer}
                      </div>
                    </div>
                    <div className='w-10per flex-center '>
                      <div className='m-b2-14-m color-text-tertiary'>
                        {item.date}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='w-100per flex end-x pointer'>
              <div
                className='padding-8-16 flex-center pointer border-radius-8 '
                style={{ border: '1px solid #171E26', width: '69px' }}
              >
                <div className='m-b2-14-m color-dark-primary no-wrap'>
                  글쓰기
                </div>
              </div>
            </div>
          </div>
          <Pagination
            totalItemsCount={11}
            activePage={currentPage}
            onChange={() => {
              setCurrentPage(currentPage);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Inquiry;
