import { styled } from "styled-components";
import CheckOnImg from "../../../images/terms/checkOn.svg";
import CheckOffImg from "../../../images/terms/checkOff.svg";
import { CheckOff, CheckOn } from "../../browser/terms/Terms";

interface BottomBtn {
  on: boolean;
}

export const TermsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 90%;
`;

export const TermsMiddleSec1 = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TermsMiddleSec2 = styled.div`
  display: flex;
  margin-top: 60px;
  height: 110px;
  border-bottom: 1px solid rgba(226, 226, 226, 1);
`;

export const AllCheckBtn = styled.div`
  display: flex;
  width: 335px;
  height: 80px;
  padding: 0px 14px 0px 14px;
  background-color: rgba(250, 250, 250, 1);
  align-items: center;
  border-radius: 13px;
  justify-content: space-between;
  cursor: pointer;
`;

export const CheckOnBtn = styled.img.attrs({
  src: `${CheckOnImg}`,
})``;

export const CheckOffBtn = styled.img.attrs({
  src: `${CheckOffImg}`,
})``;

export const TermsMiddleSec3 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin-top: 25px;
`;

export const TermsItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 14px 0px 14px;
  cursor: pointer;
`;

export const TermsLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const TemrsBottomBtnWrapper = styled.div`
  display: flex;
  margin-top: 71px;
`;

export const TermsBottomBtn = styled.div<BottomBtn>`
  display: flex;
  width: 335px;
  height: 56px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.on ? "rgba(0, 175, 135, 1)" : "#00af87"};
  border-radius: 13px;
  opacity: ${(props) => (props.on ? "100%" : "40%")};
`;
