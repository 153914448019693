import { Title } from './Content';
const SelectBox = ({ type, setType, title, arr, on }: any) => {
  if (!on) {
    return <></>;
  }

  return (
    <div className='flex-column report_result_box bg-white browser-bg-FFFFFF  border-radius-8 gap-8 padding-b-16'>
      <Title text={title} />

      <div className='flex-row padding-x-16 gap-12'>
        {arr.map((el: any) => (
          <div
            className={
              (type === el.type ? 'bg-primary-600' : 'bg-light-gray-200') +
              ' flex-1 flex-center padding-12-12-12-8 border-radius-8 hover'
            }
            onClick={() => {
              if (el.type === 'R') {
                alert('서비스 준비중입니다.');
              } else {
                setType(el.type);
              }
            }}
          >
            <div
              className={`f-size-14 line-h-20 ${
                type === el.type
                  ? 'color-white font-bold'
                  : 'font-medium color-disabled'
              }`}
            >
              {type === el.type ? '✓ ' : ''}
              {el.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectBox;
