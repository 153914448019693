
export const Graph = ({ avg, pri }: { avg: number; pri: number }) => {
  let aH = 0;
  let pH = 0;
  if (avg < pri) {
    aH = Math.abs((avg / pri) * 120);
    pH = 120;
  } else if (avg > pri) {
    pH = Math.abs((pri / avg) * 120);
    aH = 120;
  } else {
    aH = 120;
    pH = 120;
  }
  const isMinusAvg = avg < 0;
  const isMinusPri = pri < 0;
  return (
    <div className="flex-column center-x center-y padding-24-46 gap-12">
      <div className="flex-column center-x center-y">
        <div
          className="flex-row end-y w-100per center-x"
          style={{ borderBottom: "2px #171E2610 dashed" }}
        >
          {avg >= 0 ? (
            <div className="flex-column">
              {avg < pri ? (
                <div
                  className="flex-column start-x end-y z-10"
                  style={{
                    width: "64px",
                    height: `${pH - aH + 14}px`,
                  }}
                >
                  <div className="padding-4-8 bg-blue-gray-700 border-radius-8">
                    <div className="font-medium f-size-14 line-h-20 color-FFFFFF">
                      +{Math.round((pri / avg) * 100) - 100}%
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className="flex-column end-x center-y"
                style={{
                  width: "68px",
                  height: `${aH}px`,
                  backgroundColor: "#EAF8F2",
                  borderRadius: "8px",
                  paddingBottom: "8px",
                }}
              >
                <div
                  className="font-bold f-size-12 line-h-14"
                  style={{ color: "#171E2666" }}
                >
                  {Math.round(avg).toLocaleString()}
                </div>
              </div>
            </div>
          ) : avg < 0 && pri >= 0 ? (
            <div className="flex-column">
              {avg < pri ? (
                <div
                  className="flex-column start-x end-y z-10"
                  style={{
                    width: "64px",
                  }}
                >
                  <div className="padding-4-8 bg-blue-gray-700 border-radius-8">
                    <div className="font-medium f-size-14 line-h-20 color-FFFFFF">
                      +{100 - Math.round((pri / avg) * 100)}%
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className="flex-column end-x center-y"
                style={{
                  width: "68px",
                  height: `${pH - 14}px`,
                }}
              >
                <div
                  className="font-bold f-size-12 line-h-14"
                  style={{ color: "#171E2666", height: "12px" }}
                ></div>
              </div>
            </div>
          ) : (
            ""
          )}
          {(avg >= 0 && pri >= 0) || (avg < 0 && pri >= 0) ? (
            <div className="flex-column">
              {aH < pH ? (
                <div
                  className="flex-column end-x center-y"
                  style={{
                    width: "64px",
                    height: `${aH < pH ? pH - aH : aH - pH}px`,
                    borderTop: "1px #5E7188 dashed",
                  }}
                ></div>
              ) : (
                ""
              )}
              <div
                className="flex-column end-x center-y"
                style={{
                  width: "64px",
                  height: `${aH < pH ? aH : pH}px`,
                  borderTop: avg < 0 && pri >= 0 ? "" : "1px #5E7188 dashed",
                }}
              ></div>
            </div>
          ) : (
            <div style={{ width: "68px" }}></div>
          )}
          {pri >= 0 ? (
            <div
              className="flex-column start-y"
              // style={{ paddingBottom: aH + "px" }}
            >
              {avg > pri ? (
                <div
                  className="flex-column start-x center-y"
                  style={{
                    width: "64px",
                    height: "36px",
                  }}
                >
                  <div className="padding-4-8 bg-blue-gray-700 border-radius-8">
                    <div className="font-medium f-size-14 line-h-20 color-FFFFFF">
                      -{100 - Math.round((pri / avg) * 100)}%
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className="flex-column end-x center-y"
                style={{
                  width: "68px",
                  height: `${pH}px`,
                  backgroundColor: "#09CE7D",
                  borderRadius: "8px",
                  paddingBottom: "8px",
                  alignSelf: "start",
                }}
              >
                <div
                  className="font-bold f-size-12 line-h-14"
                  style={{ color: "#FFFFFF64" }}
                >
                  {Math.round(pri).toLocaleString()}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ width: "68px" }}></div>
          )}
        </div>
        <div className="flex-row start-y w-100per center-x">
          {avg < 0 ? (
            <div className="flex-column">
              <div
                className="flex-column end-x center-y"
                style={{
                  width: "68px",
                  height: `${aH}px`,
                  backgroundColor: "#EAF8F2",
                  borderRadius: "8px",
                  paddingBottom: "8px",
                }}
              >
                <div
                  className="font-bold f-size-12 line-h-14"
                  style={{ color: "#171E2666" }}
                >
                  {Math.round(avg).toLocaleString()}
                </div>
              </div>
              {avg > pri ? (
                <div
                  className="flex-column end-x end-y z-10"
                  style={{
                    width: "64px",
                    height: `${pH - aH + 14}px`,
                  }}
                >
                  <div className="padding-4-8 bg-blue-gray-700 border-radius-8">
                    <div className="font-medium f-size-14 line-h-20 color-FFFFFF">
                      {100 - Math.round((pri / avg) * 100)}%
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : avg > 0 && pri < 0 ? (
            <div className="flex-column">
              <div
                className="flex-column end-x center-y"
                style={{
                  width: "68px",
                  height: `${pH - 14}px`,
                }}
              >
                <div
                  className="font-bold f-size-12 line-h-14"
                  style={{ color: "#171E2666", height: "12px" }}
                ></div>
              </div>
              {avg > pri ? (
                <div
                  className="flex-column start-x end-y z-10"
                  style={{
                    width: "64px",
                    height: `${pH - aH + 14}px`,
                  }}
                >
                  <div className="padding-4-8 bg-blue-gray-700 border-radius-8">
                    <div className="font-medium f-size-14 line-h-20 color-FFFFFF">
                      -{100 - Math.round((pri / avg) * 100)}%
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {(avg < 0 && pri < 0) || (avg > 0 && pri < 0) ? (
            <div className="flex-column">
              <div
                className="flex-column end-x center-y"
                style={{
                  width: "64px",
                  height: `${aH < pH ? aH : pH}px`,
                  borderBottom: "1px #5E7188 dashed",
                }}
              ></div>
              {aH < pH ? (
                <div
                  className="flex-column end-x center-y"
                  style={{
                    width: "64px",
                    height: `${aH < pH ? pH - aH : aH - pH}px`,
                    borderBottom: "1px #5E7188 dashed",
                  }}
                ></div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div style={{ width: "68px" }}></div>
          )}
          {pri < 0 ? (
            <div
              className="flex-column start-y"
              // style={{ paddingBottom: aH + "px" }}
            >
              <div
                className="flex-column end-x center-y"
                style={{
                  width: "68px",
                  height: `${pH}px`,
                  backgroundColor: "#09CE7D",
                  borderRadius: "8px",
                  paddingBottom: "8px",
                  alignSelf: "start",
                }}
              >
                <div
                  className="font-bold f-size-12 line-h-14"
                  style={{ color: "#FFFFFF64" }}
                >
                  {Math.round(pri).toLocaleString()}
                </div>
              </div>
              {avg < pri ? (
                <div
                  className="flex-column start-x center-y"
                  style={{
                    width: "64px",
                    height: "36px",
                  }}
                >
                  <div className="padding-4-8 bg-blue-gray-700 border-radius-8">
                    <div className="font-medium f-size-14 line-h-20 color-FFFFFF">
                      {100 - Math.round((pri / avg) * 100) ? "+" : ""}
                      {100 - Math.round((pri / avg) * 100)}%
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div style={{ width: "68px" }}></div>
          )}
        </div>
      </div>
      <div className="flex-row end-y w-100per center-x">
        <div
          className="flex-column end-x center-y"
          style={{
            width: "68px",
          }}
        >
          <div
            className="font-medium f-size-12 line-h-14"
            style={{ color: "#171E2666" }}
          >
            지역 평균
          </div>
        </div>
        <div
          style={{
            width: "64px",
          }}
        ></div>
        <div
          className="flex-column end-x center-y"
          style={{
            width: "68px",
          }}
        >
          <div className="font-bold f-size-12 line-h-14 color-text-secondary">
            내 농장
          </div>
        </div>
      </div>
    </div>
  );
};
