import './UsageGuideSlider.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const UserGuideSlider = () => {
  const settings = {
    dots: false,
    infinite: false,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    pauseOnFocus: true,
    centerMode: true,
    centerPadding: '0',
    appendDots: (dots: any) => (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),
    dotsClass: 'dots_custom',
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: false,
          autoplay: true,
          centerMode: true,
          centerPadding: '10px',
          speed: 2000,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className='carousel'>
      <Slider {...settings}>
        <div className='step-box 1'>
          <p className='step-title font-extra-bold f-size-18'>
            1. 홈에서 '가격 분석' 선택
          </p>
          <img className='step-img' src='/asset/images/promotion/guide01.gif' />
          <div className='step-text-box'>
            <div className='step-text'>
              <img src='/asset/images/promotion/number-circle-1.svg' alt='1' />
              <p className='font-medium f-size-16'>
                트랜스파머 메인 홈 화면에서 가격 분석 메뉴를 선택합니다.
              </p>
            </div>
            <div className='step-text'>
              <img src='/asset/images/promotion/number-circle-2.svg' alt='2' />
              <p className='font-medium f-size-16'>
                농지, 전원주택 중 원하는 가격 분석 항목을 선택한 후
              </p>
            </div>
            <div className='step-text'>
              <img src='/asset/images/promotion/number-circle-3.svg' alt='3' />
              <p className='font-medium f-size-16'>
                알고 싶은 농지 또는 전원주택 주소를 입력하거나 지도를
                움직여원하는 장소로 이동합니다.
              </p>
            </div>
          </div>
        </div>
        <div className='step-box 2'>
          <p className='step-title font-extra-bold f-size-18'>2. 주소지 선택</p>
          <img className='step-img' src='/asset/images/promotion/guide02.gif' />
          <div className='step-text-box'>
            <div className='step-text'>
              <img src='/asset/images/promotion/number-circle-1.svg' alt='1' />
              <p className='font-medium f-size-16'>
                검색창에 관심 있는 주소지를 시,도 단위부터 차례로 입력하거나
              </p>
            </div>
            <div className='step-text'>
              <img src='/asset/images/promotion/number-circle-2.svg' alt='2' />
              <p className='font-medium f-size-16'>
                지도를 움직여 주소지를 선택합니다.
              </p>
            </div>
            <div className='step-text'>
              <img src='/asset/images/promotion/number-circle-3.svg' alt='3' />
              <p className='font-medium f-size-16'>
                선택한 주소지 정보 화면 아래 상세보기를 누르면 상세한 정보를
                확인 할 수 있습니다.
              </p>
            </div>
          </div>
        </div>
        <div className='step-box 3'>
          <p className='step-title font-extra-bold f-size-18'>3. 결과 확인</p>
          <img className='step-img' src='/asset/images/promotion/guide03.gif' />
          <div className='step-text-box'>
            <div className='step-text'>
              <img src='/asset/images/promotion/number-circle-1.svg' alt='1' />
              <p className='font-medium f-size-16'>
                상세보기 화면을 아래로 내리면 AI추정가의 공시지가 및 인근 평당
                대비가격을 확인 할 수 있습니다.
              </p>
            </div>
            <div className='step-text'>
              <img src='/asset/images/promotion/number-circle-2.svg' alt='2' />
              <p className='font-medium f-size-16'>
                공시지가의 연간 추이도 확인 할 수 있습니다.
              </p>
            </div>
            <div className='step-text'>
              <img src='/asset/images/promotion/number-circle-3.svg' alt='3' />
              <p className='font-medium f-size-16'>
                해당 주소지의 거래이력과 함께 주변의 최근 거래 사례로 실거래가도
                추정 가능합니다.
              </p>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};
export default UserGuideSlider;
