import './Terms.css';
import { TopNav2, TopNavWithTerms } from '../../../component/mobile/Topnav';
import Banner from '../../../component/AIIntroduction/Footer';
import Footer from '../../../component/browser/Footer';
import ScrollToTopButton from '../../../component/common/ScrollToTop';

const InfoTerms = ({ SNS, setTermsOpen }: any) => {
  return (
    <div
      className='terms-wrap'
      style={{ height: SNS && '700px', position: 'relative' }}
    >
      <ScrollToTopButton bottom={'30px'} mbottom={'30px'} isVisible={true} />
      <TopNav2 text='마케팅 및 혜택 알림 수신 동의' hamburger back />
      <TopNavWithTerms />
      <div className='terms-inner'>
        <div className='terms-title'>마케팅 및 혜택 알림 수신 동의</div>
        <div className='terms-content-box'>
          <div className='terms-privacy-script'>
            트랜스파머(주)(이하 “트랜스파머”)는 더 나은 서비스를 제공하고자
            광고·마케팅 목적의 개인정보 수집 및 이용에 대한 동의를 받고자
            합니다. 수집된 개인 정보는 SMS, PUSH 알림 등 영리목적의 광고성 정보
            전달에 활용되거나 영업 및 마케팅 목적으로 활용될 수 있습니다.
          </div>
          <div className='terms-content-item'>
            <div className='terms-content-item-title'>수집 및 이용 목적</div>
            <div className='terms-content-item-text'>
              트랜스파머 및 제휴사의 상품과 서비스 제공을 위한 분석 및 정보
              추천, 이벤트·프로모션, 혜택 등의 정보를 이메일이나 문자(또는
              카카오 알림톡), 앱 푸시 등을 통해 이용자에게 제공합니다. 결제 안내
              등 의무적으로 안내 되어야 하는 정보성 내용 및 일부 정보는 수신
              동의 여부와 무관하게 제공합니다.
            </div>
          </div>
          <div className='terms-content-item'>
            <div className='terms-content-item-title'>
              수신동의 변경 및 보유기간
            </div>
            <div className='terms-content-item-text'>
              동의 철회 시 또는 회원 탈퇴 시까지이며, 동의를 거부하시는 경우에도
              서비스는 이용이 가능합니다. 단, 맞춤 콘텐츠 추천 및 혜택·이벤트 등
              다양한 정보 안내 서비스를 받으실 수 없습니다.
            </div>
          </div>
          <div className='terms-content-item'>
            <div className='terms-content-item-title'>수신동의 철회 안내</div>
            <div className='terms-content-item-text'>
              수신동의 이후에라도 언제든지 동의를 철회할 수 있으며, 수신을
              동의하지 않아도 회사가 제공하는 기본적인 서비스를 이용하실 수
              있습니다. 다만 수신 거부할 경우 신규 서비스나 상품 관련 소식 등의
              마케팅 정보를 제공받지 못할 수 있습니다.
            </div>
          </div>
          <div className='supplementary-provisions'>
            이 개인정보처리방침은 2023년 11월 1일 부터 적용됩니다.
          </div>
        </div>
        {/* <div
          className={` padding-t-50 flex-center  ${
            SNS
              ? `sticky  bg-FFFFFF w-90per padding-b-20`
              : `padding-b-50 w-33per `
          }`}
          style={{ bottom: '-1px' }}
        >
          <Button
            on={true}
            description='확인'
            onClick={() => {
              SNS ? setTermsOpen(false) : navigate(-1);
            }}
          />
        </div> */}
      </div>
      <Banner />
      <Footer />
    </div>
  );
};

export default InfoTerms;
