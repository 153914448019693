import './SignUp.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  Agriculture,
  Category,
  IdEqualCheck,
  SignUpBtnCheck,
  SignUpType,
  SpinnerOn,
} from '../../store/signUpInfoSlice';
import { Close, Inputbox } from '../../asset/styles/mobile/common/Common';
import { genderOptions, jobOptions, yearOptions } from '../../dummy/dummy';
import Footer from '../../component/browser/Footer';
import Button from '../../component/mobile/Button';
import SignUpService from '../../service/signUp/SignUp';

import { SelectBox, TextBox } from './component';
import { TopNav2, TopNavNewBrowser } from '../../component/mobile/Topnav';
import { SignUpPopUp } from './PopUp';
import Terms from './Terms';
import { TermsSns } from './TermsSns';
import { Helmet } from 'react-helmet';
import { set } from 'lodash';
import { handleKeyDown } from '../../component/function/function';
import { WarningWrapper } from '../../asset/styles/browser/signUp/SignUp';
import { Font } from '../../asset/styles/browser/font/font';
import Banner from '../../component/AIIntroduction/Footer';
import { useNavigate } from 'react-router-dom';
import { hackleSignUptrack, hackleClient } from '../../hackler';
import { GetUserInfo } from '../../utilities/auth';

const SignUpSNS = () => {
  const [onPopUp, setOnPopUp] = useState(false);
  const [userName, setUserName] = useState(sessionStorage.getItem('name'));
  const [email, setEmail] = useState('');
  const [emailInputOpen, setEmailInputOpen] = useState(false);
  const [userPhone, setUserPhone] = useState<any>('');
  const [couponCode, setCouponCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [termsOpen, setTermsOpen] = useState(false);
  const [type, setType] = useState('');

  const dispatch = useDispatch();
  const state = useSelector((state: any) => {
    return state.signUpInfo;
  });
  const state2 = useSelector((state: any) => {
    return state.check;
  });
  console.log(state);

  useEffect(() => {
    const userEmail = sessionStorage.getItem('email');
    if (userEmail === 'undefined') {
      setEmailInputOpen(true);
    }
  }, []);

  const navigate = useNavigate();
  const SignUpApi = async () => {
    if (state2.privacy === false) {
      alert('개인정보 처리방침에 동의해주세요.');
    } else if (state2.transfarmerService === false) {
      alert('트랜스파머 서비스 이용약관에 동의해주세요.');
    } else if (state2.location === false) {
      alert('위치기반서비스 이용약관에 동의해주세요.');
    } else if (userPhone.length < 11) {
      alert('휴대폰 번호를 확인해 주세요.');
    } else {
      dispatch(SpinnerOn(true));
      try {
        const data = {
          loginType: state.signUpType,
          email: sessionStorage.getItem('email'),
          name: userName,
          category: state.category,
          agriculturalNum: state.AgricultureNumber,
          phone_1st: sessionStorage.getItem('phone')?.split('-')[1],
          phone_2nd: sessionStorage.getItem('phone')?.split('-')[2],
          couponCode: couponCode,
          socialId: sessionStorage.getItem('socialId'),
          landing: sessionStorage.getItem('landingType'),
          referer: sessionStorage.getItem('beforeUrl'),
          isNotification: state2.notification === true ? 'Y' : 'N',
        };
        const result = await SignUpService.SignUp(data);
        localStorage.setItem('accessToken', result.data.accessToken);
        const userEmail = sessionStorage.getItem('email') || '';
        dispatch(SpinnerOn(false));
        // setOnPopUp(true);
        hackleClient.setUserId(userEmail);
        hackleSignUptrack(state.signUpType, state.category, userEmail);
        GetUserInfo();
        navigate('/signup/complete', {
          state: { name: sessionStorage.getItem('name') },
        });
        const _nasa: { [key: string]: any } = {};
        if ((window as any).wcs) {
          _nasa['cnv'] = (window as any).wcs.cnv('2', '1');
        }
      } catch (error: any) {
        dispatch(SpinnerOn(false));
      }
    }
  };
  useEffect(() => {
    if (userName && userPhone) {
      dispatch(SignUpBtnCheck(true));
    } else {
      dispatch(SignUpBtnCheck(false));
    }

    if (state.signUpType === 'K' || state.signUpType === 'N') {
      setUserPhone(sessionStorage.getItem('phone'));
    }
  }, [userName, userPhone]);

  const checkSocialType = state.signUpType === 'K' || state.signUpType === 'N';

  const handlePhoneNumberChange = (e: any) => {
    const value = e.target.value;
    setErrorMessage('');
    if (!isNaN(value)) {
      setUserPhone(value);
      setErrorMessage('');
      if (value.length < 11) {
        setErrorMessage(`'-' 없이 숫자만 입력해 주세요`);
      }
    }
  };

  const validateEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getEmailAddress = (e: any) => {
    const value = e.target.value;
    setEmail(value);
    sessionStorage.setItem('email', value);
    if (!validateEmail(value)) {
      setEmailError('이메일 형식이 아닙니다.');
    } else {
      setEmailError('');
    }
  };

  const formatPhoneNumber = (item: any) => {
    let part1 = item.slice(0, 3);
    let part2 = item.slice(3, 7);
    let part3 = item.slice(7);

    let formattedNumber = part1 + '-' + part2 + '-' + part3;

    return formattedNumber;
  };

  const IdEqualCheckApi = async () => {
    dispatch(SpinnerOn(true));
    try {
      const result = await SignUpService.IdEqualCheck(email);
      dispatch(SpinnerOn(false));
      if (result.data.isValid === false) {
        alert('동일한 아이디가 있습니다.');
        dispatch(IdEqualCheck(false));
      } else {
        dispatch(IdEqualCheck(true));
      }
    } catch (error) {
      console.log(error);
      dispatch(SpinnerOn(false));
    }
  };

  useEffect(() => {
    if (userPhone.length === 11) {
      const formattedPhone = formatPhoneNumber(userPhone);
      sessionStorage.setItem('phone', formattedPhone);
    }
  }, [userPhone]);

  const Box = ({ title, value }: any) => (
    <div className='flex-column padding-12-12-12-16 bg-FAFAFA border-radius-13 w-100per gap-2'>
      <div className='m-c1-12-m color-dark-secondary'>{title}</div>
      <div className='f-size-17 line-h-26 color-dark-primary'>{value}</div>
    </div>
  );
  return (
    <div
      className='flex-column w-100per center-y space-between'
      style={{ height: `${window.innerHeight}px`, overflowY: 'auto' }}
    >
      <Helmet>
        <title>회원가입</title>
      </Helmet>
      <TopNav2 back hamburger text='회원가입' />
      {termsOpen && (
        <TermsSns
          setTermsOpen={setTermsOpen}
          termsOpen={termsOpen}
          type={type}
          setType={setType}
        />
      )}
      {onPopUp && <SignUpPopUp setOnPopUp={setOnPopUp} name={userName} />}
      <TopNavNewBrowser />
      <div className='flex-column center-y browser-padding-x-20 padding-x-16 w-360 browser-w-468 gap-32 browser-mt-80 mt-24'>
        <div className='font-medium f-size-16 browser-f-size-26 browser-font-bold line-h-34 color-000000 w-100per text-start'>
          정보확인 및 약관동의
        </div>
        <div className='flex-column gap-12 center-x center-y w-100per  border-b-1-171E2610 padding-b-32'>
          <Box title='이름' value={userName} />
          {state.signUpType === 'F' && emailInputOpen && (
            <div className='w-100per flex-column gap-4'>
              <div className='flex-column bg-FAFAFA border-radius-13 w-100per padding-12-16'>
                <div className='font-regular f-size-12 line-h-18 color-888B90 text-start'>
                  아이디
                </div>
                <div className='flex-row space-between w-100per'>
                  <Inputbox
                    value={email}
                    onKeyDown={handleKeyDown}
                    placeholder='이메일을 입력해 주세요.'
                    onChange={getEmailAddress}
                    // onBlur={(e: any) => {
                    //   e.target.value !== '' && IdEqualCheckApi();
                    // }}
                  />
                  {email.length > 0 && (
                    <img
                      alt=''
                      src='/asset/images/close/close_with_circle.svg'
                      className='pointer'
                      onClick={() => {
                        setEmail('');
                      }}
                    />
                  )}
                </div>
              </div>
              {emailError && (
                <WarningWrapper>
                  <Font
                    fontWeight='400'
                    fontSize='14'
                    lineHeight='0'
                    color='red'
                    textAlign='start'
                  >
                    {emailError}
                  </Font>
                </WarningWrapper>
              )}
            </div>
          )}

          <div className='w-100per flex-column gap-4'>
            <TextBox
              disabled={checkSocialType}
              value={userPhone}
              placeholder='휴대폰 번호를 정확히 기재해 주세요.'
              text='휴대폰 번호'
              onChange={handlePhoneNumberChange}
            ></TextBox>
            <p>{errorMessage}</p>
          </div>
          <SelectBox
            placeholder='어떤 일을 하시나요?'
            option={jobOptions}
            dispatch={Category}
            text='소속 혹은 농촌에 대한 관심사 (선택)'
          ></SelectBox>
          {state.category === 'W' ? (
            <TextBox
              placeholder='농업 경영체 등록 번호를 입력해주세요.'
              dispatch={Agriculture}
              text='농업 경영체 등록 번호'
            ></TextBox>
          ) : (
            <></>
          )}
        </div>
        <div className='w-100per'>
          <Terms SNS setTermsOpen={setTermsOpen} setType={setType} />
        </div>
        <div className='sns-signup-btn'>
          <Button
            on={state.btnCheck}
            onClick={() => {
              SignUpApi();
            }}
            description='확인'
          />
        </div>
      </div>
      <Banner />
      <Footer />
    </div>
  );
};

export default SignUpSNS;
