export default class ReportUtil {
    /**
     * 작물경쟁력, 성공가능성은 기존에 dsr 과 irr 을 이용하여 책정했으나
     * 이경우 대출에 따라 결과가 달라지는 문제가 있어서
     * 영업이익률을 참고하도록 변경함
     */
    // static cropCompetitive = (dsr: number | undefined, irr: number) => {
    //     if (typeof dsr === "number") {
    //         if (dsr < 1 || irr < 1) {
    //             return 2;
    //         } else if (dsr >= 1 && irr < 5) {
    //             return 3;
    //         } else if (dsr >= 1 && irr < 7) {
    //             return 4;
    //         } else if (dsr >= 1 && irr >= 7) {
    //             return 5;
    //         }
    //         return 1;
    //     } else {
    //         if (irr < 1) {
    //             return 2;
    //         } else if (irr < 5) {
    //             return 3;
    //         } else if (irr < 7) {
    //             return 4;
    //         } else if (irr >= 7) {
    //             return 5;
    //         }
    //         return 1;
    //     }
    static cropCompetitive = (profitRate: string | number) => {
        profitRate = Number(profitRate);
        if (profitRate >= 10) {
            return 5;
        } else if (profitRate >= 7) {
            return 4;
        } else if (profitRate >= 3) {
            return 3;
        } else if (profitRate < 3) {
            return 2;
        } else {
            return 2;
        }
    };

}