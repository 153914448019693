import './SignUp.css';
import { useDispatch } from 'react-redux';
import { SignUpType } from '../../store/signUpInfoSlice';
import Footer from '../../component/browser/Footer';
import { TopNav2, TopNavNewBrowser } from '../../component/mobile/Topnav';
import { Helmet } from 'react-helmet';
import TopBanner from '../../component/banner/TopBanner';
import { Link } from 'react-router-dom';
import { RedirectHandler } from '../../controller/Redirect';
import Banner from '../../component/AIIntroduction/Footer';
import { useEffect, useState } from 'react';

const SignUp = () => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);

  const recommendComment = [
    {
      text: `“저처럼 대도시에서 귀농한\n분들께 꼭 추천드려요.”`,
      name: '@gly*****',
    },
    {
      text: '“주말농장 찾는 과정에서\n역할이 정말 컸어요.”',
      name: '@sol****',
    },
    {
      text: '“시골에서 농사를 돕고 계신\n아버지께 알려드렸어요.”',
      name: '@sch*****',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % recommendComment.length);
    }, 1500);

    return () => clearInterval(interval);
  }, [recommendComment.length]);

  return (
    <div className='signup-wrap'>
      <Helmet>
        <title>회원가입</title>
      </Helmet>
      <TopBanner />
      <TopNav2 back hamburger text='회원가입' />
      <TopNavNewBrowser showMenu={false} />
      <div className='signup-inner-box'>
        <div className='signup-inner-contents'>
          <div className='signup-inner-top'>
            <Link to='/'>
              <img
                src='/static/media/Logo(korean).cd0fd29a240a2235848eaaa9da35b62a.svg'
                alt=''
              />
            </Link>
            <div className='comment-slider'>
              <div className='recommend-comment'>
                <div className='recommend-text'>
                  {recommendComment[currentIndex].text}
                </div>
                <div className='recommend-writer'>
                  {recommendComment[currentIndex].name}
                </div>
              </div>
            </div>
          </div>
          <div className='signup-btns'>
            <button
              className='signup-btn signup-btn-k hover'
              onClick={() => {
                dispatch(SignUpType('K'));

                RedirectHandler('Kakao');
              }}
            >
              <img src='/asset/images/icon/kakaochat.svg' alt='카카오' />
              <span>카카오로 가입하기</span>
            </button>
            <button
              className='signup-btn signup-btn-n hover'
              onClick={() => {
                dispatch(SignUpType('N'));
                RedirectHandler('Naver');
              }}
            >
              <img src='/asset/images/icon/whitenaver.svg' alt='네이버' />
              <span>네이버로 가입하기</span>
            </button>
            <button
              className='signup-btn signup-btn-f hover'
              onClick={() => {
                dispatch(SignUpType('F'));
                RedirectHandler('Facebook');
              }}
            >
              <img src='/asset/images/logo/facebook.svg' alt='페이스북' />
              <span>페이스북으로 가입하기</span>
            </button>
            <button
              className='signup-btn signup-btn-g hover'
              onClick={() => {
                dispatch(SignUpType('G'));
                RedirectHandler('Google');
              }}
            >
              <img src='/asset/images/logo/Google.svg' alt='구글' />
              <span>구글로 가입하기</span>
            </button>
            <Link to='/signUpEmail' className='signup-btn signup-btn-e hover'>
              <img src='/asset/images/icon/green-mail.svg' alt='메일' />
              <span>이메일로 가입하기</span>
            </Link>
          </div>
        </div>
      </div>
      <Banner />
      <Footer />
    </div>
  );
};

export default SignUp;
