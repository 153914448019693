import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TopNav2 } from '../../component/mobile/Topnav';
import ReportInfo from './components/Info';
import ReportDetail1 from './components/detail/ReportDetail1';
import ReportDetail3 from './components/detail/ReportDetail3';
import ReportDetail4 from './components/detail/ReportDetail4';
import ReportDetail5 from './components/detail/ReportDetail5';
import Summary1 from './components/summary/Summary1';
import {
  MouseEvent,
  TouchEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  alertCustom,
  formatDateTime,
  isMobile,
} from '../../component/function/function';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuctionService from '../../service/auction/Auction';
import { LoadingDot } from '../../store/loadingSlice';
import ReportInfo2 from './components/Info2';
import InfoTitle from './components/InfoTitle';
import ReportDetail10 from '../reportResultPrice/components/detail/ReportDetail10';
import { TriangleTooltip } from '../../component/common/tooltip';
import { SaveBtn } from '../../component/common/saveBtn';
import PriceService from '../../service/price/priceApi';
import { set } from 'lodash';
import { SaveReportPopUp } from '../../component/common/saveReportPopUp';
const AuctionReportMain = ({
  reportIdx,
  setClose,
  type,
  mypage,
  search,
  auctionType,
  setCloseSearchPointDetail,
  setOnPointHistory,

  selectedItem,
}: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const param2 = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    getOrigin();
    type = type || param2.type;
    reportIdx = reportIdx || param2.reportIdx;
  }, []);
  // useEffect(() => {
  //   detail();
  // }, [reportIdx]);
  const [originData, setOriginData] = useState<any>();
  const getOrigin = async () => {
    try {
      const result = await PriceService.origin();
      console.log('resuklt', result);
      setOriginData(result.list);
    } catch (error) {
      console.log(error);
    }
  };
  const temp = useRef<HTMLDivElement>(null);
  const [list, setList]: any = useState([]);
  const [dateTooltip, setDateTooltip] = useState(true);
  const [createdTime, setCreatedTime] = useState('');
  const [info, setInfo] = useState({
    address: '',
    area: 0,
    keyword: '',
    avgScore: '',
    buildingArea: null,
    buildingPyeong: null,
    buildingToLandRatio: null,
    category: '',
    estimated: 0,
    floor: null,
    link: '',
    floorAreaRatio: null,
    height: '',
    isFarm: '',
    officialPriceList: {
      2014: 0,
      2015: 0,
      2016: 0,
      2017: 0,
      2018: 0,
      2019: 0,
      2020: 0,
      2021: 0,
      2022: 0,
      2023: 0,
    },
    pnuCode: '',
    pyeong: 0,
    roadSurface: '',
    salesIdx: 0,
    shape: '',
    totalFloorArea: null,
    totalFloorPyeong: null,
    tradeDate: null,
    tradePrice: null,
    useStatus: '',
    year_int: null,
    zoning: '',
    appraisedValue: 0,
  });

  const getAuctionReport = async () => {
    try {
      if (!isMobile(window.innerWidth)) {
        if (!mypage) {
          setClose(false);
          dispatch(LoadingDot('A'));
        } else {
          dispatch(LoadingDot('W2'));
        }
      }

      const res = await AuctionService.report(reportIdx);
      setCreatedTime(res.result.createdTime);
      setInfo(res.result.info);
      if (res.result.list && res.result.list.length > 0) {
        setList(res.result.list);
      } else {
        setList([]);
      }
      dispatch(LoadingDot('N'));
      setClose(true);
    } catch (err) {
      dispatch(LoadingDot('N'));
      console.log(err);
    }
  };

  useEffect(() => {
    getAuctionReport();
  }, [reportIdx]);

  const [dragging, setDragging] = useState(false);
  const [clickPoint, setClickPoint] = useState(0);
  const [clickPoint2, setClickPoint2] = useState(0);
  const [isY, setIsY] = useState(false);
  const [isX, setIsX] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleMouseDownEvent = (e: MouseEvent<HTMLDivElement>) => {
    setDragging(true);
    e.preventDefault();
    e.stopPropagation();

    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'auto';
      setClickPoint(e.pageX); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };

  const handleMouseMoveEvent = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음
    e.preventDefault();
    e.stopPropagation();
    if (containerRef.current) {
      const walk = e.pageX - clickPoint;
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };
  const handleTouchDownEvent = (e: TouchEvent<HTMLDivElement>) => {
    setDragging(true);
    setIsY(false);
    setIsX(false);
    e.stopPropagation();
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'auto';
      setClickPoint(e.touches[0].pageX); // clickPoint는 처음 클릭한 지점
      setClickPoint2(e.touches[0].pageY); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };

  const handleTouchMoveEvent = (e: TouchEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음
    if (isY) {
      return;
    }
    if (
      Math.abs(e.touches[0].pageY - clickPoint2) >
        Math.abs(e.touches[0].pageX - clickPoint) &&
      !isX
    ) {
      setIsY(true);
      return;
    }
    setIsX(true);

    e.stopPropagation();
    if (containerRef.current) {
      const walk = e.touches[0].pageX - clickPoint;
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const term = isMobile(window.innerWidth) ? window.innerWidth : 360;
  const [contentIdx, setContentIdx] = useState(0);
  const handleMouseEventEnd = (e: MouseEvent<HTMLDivElement> | TouchEvent) => {
    if (!dragging) return;
    setDragging(false);
    // e.preventDefault();
    // e.stopPropagation();
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'smooth';

      containerRef.current.scrollLeft =
        containerRef.current.scrollLeft - scrollLeft === 0
          ? containerRef.current.scrollLeft
          : containerRef.current.scrollLeft - scrollLeft > 0
          ? (containerRef.current.scrollLeft - scrollLeft) % term > 50
            ? containerRef.current.scrollLeft -
              (containerRef.current.scrollLeft % term) +
              term
            : containerRef.current.scrollLeft -
              (containerRef.current.scrollLeft % term)
          : (containerRef.current.scrollLeft - scrollLeft) % term < -50
          ? containerRef.current.scrollLeft -
            (containerRef.current.scrollLeft % term)
          : containerRef.current.scrollLeft +
            (term - (containerRef.current.scrollLeft % term));
      // containerRef.current.scrollLeft -
      // (containerRef.current.scrollLeft % term) +
      // ((containerRef.current.scrollLeft - scrollLeft)%term > 50 ? +term : ((containerRef.current.scrollLeft - scrollLeft)%term < -50 ? 0 : + (scrollLeft)%term));
      // setScrollLeft(
      //   containerRef.current.scrollLeft -
      //     (containerRef.current.scrollLeft % term) +
      //     ((containerRef.current.scrollLeft - scrollLeft)%term > 0 ? +term : 0)
      // );
      console.log(
        scrollLeft,
        containerRef.current.scrollLeft,
        containerRef.current.scrollLeft % term,
        (containerRef.current.scrollLeft - scrollLeft) % term,
        +term,
        0,
        (containerRef.current.scrollLeft - scrollLeft) % term > 50
          ? +term
          : (containerRef.current.scrollLeft - scrollLeft) % term < -50
          ? 0
          : +scrollLeft % term
      );

      if (containerRef.current.scrollLeft % term === 0) {
        setContentIdx(containerRef.current.scrollLeft / term);
        console.log(containerRef.current.scrollLeft / term);
      } else {
        setContentIdx(
          Math.round(
            (containerRef.current.scrollLeft - scrollLeft === 0
              ? containerRef.current.scrollLeft
              : containerRef.current.scrollLeft - scrollLeft > 0
              ? (containerRef.current.scrollLeft - scrollLeft) % term > 50
                ? containerRef.current.scrollLeft -
                  (containerRef.current.scrollLeft % term) +
                  term
                : containerRef.current.scrollLeft -
                  (containerRef.current.scrollLeft % term)
              : (containerRef.current.scrollLeft - scrollLeft) % term < -50
              ? containerRef.current.scrollLeft -
                (containerRef.current.scrollLeft % term)
              : containerRef.current.scrollLeft +
                (term - (containerRef.current.scrollLeft % term))) / term
          )
        );
        console.log(
          (containerRef.current.scrollLeft -
            (containerRef.current.scrollLeft % term) +
            (containerRef.current.scrollLeft - scrollLeft > 50
              ? +term
              : containerRef.current.scrollLeft - scrollLeft < -50
              ? 0
              : +scrollLeft)) /
            term
        );
      }
    }
  };

  const handleNavigatePage = (
    e: MouseEvent<HTMLDivElement> | TouchEvent,
    idx: number
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'smooth';
      containerRef.current.scrollLeft = idx * term;
    }
    setContentIdx(idx);
  };

  useEffect(() => {
    setContentIdx(0);
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'smooth';
      containerRef.current.scrollLeft = 0;
    }
  }, [list]);

  return (
    <div
      className='w-100per  browser-h-100per of-hidden  browser-bg-FFFFFF of-y-auto'
      style={{
        // overflowY: mypage && "auto",
        paddingBottom: search ? '30px' : mypage ? '100px' : '',
        backgroundColor: '#FFFFFF',
        height: mypage
          ? search
            ? '100%'
            : isMobile(window.innerWidth)
            ? '100%'
            : '100%'
          : '',
        width: '100%',
      }}
    >
      {(location.pathname.slice(-1) === '/'
        ? location.pathname.slice(0, -1)
        : location.pathname) === '/mypage/point' ? (
        <TopNav2
          browser
          text={
            type === 'S'
              ? '매물 분석'
              : type === 'P'
              ? '공매 분석'
              : '경매 분석'
          }
          closeBack
          close
          setClose={setClose}
          report
          mypage
        />
      ) : (location.pathname.slice(-1) === '/'
          ? location.pathname.slice(0, -1)
          : location.pathname) === '/mypage/map' ? (
        ''
      ) : (
        <TopNav2
          search
          browser
          text={
            type === 'S'
              ? '매물 분석'
              : type === 'P'
              ? '공매 분석'
              : '경매 분석'
          }
          closeBack
          close
          setClose={setClose}
        />
      )}
      {search && (
        <div className='flex-row space-between padding-12-16 center-y'>
          <img
            src='/asset/images/arrow/arrowBack.svg'
            alt='arrow'
            className='pointer'
            onClick={() => {
              setCloseSearchPointDetail(false);
            }}
          />
          <div className='m-b1-16-b color-dark-primary'>
            {auctionType === 'S'
              ? '매물 분석'
              : auctionType === 'P'
              ? '공매 분석'
              : '경매 분석'}
          </div>
          <img
            src='/asset/images/close/CloseBlack24.svg'
            className='pointer'
            alt='close'
            onClick={() => {
              setCloseSearchPointDetail(false);
              setOnPointHistory(false);
            }}
          />
        </div>
      )}
      <div
        className=''
        style={{
          // height: isMobile(window.innerWidth)
          //   ? mypage
          //     ? '700px'
          //     : window.innerHeight - 48
          //   : window.innerHeight - 50,
          height: mypage
            ? search
              ? '100%'
              : isMobile(window.innerWidth)
              ? '100%'
              : '700px'
            : '',
        }}
        ref={temp}
      >
        <div
          className='padding-24-16 flex w relative'
          style={{ height: 'fit-content' }}
        >
          <div className='flex-row space-between border-b-171E26 w-100per padding-b-8'>
            <div className='m-b1-16-m color-text-tertiary'>분석일</div>
            <div className='m-b1-16-m color-dark-secondary'>
              {createdTime &&
                createdTime.split('T')[0] +
                  ' ' +
                  createdTime.split('T')[1].split('.')[0]}
            </div>
          </div>
          {mypage && dateTooltip && (
            <TriangleTooltip
              text='분석 시점의 결과는 현재와 다를 수 있어요.'
              text2='최신 분석 결과는 주소지 조회 후 다시 이용해 주세요.'
              top='28'
              left='50'
              on={true}
              off={() => setDateTooltip(false)}
              xImg={true}
            />
          )}
        </div>
        {list.length ? (
          <div className='padding-16-16-0 mobile-body-1-bold color-text-secondary'>
            <div>합필 입찰 정보 ({list.length}개 소재지)</div>
          </div>
        ) : (
          ''
        )}
        <ReportInfo
          data={info}
          type={
            search
              ? auctionType
              : mypage
              ? isMobile(window.innerWidth)
                ? type
                : auctionType
              : isMobile(window.innerWidth)
              ? location.state.type
              : type
          }
          list={list}
          auctionType={auctionType}
        />
        <div style={{ height: '8px', background: '#F7F8F9' }} />
        {list.length ? (
          <ReportInfo2
            data={info}
            type={type}
            handleNavigatePage={handleNavigatePage}
            list={[...list]}
          />
        ) : (
          ''
        )}
        {list.length ? (
          <div style={{ height: '8px', background: '#F7F8F9' }} />
        ) : (
          ''
        )}
        {list.length ? (
          <div className='padding-16 flex-row flex-between w-100per border-b-1-171E2610 space-between'>
            <div
              style={{
                width: '32px',
                height: '32px',
                background: '#f0f0f0',
                borderRadius: '999px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={(e) => {
                if (contentIdx === 0) return;
                handleNavigatePage(e, contentIdx - 1);
              }}
            >
              <img
                style={{ width: '8px', height: '14px' }}
                src='/asset/images/arrow/ArrowLeftPage.svg'
                alt=''
              />
            </div>
            <div className='web-body-2-bold center-y flex-row gap-8'>
              <p>{contentIdx + 1}</p>
              <p>/</p>
              <p>{list.length}</p>
            </div>
            <div
              style={{
                width: '32px',
                height: '32px',
                background: '#f0f0f0',
                borderRadius: '999px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={(e) => {
                if (contentIdx === list.length - 1) return;
                handleNavigatePage(e, contentIdx + 1);
              }}
            >
              <img
                style={{
                  width: '8px',
                  height: '14px',
                  transform: 'rotate(180deg)',
                }}
                src='/asset/images/arrow/ArrowLeftPage.svg'
                alt=''
              />
            </div>
          </div>
        ) : (
          ''
        )}
        {list.length ? (
          <div
            className='flex-row overflow-auto'
            style={{
              width: isMobile(window.innerWidth)
                ? window.innerWidth + 'px'
                : '365px',
              overflow: 'hidden',
              transition: '1s',
            }}
            ref={containerRef}
            onMouseDown={handleMouseDownEvent}
            onTouchStart={handleTouchDownEvent}
            onMouseLeave={handleMouseEventEnd}
            onTouchEnd={handleMouseEventEnd}
            onMouseUp={(e: any) => {
              handleMouseEventEnd(e);
              setDragging(false);
            }}
            onTouchMove={handleTouchMoveEvent}
            onMouseMove={handleMouseMoveEvent}
          >
            {list.map((el: any) => (
              <MainComponent
                list={list}
                info={{ ...el }}
                info2={info}
                type={type}
                navigate={navigate}
                auctionType={auctionType}
                search={search}
                originData={originData}
                selectedItem={selectedItem}
                mypage={mypage}
              />
            ))}
          </div>
        ) : (
          <MainComponent
            list={list}
            info={info}
            info2={info}
            type={type}
            navigate={navigate}
            auctionType={auctionType}
            mypage={mypage}
            search={search}
            originData={originData}
            selectedItem={selectedItem}
          />
        )}
      </div>
    </div>
  );
};

export default AuctionReportMain;
const MainComponent = ({
  list,
  info,
  info2,
  type,
  navigate,
  auctionType,
  mypage,
  search,
  originData,
  selectedItem,
}: {
  list: any;
  info2: any;
  info: {
    address: string;
    area: number;
    keyword: string;
    avgScore: string;
    buildingArea: null;
    buildingPyeong: null;
    buildingToLandRatio: null;
    category: string;
    estimated: number;
    floor: null;
    link: string;
    floorAreaRatio: null;
    height: string;
    isFarm: string;
    officialPriceList: {
      2014: number;
      2015: number;
      2016: number;
      2017: number;
      2018: number;
      2019: number;
      2020: number;
      2021: number;
      2022: number;
      2023: number;
    };
    pnuCode: string;
    pyeong: number;
    roadSurface: string;
    salesIdx: number;
    shape: string;
    totalFloorArea: null;
    totalFloorPyeong: null;
    tradeDate: null;
    tradePrice: null;
    useStatus: string;
    year_int: null;
    zoning: string;
    appraisedValue: number;
  };
  type: any;
  navigate: any;
  auctionType?: any;
  mypage?: any;
  search?: boolean;
  originData: any;
  selectedItem: any;
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [saveComplete, setSaveComplete] = useState(false);
  const saveReport = async () => {
    dispatch(LoadingDot('Y'));

    try {
      const data = {
        type: isMobile(window.innerWidth) ? location.state.type : type,
        value: isMobile(window.innerWidth)
          ? location.state.value
          : type === 'S'
          ? selectedItem?.address || ''
          : selectedItem?.auctionNumber || '',
      };
      const result = await AuctionService.save(data);
      dispatch(LoadingDot('N'));
      setSaveComplete(true);
      console.log(result);
    } catch (err) {
      console.log(err);
      dispatch(LoadingDot('N'));
    }
  };

  return (
    <div className='flex-column'>
      {saveComplete && <SaveReportPopUp setSavePopUp={setSaveComplete} />}
      {list.length ? (
        <InfoTitle
          address={info ? info.address : ''}
          type={auctionType}
          keyword={info ? info.keyword : ''}
        />
      ) : (
        ''
      )}
      <div
        style={{
          width: isMobile(window.innerWidth) ? window.innerWidth : 360,
        }}
      />
      {info && info.pnuCode ? (
        <div>
          <Summary1 data={info} />
          {info && info.officialPriceList ? (
            <div>
              <div style={{ height: '8px', background: '#F7F8F9' }} />
              <ReportDetail5 data={info} />
              <div style={{ height: '8px', background: '#F7F8F9' }} />
            </div>
          ) : (
            <div style={{ height: '8px', background: '#F7F8F9' }} />
          )}
          <ReportDetail1 data={info} />
          <div style={{ height: '8px', background: '#F7F8F9' }} />
          <ReportDetail4 data={info} />
          <div style={{ height: '8px', background: '#F7F8F9' }}></div>
          {info && info.isFarm === 'Y' ? '' : <ReportDetail3 data={info} />}

          <div
            style={{
              padding: '16px ',
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
            }}
          >
            {info2.link ? (
              <div
                className='hover'
                style={{
                  width: '100%',
                  padding: '17px 16px',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '2px',
                  borderRadius: '8px',
                  border: info2.link
                    ? ' 1px solid #1e9b6b'
                    : '1px solid #F0F0F0',
                  justifyContent: 'center',
                }}
                onClick={
                  info2.link
                    ? () => window.open(info2.link)
                    : () => {
                        alertCustom('현재 링크 데이터가 없습니다.', '1');
                      }
                }
              >
                <div
                  className={
                    'font-bold f-size-14 line-h-20 ' +
                    (info2.link ? 'color-primary-500' : 'color-text-tertiary')
                  }
                >
                  {type !== 'S' ? '법원경매 사이트' : '농지은행 사이트'}
                </div>
                {info2.link ? (
                  <img src='/asset/images/arrow/ArrowRightGreen20.svg' alt='' />
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
            <div
              className='hover'
              style={{
                width: '100%',
                padding: '17px 16px',
                display: 'flex',
                flexDirection: 'row',
                gap: '2px',
                borderRadius: '8px',
                justifyContent: 'center',
                background: '#FFFFFF',
                border: '1px solid #1E9B6B',
              }}
              onClick={
                info.isFarm === 'Y'
                  ? () => {
                      sessionStorage.setItem('tab', 'findland');
                      navigate('/findland/map?area=' + info.address);
                    }
                  : () => {
                      sessionStorage.setItem('tab', 'house');
                      navigate('/house/map?area=' + info.address);
                    }
              }
            >
              <div className='font-bold f-size-14 line-h-20 color-primary-500'>
                {info.isFarm === 'Y' ? '농지 찾기 서비스' : '내 집 짓기 서비스'}
              </div>
              {info2.link ? (
                <img src='/asset/images/arrow/ArrowRightGreen20.svg' alt='' />
              ) : (
                ''
              )}
            </div>
          </div>
          <div style={{ height: '8px', background: '#F7F8F9' }} />
          {!mypage && !search && <ReportDetail10 originData={originData} />}
          {!mypage && !search && (
            <>
              <div style={{ height: '8px', background: '#F7F8F9' }} />
              <div
                className='w-100per flex-center'
                style={{ padding: '40px 16px' }}
              >
                <SaveBtn onClick={saveReport} />
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
          <div className='flex-row center-x padding-32'>
            <div className='mobile-body-1-bold color-disabled text-center'>
              분석정보가 <br /> 없습니다
            </div>
          </div>
          <div style={{ height: '8px', background: '#F7F8F9' }} />
          <ReportDetail10 originData={originData} />
        </div>
      )}
    </div>
  );
};
