import { RowSectionTitle } from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import { transNum } from '../../../../component/function/function';
import { IReport_desc } from '../../Main';
import { useState } from 'react';
import { Tooltip2 } from '../../../../component/common/tooltip';
import { BoxCard1 } from '../../../../component/report/Box';

const Summary1 = ({ data }: { data: IReport_desc }) => {
  const [tooltip, setTooltip] = useState(false);
  return (
    <div className='browser-bg-FFFFFF'>
      <RowSectionTitle>
        <div className='flex-row relative gap-8'>
          <TextSectionTitle>AI 주택 추정가</TextSectionTitle>
          <img
            src='/asset/images/warn/Warn20.svg'
            className='pointer'
            onClick={() => {
              setTooltip(!tooltip);
            }}
          />
          <div style={{ position: 'absolute', zIndex: '1' }}>
            <Tooltip2
              text={<div>유사 거래 데이터를 기반으로 추정합니다.</div>}
              on={tooltip}
              width
              top='30'
              left='0'
              off={() => {
                setTooltip(false);
              }}
            />
          </div>
        </div>
      </RowSectionTitle>
      <div className='padding-0-16-24 gap-8'>
        <div className='flex-row w-100per'>
          <BoxCard1
            title='AI 주택 추정가'
            value={
              <div className='flex-coloumn'>
                <div className='font-bold f-size-18 line-h-26 color-text-secondary'>
                  {transNum(data.estimatedHousingPrice) + '원'}
                </div>
                <div className='font-medium f-size-12 line-h-14 color-text-tertiary'>
                  {transNum(data.perPyeongEstimatedHousingPrice) + '원/평'}
                </div>
              </div>
            }
          />
          <BoxCard1
            title='실거래가'
            value={
              data.houseTradePrice !== null ? (
                <div className='flex-coloumn'>
                  <div className='font-bold f-size-18 line-h-26 color-text-secondary'>
                    {transNum(data.houseTradePrice) + '원'}
                  </div>
                  <div className='font-medium f-size-12 line-h-14 color-text-tertiary'>
                    {data.houseTradeDate && data.houseTradeDate.slice(0, 10)}
                  </div>
                </div>
              ) : (
                <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
                  최근 5년 내 <br /> 거래 이력이 없습니다.
                </div>
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Summary1;
