import LandInfo from '../../../../component/report/LandInfo';
import { RowTable5 } from '../../../../component/report/Row';

// import { ITotalData } from "../../Main";
const ReportDetail3 = ({ data }: { data: any }) => {
  return (
    <LandInfo.BuildingInfo
      data={data}
      title="건물 정보 분석"
      RowTable={RowTable5}
    />
  );
};

export default ReportDetail3;
