import "react-calendar/dist/Calendar.css"; // css import
import Calendar2 from "react-calendar";
import { useEffect, useState } from "react";
import { on } from "events";
import moment from "moment";
import { COMPLETIONSTATEMENT_TYPES } from "@babel/types";
import { set } from "lodash";
import { isMobile } from "../../../component/function/function";
export const Calendar = ({
  onCalendar,
  setOnCalendar,
  setStartDate,
  setEndDate,
}: // setStartDate,
// setEndDate,
// startDate,
any) => {
  const [date, setDate] = useState("");
  const [date2, setDate2] = useState("");
  const [temp, setTemp] = useState("");
  const [temp2, setTemp2] = useState("");

  useEffect(() => {
    // 모달이 열릴 때 스크롤 막기
    if (onCalendar) {
      document.body.style.overflow = "hidden";
    }

    // 모달이 닫힐 때 스크롤 해제
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [onCalendar]);
  return (
    <div
      className='flex-column w-100per h-100per flex-center '
      style={{
        backgroundColor: "#00000060",
        position: "fixed",
        zIndex: 999,
        overflowX: "hidden",
      }}
    >
      <div
        className={`bg-FFFFFF  border-radius-16 relative gap-16 flex-column ${
          isMobile(window.innerWidth) ? `w-90per` : `w-360`
        }`}
        style={{ height: "520px", paddingTop: "10px", overflow: "hidden" }}
      >
        <style>{`
        .react-calendar {
          font-family: 'Pretendard', sans-serif; 
        }
      `}</style>
        <style>{`
       .react-calendar__navigation {
      span {
          font-size: 16px;
          font-weight: 500;
          font-family : Pretendard

        }
      }
      `}</style>
        <style>{`
    .react-calendar__tile--active {
        background : #EAF8F2;
        color: black; 
      }
      `}</style>

        <div className='flex center-x border-radius-t-l-16 border-radius-t-r-16'>
          <Calendar2
            prevLabel={
              <img
                src='/asset/images/arrow/arrow_left_calendar.svg'
                className='w-12 h-8'
                alt='arrow'
              />
            }
            prev2Label={null}
            nextLabel={
              <img
                src='/asset/images/arrow/arrow_right_calendar.svg'
                className='w-10 h-8'
                alt='arrow'
              />
            }
            next2Label={null}
            formatDay={(locale, date) =>
              date.toLocaleString("en", { day: "numeric" })
            }
            locale='ko-KO'
            // value={startDate}
            selectRange={true}
            className='w-100per bg-FFFFFF border-radius-t-l-16 border-radius-t-r-16'
            onChange={(e: any) => {
              setTemp(e[0]);
              setTemp2(e[1]);
              // setDate(
              //   e.getFullYear() +
              //     "." +
              //     String(e.getMonth() + 1).padStart(2, "0") +
              //     "." +
              //     e.getDate()
              // );
            }}
          />
        </div>
        <div className='flex-row w-100per padding-x-16'>
          <div className='flex-row gap-4 w-50per'>
            <div className='m-c1-12-m color-text-tertiary'>시작일자</div>
            <div className='m-c1-12-m color-dark-primary'>
              {temp && moment(temp).format("YYYY년 MM월 DD일")}{" "}
            </div>
          </div>
          <div className='flex-row gap-4'>
            <div className='m-c1-12-m color-text-tertiary'>종료일자</div>
            <div className='m-c1-12-m color-dark-primary'>
              {temp2 && moment(temp2).format("YYYY년 MM월 DD일")}{" "}
            </div>
          </div>
        </div>
        <div className='flex-row padding-x-16 gap-8'>
          <div
            className='w-50per flex-center padding-14 bg-blue-gray-100 border-radius-8 pointer'
            onClick={() => {
              setOnCalendar(false);
            }}
          >
            <div className='m-t2-18-b color-blue-gray-600'>닫기</div>
          </div>
          <div
            className='w-50per flex-center padding-14 bg-primary border-radius-8 pointer'
            onClick={() => {
              if (temp && temp2) {
                setStartDate(moment(temp).format("YYYY-MM-DD"));
                setEndDate(moment(temp2).format("YYYY-MM-DD"));
                setOnCalendar(false);
              } else {
                alert("일자를 선택해주세요.");
              }
            }}
          >
            <div className='m-t2-18-b color-FFFFFF'>확인</div>
          </div>
        </div>
      </div>
    </div>
  );
};
