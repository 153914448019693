import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button2 from '../../component/mobile/Button';
import Footer from '../../component/browser/Footer';
import {
  TopNav2,
  TopNavNewBrowserWithHamburger,
} from '../../component/mobile/Topnav';
import Ellipse from '../../asset/images/terms/Ellipse.svg';
import { isMobile } from '../../component/function/function';
import { Helmet } from 'react-helmet';
import {
  CheckOffBtn,
  CheckOnBtn,
} from '../../asset/styles/mobile/signUp/Terms';
import { DeletePopUp } from './DeletePopUp';
import './delete.css';
import { useSelector } from 'react-redux';
import SignUpService from '../../service/signUp/SignUp';
import { DeletePopUp2 } from './DeletePopUp2';

const Delete = () => {
  const [inputValue, setInputValue] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [focused, setFocused] = useState(false);
  const navigate = useNavigate();
  const [onPopUp, setOnPopUp] = useState(false);
  const [socialDelete, setSocialDelete] = useState(false);
  const [checkedStates, setCheckedStates] = useState(Array(9).fill(false));
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [state, setState] = useState({
    reason: '',
    password: '',
  });

  // const WithDrawalInfo = async () => {
  //   const data = {
  //     password: '',
  //     reason: selectedReasons,
  //   };
  //   try {
  //     const result = await SignUpService.Withdrawal(data);
  //     setSocialDelete(true);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const SignupType = useSelector((state: any) => {
    return state.signUpInfo.signUpType;
  });
  console.log(SignupType, 'SignupType');
  const handleFocusBlur = (isFocused: any) => {
    setFocused(isFocused);
  };

  const handleCheckboxClick = (index: number) => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = !newCheckedStates[index];
    setCheckedStates(newCheckedStates);

    const reasons = [
      '(필수) 유의사항을 확인하였으며 동의합니다.',
      '서비스 사용을 잘 안하게 돼요.',
      '서비스 사용이 어려워요.',
      '다른 유사 서비스를 이용 중이에요.',
      '서비스 이용 금액이 비싼 것 같아요.',
      '혜택(무료 포인트, 쿠폰)이 너무 적어요.',
      '개인정보를 삭제하고 싶어요.',
      '다른 계정이 있어요.',
      '기타',
    ];

    const selectedReasons = newCheckedStates.reduce((acc, isChecked, idx) => {
      if (isChecked) {
        acc.push(reasons[idx]);
      }
      return acc;
    }, []);

    setSelectedReasons(selectedReasons);

    setState((prevState) => ({
      ...prevState,
      reason: selectedReasons.join('\n'),
    }));

    if (index === 8) {
      setShowInput(newCheckedStates[8]);
    }
  };

  function CheckImageWithText(props: { children: string }) {
    return (
      <div className='withdrawal-notice'>
        <div className='withdrawal-notice-dot'>
          <img src={Ellipse} alt='' />
        </div>
        {props.children}
      </div>
    );
  }

  return (
    <div className='flex-column w-100per center-y browser-space-between'>
      <Helmet>
        <title>회원탈퇴</title>
      </Helmet>
      <TopNav2 back hamburger text='회원탈퇴' />
      {onPopUp && (
        <DeletePopUp
          setOnPopUp={setOnPopUp}
          selectedReasons={selectedReasons}
        />
      )}
      <TopNavNewBrowserWithHamburger />
      <div
        className={`flex-column ${
          isMobile(window.innerWidth) ? `w-100per` : `w-468`
        } padding-32-16-24-16 tablet-padding-32-40-24-40 center-y  gap-24`}
      >
        {socialDelete && <DeletePopUp2 />}
        {!isMobile(window.innerWidth) && (
          <div className='flex-start w-100per' style={{ marginTop: '48px' }}>
            <div className='font-bold f-size-26 line-h-34 color-dark-primary '>
              회원탈퇴
            </div>
          </div>
        )}
        <div className='flex-column gap-32 w-100per'>
          <div className='font-bold f-size-18 line-h-26 color-dark-primary w-100per text-start'>
            {localStorage.getItem('username')}님<br />
            <span style={{ color: 'rgba(30, 155, 107, 1)' }}>
              정말 떠나시겠어요?
            </span>
          </div>
        </div>
        <div className='flex-column gap-32 w-100per gap-16'>
          <div className='box'>
            <CheckImageWithText>
              탈퇴 시 사용자의 모든 정보가 사라지고 복구가 불가능합니다.
            </CheckImageWithText>
            <CheckImageWithText>
              단, 탈퇴 고객의 개인정보는 회원에게 개인정보 보관기간에 대해
              별도의 동의를 얻은 경우, 또는 관련 법령에서 정한 정보보관 의무
              기간 동안 안전하게 보관합니다.
            </CheckImageWithText>
            <CheckImageWithText>
              보유하고 계신 트랜스파머의 보유 포인트, 쿠폰, 내 보고서 등은 자동
              소멸되어 재가입하실 경우에도 복원되지 않습니다.
            </CheckImageWithText>
            <CheckImageWithText>
              자세한 사항은 개인정보처리 방침을 확인해 주세요.
            </CheckImageWithText>
            <CheckImageWithText>
              탈퇴 최종 확인은 비밀번호 인증으로 진행됩니다.
            </CheckImageWithText>
          </div>
        </div>

        <div className='flex-column gap-16 w-100per'>
          <div
            className='padding-0-16-0 flex w-100per pointer'
            style={{
              gap: '8px',
              borderBottom: '1px solid #171E261A',
              paddingBottom: '32px',
            }}
            onClick={() => handleCheckboxClick(0)}
          >
            {checkedStates[0] ? <CheckOnBtn /> : <CheckOffBtn />}
            (필수) 유의사항을 확인하였으며 동의합니다.
          </div>
        </div>
        <div className='flex-column w-100per gap-24 '>
          <div className='flex-column gap-8'>
            <div className='font-bold f-size-18 line-h-26 color-dark-primary w-100per text-start'>
              <span
                className='font-bold f-size-18 line-h-26 color-dark-primary'
                style={{ color: 'rgba(30, 155, 107, 1)' }}
              >
                떠나시는 이유
              </span>
              가 궁금해요.
            </div>
            <div className='font-medium f-size-16 line-h-26 color-888B90'>
              더 발전하는 서비스가 될 수 있도록 노력하겠습니다.
            </div>
          </div>

          <div className='flex-column w-100per'>
            <div className='box2' style={{ padding: '16px', gap: '24px' }}>
              <div
                className='box2Text'
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(1)}
              >
                {checkedStates[1] ? <CheckOnBtn /> : <CheckOffBtn />}
                서비스 사용을 잘 안하게 돼요.
              </div>
              <div
                className='box2Text'
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(2)}
              >
                {checkedStates[2] ? <CheckOnBtn /> : <CheckOffBtn />}
                서비스 사용이 어려워요.
              </div>
              <div
                className='box2Text'
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(3)}
              >
                {checkedStates[3] ? <CheckOnBtn /> : <CheckOffBtn />}
                다른 유사 서비스를 이용 중이에요.
              </div>
              <div
                className='box2Text'
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(4)}
              >
                {checkedStates[4] ? <CheckOnBtn /> : <CheckOffBtn />}
                서비스 이용 금액이 비싼 것 같아요.
              </div>
              <div
                className='box2Text'
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(5)}
              >
                {checkedStates[5] ? <CheckOnBtn /> : <CheckOffBtn />}
                혜택(무료 포인트, 쿠폰)이 너무 적어요.
              </div>
              <div
                className='box2Text'
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(6)}
              >
                {checkedStates[6] ? <CheckOnBtn /> : <CheckOffBtn />}
                개인정보를 삭제하고 싶어요.
              </div>
              <div
                className='box2Text'
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(7)}
              >
                {checkedStates[7] ? <CheckOnBtn /> : <CheckOffBtn />}
                다른 계정이 있어요.
              </div>
              <div
                className='box2Text'
                style={{ display: 'flex', gap: '8px' }}
                onClick={() => handleCheckboxClick(8)}
              >
                {checkedStates[8] ? <CheckOnBtn /> : <CheckOffBtn />}
                기타
              </div>
              {showInput && (
                <input
                  className={`customdelete-input ${focused ? 'focused' : ''}`}
                  onFocus={() => handleFocusBlur(true)}
                  onBlur={() => handleFocusBlur(false)}
                  type='text'
                  placeholder='다른 이유가 있다면 알려주시면 감사하겠습니다.'
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              )}
            </div>
          </div>
        </div>
        <div className='flex-column gap-32  w-100per'>
          <div className='padding-16-0-48-0 flex border-t-171E26 w-100per gap-8'>
            <Button2
              on={false}
              onClick={() => {
                navigate('/mypage', { replace: true });
              }}
              description='취소'
            />
            <Button2
              on={checkedStates[0]}
              onClick={() => {
                if (!checkedStates[0]) {
                  alert('필수 항목에 동의해야 합니다.');
                } else {
                  setOnPopUp(true);
                }
              }}
              description='탈퇴하기'
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Delete;
