import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, { Suspense, useEffect, useState } from 'react';

import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import Spinner from './component/browser/Spinner';
import path from 'path';

import KakaoRedirect from './pages/auth/KakaoRedirect';
import NaverRedirect from './pages/auth/NaverRedirect';
import FacebookRedirect from './pages/auth/FacebookRedirect';
import GoogleRedirect from './pages/auth/GoogleRedirect';

import SignUpSNS from './pages/signUp/SignUpSNS';
import SignUp from './pages/signUp/SignUp';
import Delete from './pages/signUp/delete';

import AIFarmingMap from './pages/aiFarming/map/Main';
import AIFarmingTicket from './pages/aiFarming/ticket/Main';
import AIFarmingTicketPurchase from './pages/aiFarming/ticket/Purchase';
// import Main from './pages/reportResult/ReportResult';

import Mypage from './pages/mypage/Mypage';
import Survey from './pages/signUp/Survey';
import Modify from './pages/mypage/modify/Modify';
import Edit from './pages/mypage/edit/Edit';
import Question from './pages/question/Question';
import Notice from './pages/notice/Notice';
import Community from './pages/community/Community';
import CommunityEdit from './pages/community/CommunityEdit';
import CommunityDetail from './pages/community/CommunityDetail';
import ReComment from './pages/community/ReComment';
import Inquiry from './pages/inquiry/Inquiry';
import InquiryDetail from './pages/inquiry/InquiryDetail';
import InquiryDetail2 from './pages/inquiry/InquiryDetail2';
import CommentModify from './pages/community/CommentModify';
import CS from './pages/mypage/cs';
import Report from './pages/report/Report';
import Current from './pages/report/Current';
import Cost from './pages/report/Cost';
import UpdateAgricultural from './pages/signUp/UpdateAgricultural';
import AuthProvider from './component/common/authProvider';
import ProtectedRoute from './component/common/protectedRoute';
import LandValueReport from './pages/findLand/component/Report';
import HouseInput from './pages/house/report/Report';
import ScrollToTop from './controller/scroll/ScrollToTop';
import FarmingReportMain from './pages/aiFarming/report/Main';
import InquiryWrite from './pages/inquiry/InquiryWrite';
import SearchReportMain from './pages/reportResultSearch/Main';
import HouseReportMain from './pages/reportResultHouse/Main';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingDot } from './store/loadingSlice';
import PensionInput from './pages/pension/report/Report';
import PensionReportMain from './pages/reportResultPension/Main';
import PriceReportMain from './pages/reportResultPrice/Main';
import { request } from './service/service';
import Loading from './component/common/Loading';
import Main from './pages/main/NewMain';
import ReactGA from 'react-ga';
import PrivacyTerms from './pages/signUp/terms/Privacy';
import LocationTerms from './pages/signUp/terms/Location';
import InfoTerms from './pages/signUp/terms/Info';
import ServiceTerms from './pages/signUp/terms/Service';
import AuctionReportMain from './pages/reportResultSale/Main';
import Promotion from './pages/banner/Report';
import Recharge from './pages/point/Recharge';
import PointMain from './pages/point/Main';
import Payment from './pages/point/Payment';
import Profile from './pages/profile/Profile';
import HiddenPolicy from './pages/hiddenPolicy/Main';
import Complete from './pages/point/RechargeComplete';
import userSlice, { UserReset } from './store/userSlice';

import Find3 from './pages/auth/find/Find3';
import Find from './pages/auth/find/Find';
import SignupPromotion from './pages/promotion/signupPages/Price';
import SignupAnnuity from './pages/promotion/signupPages/Annuity';
import PointHistory from './pages/mypage/history/PointHistory';
import RefundPolicy from './pages/mypage/refund/RefundPolicy';
import TagManager from 'react-gtm-module';
import { LoginPopUp2 } from './component/common/LoginPopUp2';
import Complete2 from './pages/point/RechargeComplete2';
import SignUpEmail from './pages/signUp/SignUpEmail';
import FreePoint from './pages/mypage/freePoint/FreePoint';
import SignupFindLand from './pages/promotion/signupPages/FindLand';
import SignupAiFarming from './pages/promotion/signupPages/AiFarming';
import SignupMyHouse from './pages/promotion/signupPages/MyHouse';
import UserService from './service/checkAccount/CheckAccount';
import NoticeDetail from './pages/notice/NoticeDetail';

import PriceReportMain2 from './pages/reportResultPrice/Main copy';
import MypageReport from './pages/mypage/report/Report';
import Consulting from './pages/promotion/consulting/Consulting';
import ConsultingPopUp from './pages/promotion/consulting/Popup';
import { RechargeFail } from './pages/point/RechargeFail';
import SignUpComplete from './pages/signUp/SignUpComplete';
import { GetUserInfo } from './utilities/auth';
import { hackleClient } from './hackler';
import { PropertyOperationsBuilder } from '@hackler/react-sdk';
import NewsRoom from './pages/newsRoom/newsRoom';
import NewsRoomDetail from './pages/newsRoom/newsRoomDetail';

const Login = React.lazy(() => import('./pages/auth/Login'));
const Terms = React.lazy(() => import('./pages/signUp/Terms'));

declare global {
  interface Window {
    kakao: any;
    INIStdPay: any;
  }
}
function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-KGBR7N3P', // GTM 컨테이너 ID로 대체해야 함
  };

  const dispatch = useDispatch();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    localStorage.removeItem('persist:root');
    dispatch(UserReset(''));
  }, []);
  const version = 55;

  useEffect(() => {
    const res = async () => {
      try {
        const result = await request.get('version/1');

        if (result.data.result[0].version !== version) {
          alert('홈페이지가 업데이트 되어 새로고침됩니다.');
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    };

    let timer = setInterval(() => {
      res();
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (localStorage.accessToken) {
      // 방문수 체크
      UserService.visit();
    }
  }, []);

  dispatch(LoadingDot('N'));
  return (
    <>
      <Loading />
      <AuthProvider>
        <Suspense fallback={<Spinner />}>
          <ScrollToTop />
          <Routes>
            <Route element={<Main />} path='/'></Route>
            <Route path='/aiFarming' element={<Navigate replace to='/' />} />
            {/* <Route element={<AfterLoginMain />} path="/afterlogin"></Route> */}
            <Route element={<Login />} path='/login'></Route>
            <Route element={<Find />} path='/find'></Route>
            <Route element={<Find3 />} path='/find3'></Route>
            <Route element={<Terms />} path='/terms'></Route>
            <Route
              element={<PrivacyTerms />}
              path='/terms/privacy_policy'
            ></Route>
            <Route element={<LocationTerms />} path='/terms/location'></Route>
            <Route element={<InfoTerms />} path='/terms/info'></Route>
            <Route element={<ServiceTerms />} path='/terms/service'></Route>
            <Route element={<Notice />} path='/notice'></Route>
            <Route
              element={<NoticeDetail />}
              path='/noticeDetail/:articleIdx'
            ></Route>
            <Route element={<SignUp />} path='/signUp'></Route>
            <Route element={<SignUpEmail />} path='/SignUpEmail'></Route>
            <Route element={<SignUpSNS />} path='/signUpSNS'></Route>
            <Route element={<SignUpComplete />} path='/signup/complete'></Route>
            <Route element={<Delete />} path='/delete'></Route>
            <Route
              element={<UpdateAgricultural />}
              path='/update/agricultural'
            ></Route>
            <Route path='/kakao/redirect' element={<KakaoRedirect />} />
            <Route path='/naver/redirect' element={<NaverRedirect />} />
            <Route path='/facebook/redirect' element={<FacebookRedirect />} />
            <Route path='/google/redirect' element={<GoogleRedirect />} />
            <Route element={<Survey />} path='/survey' />
            <Route element={<AIFarmingMap />} path='/aiFarming/map' />
            <Route element={<AIFarmingMap />} path='/findland/map' />
            <Route element={<AIFarmingMap />} path='/house/map' />
            <Route element={<AIFarmingMap />} path='/price/map' />
            <Route element={<AIFarmingMap />} path='/annuity/map' />
            <Route element={<AIFarmingMap />} path='/auction/map' />
            <Route element={<AIFarmingMap />} path='/mypage/map' />
            <Route element={<AIFarmingTicket />} path='/aiFarming/ticket' />
            <Route
              element={<AIFarmingTicketPurchase />}
              path='/aiFarming/ticket/purchase'
            />
            <Route
              element={
                <ProtectedRoute>
                  <Mypage />
                </ProtectedRoute>
              }
              path='/mypage'
            ></Route>
            <Route
              element={
                <ProtectedRoute>
                  <CS />
                </ProtectedRoute>
              }
              path='/mypage/cs'
            ></Route>
            <Route
              element={
                <ProtectedRoute>
                  <Modify />
                </ProtectedRoute>
              }
              path='/mypage/modify'
            ></Route>
            <Route
              element={
                <ProtectedRoute>
                  <Edit />
                </ProtectedRoute>
              }
              path='/mypage/edit'
            ></Route>
            <Route
              element={
                <ProtectedRoute>
                  <Question />
                </ProtectedRoute>
              }
              path='/question'
            ></Route>
            <Route
              element={
                <ProtectedRoute>
                  <FreePoint />
                </ProtectedRoute>
              }
              path='/mypage/freepoint'
            ></Route>
            <Route
              element={
                <ProtectedRoute>
                  <PointHistory />
                </ProtectedRoute>
              }
              path='/mypage/point'
            ></Route>
            <Route
              element={
                <ProtectedRoute>
                  <MypageReport />
                </ProtectedRoute>
              }
              path='/mypage/report/:reportIdx'
            ></Route>
            <Route
              element={<RefundPolicy />}
              path='/mypage/refundpolicy'
            ></Route>
            <Route element={<Notice />} path='/mypage/notice'></Route>
            <Route
              element={
                <ProtectedRoute>
                  <Inquiry />
                </ProtectedRoute>
              }
              path='/inquiry'
            ></Route>
            <Route element={<InquiryWrite />} path='/inquiry/write'></Route>
            <Route
              element={
                <ProtectedRoute>
                  <InquiryDetail />
                </ProtectedRoute>
              }
              path='/inquiry/detail/:id'
            ></Route>
            <Route
              element={<InquiryDetail2 />}
              path='/inquiry/detail2/:id'
            ></Route>
            <Route
              element={
                <ProtectedRoute>
                  <Community />
                </ProtectedRoute>
              }
              path='/community'
            ></Route>
            <Route
              element={
                <ProtectedRoute>
                  <CommunityEdit mode='W' />
                </ProtectedRoute>
              }
              path='/community/write'
            ></Route>
            <Route
              element={<CommunityDetail />}
              path='/community/detail/:id'
            ></Route>
            <Route
              element={<CommunityEdit mode='M' />}
              path='/community/modify/:id'
            ></Route>
            <Route
              element={<CommentModify />}
              path='/community/commentmodify/:commentIdx'
            ></Route>
            <Route
              element={<ReComment />}
              path='/community/detail/:boardIdx/:commentIdx'
            />
            <Route element={<Report />} path='/operate/report' />
            <Route element={<Current />} path='/operate/report/current' />
            <Route element={<Cost />} path='/operate/report/cost' />
            <Route element={<Report />} path='/search/report' />
            <Route element={<Current />} path='/search/report/current' />
            <Route element={<Cost />} path='/search/report/cost' />
            <Route element={<LandValueReport />} path='/findland/report' />
            <Route element={<HouseInput />} path='/house/input' />
            <Route
              element={<HouseReportMain />}
              path='/house/report/:reportIdx'
            ></Route>
            <Route
              element={<SearchReportMain />}
              path='/findland/report/:reportIdx'
            ></Route>
            <Route
              element={<FarmingReportMain />}
              path='/aiFarming/report/:reportIdx'
            />
            <Route
              element={<PriceReportMain />}
              path='/price/report/:address'
            ></Route>
            <Route
              element={<PensionInput />}
              path='/annuity/input/:address'
            ></Route>
            <Route
              element={<PensionReportMain />}
              path='/annuity/report/:reportIdx'
            ></Route>
            <Route element={<Promotion />} path='/promotion/J7QE9EC'></Route>
            <Route
              element={<SignupPromotion />}
              path={`/promotion/정확한-토지-농지-주택-가격을-알아보는-방법`}
            ></Route>
            <Route
              element={<SignupAnnuity />}
              path={`/promotion/농지_가격_확인부터_농지연금_계획까지_한번에`}
            ></Route>
            <Route
              element={<SignupFindLand />}
              path={`/promotion/농사-수익과-비용분석-AI로-농업-경영-계획-세우기`}
            ></Route>
            <Route
              element={<SignupAiFarming />}
              path={`/promotion/AI로-농장-진단-농업-경영-솔루션`}
            ></Route>
            <Route
              element={<SignupMyHouse />}
              path={`/promotion/전원주택-설계와-건축비-분석을-AI로-간편하게`}
            ></Route>
            <Route
              element={<Consulting />}
              path={`/promotion/트랜스파머-맞춤-토지-컨설팅`}
            ></Route>
            <Route
              element={<Consulting />}
              path={`/promotion/트랜스파머-맞춤-토지-컨설팅/:tab`}
            ></Route>
            <Route
              element={<ConsultingPopUp />}
              path={
                '/promotion/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8-%EB%A7%9E%EC%B6%A4-%ED%86%A0%EC%A7%80-%EC%BB%A8%EC%84%A4%ED%8C%85?utm_source=TF&utm_medium=popup_main&utm_campaign=consulting_report&utm_id=promotion&utm_content=consulting_report_popup'
              }
            ></Route>

            <Route
              element={<AuctionReportMain />}
              path='/auction/report/:reportIdx/:type'
            ></Route>
            <Route element={<Recharge />} path='/point/recharge'></Route>
            <Route
              element={<Complete />}
              path='/point/recharge/payment/complete'
            ></Route>
            <Route
              element={<Complete2 />}
              path='/point/recharge/payment/complete2/:point'
            ></Route>
            <Route element={<PointMain />} path='/point'></Route>

            <Route element={<Payment />} path='/point/recharge/payment'></Route>
            <Route element={<Profile />} path='/profile'></Route>
            <Route element={<HiddenPolicy />} path='/hidden/policy'></Route>
            <Route element={<LoginPopUp2 />} path='/loginpopup'></Route>
            <Route element={<PriceReportMain2 />} path='/price/pdf'></Route>
            <Route element={<RechargeFail />} path='/recharge/fail'></Route>
            <Route element={<NewsRoom />} path='/newsroom'></Route>
            <Route
              element={<NewsRoomDetail />}
              path='/newsroom/detail/:idx'
            ></Route>
          </Routes>
        </Suspense>
      </AuthProvider>
    </>
  );
}

export default App;
