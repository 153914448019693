export const SaveBtn = ({ onClick }: any) => {
  return (
    <div
      className='padding-16 bg-primary w-328 border-radius-8 flex-center pointer'
      onClick={() => {
        onClick();
      }}
    >
      <div className='m-t2-18-b color-FFFFFF'>보고서 저장하기</div>
    </div>
  );
};
