import Button from '../../component/mobile/Button';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';

import SignUpService from '../../service/signUp/SignUp';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../utilities/auth';
import UserService from '../../service/checkAccount/CheckAccount';
import SignController from '../../controller/sign/SignController';
// import  checkDelete from '../src/asset/images/login/check_delete.svg'

export const DeletePopUp2 = ({ reason }: any) => {
  const { isLogin, onLogout } = useAuth();
  const LogoutApi = () => SignController.LogoutApi(onLogout);
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();
  const [password, setPassword] = useState<any>(null);
  const [isDeleted, setIsDeleted] = useState('Y');
  const [createdDate, setCreatedDate] = useState('');

  const state = useSelector((state: any) => {
    return state.signUpInfo;
  });

  const checkUserInfo = async () => {
    try {
      const result = await UserService.checkInfo();
      setUserEmail(result.email);
    } catch (error) {
      console.log(error);
    }
  };
  const stateUserInfo = useSelector((state: any) => {
    return state.userInfo;
  });

  useEffect(() => {
    checkUserInfo();
  }, [stateUserInfo]);

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}.${String(
      currentDate.getMonth() + 1
    ).padStart(2, '0')}.${String(currentDate.getDate()).padStart(
      2,
      '0'
    )} ${String(currentDate.getHours()).padStart(2, '0')}:${String(
      currentDate.getMinutes()
    ).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
    setCreatedDate(formattedDate);
  }, []);

  function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
  }

  return (
    <div
      id='post-meta-col'
      className='flex-column fixed h-100per z-9999 browser-flex-center w-100per flex-center'
      style={{
        backgroundColor: '#00000060',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div className='bg-FFFFFF padding-24-16-16-16 border-radius-16 gap-12 w-312 flex-center flex-column'>
        <div className='flex-column gap-24 w-100per'>
          <div className='flex-column gap-12 flex-center'>
            <img
              className='w-48 h-48 '
              alt='check'
              src='/asset/images/check/change_info_check.svg'
            />
          </div>
          <div className='flex-column gap-8 flex-center'>
            <div className='m-b1-16-b'>회원탈퇴 완료</div>
            <div className='deleteText_1'>
              그동안 트랜스파머를 이용해 주셔서 감사합니다.
              <br />
              더욱 노력하고 발전하는 트랜스파머가 되겠습니다.
            </div>
          </div>
          <div className='flex-column bg-FAFAFA border-radius-13 w-100per padding-24-16 gap-4 '>
            <div className='font-regular f-size-12 line-h-18 color-888B90 text-start '>
              탈퇴 신청 아이디
            </div>
            <div className='flex-row space-between w-100per mb-16'>
              <div className='withdrawalId'>{userEmail}</div>
            </div>
            <div className='font-regular f-size-12 line-h-18 color-888B90 text-start'>
              탈퇴 신청 일자
            </div>
            <div className='flex-row space-between w-100per'>
              <div className='withdrawalDate'>{createdDate}</div>
            </div>
          </div>
          <div className='p flex w-100per gap-8'>
            <Button
              on={true}
              description='확인'
              onClick={() => {
                navigate('/', { replace: true });
                LogoutApi();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
