import { useState } from 'react';
import { isMobile, transNum } from '../../../component/function/function';

export const FilterModal = ({
  open,
  setOpen,
  bCategory,
  eCategory,
  termType,
  setBCategory,
  setECategory,
  setTermType,
  tab,
  totalCount
}: any) => {
  const [spread1, setSpread1] = useState(true);
  const [spread2, setSpread2] = useState(true);
  const [spread3, setSpread3] = useState(true);

  if (!open) {
    return <></>;
  }

  return (
    <div
      className={
        window.innerWidth < 768 ? 'fixed end-y' : 'fixed center-y center-x'
      }
      onClick={() => {}}
      style={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: '#00000025',
        zIndex: 30
      }}
    >
      <div
        className={
          window.innerWidth < 768
            ? 'bg-FFFFFF flex-column border-top-radius-16 w-100per gap-16'
            : 'bg-FFFFFF flex-column border-radius-16 gap-16'
        }
        style={window.innerWidth < 768 ? {} : { width: '360px' }}
      >
        <div className='padding-24-16-4'>
          <div className='flex-row space-between'>
            <div className='font-bold f-size-16 line-h-24 color-text-primary'>
              필터
            </div>
            <img
              src='/asset/images/close/CloseBlack24.svg'
              alt=''
              className='hover'
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          <div className='font-medium f-size-14 line-h-20 color-text-tertiary'>
            원하는 정책 지원 종류를 선택해주세요
          </div>
          <div
            style={{
              overflowY: 'auto',
              marginTop: '24px',
              paddingBottom: '24px',
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              maxHeight: window.innerHeight - 212 + 'px'
            }}
          >
            {tab !== 3 ? (
              <div className='flex-column gap-16'>
                <div className='flex-row space-between center-y'>
                  <div className='font-bold f-size-16 line-h-26 color-text-secondary'>
                    지원 교육 종류
                  </div>
                  <img
                    src='/asset/images/arrow/ArrowTop24.svg'
                    alt=''
                    style={{ transform: spread1 ? '' : 'rotate(180deg)' }}
                    onClick={() => setSpread1((prev) => !prev)}
                  />
                </div>
                <div
                  className='gap-16 '
                  style={{
                    flexWrap: 'wrap',
                    maxHeight: spread1 ? '100%' : 0,
                    overflow: 'hidden'
                  }}
                >
                  <div className='flex-row gap-16' style={{ flexWrap: 'wrap' }}>
                    {[
                      '후계농업경영인',
                      '청년',
                      '귀농귀촌',
                      '전문가',
                      '컨설팅',
                      '마케팅',
                      '인증',
                      '식품',
                      '기타'
                    ].map((el, idx) => (
                      <div
                        key={idx}
                        className='flex-row center-y gap-8 w-50per hover'
                        style={{ flex: '1 1 40%' }}
                        onClick={
                          eCategory.includes(el)
                            ? () => {
                                if (eCategory.includes(',' + el)) {
                                  setECategory((prev: any) =>
                                    prev.split(',' + el).join('')
                                  );
                                } else if (eCategory.includes(el + ',')) {
                                  setECategory((prev: any) =>
                                    prev.split(el + ',').join('')
                                  );
                                } else {
                                  setECategory('');
                                }
                              }
                            : () => {
                                setECategory((prev: any) =>
                                  prev === '' ? el : prev + ',' + el
                                );
                              }
                        }
                      >
                        {eCategory.includes(el) ? (
                          <img
                            src='/asset/images/check/Check24Green.svg'
                            alt=''
                          />
                        ) : (
                          <div
                            style={{
                              width: '24px',
                              height: '24px',
                              border: '1px solid #171e2610',
                              borderRadius: '4px'
                            }}
                          />
                        )}
                        <div
                          className={
                            'font-medium f-size-14 line-h-20 nowrap' +
                            (eCategory.includes(el)
                              ? ' color-primary-400'
                              : ' color-text-tertiary')
                          }
                        >
                          {el}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            {tab !== 2 ? (
              <div className='flex-column gap-16'>
                <div className='flex-row space-between center-y'>
                  <div className='font-bold f-size-16 line-h-26 color-text-secondary'>
                    지원 사업 종류
                  </div>
                  <img
                    src='/asset/images/arrow/ArrowTop24.svg'
                    alt=''
                    style={{ transform: spread2 ? '' : 'rotate(180deg)' }}
                    onClick={() => setSpread2((prev) => !prev)}
                  />
                </div>
                <div
                  className='gap-16'
                  style={{
                    flexWrap: 'wrap',
                    maxHeight: spread2 ? '100%' : 0,
                    overflow: 'hidden'
                  }}
                >
                  <div className='flex-row gap-16' style={{ flexWrap: 'wrap' }}>
                    {[
                      '농지',
                      '주택/민박',
                      '귀농',
                      '귀촌',
                      '체험/체류',
                      '농시설/농기계',
                      '종자/육묘',
                      '인력',
                      '친환경/에너지',
                      '생산',
                      '가공/판매/유통',
                      '재해/방역/방제',
                      '컨설팅',
                      '청년/창업',
                      '복지'
                    ].map((el, idx) => (
                      <div
                        key={idx}
                        className='flex-row center-y gap-8 w-50per hover'
                        style={{ flex: '1 1 40%' }}
                        onClick={
                          bCategory.includes(el)
                            ? () => {
                                if (bCategory.includes(',' + el)) {
                                  setBCategory((prev: any) =>
                                    prev.split(',' + el).join('')
                                  );
                                } else if (eCategory.includes(el + ',')) {
                                  setBCategory((prev: any) =>
                                    prev.split(el + ',').join('')
                                  );
                                } else {
                                  setBCategory('');
                                }
                              }
                            : () => {
                                setBCategory((prev: any) =>
                                  prev === '' ? el : prev + ',' + el
                                );
                              }
                        }
                      >
                        {bCategory.includes(el) ? (
                          <img
                            src='/asset/images/check/Check24Green.svg'
                            alt=''
                          />
                        ) : (
                          <div
                            style={{
                              width: '24px',
                              height: '24px',
                              border: '1px solid #171e2610',
                              borderRadius: '4px'
                            }}
                          />
                        )}
                        <div
                          className={
                            'font-medium f-size-14 line-h-20 nowrap ' +
                            (bCategory.includes(el)
                              ? ' color-primary-400'
                              : ' color-text-tertiary')
                          }
                        >
                          {el}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className='flex-column gap-16'>
              <div className='flex-row space-between center-y'>
                <div className='font-bold f-size-16 line-h-26 color-text-secondary'>
                  정책 지원 기한
                </div>
                <img
                  src='/asset/images/arrow/ArrowTop24.svg'
                  alt=''
                  style={{ transform: spread3 ? '' : 'rotate(180deg)' }}
                  onClick={() => setSpread3((prev) => !prev)}
                />
              </div>
              <div
                className='gap-16'
                style={{
                  flexWrap: 'wrap',
                  maxHeight: spread3 ? '100%' : 0,
                  overflow: 'hidden'
                }}
              >
                <div className='flex-row gap-16' style={{ flexWrap: 'wrap' }}>
                  {[
                    '진행중',
                    '시행예정',
                    '진행중+시행예정',
                    '마감',
                    '전체'
                  ].map((el, idx) => (
                    <div
                      key={idx}
                      className='flex-row center-y gap-8 w-50per hover'
                      style={{ flex: '1 1 40%' }}
                      onClick={() => setTermType(el)}
                    >
                      {el === termType ? (
                        <img
                          src='/asset/images/check/Check24Green.svg'
                          alt=''
                        />
                      ) : (
                        <div
                          style={{
                            width: '24px',
                            height: '24px',
                            border: '1px solid #171e2610',
                            borderRadius: '4px'
                          }}
                        />
                      )}
                      <div
                        className={
                          'font-medium f-size-14 line-h-20 nowrap ' +
                          (el === termType
                            ? ' color-primary-400'
                            : ' color-text-tertiary')
                        }
                      >
                        {el}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className='padding-8-0-16 flex-row gap-8'>
            <div
              className='border-radius-8 padding-8-12 gap-4 flex-row center-x center-y hover'
              style={{ flexGrow: 1 }}
              onClick={() => {
                setECategory('');
                setBCategory('');
                setTermType('진행중+시행예정');
              }}
            >
              <img
                src='/asset/images/change/Refresh24Green.svg'
                style={{ height: '24px', width: '24px' }}
                alt=''
              />
              <div className='font-medium f-size-16 line-h-24 color-primary-500'>
                초기화
              </div>
            </div>
            <div
              className='border-radius-8 gap-10 flex-row padding-15-46 bg-primary hover'
              onClick={() => {
                setOpen(false);
              }}
            >
              <div className='font-medium f-size-16 line-h-24 color-FFFFFF'>
                결과보기 {Number(totalCount).toLocaleString()}건
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FilterModal2 = ({ open, setOpen, detail }: any) => {
  if (!open) {
    return <></>;
  }

  return (
    <div
      className={
        window.innerWidth < 768 ? 'fixed end-y' : 'fixed center-y center-x'
      }
      style={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: '#00000025',
        zIndex: 30
      }}
    >
      <div
        className={
          window.innerWidth < 768
            ? 'bg-FFFFFF flex-column border-top-radius-16 w-100per gap-16'
            : 'bg-FFFFFF flex-column border-radius-16 gap-16'
        }
        style={window.innerWidth < 768 ? {} : { width: '360px' }}
      >
        <div className='padding-16-16-0'>
          <div className='flex-row end-x'>
            <img
              onClick={() => setOpen(false)}
              className='hover'
              src='/asset/images/close/CloseBlack24.svg'
              alt=''
              style={{ width: '24px', height: '24px' }}
            />
          </div>
          <div
            className='flex-column gap-24 overflow-y-auto'
            style={{ maxHeight: window.innerHeight - 200 + 'px' }}
          >
            <div className='flex-column gap-8'>
              <div className='padding-4-8 border-1-1E9B6B w-fit-content border-radius-4'>
                <div className='font-medium f-size-12 line-h-16 color-primary-500'>
                  {detail.category}
                </div>
              </div>
              <div className='flex-row gap-16'>
                <div className='font-bold f-size-18 line-h-26 color-text-primary'>
                  {detail.title}
                </div>
                {/* <img src="/asset/images/mark/BookMark.svg" alt="" /> */}
              </div>
            </div>
            <div className='flex-column gap-24'>
              {[
                {
                  title: '01. 신청 기간',
                  content: `${
                    detail.startDate && detail.startDate.slice(0, 10)
                  } ~ ${detail.endDate && detail.endDate.slice(0, 10)}`
                },
                {
                  title: '02. 지원금액',
                  content: detail.amount || '-'
                },
                {
                  title: '03. 지원대상',
                  content: detail.target
                },
                {
                  title: '04. 담당기관',
                  content: detail.agency
                },
                {
                  title: '05. 사업요약',
                  content: detail.description
                }
              ].map((el) => (
                <div className='flex-column gap-4'>
                  <div className='font-bold f-size-16 line-h-24 color-text-primary'>
                    {el.title}
                  </div>
                  <div className='font-medium f-size-16 line-h-24 color-text-secondary'>
                    {el.content}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='padding-8-16 gap-8 border-1-171E2610'>
          <div className='padding-8-0-16 flex-row gap-8'>
            <div
              className='border-radius-8 padding-8-12 gap-4 flex-row center-x center-y hover border-1-1E9B6B '
              onClick={() => window.open(detail.link2 || detail.link)}
              style={{ flexGrow: 1 }}
            >
              <div className='font-medium f-size-16 line-h-24 color-primary-500'>
                자세히 보기
              </div>
            </div>
            {/* <div
              className="border-radius-8 gap-10 flex-row padding-15-46 bg-primary hover"
              onClick={() => {}}
            >
              <div className="font-medium f-size-16 line-h-24 color-FFFFFF">
                전화로 문의
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export const FilterModal3 = ({ open, setOpen, year, setYear }: any) => {
  if (!open) {
    return <></>;
  }

  return (
    <div
      className={
        window.innerWidth < 768 ? 'fixed end-y' : 'fixed center-y center-x'
      }
      style={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: '#00000025',
        zIndex: 30
      }}
    >
      <div
        className={
          window.innerWidth < 768
            ? 'bg-FFFFFF flex-column border-top-radius-16 w-100per gap-16'
            : 'bg-FFFFFF flex-column border-radius-16 gap-16'
        }
        style={window.innerWidth < 768 ? {} : { width: '360px' }}
      >
        <div className='padding-24-16 flex-column gap-24'>
          <div className='flex-column gap-4'>
            <div className='flex-row space-between'>
              <div className='font-bold f-size-16 line-h-24 color-text-primary'>
                연도 선택
              </div>
              <img
                src='/asset/images/close/CloseBlack24.svg'
                alt=''
                className='hover'
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
            <div className='font-medium f-size-14 line-h-20 color-text-tertiary'>
              원하는 연도를 선택해주세요
            </div>
          </div>
          <div className='flex-column'>
            {[2024, 2023, 2022, 2021, 2020].map((el, idx) => (
              <div
                className='flex-row space-between padding-12-16 hover'
                style={{ borderBottom: '1px solid #171e2610' }}
                onClick={() => {
                  setYear(el);
                  setOpen(false);
                }}
              >
                <div
                  className='font-medium f-size-16 line-h-24'
                  style={{ color: Number(year) === el ? '#1E9B6B' : '#171E26' }}
                >
                  {el}
                </div>
                <div></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
