import { useState } from 'react';
import Button from '../../../component/mobile/Button';

export const SectionTitle = ({ title, description }: any) => (
  <div className="flex-column gap-5">
    <div className="font-bold f-size-16 line-h-24 color-text-primary">
      {title}
    </div>
    <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
      {description}
    </div>
  </div>
);

type CostPropType = {
  title: string;
  item: any;
  minusClick: any;
  plusClick: any;
  disabled: boolean;
  value: any;
  onChange: any;
};

export const CostControl = (prop: CostPropType) => {
  return (
    <div className="flex-column padding-b-20 border-b-171E26">
      <div className="font-bold f-size-14 line-h-20 color-text-secondary">
        {prop.title}
      </div>
      <div className="flex-row center-y end-x gap-10">
        <div className="padding-6-8 border-radius-8 bg-F1F1FE end-x">
          <div
            className="font-bold f-size-12 line-h-24 color-text-decrease hover"
            onClick={prop.minusClick}
          >
            -10%
          </div>
        </div>
        <input
          disabled={prop.disabled}
          type="string"
          className="padding-6-8 border-radius-8 border-1-171E26 text-end font-medium f-size-14 line-h-24 w-50per"
          placeholder="0"
          value={prop.value}
          onChange={prop.onChange}
        />
        <div className="padding-6-8 border-radius-8 bg-FFF6F6">
          <div
            className="font-bold f-size-12 line-h-24 color-text-increase hover"
            onClick={prop.plusClick}
          >
            +10%
          </div>
        </div>
      </div>
    </div>
  );
};

export const CropSelecter = ({ value, onChange, cropList, off }: any) => {
  const [crop, setCrop]: any = useState(value);
  const [index, setIndex]: any = useState();
  return window.innerWidth < 1024 ? (
    <div
      className="fixed z-100 flex-column end-x"
      style={{ top: 0, bottom: 0, left: 0, right: 0, background: '#00000070' }}
    >
      <div
        className="bg-FFFFFF"
        style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
      >
        <div className="flex-column border-b-1-171E2610 gap-16 padding-b-24">
          <div className="padding-24-16-4">
            <div className="flex-row space-between">
              <div className="font-bold f-size-16 line-h-24 color-text-primary">
                작물선택
              </div>
              <img
                src="/asset/images/close/CloseBlack24.svg"
                className="hover"
                onClick={off}
                alt=""
              />
            </div>
            <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              원하는 작물 1종류를 선택해주세요
            </div>
          </div>
          <div className="flex-row padding-x-16">
            <div
              className=""
              style={{
                borderRadius: '15px',
                padding: '5px 12px',
                background: true ? '#2A3746' : '',
                border: true ? '' : '1px solid #171e2640',
              }}
            >
              <div
                className="font-bold f-size-14 line-h-20"
                style={{ color: true ? '#FFFFFF' : '#171e2656' }}
              >
                전체
              </div>
            </div>
          </div>
        </div>
        <div className="of-y-auto">
          <div
            className="padding-x-22"
            style={{ maxHeight: `${window.innerHeight - 100 - 230}px` }}
          >
            <div className="flex-column gap-16 center-y border-b-1-171E2610 padding-y-24">
              {/* <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                식량작물
              </div> */}
              <div className="flex-row flex-wrap start-x w-100per gap-8">
                {cropList.map((el: any, i: any) => {
                  return (
                    <div
                      className="flex-column gap-8 center-y hover center-y"
                      onClick={() => {
                        setCrop(el);
                        setIndex(i);
                      }}
                      style={{ width: '100px' }}
                    >
                      <div
                        className="relative"
                        style={{
                          width: '80px',
                          height: '80px',
                        }}
                      >
                        <div
                          className=" border-radius-100per of-hidden center-x center-y"
                          style={{
                            width: '80px',
                            height: '80px',
                            border:
                              crop && crop.name === el.name
                                ? '2px solid #1E9B6B'
                                : '1px solid #171e2610',
                          }}
                        >
                          <img
                            src={`https://back.transfarmer.kr/img/${el.img}`}
                            alt=""
                            style={{ width: '70px', height: '70px' }}
                          />
                        </div>
                        {crop && crop.name === el.name ? (
                          <img
                            src="/asset/images/check/Check24GreenCircle.svg"
                            alt=""
                            style={{
                              position: 'absolute',
                              left: 0,
                              bottom: 0,
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                      <div
                        className="font-medium f-size-14 line-h-20 text-center"
                        style={{
                          color:
                            crop && crop.name === el.name
                              ? '#1e9b6b'
                              : '#000000',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {el.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: '16px 16px 30px' }}>
          <Button
            description="선택완료"
            onClick={() => onChange(crop, index)}
          />
        </div>
      </div>
    </div>
  ) : (
    <div
      className="fixed z-100 flex-column end-x "
      style={{
        top: 0,
        bottom: 0,
        left: '449px',
        width: '375px',
      }}
    >
      <div className="bg-FFFFFF" style={{ height: `${window.innerHeight}px` }}>
        <div className="flex-column border-b-1-171E2610 gap-16 padding-b-24">
          <div className="padding-24-16-4">
            <div className="flex-row space-between">
              <div className="font-bold f-size-16 line-h-24 color-text-primary">
                작물선택
              </div>
              <img
                src="/asset/images/close/CloseBlack24.svg"
                className="hover"
                onClick={off}
                alt=""
              />
            </div>
            <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              원하는 작물 1종류를 선택해주세요
            </div>
          </div>
          <div className="flex-row padding-x-16">
            <div
              className=""
              style={{
                borderRadius: '15px',
                padding: '5px 12px',
                background: true ? '#2A3746' : '',
                border: true ? '' : '1px solid #171e2640',
              }}
            >
              <div
                className="font-bold f-size-14 line-h-20"
                style={{ color: true ? '#FFFFFF' : '#171e2656' }}
              >
                전체
              </div>
            </div>
          </div>
        </div>
        <div className="of-y-auto">
          <div
            className="padding-x-22"
            style={{ height: `${window.innerHeight - 100 - 142.8}px` }}
          >
            <div className="flex-column gap-16 center-y border-b-1-171E2610 padding-y-24">
              {/* <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                식량작물
              </div> */}
              <div className="flex-row flex-wrap start-x w-100per gap-8">
                {cropList.map((el: any, i: any) => {
                  return (
                    <div
                      className="flex-column gap-8 center-y hover center-y"
                      onClick={() => {
                        setCrop(el);
                        setIndex(i);
                      }}
                      style={{ width: '100px' }}
                    >
                      <div
                        className="relative"
                        style={{
                          width: '80px',
                          height: '80px',
                        }}
                      >
                        <div
                          className=" border-radius-100per of-hidden center-x center-y"
                          style={{
                            width: '80px',
                            height: '80px',
                            border:
                              crop && crop.name === el.name
                                ? '2px solid #1E9B6B'
                                : '1px solid #171e2610',
                          }}
                        >
                          <img
                            src={`https://back.transfarmer.kr/img/${el.img}`}
                            alt=""
                            style={{ width: '70px', height: '70px' }}
                          />
                        </div>
                        {crop && crop.name === el.name ? (
                          <img
                            src="/asset/images/check/Check24GreenCircle.svg"
                            alt=""
                            style={{
                              position: 'absolute',
                              left: 0,
                              bottom: 0,
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                      <div
                        className="font-medium f-size-14 line-h-20 text-center"
                        style={{
                          color:
                            crop && crop.name === el.name
                              ? '#1e9b6b'
                              : '#000000',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {el.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: '16px 16px 30px' }}>
          <Button
            on={crop}
            description="선택완료"
            onClick={() => {
              onChange(crop, index);
            }}
          />
        </div>
      </div>
    </div>
  );
};
