import { useEffect, useState } from 'react';
import { RowSectionTitle, RowTable5 } from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import GetLandShpaeImage from '../../../../asset/styles/Land-Shape';

const ReportDetail4 = ({ data, house }: { data: any; house?: any }) => {
  let temp;
  if (data && data.zoning && data && data.zoning.length === 9) {
    const firstThreeChars = data && data.zoning.slice(0, 3); // 첫 3글자 추출
    const remainingChars = data && data.zoning.slice(3); // 나머지 글자 추출
    temp = (
      <div className='text-end'>
        {firstThreeChars}
        <br />
        {remainingChars}
      </div>
    );
  } else if (data && data.zoning && data && data.zoning.length === 8) {
    const firstThreeChars = data && data.zoning.slice(0, 2); // 첫 3글자 추출
    const remainingChars = data && data.zoning.slice(2); // 나머지 글자 추출
    temp = (
      <div className='text-end'>
        {firstThreeChars}
        <br />
        {remainingChars}
      </div>
    );
  } else {
    temp = data && data.zoning;
  }

  const shapeWithoutSuffix =
    data && data.shape
      ? data.shape.slice(-1) === '형'
        ? data.shape.slice(0, -1)
        : data.shape
      : '';

  const [areaType, setAreaType] = useState('P');
  return (
    <div className='browser-bg-FFFFFF w-100per mb-16'>
      <RowSectionTitle>
        <TextSectionTitle>
          <div className='flex-row space-between w-100per'>
            토지 정보 분석
            <div
              className='hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary'
              onClick={() => setAreaType((prev) => (prev !== 'P' ? 'P' : 'M'))}
            >
              <img src='/asset/images/arrow/Refresh20.svg' alt='' />
              {areaType === 'P' ? 'm²' : '평'}
            </div>
          </div>
        </TextSectionTitle>
      </RowSectionTitle>
      <div className='flex-column'>
        <div className='padding-24-16 gap-16 flex-column'>
          <div className='flex-row gap-24' style={{ alignItems: 'baseline' }}>
            <RowTable5
              title='토지면적'
              value={`${
                data && areaType !== 'P'
                  ? house
                    ? data.landArea + 'm²'
                    : data.area + 'm²'
                  : house
                  ? data.landPyeong + '평'
                  : data.pyeong + '평'
              } `}
            />
            <RowTable5 title='용도지역' value={temp} />
          </div>
          <div className='flex-row gap-24'>
            <RowTable5 title='지목' value={data && data.category} />
            <RowTable5 title='이용상황' value={data && data.useStatus} />
          </div>
          <div className='flex-row gap-24'>
            <RowTable5 title='고저' value={data && data.height} />
            <RowTable5 title='도로' value={data && data.roadSurface} />
          </div>
        </div>
        <div className='padding-0-16-16 gap-10'>
          <div className='padding-24-16 bg-light-gray border-radius-8 gap-18 flex-row center-y'>
            <img src={GetLandShpaeImage(shapeWithoutSuffix)} alt='' />
            <div className='flex-column gap-4'>
              <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
                {shapeWithoutSuffix}형
              </div>
              <div className='font-medium f-size-12 line-h-14 color-text-tertiary'>
                {(data && data.road_desc) || data.roadSurface_desc}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetail4;
