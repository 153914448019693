import {
  On1,
  On2,
  On3,
  On4,
  On5,
  Off1,
  Off2,
  Off3,
  Off4,
  Off5,
  ButtonBox,
  BottomNavCon,
} from '../../asset/styles/mobile/common/BottomNav';
import { Font } from '../../asset/styles/browser/font/font';
import { useNavigate } from 'react-router-dom';

import priceOn from './../../asset/images/bottomNavIcon/price_on.svg';
import pensionOn from './../../asset/images/bottomNavIcon/pension_on.svg';
import homeOn from './../../asset/images/bottomNavIcon/home_on .svg';
import houseOn from './../../asset/images/bottomNavIcon/house_on.svg';
import priceOff from './../../asset/images/bottomNavIcon/price_off.svg';
import pensionOff from './../../asset/images/bottomNavIcon/pension_off.svg';
import homeOff from './../../asset/images/bottomNavIcon/home_off.svg';
import houseOff from './../../asset/images/bottomNavIcon/house_off.svg';
import findlandOn from './../../asset/images/bottomNavIcon/findland_on.svg';
import findlandOff from './../../asset/images/bottomNavIcon/findland_off.svg';

import { useDispatch } from 'react-redux';
import { ChangeType } from '../../store/typeSlice';
import IconButtonBox from '../common/IconButtonBox';

export const BottomNav = ({ number }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    // <div className="bottom-nav tablet">
    //   <IconButtonBox
    //     onClick={() => {
    //       navigate("/");
    //     }}
    //     number={1}
    //     description="홈"
    //   >
    //     {number === 1 ? <On1 /> : <Off1 />}
    //   </IconButtonBox>
    //   <IconButtonBox
    //     number={2}
    //     description="AI농업"
    //     onClick={() => navigate("/aiFarming")}
    //   >
    //     {number === 2 ? <On2 /> : <Off2 />}
    //   </IconButtonBox>
    //   <IconButtonBox
    //     number={3}
    //     description="AI주택"
    //     onClick={() => navigate("/aiHouse")}
    //   >
    //     {number === 3 ? <On3 /> : <Off3 />}
    //   </IconButtonBox>
    //   <IconButtonBox
    //     number={4}
    //     description="커뮤니티"
    //     onClick={() => {
    //       navigate("/community");
    //     }}
    //   >
    //     {number === 4 ? <On4 /> : <Off4 />}
    //   </IconButtonBox>
    //   <IconButtonBox
    //     onClick={() => {
    //       navigate("/mypage");
    //     }}
    //     number={5}
    //     description="마이페이지"
    //   >
    //     {number === 5 ? <On5 /> : <Off5 />}
    //   </IconButtonBox>
    // </div>

    <div
      className="h-56 w-100per bg-FFFFFF bottom-nav tablet"
      style={{ zIndex: '1' }}
    >
      <IconButtonBox
        description="가격 분석"
        iconOn={priceOn}
        iconOff={priceOff}
        on={number === 1}
        onClick={() => {
          navigate('/price/map');
          dispatch(ChangeType('price'));
          sessionStorage.setItem('tab', 'price');
        }}
      />

      <IconButtonBox
        description="내 농장 진단"
        iconOn={homeOn}
        iconOff={homeOff}
        on={number === 2}
        onClick={() => {
          navigate('/aiFarming/map');
          dispatch(ChangeType('/aifarm'));
          sessionStorage.setItem('tab', 'aifarm');
        }}
      />
      <IconButtonBox
        description="농지 찾기"
        iconOn={homeOn}
        iconOff={homeOff}
        on={number === 3}
        onClick={() => {
          navigate('/findland/map');
          dispatch(ChangeType('findland'));
          sessionStorage.setItem('tab', 'findland');
        }}
      />
      <IconButtonBox
        description="내 집 짓기"
        iconOn={houseOn}
        iconOff={houseOff}
        on={number === 4}
        onClick={() => {
          navigate('/house/map');
          dispatch(ChangeType('house'));
          sessionStorage.setItem('tab', 'house');
        }}
      />
      <IconButtonBox
        description="농지연금 분석"
        iconOn={pensionOn}
        iconOff={pensionOff}
        on={number === 5}
        onClick={() => {
          navigate('/annuity/map');
          dispatch(ChangeType('annuity'));
          sessionStorage.setItem('tab', 'annuity');
        }}
      />
    </div>
  );
};
