import Request from '../../@api/request';

// const baseURL = "http://localhost:3333/";
// const baseURL = "https://back.transfarmer.kr/";
const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);

export default class MypageSerivce {
  static getMyInfo = async () => {
    const result = await request.get('users/information/profile');
    return result;
  };
  // static noticeList = async (offset: number, limit: number) => {
  //     const result = await request.get(`boards/article/list?offset=${offset}&limit=${limit}`);
  //     return result;
  // };
  static noticeList = async (board: string, offset: number, limit: number) => {
    const result = await request.get(
      `boards/article/list?board=${board}&offset=${offset}&limit=${limit}`
    );
    return result;
  };
  static noticeDetail = async (articleIdx: string) => {
    const result = await request.get(`boards/article/${articleIdx}`);
    return result;
  };
  static getDeleteInfo = async (isDeleted: string) => {
    const result = await request.get(`admin/user/list?isDeleted=${isDeleted}`);
    return result;
  };
}
