import { styled } from "styled-components";
import MenuImg from "./../../../images/topnav/menuIcon_mobile.png";
import MenuImg2 from "./../../../images/topnav/menuIcon_mobile_black.svg";
import HomeImg from "./../../../images/topnav/home.png";

export const TopNavFullContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 0.8px solid #efefef;
`;

export const TopNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  align-items: center;
  height: 56px;
`;

export const Menu = styled.img.attrs({
  src: `${MenuImg}`,
})``;
export const MenuBlack = styled.img.attrs({
  src: `${MenuImg2}`,
})``;

export const DropDownContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

export const DropDownTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 0.5px solid #efefef;
  padding: 5px 0px 5px 0px;
`;

export const Home = styled.img.attrs({
  src: `${HomeImg}`,
})`
  width: 30px;
  cursor: pointer;
`;
