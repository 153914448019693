import { useNavigate } from "react-router-dom";

const Promotion = ({}: any) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        background: "#1e9b6b",
        color: "#FFFFFF",
        width: "100%",
        height: "100%",
        padding: "16px",
        paddingBottom: "66px",
      }}
      className="font-medium f-size-22 line-h-24 text-center "
    >
      <div className="flex-center w-100per">
        <div
          className="flex start-x padding-b-16 pointer"
          style={{ width: "560px" }}
          onClick={() => {
            navigate("/");
          }}
        >
          <img src="/asset/images/main/logo.svg" />
        </div>
      </div>
      <img
        src="/asset/images/banner/promotion3.png"
        style={{
          maxWidth: "560px",
          width: "100%",
          marginBottom: "16px",
          borderRadius: "12px",
        }}
        alt=""
      />
      <div>아래 쿠폰번호를 복사하세요</div>
      <div
        style={{
          margin: "8px",
          padding: "4px 8px",
          borderRadius: "8px",
          background: "#187753",
        }}
      >
        J7QE9EC
      </div>
      <div
        style={{ marginTop: "24px", marginBottom: "8px" }}
      >{`<이용방법>`}</div>
      <div className="f-size-18">
        회원가입 화면에서 쿠폰번호를 입력해 주세요
      </div>
      <div
        onClick={() => navigate("/")}
        style={{
          display: "flex",
          justifyContent: "cente r",
          marginTop: "32px",
          cursor: "pointer",
        }}
      >
        <img src="/asset/images/main/logo.svg" alt="" />
        <div>로 가기</div>
      </div>
    </div>
  );
};

export default Promotion;
