import './SignUpPromotion.css';
import './Font.css';
import Banner from '../../../component/AIIntroduction/Footer';
import FooterBrowser from '../../../component/browser/Footer';
import { Helmet } from 'react-helmet';
import { TopNavNewBrowser } from '../../../component/mobile/Topnav';
import { useEffect, useState } from 'react';
import { PromotionTopNav } from '../../../component/mobile/PromotionTopNav';
import CTA from '../ctaBtn/CTA';
import CompanyInfoSlider from '../infoSlider/InfoSlider';
import ABTest from '../../../service/promotion/Promotion';
import TopBanner from '../../../component/banner/TopBanner';
import SliderTab from './components/SliderTab';
import { Link, useNavigate } from 'react-router-dom';
import LandingBanner from './components/Landing-banner';
import ScrollToTopButton from '../../../component/common/ScrollToTop';

const SignupFindLand = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    sessionStorage.setItem('landingType', 'findland');
    const fetchData = async () => {
      try {
        const data = { type: 'X', title: '농지찾기', action: 'N' };
        const result = await ABTest.ABLog(data);
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const actionUpdate = async () => {
    try {
      const data = { type: 'X', title: '농지찾기', action: 'Y' };
      const result = await ABTest.ABLog(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    sessionStorage.setItem('landingType', 'findland');
    // sessionStorage.setItem('beforeUrl', document.referrer);
    sessionStorage.setItem('beforeUrl', window.location.href);
  }, []);
  return (
    <div className='wrap'>
      <Helmet>
        <title>귀농도 똑똑하게, AI로 농업 경영 계획하기 - 트랜스파머 </title>
        <meta
          name='description'
          content='농지의 토질 분석, 적합 작물 추천, 운영 수익 계산, 비용 분석 등 농업 경영을 AI로 간편하게 할 수 있습니다.'
        />
        <meta name='robots' content='index, follow' />
        <meta
          name='keyword'
          content='농사, 농지, 농업경영, 영농계획, 농업경영계획서, 농지가격, 농지투자, 농지투자체크리스트, 농사작물, 작물선택, 재배방법'
        />
        <link
          rel='canonical'
          href='https://www.transfarmer.co.kr/promotion/농사-수익과-비용분석-AI로-농업-경영-계획-세우기'
        />
        {/* 오픈그래프 영역 */}
        <meta property='og:title' content='트랜스파머' />
        <meta
          property='og:description'
          content='AI로 농가 경영 계획을 똑똑하게'
        />
        <meta property='og:image' content='/asset/images/logo/og-img.png' />
        <meta
          property='og:url'
          content='https://www.transfarmer.co.kr/promotion/AI농사-수익과-비용분석-AI로-농업-경영-계획-세우기'
        />
      </Helmet>
      <div className='container'>
        <ScrollToTopButton bottom={'130px'} mbottom={'90px'} isVisible={true} />
        <TopBanner />
        <PromotionTopNav open={open} setOpen={setOpen} />
        <TopNavNewBrowser />
        <div className='section section01'>
          <div className='section01-title-box'>
            <h1 className='menu-tag'>농지찾기</h1>
            <div className='section-description'>AI 귀농의 시작</div>
            <div className='section-title-box'>
              <div className='section-title'>
                <div className='text40'>
                  농지 찾기<span className='text40 text-light-grey'>부터</span>
                  <br />
                  농업경영계획
                  <span className='text40 text-light-grey'>까지 </span>
                </div>
              </div>
            </div>
          </div>
          <div className='section01-content-box section01-content-box-2'>
            <img
              src='/asset/images/promotion/findland-main.svg'
              alt='농지가치 평가보고서'
            />
          </div>
        </div>
        <div className='section section02-1 '>
          <div className='section02-title-box'>
            <img
              src='/asset/images/promotion/worry-face.svg'
              alt='걱정하는 이모지'
            />
            <p className='text40 text-light-green'>농사,</p>
            <p className='text40 text-grey'>
              열심히만 <br />
              준비한다고
            </p>
            <p className='text40 text-light-green'>성공할까요?</p>
          </div>
          <div className='section02-content-box'>
            <div>
              <img
                src='/asset/images/promotion/green-circle-question-mark.svg'
                alt='초록색물음표'
              />
              <span className='text17-white'>
                농지 선택 체크리스트 해보셨나요?
              </span>
            </div>
            <div>
              <img
                src='/asset/images/promotion/green-circle-question-mark.svg'
                alt='초록색물음표'
              />
              <span className='text17-white'>
                최고 수익 작물은 알고 계세요?
              </span>
            </div>
          </div>
        </div>
        <div className='section section05'>
          <div className='section05-title-box'>
            <div className='text32'>
              경험에만 의존하는
              <br />
              농업은 그만!!!
            </div>
            <div className='section05-title--box-1'>
              <p className='text32 text-color-box'>AI데이터 기반으로</p>
              <p className='text32 text-color-box'>농업 경영계획 세우고</p>
              <p className='text32'>
                똑똑한 농사를 <br />
                시작하세요
              </p>
            </div>
            <div className='line-column'></div>
          </div>
          <div className='section05-content-box'>
            <div className='section05-speech-bubble-box'>
              <div className='section05-speech-bubble'>
                <div className='text20'>
                  귀농 준비하는 중이예요,
                  <br />
                  <span>초보 농사꾼에게</span> <br />
                  <span>좋은 농지를 찾고 싶어요.</span>
                </div>
                <img
                  className='color-bubble'
                  src='/asset/images/promotion/grey-bubble-big.svg'
                  alt='말풍선'
                />
              </div>
              <div className='people-img'>
                <img
                  src='/asset/images/promotion/people_illust_01.svg'
                  alt='사람1'
                />
              </div>
            </div>
            <div className='section05-speech-bubble-box-right'>
              <div className='section05-speech-bubble'>
                <div className='text20'>
                  하우스가 수익률이 좋다던데~
                  <br />
                  <span>하우스 농사하기 좋은 농지 찾고</span>
                  <br />
                  <span>비용 설계하고 싶어요.</span>
                </div>
                <img
                  className='line-bubble'
                  src='/asset/images/promotion/line-bubble-big.svg'
                  alt='말풍선'
                />
              </div>
              <div className='people-img people-img-right'>
                <img
                  src='/asset/images/promotion/people_illust_03.svg'
                  alt='사람2'
                />
              </div>
            </div>
            <div className='section05-speech-bubble-box'>
              <div className='section05-speech-bubble'>
                <div className='text20'>
                  부모님댁 가까운 곳에 있는
                  <br />
                  <span>좋은 농지를 사고 싶어요.</span>
                </div>
                <img
                  className='color-bubble'
                  src='/asset/images/promotion/grey-bubble.svg'
                  alt='말풍선'
                />
              </div>
              <div className='people-img'>
                <img
                  src='/asset/images/promotion/people_illust_02.svg'
                  alt='사람3'
                />
              </div>
            </div>
            <div className='section05-speech-bubble-box-right'>
              <div className='section05-speech-bubble'>
                <div className='text20'>
                  은퇴 후 농사지으며 생활하고
                  <br />
                  <span>노후자금도 마련하고 싶어요.</span>
                </div>
                <img
                  className='line-bubble'
                  src='/asset/images/promotion/line-bubble.svg'
                  alt='말풍선'
                />
              </div>
              <div className='people-img people-img-right'>
                <img
                  src='/asset/images/promotion/people_illust_04.svg'
                  alt='사람4'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='section section06 section06-1'>
          <div className='section06-title-box'>
            <div className='section06-title-1'>
              <p className='text27 text27-green'>가장 쉬운</p>
              <p className='text27 text-round-box'>농지테크</p>
            </div>
            <div className='text27'>트랜스파머에서</div>
            {/* <div className='section06-title-2'>
              <p className='text27 text-dot'>쉽고 편리하게</p>
              <p className='text27'>농지 경영 계획하세요!</p>
            </div> */}
            <div className='flex-column flex-center relative'>
              <div className='text27'>쉽고 편리하게</div>
              <div className='text27'>농지 경영 계획하세요!</div>
              <div
                className='flex-row  absolute'
                style={{ bottom: '75px', left: '112px', gap: '16px' }}
              >
                {[1, 2, 3, 4].map(() => {
                  return (
                    <div
                      className='border-radius-999'
                      style={{
                        width: '9px',
                        height: '9px',
                        backgroundColor: '#00EA00',
                      }}
                    >
                      &nbsp;
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='section06-content-box'>
            <div className='section06-content-01'>
              <div className='content-01-title'>
                <div className='content-01-grey-line'></div>
                <p className='text20'>농지 경영에 필요한</p>
                <p className='text27 text-27-heavy'>빅 데이터를 활용</p>
              </div>
              <SliderTab />
            </div>
            <div className='section06-content-02 text20'>
              전국 <span>21억 건 이상</span> 누적 데이터로 <br />
              맞춤형 농가 경영 정보를 제공합니다.
            </div>
          </div>
        </div>
        <div className='section findland-add1'>
          <div className='findland-add1-title-box'>
            <span className='text32'>
              어떤 농지의 <br />
              투자가치가
            </span>
            <img src='/asset/images/promotion/better.svg' alt='' />
          </div>
          <div className='findland-add1-content-box'>
            <img
              src='/asset/images/promotion/sec03-compare.svg'
              alt='비교이미지'
            />
            <p className='text14'>
              *실제 경매 물건을 대상으로 조회 및 비교하였습니다.
            </p>
          </div>
          <img
            src='/asset/images/promotion/findland-sec03-confuse.svg'
            alt='돈주머니'
          />
        </div>
        <div className='section findland-add2'>
          <div className='findland-add2-title-box'>
            <div className='text32'>
              트랜스파머
              <br />
              <span className='text32 text-color-box'>농지 찾기</span>를 하면
            </div>
            <img
              src='/asset/images/promotion/red-arrow.svg'
              alt='빨간 화살표'
            />
          </div>
          <div className='findland-add2-content-box'>
            <img
              className='polygon-bg'
              src='/asset/images/promotion/Polygon3.svg'
              alt=''
            />
            <div className='findland-add2-content-01'>
              <div className='findland-add2-content-block'>
                <div className='findland-add2-content-block-title'>
                  <p className='text27'>부동산 정보</p>
                  <img
                    src='/asset/images/promotion/findland-house.svg'
                    alt=''
                  />
                </div>
                <img src='/asset/images/promotion/estate-info.svg' alt='' />
                <div className='checkbox-block'>
                  <div className='checkbox-text'>
                    <img src='/asset/images/promotion/check.svg' alt='체크' />
                    <p className='text17'>최근 실거래가</p>
                  </div>
                  <div className='grey-line'></div>
                  <div className='checkbox-text'>
                    <img src='/asset/images/promotion/check.svg' alt='체크' />
                    <p className='text17'>AI 농지추정가</p>
                  </div>
                  <div className='grey-line'></div>
                  <div className='checkbox-text'>
                    <img src='/asset/images/promotion/check.svg' alt='체크' />
                    <p className='text17'>공시지가 연평균성장률</p>
                  </div>
                </div>
              </div>
              <div className='green-line'></div>
              <div className='findland-add2-content-block'>
                <div className='findland-add2-content-block-title'>
                  <p className='text27'>농업 정보</p>
                  <img src='/asset/images/promotion/findland-rice.svg' alt='' />
                </div>
                <img src='/asset/images/promotion/farming-info.svg' alt='' />
                <div className='checkbox-block'>
                  <div className='checkbox-text'>
                    <img src='/asset/images/promotion/check.svg' alt='체크' />
                    <p className='text17'>토질 분석</p>
                  </div>
                  <div className='grey-line'></div>
                  <div className='checkbox-text'>
                    <img src='/asset/images/promotion/check.svg' alt='체크' />
                    <p className='text17'>기후 분석</p>
                  </div>
                  <div className='grey-line'></div>
                  <div className='checkbox-text'>
                    <img src='/asset/images/promotion/check.svg' alt='체크' />
                    <p className='text17'>주요 작물</p>
                  </div>
                  <div className='grey-line'></div>
                  <div className='checkbox-text'>
                    <img src='/asset/images/promotion/check.svg' alt='체크' />
                    <p className='text17'>추천 농사</p>
                  </div>
                </div>
              </div>
              <div className='green-line'></div>
              <div className='findland-add2-content-block'>
                <div className='findland-add2-content-block-title'>
                  <p className='text27'>금융 정보</p>
                  <img src='/asset/images/promotion/bank.svg' alt='' />
                </div>
                <img src='/asset/images/promotion/finance-info.svg' alt='' />
                <div className='checkbox-block'>
                  <div className='checkbox-text'>
                    <img src='/asset/images/promotion/check.svg' alt='체크' />
                    <p className='text17'>작물별 수익 데이터</p>
                  </div>
                  <div className='grey-line'></div>
                  <div className='checkbox-text'>
                    <img src='/asset/images/promotion/check.svg' alt='체크' />
                    <p className='text17'>농사 비용</p>
                  </div>
                  <div className='grey-line'></div>
                  <div className='checkbox-text'>
                    <img src='/asset/images/promotion/check.svg' alt='체크' />
                    <p className='text17'>대출 계획</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='findland-add2-content-02'>
              <div className='content-02-title'>
                <p className='text20'>꼼꼼하게 확인하고, 비교해서</p>
                <img src='/asset/images/promotion/best-farm-text.svg' alt='' />
                <div className='findland-table'>
                  <img src='/asset/images/promotion/compare-land.png' alt='' />
                  <div className='table-hidden-text'>
                    거래가격(경매가),거래가격(경매가),규모,공시지가 성장률,배수
                    상태,토양 추천 농사,벼 농사 매출액,벼 농사 운영비,배 농사
                    매출액,배 농사 운영비,배수 양호,배수 약간 불량,논, 과수
                    농사,밭, 과수상전, 초지, 임지 농사
                  </div>
                </div>
              </div>
            </div>
            <img src='/asset/images/promotion/human-with-bulb.svg' alt='전구' />
          </div>
        </div>
        <div className='section section08 section08-1'>
          <div className='section08-title-box'>
            <img src='/asset/images/promotion/Lightbulb.svg' alt='전구' />
            <p className='text27'>이렇게 이용하세요</p>
            <p className='text20'>
              쉽고, 편하게 <br /> <span>AI데이터를 활용</span>하여
              <br />
              농업 및 농가경영을 계획하세요
            </p>
          </div>
          <div className='section08-01-content-box'>
            <div>
              <div className='usage-step-title text-20-extra-bold  green'>
                {' '}
                1 <br />
                홈에서 '농지찾기' 선택
              </div>
              <div className='usage-step-content'>
                <img
                  className='findland-gif'
                  src='/asset/images/promotion/findland-01.gif'
                  alt='이용가이드1'
                />
              </div>
            </div>
            <div>
              <div className='usage-step-title text-20-extra-bold  green'>
                {' '}
                2 <br />
                영농 사업 계획서 입력
              </div>
              <div className='usage-step-content'>
                <img
                  className='findland-gif'
                  src='/asset/images/promotion/findland-02.gif'
                  alt='이용가이드2'
                />
              </div>
            </div>
            <div>
              <div className='usage-step-title text-20-extra-bold  green'>
                {' '}
                3 <br />
                농지가치 평가보고서 확인하기
              </div>
              <div className='usage-step-content'>
                <img
                  className='findland-gif'
                  src='/asset/images/promotion/findland-03.gif'
                  alt='이용가이드3'
                />
              </div>
            </div>
          </div>
          <div
            className='w-100per flex-center padding-12 pointer'
            style={{ backgroundColor: '#171E26' }}
            onClick={() => {
              navigate(
                '/promotion/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8-%EB%A7%9E%EC%B6%A4-%ED%86%A0%EC%A7%80-%EC%BB%A8%EC%84%A4%ED%8C%85?utm_source=TF&utm_medium=banner&utm_campaign=consulting_banner&utm_id=promotion&utm_content=consulting_banner'
              );
            }}
          >
            <div
              className='flex-row space-between w-100per'
              style={{ maxWidth: '1200px' }}
            >
              <div className='flex-row gap-8 w-100per center-y'>
                <div
                  className='bg-primary-400 border-radius-2'
                  style={{ padding: '2px 6px' }}
                >
                  <div className='m-b2-14-b color-dark-primary'>
                    맞춤 컨설팅
                  </div>
                </div>
                <div className='m-b1-16-b color-FFFFFF'>
                  토지 매매, 이제 <span style={{ color: '#09CE7D' }}>안심</span>
                  하세요
                </div>
              </div>
              <img
                className='w-24'
                src='/asset/images/arrow/ArrowRightWhite20.svg'
              />
            </div>
          </div>
          <LandingBanner />
        </div>
        <div className='section section09'>
          <div className='section09-title-box'>
            <img src='/asset/images/promotion/Logo_Symbol.svg' alt='로고' />
            <p className='text32'>트랜스파머는</p>
            <p className='text20'>
              농지 검색에서 수익창출까지 <br />
              농지 위의 모든 것을 데이터화하여 <br />
              가치를 만들어 갑니다
            </p>
          </div>
          <div className='section09-content-box'>
            <CompanyInfoSlider />
          </div>
        </div>
        <Banner />
        <FooterBrowser />
      </div>
      <CTA
        text1='농지 사면'
        text3='비용과 수익은 얼마일까?'
        btnText1='AI 농가 경영'
        btnText2='계획 세우기'
        actionUpdate={actionUpdate}
        greenTextColor='#00EA00'
        actionBtnBackground='#00EA00'
      />
    </div>
  );
};
export default SignupFindLand;
