import { useNavigate } from 'react-router-dom';
import { TopNavNew, TopNavNewBrowser } from '../../component/mobile/Topnav';
import { useEffect, useState } from 'react';

import Main1 from './components/Main1';
import Main2 from './components/Main2';
import Main3 from './components/Main3';
import Main4 from './components/Main4';
import FooterBrowser from '../../component/browser/Footer';
import FooterMobile from '../../component/AIIntroduction/Footer';
import MainService from '../../service/main/Main';
import { Helmet } from 'react-helmet';
import PopUpBanner from './PopUp';
import TopBanner from '../../component/banner/TopBanner';
import Guide from './components/Guide';
import { LoadingDot } from '../../store/loadingSlice';
import { useDispatch, useSelector } from 'react-redux';
import './Main.css';
import ConsultingPopUp from '../promotion/consulting/Popup';
import { useCookies } from 'react-cookie';
import UserService from '../../service/checkAccount/CheckAccount';
const Main = () => {
  const [cookies, setCookie] = useCookies(['hideModal']);
  const [consultingPopUp, setConsultingPopUp] = useState(false);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 쿠키 값을 기반으로 상태 설정
    if (cookies.hideModal) {
      setConsultingPopUp(false);
    } else {
      setConsultingPopUp(true);
    }
  }, [cookies.hideModal]);

  // 로딩 중에는 아무것도 렌더링하지 않음

  const [field, setField] = useState([]);
  const [paddy, setPaddy] = useState([]);
  const [fruit, setFruit] = useState([]);
  const [houseTransaction, setHouseTransaction] = useState([]);
  const [houseNew, setHouseNew] = useState([]);
  const [houseRemodel, setHouseRemodel] = useState([]);
  const [houseIndexGraph, setHouseIndexGraph] = useState([]);
  const [newConstructionIndexGraph, setNewConstructionIndexGraph] = useState(
    []
  );
  const [remodelingIndexGraph, setRemodelingIndexGraph] = useState([]);
  const [jointMarketGraph, setJointMarketGraph] = useState([]);
  const [wholesaleGraph, setWholesaleGraph] = useState([]);
  const [farmPriceGraph, setFarmPriceGraph] = useState([]);
  const [top5FieldCrop, setTop5FieldCrop] = useState([]);
  const [top5FacilityCrop, setTop5FacilityCrop] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [updateDate, setUpdateDate] = useState('');

  const dispatch = useDispatch();
  const SearchApi = async () => {
    try {
      dispatch(LoadingDot('N'));
      const result = await MainService.search();
      setField(result.result.top5Field);
      setPaddy(result.result.top5Paddy);
      setFruit(result.result.top5Fruit);
      setHouseTransaction(result.result.houseIndexList);
      setHouseNew(result.result.newConstructionIndexList);
      setHouseRemodel(result.result.remodelingIndexList);
      setHouseIndexGraph(result.result.houseIndexGraph);
      setNewConstructionIndexGraph(result.result.newConstructionIndexGraph);
      setRemodelingIndexGraph(result.result.remodelingIndexGraph);
      setJointMarketGraph(result.result.jointMarketGraph);
      setWholesaleGraph(result.result.wholesaleGraph);
      setFarmPriceGraph(
        result.result.farmPriceGraph.map((el: any) => {
          return { ...el, year: `${el.year}-${('0' + el.month).slice(-2)}` };
        })
      );
      setDataCount(result.result.dataNum.sum);
      setUpdateDate(result.result.dataNum.createdTime.split('T')[0]);
      setTop5FieldCrop(result.result.top5FieldCrop);
      setTop5FacilityCrop(result.result.top5FacilityCrop);
    } catch (error) {
      console.log(error);
    }
  };
  const checkInfo = async () => {
    try {
      const result = await UserService.checkInfo();
      console.log('info', result);
      localStorage.setItem('username', result.name);
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //   removeCookie('hideModal');
  // }, []);
  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      checkInfo();
    }
    SearchApi();
  }, []);

  const [address, setAddress] = useState('');
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleDoNotShowToday = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setCookie('hideModal', true, { path: '/', expires: tomorrow });
    setConsultingPopUp(false);
  };
  useEffect(() => {
    console.log(cookies.hideModal);
    if (!cookies.hideModal) {
      setConsultingPopUp(true);
    }
  }, [cookies]);

  return (
    <div className='new-main-wrap'>
      <PopUpBanner />
      <TopBanner />
      <TopNavNew setOpen={setOpen} open={open} />
      <Helmet>
        <title>트랜스파머 - 인공지능(AI) 으로 농촌생활을 똑똑하게!</title>
      </Helmet>
      <TopNavNewBrowser />
      {consultingPopUp && (
        <ConsultingPopUp
          handleDoNotShowToday={handleDoNotShowToday}
          setConsultingPopUp={setConsultingPopUp}
          consultingPopUp={consultingPopUp}
        />
      )}
      {/* <div className="padding-8-16 bg-primary-500">
        <div className="flex-row space-between border-radius-8 padding-16 bg-FFFFFF w-328 h-56">
          <input
            className="border-none w-80per font-medium f-size-14 line-h-20 color-text-tertiary"
            placeholder="관심있는 토지 주소를 입력하세요"
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />

          <img src={search} className="pointer" />
        </div>
      </div> */}
      <div className='new-main-inner'>
        <Main1 />
        <Guide />
        <Main2
          field={field}
          paddy={paddy}
          fruit={fruit}
          houseNew={houseNew}
          houseTransaction={houseTransaction}
          houseRemodel={houseRemodel}
          top5FacilityCrop={top5FacilityCrop}
          top5FieldCrop={top5FieldCrop}
        />
        <div>
          <Main3 updateDate={updateDate} dataCount={dataCount} />
          <Main4
            houseIndexGraph={houseIndexGraph}
            newConstructionIndexGraph={newConstructionIndexGraph}
            remodelingIndexGraph={remodelingIndexGraph}
            jointMarketGraph={jointMarketGraph}
            wholesaleGraph={wholesaleGraph}
            farmPriceGraph={farmPriceGraph}
          />
        </div>
      </div>
      {/* <BottomNav number={0} /> */}
      <FooterMobile />
      <FooterBrowser />
    </div>
  );
};

export default Main;
