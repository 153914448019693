import { useEffect, useRef, useState } from 'react';
import { TopNav2, TopNavPointBrowser } from '../../component/mobile/Topnav';
import Toast from '../../component/common/Toast';
import Button from '../../component/mobile/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { PointPopUp } from './Popup';
import UserService from '../../service/checkAccount/CheckAccount';
import { isMobile } from '../../component/function/function';
import SHA256 from './SHA';
import PayService from '../../service/pay/Pay';
import useDidMountEffect from '../../hooks/UseDidMountEffect';

// import * as crypto from 'crypto';
const Payment = ({ isTest }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.state);
  const type = location.state.type;
  localStorage.setItem('point', JSON.stringify(location.state));
  const temp = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [depositName, setDepositName] = useState(
    localStorage.getItem('username') || ''
  );

  const [onToast, setOnToast] = useState(false);
  const mobilePurchaseRef: any = useRef(null);
  const [paymentTab, setPaymentTab] = useState(1);
  const [receiptTab, setReceiptTab] = useState(1);
  const [receiptTel, setReceiptTel] = useState('01012345678');
  const [dropDown, setDropDown] = useState({
    recharge: true,
    payment: true,
    receipt: true,
    check: true,
  });

  const [iframeURL, setIframeURL] = useState('' as any);

  const [parameters, setParameters] = useState({
    acceptmethod: '',
    buyername: '',
    goodname: '',
    gopaymethod: '',
    mid: '',
    oid: '',
    price: 0,
    returnUrl: '',
  });
  // returnUrl: `${process.env.REACT_APP_HOST}pay/complete`,

  const getIframeURL = async () => {
    console.log('location.pathname', window.location.href);
    try {
      if (isMobile(window.innerWidth)) {
        const result = await PayService.getParam(
          Number(location.state.point),
          depositName,
          window.location.href
        );
        console.log(result);
        setParameters({
          acceptmethod: result.acceptmethod,
          buyername: result.buyername,
          goodname: result.goodname,
          gopaymethod: result.gopaymethod,
          mid: result.mid,
          oid: result.oid,
          price: result.price,
          returnUrl: result.returnUrl,
        });
        // mobilePurchaseRef.current.action =
        //   'https://mobile.inicis.com/smart/payment/';
        // mobilePurchaseRef.current.target = '_self';
        // mobilePurchaseRef.current.submit();
      } else {
        const result = await PayService.getIframe(
          Number(location.state.point),
          depositName
        );
        if (window.location.href) {
          setIframeURL(
            `${process.env.REACT_APP_HOST}pay/iframe/${result.data.billKey}?host=${window.location.href}`
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useDidMountEffect(() => {
    mobilePurchaseRef.current.action =
      'https://mobile.inicis.com/smart/payment/';
    mobilePurchaseRef.current.target = '_self';
    mobilePurchaseRef.current.submit();
  }, [parameters]);

  useEffect(() => {
    const handleUrlChange = (event: any) => {
      console.log(event.origin);
      if (
        !(
          event.origin === process.env.REACT_APP_HOST ||
          event.origin === `${process.env.REACT_APP_HOST}`.slice(0, -1)
        )
      ) {
        // if (event.origin !== 'http://192.168.0.106:3333') {
        // 보안상의 이유로 올바른 origin인지 확인
        return;
      }

      if (event.data === 'changeParentLocation') {
        // URL 변경 및 새로고침 등 부모 창에서 수행할 작업
        navigate('/point/recharge');
      }
    };

    window.addEventListener('message', handleUrlChange);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      window.removeEventListener('message', handleUrlChange);
    };
  }, []);

  const Col = ({ text1, text2, index }: any) => (
    <div className='flex-row space-between padding-y-16 center-y border-b-171E26'>
      <div className='m-b2-14-m w-c1-14-m color-dark-secondary'>{text1}</div>
      <div
        className={`${
          index === 3 ? `m-b2-14-b w-c1-14-b` : `m-b2-14-m w-c1-14-m`
        } ${
          type === 1 && location.state.ownPoint === 0 && index === 1
            ? `color-disabled`
            : `color-dark-primary`
        }`}
      >
        {text2}
      </div>
    </div>
  );

  const PaymentBox = ({ text, tab }: any) => (
    <div
      className={`padding-y-8 w-50per border-radius-8 flex-center pointer ${
        paymentTab === tab
          ? `bg-primary-100 border-09CE7D`
          : `bg-white border-171E26`
      }`}
      onClick={() => {
        setPaymentTab(tab);
      }}
    >
      <div className='font-medium f-size-16 line-h-24 color-dark-primary'>
        {text}
      </div>
    </div>
  );
  const ReceiptRadioHandler = (text: string, tab: number) => {
    setReceiptTab(tab);
    if (text === '미발행') {
      setDropDown((prev) => ({ ...prev, receipt: false }));
    }
  };
  const PaymentBox2 = ({ text1, text2, type }: any) => (
    <div className='flex-column gap-8'>
      <div className='m-b2-14-m w-c1-14-m color-text-tertiary'>{text1}</div>
      <div
        className={`w-100per padding-12-16 space-between flex-row ${
          type === 1 ? `bg-light-gray-100` : `bg-171E2610`
        } border-radius-8`}
      >
        <div className='m-b1-16-m w-b2-16-m color-dark-primary'>{text2}</div>
        {type === 3 && (
          <div
            className='flex-center bg-light-gray-100 padding-2-8 pointer'
            onClick={() => {
              navigator.clipboard.writeText(text2);
              // setOnToast(true);
            }}
          >
            <div className='m-b2-14-m w-c1-14-m  color-disabled'>복사</div>
          </div>
        )}
      </div>
    </div>
  );

  const PaymentApi = async () => {
    const data = {
      method: 'B',
      amount: Number(location.state.point),
      depositor: depositName,
    };
    try {
      const result = await UserService.Payment(data);
      if (result.data.success) {
        navigate('/point/recharge/payment/complete', {
          state:
            type === 1
              ? {
                  type: type,
                  currentPoint: location.state.ownPoint,
                  chargePoint: location.state.point,
                }
              : {
                  type: type,
                  month: location.state.month,
                  period: location.state.period,
                  price: location.state.price,
                },
        });
      }
    } catch (error: any) {
      alert(error.response.data.message);
    }
  };
  const ReceiptBox = ({ text, tab }: any) => (
    <div
      className='flex-row gap-8 center-y pointer'
      onClick={() => {
        ReceiptRadioHandler(text, tab);
      }}
    >
      <div
        className={`w-18 h-18 flex-center ${
          receiptTab === tab ? `bg-primary-300` : `bg-171E2610`
        } border-radius-999`}
      >
        <div className='bg-white border-radius-999 w-8 h-8'>&nbsp;</div>
      </div>
      <div className='m-b2-14-m w-c1-14-m color-dark-primary'>{text}</div>
    </div>
  );
  // useEffect(() => {
  //   if (onToast) {
  //     setTimeout(() => {
  //       setOnToast(false);
  //     }, 2000);
  //   }
  // }, [onToast]);
  useEffect(() => {
    if (temp.current) {
      // 페이지 초기 렌더링 시에만 paddingBottom 설정
      // document.body.style.paddingBottom = `${temp.current.offsetHeight}px`;
      if (!isMobile(window.innerWidth)) {
        document.body.style.backgroundColor = '#F0F0F0';
      }
    }

    // cleanup 함수
    return () => {
      document.body.style.paddingBottom = ''; // 이전에 설정한 paddingBottom을 초기화
      document.body.style.backgroundColor = '';
    };
  }, []);
  return (
    <div className='w-100per flex-column browser-flex-center '>
      {iframeURL ? (
        <div
          style={{
            position: 'fixed',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
          }}
        >
          <iframe
            src={iframeURL}
            id='myIframe'
            title='Rendered Page'
            width='100%'
            height='100%'
          />
        </div>
      ) : (
        ''
      )}
      {isMobile(window.innerWidth) ? (
        <form
          style={{ display: 'none' }}
          name='mobileweb'
          method='post'
          acceptCharset='euc-kr'
          ref={mobilePurchaseRef}
        >
          <input
            type='text'
            readOnly
            name='P_NEXT_URL'
            value={parameters.returnUrl}
          />
          <input
            type='text'
            readOnly
            name='P_INI_PAYMENT'
            value={parameters.gopaymethod}
          />
          <input type='text' readOnly name='P_MID' value={parameters.mid} />
          <input type='text' readOnly name='P_OID' value={parameters.oid} />
          <input
            type='text'
            readOnly
            name='P_GOODS'
            value={parameters.goodname}
          />
          <input type='text' readOnly name='P_AMT' value={parameters.price} />
          <input
            type='text'
            readOnly
            name='P_UNAME'
            value={parameters.buyername}
          />
          <input type='text' readOnly name='P_RESERVED' value='centerCd=Y' />

          {/* 휴대폰결제 필수 [1:컨텐츠, 2:실물] */}
          {/* {payStatus === 2 && (
              <input
                type='text'
                readOnly
                name='P_HPP_METHOD'
                value='HPP(1):va_receipt:below1000:centerCd(Y)'
              />
            )} */}
        </form>
      ) : (
        ''
      )}
      <div className='flex-column  flex w-100per flex-column w-1024-max-w-768 bg-FFFFFF'>
        <TopNav2
          back
          text='충전/결제'
          hamburger
          onClick={() => {
            setOpen(true);
            console.log('aa');
          }}
        />
        <PointPopUp type='payment' open={open} setOpen={setOpen} />
        <div className='mt-16 flex w-100per flex-column w-1024-max-w-768 '>
          <TopNavPointBrowser close back text='충전/결제' type='payment' />
          <div className='flex-row padding-16 space-between w-100per w-1024-padding-16-40'>
            <div className='m-t2-18-b w-b1-18-b color-dark-primary'>
              충전정보
            </div>
            <div className='flex-row gap-8 center-y'>
              <div className='m-b1-16-m w-b2-16-m  color-primary-300'>
                {type === 1
                  ? Number(location.state.point).toLocaleString() + 'P'
                  : location.state.month + '개월 이용권'}
              </div>
              <img
                alt=''
                className='pointer'
                src={
                  dropDown.recharge
                    ? '/asset/images/arrow/ArrowTop24.svg'
                    : '/asset/images/arrow/ArrowBottom24.svg'
                }
                onClick={() => {
                  setDropDown((prev) => ({
                    ...prev,
                    recharge: !dropDown.recharge,
                  }));
                }}
              />
            </div>
          </div>
          <div
            className='flex-column transition'
            style={{
              maxHeight: dropDown.recharge ? '300px' : 0,
              overflow: 'hidden',
            }}
          >
            <div className='flex-column  gap-16 padding-0-16-24-16 w-1024-padding-16-40'>
              <div className='flex-column'>
                <div className='padding-y-16 flex-row gap-4 border-b-2-171E26 '>
                  <img
                    alt=''
                    src={
                      type === 1
                        ? '/asset/images/icon/point_icon.svg'
                        : '/asset/images/icon/period_icon.svg'
                    }
                  />
                  <div className='font-bold f-size-18 line-h-26 color-000000'>
                    <span className='color-primary-300'>
                      {type === 1
                        ? Number(location.state.point).toLocaleString() + 'P'
                        : location.state.month + '개월 이용권'}{' '}
                      &nbsp;
                    </span>
                    {type === 1 ? '충전' : '결제'} 합니다.
                  </div>
                </div>
                <Col
                  text1={type === 1 ? '충전 전 포인트' : '결제 예정 이용권'}
                  text2={
                    type === 1
                      ? location.state.ownPoint.toLocaleString() + 'P'
                      : '+' + location.state.month + '개월'
                  }
                  index={1}
                />
                <Col
                  text1={type === 1 ? '충전 예정 포인트' : '결제 후 이용기간'}
                  text2={
                    type === 1
                      ? '+' +
                        Number(location.state.point).toLocaleString() +
                        'P'
                      : '~' + location.state.period + '까지'
                  }
                  index={2}
                />
                {type === 1 && (
                  <Col
                    text1='충전 후 포인트'
                    text2={
                      (
                        location.state.ownPoint + Number(location.state.point)
                      ).toLocaleString() + 'P'
                    }
                    index={3}
                  />
                )}
              </div>
              <div className='m-c1-12-m w-c2-12-m color-text-tertiary'>
                {type === 1
                  ? '1P는 트랜스파머 서비스 내에서 1원에 해당합니다.'
                  : '이용기간 동안 모든 서비스를 무제한 이용 가능합니다.'}
              </div>
            </div>
          </div>
          <div className='h-8 bg-F0F0F0 w-100per'>&nbsp;</div>

          <div className='w-100per flex-column'>
            <div className='flex-row space-between padding-16 w-1024-padding-16-40 center-y'>
              <div className='m-t2-18-b w-b1-18-b color-dark-primary'>
                최종결제확인
              </div>
              <div className='flex-row gap-8 '>
                <div className='m-b1-16-m w-b2-16-m color-primary-300 no-wrap'>
                  {type === 1
                    ? Number(location.state.point).toLocaleString() + '원'
                    : Number(location.state.price).toLocaleString() + '원'}
                </div>
                <img
                  alt=''
                  className='pointer'
                  src={
                    dropDown.check
                      ? '/asset/images/arrow/ArrowTop24.svg'
                      : '/asset/images/arrow/ArrowBottom24.svg'
                  }
                  onClick={() => {
                    setDropDown((prev) => ({
                      ...prev,
                      check: !dropDown.check,
                    }));
                  }}
                />
              </div>
            </div>
            <div
              className='flex-column transition'
              style={{
                maxHeight: dropDown.check ? '350px' : 0,
                overflow: 'hidden',
              }}
            >
              <div className='flex-column gap-16 padding-0-16-24-16 w-1024-0-40-24-40'>
                <div className='flex-column gap-16'>
                  <div className='flex-column'>
                    <div className='padding-y-16 flex-row space-between'>
                      <div className='m-b2-14-m w-c1-14-m color-text-secondary'>
                        결제 금액
                      </div>
                      <div className='m-b2-14-b w-c1-14-b color-dark-primary'>
                        {type === 1
                          ? Number(location.state.point).toLocaleString() + '원'
                          : Number(location.state.price).toLocaleString() +
                            '원'}
                        <span className='m-c1-12-m  w-c2-12-m color-text-tertiary'>
                          (VAT 포함)
                        </span>{' '}
                      </div>
                    </div>
                    <div className='padding-y-16 flex-row space-between'>
                      <div className='m-b2-14-m w-c1-14-m color-text-secondary'>
                        {type === 1 ? '총 충전 포인트' : '총 구독일'}
                      </div>
                      <div className='m-b2-14-m w-c1-14-m color-dark-primary'>
                        {type === 1
                          ? '+' +
                            Number(location.state.point).toLocaleString() +
                            'P'
                          : '+' + location.state.month + '개월'}
                      </div>
                    </div>
                    {type === 2 && (
                      <>
                        <div className='padding-y-16 flex-row space-between'>
                          <div className='m-b2-14-m w-c1-14-m color-text-secondary'>
                            기존 금액
                          </div>
                          <div className='color-171E2620 f-size-14 line-h-20 font-bold'>
                            {Number(
                              location.state.originalPrice
                            ).toLocaleString() + '원'}
                          </div>
                        </div>
                        {type === 2 && location.state.sale && (
                          <div className='padding-y-16 flex-row space-between'>
                            <div className='m-b2-14-m w-c1-14-m color-text-secondary'>
                              할인 금액
                            </div>
                            <div className='m-b2-14-m w-c1-14-m color-dark-primary'>
                              <span className='color-text-increase'>
                                {location.state.sale}% 할인 &nbsp;
                              </span>
                              {(
                                Number(location.state.price) -
                                Number(location.state.originalPrice)
                              ).toLocaleString() + '원'}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className='padding-12-16 flex-row space-between border-radius-8 bg-primary-100 center-y'>
                    <div className='m-b2-14-m w-c1-14-m color-primary-300'>
                      총 결제금액
                    </div>
                    <div className='m-t2-18-b w-b1-18-b color-primary-300'>
                      {type === 1
                        ? Number(location.state.point).toLocaleString() + '원'
                        : Number(location.state.price).toLocaleString() + '원'}
                    </div>
                  </div>
                </div>
                <div className='font-medium f-size-12 line-h-14 color-text-tertiary text-center'>
                  위 충전 조건 확인 후, 동의하면 충전해 주세요.
                </div>
              </div>
            </div>
          </div>
          <div
            ref={temp}
            className=' padding-16-16-48-16 border-t-171E26 w-100per bg-white w-1024-max-w-768 w-1024-16-40-48-40'
            style={{ bottom: 0 }}
          >
            <Button
              onClick={() => {
                if (isTest) {
                  getIframeURL();
                } else {
                  alert('준비중입니다.');
                }
              }}
              description={
                Number(location.state.point).toLocaleString() + 'P 충전하기'
              }
              on={!isTest ? false : true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
