import { Content1, Footer, ReportTitle } from '../component';

export const Estimated = () => {
  const content = [
    '본 물건의 트랜스파머 추정가는 2,380만원으로 분석됨',
    '주변 실거래 사례의 공시지가 대비 배율은 평균 2.15배로 분석되며, ',
    '정확한 물건의 추정가 산정을 위해 트랜스파머 인공지능 보정계수는 평균 1.25배를 적용하였음',
  ];
  return (
    <div className='flex-column w-100per space-between page'>
      <div className='flex-column  w-100per'>
        <ReportTitle
          address='전북특별자치도 김제시 순동 445-19'
          category='추정 근거 및 1차 추정가'
        />
        <div className='flex-column padding-24 gap-12'>
          <Content1 content={content} />
        </div>
      </div>
      <Footer number={9} />
    </div>
  );
};
