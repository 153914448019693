import { useLocation, useNavigate } from 'react-router-dom';
import { TopNav2, TopNavNewBrowser } from '../../component/mobile/Topnav';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import {
  hackleClickGotoMain,
  hackleClickGotoPricePage,
  hackleClient,
} from '../../hackler';
import { SetPopUpOn } from '../../store/onOffSlice';
import Footer from '../../component/browser/Footer';
import { GetUserInfo } from '../../utilities/auth';
const SignUpComplete = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const state = useSelector((state: any) => {
    return state.signUpInfo;
  });

  useEffect(() => {
    // console.log(state);
    // GTM 컨테이너 ID를 설정합니다.
    const tagManagerArgs = {
      gtmId: 'GTM-KGBR7N3P', // GTM 컨테이너 ID로 대체해야 합니다.
    };

    // GTM을 초기화합니다.
    TagManager.initialize(tagManagerArgs);
    TagManager.dataLayer({
      dataLayer: {
        event: 'signup', // 컴포넌트 로드 이벤트
        componentName: 'signup', // 컴포넌트 이름 또는 추가 정보
        method:
          state.signUpType === 'N'
            ? 'naver'
            : state.signUpType === 'K'
            ? 'kakao'
            : state.signUpType === 'C'
            ? 'general'
            : state.signUpType === 'G'
            ? 'google'
            : state.signUpType === 'F'
            ? 'facebook'
            : '',
      },
    });
    // GetUserInfo();
  }, []);
  useEffect(() => {
    localStorage.setItem('username', location.state.name);
  }, []);
  return (
    <div
      id='post-meta-col'
      className='w-100per h-100vh flex-column flex-center'
    >
      <TopNav2 text='회원가입 완료' hamburger />
      <TopNavNewBrowser />
      <div
        className='h-100per w-100per flex-center gap-48 flex-column'
        style={{ maxWidth: '468px' }}
      >
        <div className='flex-column gap-12 w-100per flex-center '>
          <img src='/asset/images/icon/congratulation.svg' />
          <div className='flex-column gap-8 flex-center'>
            <div className='m-b1-16-b color-dark-primary'>
              {location.state.name}님, 환영합니다!
            </div>
            <div className='m-b2-14-m color-disabled'>
              가입 선물 10,000P 지급했어요!
            </div>
          </div>
        </div>
        <div className='padding-x-16 w-100per flex-column gap-16'>
          <div
            className='bg-primary border-radius-8 padding-14-16 flex-center pointer'
            onClick={() => {
              sessionStorage.setItem('tab', 'price');
              dispatch(SetPopUpOn(true));
              hackleClickGotoPricePage();
              navigate(
                '/price/map?utm_source=TF&utm_medium=CTA&utm_campaign=direct_button&utm_id=promotion&utm_content=signup_done'
              );
            }}
          >
            <div className='flex-row gap-12'>
              <div className='m-t2-18-b color-FFFFFF'>
                지금 바로 내 땅 검색하기
              </div>
              <img src='/asset/images/arrow/ArrowRightWhite.svg' />
            </div>
          </div>
          <div
            className='bg-FFFFFF border-radius-8 padding-14-16 flex-center pointer'
            style={{ border: '1px solid #1E9B6B' }}
            onClick={() => {
              hackleClickGotoMain();
              navigate('/');
            }}
          >
            <div className='m-t2-18-b' style={{ color: '#1E986B' }}>
              홈으로
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignUpComplete;
