import { useEffect, useState } from 'react';

import { TextSectionTitle } from '../../../../component/report/Text';
import { RowSectionTitle, RowTable3 } from '../../../../component/report/Row';
import { ILandInfo } from '../../Main';
import GetLandShpaeImage from '../../../../asset/styles/Land-Shape';

const ReportDetail4 = ({ data }: { data: ILandInfo }) => {
  let temp;
  if (data.zoning && data.zoning.length === 9) {
    const firstThreeChars = data.zoning.slice(0, 3); // 첫 3글자 추출
    const remainingChars = data.zoning.slice(3); // 나머지 글자 추출
    temp = (
      <div className='text-end'>
        {firstThreeChars}
        <br />
        {remainingChars}
      </div>
    );
  } else if (data.zoning && data.zoning.length === 8) {
    const firstThreeChars = data.zoning.slice(0, 2); // 첫 3글자 추출
    const remainingChars = data.zoning.slice(2); // 나머지 글자 추출
    temp = (
      <div className='text-end'>
        {firstThreeChars}
        <br />
        {remainingChars}
      </div>
    );
  } else {
    temp = data.zoning;
    // <div className='text-end '>{temp}</div>;
  }

  const shapeWithoutSuffix =
    data.shape.slice(-1) === '형' ? data.shape.slice(0, -1) : data.shape;

  return (
    <div className='padding-b-24 browser-bg-FFFFFF'>
      <RowSectionTitle>
        <TextSectionTitle>4. 토지정보 </TextSectionTitle>
      </RowSectionTitle>
      <div className='flex-column'>
        <div className='padding-24-16 gap-16 flex-column'>
          <div className='flex-row gap-24 end-y'>
            <RowTable3 title='토지면적' value={`${data.landArea} m² `} />
            <RowTable3 title={<>용도 지역</>} value={temp} />
            {/* <div className='flex-row padding-b-8 border-b-171E26 space-between w-50per'>
              <div>용도지역</div>
              <div>awef</div>
            </div> */}
          </div>
          <div className='flex-row gap-24'>
            <RowTable3 title='지목' value={data.category} />
            <RowTable3 title='이용상황' value={data.useStatus} />
          </div>
          <div className='flex-row gap-24'>
            <RowTable3 title='고저' value={data.height} />
            <RowTable3 title='도로' value={data.roadSurface} />
          </div>
        </div>

        <div className='padding-0-16-16 gap-10'>
          <div className='padding-24-16 bg-light-gray border-radius-8 gap-18 flex-row center-y'>
            <img src={GetLandShpaeImage(shapeWithoutSuffix)} alt='' />
            <div className='flex-column gap-4'>
              <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
                {shapeWithoutSuffix}형
              </div>
              <div className='font-medium f-size-12 line-h-14 color-text-tertiary'>
                {data.roadSurface_desc}
              </div>
            </div>
          </div>
        </div>
        <div className='flex-row gap-4 end-x padding-x-16'>
          <span
            className='flex-row gap-4 end-x hover'
            onClick={() => {
              window.open(
                `https://www.eum.go.kr/web/ar/lu/luLandDet.jsp?selSido=11&selSgg=470&selUmd=0102&selRi=00&landGbn=1&bobn=711&bubn=1&sggcd=&isNoScr=script&mode=search&selGbn=umd&s_type=1&add=land&pnu=${data.pnuCode}`
              );
            }}
          >
            <div className='font-medium f-size-12 line-h-14 color-text-secondary'>
              토지이용계획원 바로가기
            </div>
            <div className='font-medium f-size-12 line-h-14 color-text-secondary'>
              {'>'}
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReportDetail4;
