export const RedirectHandler = (key: string) => {
  if (key === 'Kakao') {
    let clientId = process.env.REACT_APP_KAKAO_CLIENT_ID;
    let redirectUrl = `${window.location.origin}/kakao/redirect`;
    let queryParams = `?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code`;
    sessionStorage.setItem('url', redirectUrl);
    window.location.href = `https://kauth.kakao.com/oauth/authorize${queryParams}`;
  } else if (key === 'Naver') {
    let clientId = process.env.REACT_APP_NAVER_CLIENT_ID;
    let redirectUrl = `${window.location.origin}/naver/redirect`;
    let queryParams = `?response_type=code&client_id=${clientId}&state=state&redirect_uri=${redirectUrl}`;
    sessionStorage.setItem('url', redirectUrl);
    window.location.href = `https://nid.naver.com/oauth2.0/authorize${queryParams}`;
  } else if (key === 'Facebook') {
    const client_id = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
    const redirectUrl = `${window.location.origin}/facebook/redirect`;
    const url = `https://www.facebook.com/v11.0/dialog/oauth?client_id=${client_id}&redirect_uri=${redirectUrl}&scope=email`;
    sessionStorage.setItem('url', redirectUrl);
    window.location.href = url;
  } else if (key === 'Google') {
    const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const GOOGLE_REDIRECT_URI = `${window.location.origin}/google/redirect`;
    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${GOOGLE_REDIRECT_URI}&response_type=code&scope=email profile`;
    sessionStorage.setItem('url', GOOGLE_REDIRECT_URI);
    window.location.href = url;
  }
};
