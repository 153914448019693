import { createSlice } from "@reduxjs/toolkit";

const initialInfoState = {
  name: "",
  phoneNumber: "",
  phoneNumber2: "",
  certificationNumber: "",
  category: "",
  category2: "",
  agricultureNumber: "",
  id: "",
  idCheck: false,
  passwordCheck: false,
  password: "",
  password2: "",
  passwordEqual: false,
  btnCheck: false,
  spinner: false,
  idEqualCheck: false,
  birthdate: "",
  gender: "",
  modal: false,
  signUpType: "",
  terms: "",
};

const signUpInfoSlice = createSlice({
  name: "signUpInfo",
  initialState: initialInfoState,
  reducers: {
    Name(state, action) {
      state.name = action.payload;
    },

    PhoneNumber(state, action) {
      state.phoneNumber = action.payload;
    },
    PhoneNumber2(state, action) {
      state.phoneNumber2 = action.payload;
    },
    CertificationNumber(state, action) {
      state.certificationNumber = action.payload;
    },
    Category(state, action) {
      state.category = action.payload;
    },
    Category2(state, action) {
      state.category2 = action.payload;
    },
    Agriculture(state, action) {
      state.agricultureNumber = action.payload;
    },
    Id(state, action) {
      state.id = action.payload;
    },
    IdCheck(state, action) {
      state.idCheck = action.payload;
    },
    IdEqualCheck(state, action) {
      state.idEqualCheck = action.payload;
    },
    PasswordCheck(state, action) {
      state.passwordCheck = action.payload;
    },
    Password(state, action) {
      state.password = action.payload;
    },
    Password2(state, action) {
      state.password2 = action.payload;
    },
    PasswordEqual(state, action) {
      state.passwordEqual = action.payload;
    },
    SignUpBtnCheck(state, action) {
      state.btnCheck = action.payload;
    },
    SpinnerOn(state, action) {
      state.spinner = action.payload;
    },
    BirthDate(state, action) {
      state.birthdate = action.payload;
    },
    Gender(state, action) {
      state.gender = action.payload;
    },
    ModalOn(state, action) {
      state.modal = action.payload;
    },
    SignUpType(state, action) {
      state.signUpType = action.payload;
    },
    TermsHandler(state, action) {
      state.terms = action.payload;
    },
  },
});

export const {
  Name,
  PhoneNumber,
  CertificationNumber,
  Category,
  PhoneNumber2,
  Agriculture,
  Id,
  Password,
  IdCheck,
  PasswordCheck,
  PasswordEqual,
  Password2,
  SignUpBtnCheck,
  SpinnerOn,
  IdEqualCheck,
  BirthDate,
  Gender,
  ModalOn,
  SignUpType,
  TermsHandler,
  Category2,
} = signUpInfoSlice.actions;
export default signUpInfoSlice.reducer;
