import { useNavigate } from 'react-router-dom';
import Button from '../../component/mobile/Button';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
export const SignUpPopUp = ({ setOnPopUp, name }: any) => {
  const navigate = useNavigate();
  const state = useSelector((state: any) => {
    return state.signUpInfo;
  });
  useEffect(() => {
    console.log('signuptype', state.signUpType);
    // GTM 컨테이너 ID를 설정합니다.
    const tagManagerArgs = {
      gtmId: 'GTM-KGBR7N3P', // GTM 컨테이너 ID로 대체해야 합니다.
    };

    // GTM을 초기화합니다.
    TagManager.initialize(tagManagerArgs);
    TagManager.dataLayer({
      dataLayer: {
        event: 'signup', // 컴포넌트 로드 이벤트
        componentName: 'signup', // 컴포넌트 이름 또는 추가 정보
        method:
          state.signUpType === 'N'
            ? 'naver'
            : state.signUpType === 'K'
            ? 'kakao'
            : state.signUpType === 'C'
            ? 'general'
            : state.signUpType === 'G'
            ? 'google'
            : state.signUpType === 'F'
            ? 'facebook'
            : '',
      },
    });
  }, []);
  return (
    <div>
      <div
        id='post-meta-col'
        className='flex-column fixed h-100per z-9999 browser-flex-center w-100per flex-center'
        style={{
          backgroundColor: '#00000060',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div className='bg-FFFFFF padding-24-16-16-16 border-radius-16 gap-12 w-312 flex-center flex-column'>
          <img src='/asset/images/icon/congratulation.svg' />
          <div className='flex-column gap-24 w-100per'>
            <div className='flex-column gap-8 flex-center'>
              <div className='m-b1-16-b'>{name}님, 환영합니다</div>
              <div className='m-b2-14-m color-disabled'>가입 완료!</div>
            </div>
            <div className='w-100per'>
              <Button
                on={true}
                description='확인'
                onClick={() => {
                  setOnPopUp(false);
                  sessionStorage.removeItem('name');
                  sessionStorage.removeItem('email');
                  sessionStorage.removeItem('phone');
                  navigate('/', { replace: true });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
