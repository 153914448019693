import "../../asset/styles/SaleInfo.css";
import { Topnav } from "../../component/mobile/Topnav";
import sample from "./../../asset/images/community/sample.svg";
import menuBtn from "./../../asset/images/community/menuBtn.svg";
import like from "./../../asset/images/community/heartIcon.svg";
import commentImg from "./../../asset/images/community/speechBubble.svg";
import { useEffect, useState, useRef } from "react";
import { BottomNav } from "../../component/mobile/BottomNav";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import BoardService from "../../service/board/Board";
import { getComment } from "./Common";
import NewComment from "./NewComment";

interface ReCommentType {
  articleIdx: string;
  commentAmount: number | undefined;
  commentIdx: string;
  createdDate: string;
  email: string;
  likeAmount: number | undefined;
  content: string;
}
interface ReComment extends Array<ReCommentType> { }
interface CommentType {
  articleIdx: number;
  commentAmount: number | null;
  commentIdx: number;
  content: string;
  createdDate: string;
  email: string;
  likeAmount: number | null;
}

const ReComment = () => {
  const ref = useRef<HTMLInputElement>(null);
  const params = useParams();
  const navigate = useNavigate();
  const [writer, setWriter] = useState("");
  const [open, setOpen] = useState(false);
  const [newcomment, setNewComment] = useState("");
  const [comment, setComment] = useState<CommentType>();
  const [commentIdx, setCommentIdx] = useState("");
  const [reComment, setReComment] = useState<ReComment>([]);
  const [limit, setLimit] = useState(4);
  const email = useSelector((state: any) => {
    return state.user.userId;
  });

  const customStyles = {
    content: {
      top: "0%",
      left: "0%",
      right: "0%",
      transform: "translate(0%, 135%)",
    },
  };

  const getReplyCommentApi = async () => {
    const key = "reply";
    const value = params.commentIdx;
    let offset = 0;
    try {
      const result = await BoardService.GetComment(key, value, offset, limit);
      setReComment(result.rows);
      sessionStorage.setItem("offset", "4");
      sessionStorage.setItem("totalCount", result.totalCount);
    } catch (error) {
      console.log(error);
    }
  };
  const getReplyCommentScrollApi = async () => {
    let offset = Number(sessionStorage.getItem("offset"));
    let totalCount = Number(sessionStorage.getItem("totalCount"));
    const key = "reply";
    const value = params.commentIdx;
    if (offset < totalCount) {
      try {
        const result = await BoardService.GetComment(key, value, offset, limit);
        setReComment((prev) => [...prev, ...result.rows]);
        sessionStorage.setItem("offset", (offset + limit).toString());
      } catch (error) {
        console.log(error);
      }
    }
  };

  const writeCommentApi = async () => {
    if (newcomment === "") {
      alert("내용을 입력해주세요.");
    } else {
      const data = {
        content: newcomment,
        articleIdx: params.boardIdx,
        parentIdx: params.commentIdx,
      };
      try {
        const result = await BoardService.WriteComment(data);
        if (result.success) {
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const DeleteCommentApi = async () => {
    if (window.confirm("삭제하시겠습니까?")) {
      try {
        const result = await BoardService.DeleteComment(commentIdx);
        alert("삭제하였습니다.");

        window.location.reload();
      } catch (error) {
        console.log(error);
        setOpen(false);
      }
    } else {
      alert("취소하였습니다.");
    }
  };
  const handleScroll = () => {
    if (ref.current) {
      if (
        Math.round(
          (ref.current as any).offsetHeight + (ref.current as any).scrollTop
        ) === (ref.current as any).scrollHeight
      ) {
        console.log("닿음");
        getReplyCommentScrollApi();
      }
    }
  };
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        handleScroll();
      },
      {
        capture: true,
      }
    );
    return () => {
      window.removeEventListener("scroll", () => handleScroll());
    };
  }, []);

  useEffect(() => {
    sessionStorage.clear();
    getReplyCommentApi();
    const commentIdx = params.commentIdx;
    getComment(commentIdx, setComment);
  }, []);

  return (
    <div
      ref={ref}
      className="flex-column w-100per overflow-auto"
      style={{ paddingBottom: "120px", height: window.innerHeight }}
    >
      <Modal
        className="fade-in-box"
        style={customStyles}
        isOpen={open}
        onRequestClose={() => {
          setOpen(false);
        }}
      >
        {email === writer ? (
          <div
            className="flex-column flex-center w-100per"
            style={{ paddingBottom: "265px" }}
          >
            <div
              className=" w-100per flex-center padding-10-0"
              onClick={() => {
                DeleteCommentApi();
              }}
            >
              <div className="font-bold">삭제하기</div>
            </div>
            <div
              className=" w-100per flex-center padding-10-0"
              onClick={() => {
                navigate(`/community/commentmodify/${commentIdx}`);
              }}
            >
              <div className="font-bold">수정하기</div>
            </div>
          </div>
        ) : (
          <div
            className="flex-column flex-center w-100per"
            style={{ paddingBottom: "265px" }}
          >
            <div className=" w-100per flex-center padding-10-0">
              <div className="font-bold">글 신고하기</div>
            </div>
            <div className=" w-100per flex-center padding-10-0">
              <div className="font-bold">사용자 차단하기</div>
            </div>
          </div>
        )}
      </Modal>
      <Topnav text="커뮤니티" />
      <div className="flex-column w-100per padding-0-20 mt-20">
        <div className="flex-column w-100per padding-b-20 borderbottom-1px-e8ebe6 gap-10">
          <div className="flex-row center-y space-between">
            <div className="flex-row center-y gap-10">
              <img src={sample} />
              <div className="flex-column">
                <div className="font-normal">{comment && comment.email}</div>
                <div className="font-normal w-100per">
                  {comment && comment.createdDate}
                </div>
              </div>
            </div>
          </div>
          <div
            className="font-normal"
            style={{ fontSize: "20px", wordBreak: "break-all" }}
          >
            {comment && comment.content}
          </div>
          <div className="flex-row space-between">
            <div className="flex-row center-y">
              <div className="font-normal">
                {" "}
                ↪ 댓글달기 &#12685; {comment && comment.commentAmount}개
              </div>
            </div>
            <div className="flex-row center-y gap-10">
              <img src={like} />
              <div className="font-normal">3</div>
            </div>
          </div>
        </div>
        {reComment &&
          reComment.map((item) => {
            return (
              <div
                className="flex-column gap-20 mt-20"
                style={{ paddingLeft: "20px" }}
              >
                <div className="flex-row space-between">
                  <div className="flex-row gap-10 center-y">
                    <img src={sample} />
                    <div className="flex-column">
                      <div className="font-normal">{item.email}</div>
                      <div className="font-normal">{item.createdDate}</div>
                    </div>
                  </div>
                  <img
                    src={menuBtn}
                    onClick={() => {
                      setOpen(true);
                      setWriter(item.email);
                      setCommentIdx(item.commentIdx);
                    }}
                  />
                </div>
                <div className="flex-column  gap-10">
                  <div
                    className="font-normal"
                    style={{
                      fontWeight: "500",
                      maxWidth: "100%",
                      wordBreak: "break-all",
                    }}
                  >
                    {item.content}
                  </div>
                  <div className="flex-row gap-20 end-x">
                    <div
                      className="flex-row center-y"
                      onClick={() => {
                        // notify();
                      }}
                    >
                      <img src={like} />
                      <div
                        className="font-normal"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        좋아요
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <NewComment ref={ref} onSuccess={() => { window.location.reload(); }} setComment={setNewComment} data={{
        content: newcomment,
        articleIdx: params.boardIdx,
        parentIdx: params.commentIdx,
      }}></NewComment>      
    </div>
  );
};
export default ReComment;
