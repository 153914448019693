import { TextSectionTitle } from "../../../../component/report/Text";
import {
  RowSectionTitle,
  RowTableValueBold,
} from "../../../../component/report/Row";
import {
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  ReferenceLine,
} from "recharts";
import { IIndexInfo } from "../../Main";
import { BoxGreen1 } from "../../../../component/report/Box";

const ReportDetail2 = ({ data }: { data: IIndexInfo }) => {
  return (
    <div className="padding-b-24 browser-bg-FFFFFF">
      <RowSectionTitle>
        <TextSectionTitle>2. 건축거래 활성화 지수</TextSectionTitle>
      </RowSectionTitle>
      {[
        { title: "신축거래 활성화 지수", data: data.newConstructionIndexList },
        { title: "리모델링거래 활성화 지수", data: data.remodelingIndexList },
      ].map((el) => {
        if (el.data.length === 0 || el.data[0].gugun === null) {
          return;
        }
        return (
          <div className="flex-column ">
            <div className="padding-16-16-0">
              <RowTableValueBold
                title={el.title}
                value={el.data[el.data.length - 1].gugun}
              />
            </div>

            <div className="flex-column of-x-auto">
              <LineChart
                className="flex-medium f-size-11 center-x"
                width={360 - 16}
                height={181}
                data={el.data.map((el, i: number) => {
                  return {
                    day: `${el.stdYear}-${el.stdMonth}`,
                    value: el.index,
                  };
                })}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <Line
                  type="monotone"
                  dataKey="value"
                  name="활성화 지수"
                  stroke="#09CE7D"
                />
                <ReferenceLine
                  y={100}
                  label="20~22년 평균 기준(100)"
                  stroke="red"
                />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
              </LineChart>
            </div>
            <div className="padding-8-16 gap-10">
              <BoxGreen1>
                <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                  {el.data[el.data.length - 1].stdMonth}월(최근 집계 기준){" "}
                  {el.data[el.data.length - 1].gugun}
                </div>
                <div
                  className="font-bold f-size-16 line-h-24 "
                  style={{
                    color:
                      el.data[el.data.length - 1].index < 100
                        ? "#d62554"
                        : "#1E9B6B",
                  }}
                >
                  {el.title}는 <br /> {el.data[el.data.length - 1].index}
                  입니다.
                </div>
              </BoxGreen1>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReportDetail2;
