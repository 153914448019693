import Request from "../../@api/request";

interface userData {
  birthDate: string;
  category: string;
  categoryDesc: string;
  agriculturalNum: string;
  areaOfInterest: string;
}

interface CheckNick {
  nick: string;
}
interface userData {
  birthDate: string;
  category: string;
  categoryDesc: string;
  agriculturalNum: string;
  areaOfInterest: string;
}
// const baseURL = "http://localhost:3333";
// const baseURL = "https://back.transfarmer.kr/";
const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);
export default class UserService {
  static checkLogin = async () => {
    return await request.get(`users/sign/check`);
  };
  static checkAccount = async () => {
    return await request.get(`users/sign`);
  };
  static checkNick = async (data: CheckNick) => {
    const result = await request.post("users/information/nick", data);
    return result.data;
  };
  static checkInfo = async () => {
    const result = await request.post("users/information/info", {});
    return result.data;
  };

  static updateAgriNum = async (agriculturalNum: number) => {
    const result = await request.put("users/information/info/update/agriNum", {
      agriculturalNum,
    });
    return result;
  };
  static checkPoint = async () => {
    const result = await request.get(`/users/information/point`);
    return result;
  };
  static Payment = async (data: any) => {
    const result = await request.post("users/information/point/payment", data);
    return result;
  };
  static getPointHistory = async (
    type: string,
    startDate: string,
    endDate: string,
    category?: string,

    reserveCategory?: string
  ) => {
    const result = await request.get(
      `users/information/point/history?type=${type}&category=${category}&startDate=${startDate}&endDate=${endDate}&reserveCategory=${reserveCategory}`
    );
    return result;
  };
  static PointHistoryDetail = async (idx: number, category?: string) => {
    const result = await request.get(
      `users/information/point/history/detail/${idx}/category=${category}`
    );
    return result;
  };

  static UserInfoUpdate = async (data: userData) => {
    const result = await request.patch("users/information/info/update", data);
    return result;
  };

  static visit = async () => {
    try{
      const result = await request.get("users/information/visit");
      return result
    }catch(err){
      console.log(err)
    }
  };
}
