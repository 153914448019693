import { isMobile, transNum } from '../../../../component/function/function';

const Item = ({
  open,
  saleInfo,
  isFarm,
  type,
  panto,
  data,
  setModalAddress,
  setClose,
  close,
  onClick,
}: {
  open: any;
  saleInfo: any;
  panto: any;
  isFarm: any;
  type: any;
  setClose?: any;
  setModalAddress: any;
  close?: boolean;
  data: {
    address?: string;
    area?: number;
    buildingArea?: null;
    buildingPyeong?: null;
    endDate?: string;
    estimated?: number;
    img?: string;
    keyword?: string;
    likeCount?: number;
    price?: number;
    pyeong?: number;
    regTime?: string;
    roadSurface?: string;
    salesIdx?: number;
    startDate?: string;
    views?: number;
    category?: string;
    isFarm?: string;
    auctionIdx?: number;
    auctionNumber?: string;
    court?: string;
    bidDate?: string;
    appraisedValue?: number;
    lowestPrice?: number;
    bidCount?: number;
    dynamicPrice?: number;
  };
  onClick: () => void;
}) => {
  return (
    <div
      className='padding-y-24 flex-row gap-16 border-b-1-171E2640 hover space-between'
      onClick={onClick}
    >
      <div className='flex-column gap-16'>
        <div className='flex-column gap-2'>
          <div className='flex-row gap-8'>
            {type === 'S' ? (
              <div className='font-medium f-size-12 line-h-14 color-text-tertiary'>
                {data.regTime && data.regTime.slice(0, 10)}
              </div>
            ) : (
              ''
            )}
            {type === 'A' || type === 'P' ? (
              <div className='font-medium f-size-12 line-h-14 color-text-tertiary'>
                {data.auctionNumber && data.auctionNumber.split(' ')[1]}
              </div>
            ) : (
              ''
            )}
            {type === 'A' || type === 'P' ? (
              <div className='font-medium f-size-12 line-h-14 color-text-tertiary'>
                {data.court}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className='font-bold f-size-16 line-h-24 color-text-primary'>
            {data.address}
          </div>
        </div>
        <div className='flex-column gap-4'>
          <div className='flex-row gap-4'>
            {type === 'S' && data.isFarm === 'Y' ? (
              <div className='padding-2-8 flex-row gap-10 border-radius-4 border-1-171E2640'>
                <div className='font-medium f-size-12 line-h-16 color-text-tertiary'>
                  농지은행
                </div>
              </div>
            ) : (
              ''
            )}
            {type === 'S' && data.isFarm === 'Y' ? (
              <div className='padding-2-8 flex-row gap-10 border-radius-4 border-1-171E2640'>
                <div className='font-medium f-size-12 line-h-16 color-text-tertiary'>
                  토지 {data.pyeong}평
                </div>
              </div>
            ) : (
              ''
            )}

            {(type === 'A' || type === 'P') && data.isFarm === 'Y' ? (
              <div className='padding-2-8 flex-row gap-10 border-radius-4 border-1-171E2640'>
                <div className='font-medium f-size-12 line-h-16 color-text-tertiary'>
                  {data.category} {data.pyeong}평
                </div>
              </div>
            ) : (
              ''
            )}

            {(type === 'A' || type === 'P') && data.isFarm === 'N' ? (
              <div className='padding-2-8 flex-row gap-10 border-radius-4 border-1-171E2640'>
                <div className='font-medium f-size-12 line-h-16 color-text-tertiary'>
                  토지 {data.pyeong}평
                </div>
              </div>
            ) : (
              ''
            )}
            {(type === 'A' || type === 'P') && data.isFarm === 'N' ? (
              <div className='padding-2-8 flex-row gap-10 border-radius-4 border-1-171E2640'>
                <div className='font-medium f-size-12 line-h-16 color-text-tertiary'>
                  주택
                </div>
              </div>
            ) : (
              ''
            )}

            {(type === 'A' || type === 'P') && data.bidCount ? (
              <div
                className='padding-2-8 flex-row gap-10 border-radius-4'
                style={{ border: '1px solid #1e9b6b' }}
              >
                <div className='font-medium f-size-12 line-h-16 color-primary-500'>
                  유찰 {data.bidCount}회
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          {data.endDate && type === 'S' ? (
            <div className='font-bold f-size-14 line-h-20 color-text-primary'>
              {Math.ceil(
                (new Date(data.endDate).getTime() - new Date().getTime()) /
                  (1000 * 3600 * 24)
              ) >= 0
                ? `마감일 ${data.endDate.slice(0, 10)} (D-${Math.ceil(
                    (new Date(data.endDate).getTime() - new Date().getTime()) /
                      (1000 * 3600 * 24)
                  )})`
                : `마감일 ${data.endDate.slice(0, 10)} (종료)`}
            </div>
          ) : type === 'S' ? (
            <div className='font-bold f-size-14 line-h-20 color-text-primary'>
              상시게시
            </div>
          ) : (
            ''
          )}
          {type === 'S' ? (
            <div className='font-medium f-size-14 line-h-20 color-text-secondary'>
              희망가 {transNum(data.price || 0)}
            </div>
          ) : (
            ''
          )}
          {type === 'S' ? (
            <div
              className='font-bold f-size-14 line-h-20 color-primary-500'
              style={{ color: '#EC4F4F' }}
            >
              AI 추정가 {transNum(data.estimated || 0)} (
              {Math.floor(((data.estimated || 0) / (data.price || 0)) * 1000) /
                10}
              %)
            </div>
          ) : (
            ''
          )}

          {(type === 'A' || type === 'P') && data.bidDate ? (
            <div className='font-bold f-size-14 line-h-20 color-text-primary'>
              {Math.ceil(
                (new Date(data.bidDate).getTime() - new Date().getTime()) /
                  (1000 * 3600 * 24)
              ) >= 0
                ? `입찰일 ${data.bidDate.slice(0, 10)} (마감 D-${Math.ceil(
                    (new Date(data.bidDate).getTime() - new Date().getTime()) /
                      (1000 * 3600 * 24)
                  )})`
                : `입찰일 ${data.bidDate.slice(0, 10)} (마감)`}
            </div>
          ) : (
            ''
          )}
          {type === 'A' || type === 'P' ? (
            <div className='font-medium f-size-14 line-h-20 color-text-secondary'>
              감정가 {transNum(data.appraisedValue || 0)}원 (
              {data.bidDate?.slice(0, 10)} 기준)
            </div>
          ) : (
            ''
          )}
          {type === 'A' || type === 'P' ? (
            <div
              className='font-bold f-size-14 line-h-20 color-primary-500'
              style={{ color: '#EC4F4F' }}
            >
              최저가 {transNum(data.lowestPrice || 0)}원 (
              {Math.floor(
                ((data.lowestPrice || 0) / (data.appraisedValue || 0)) * 1000
              ) / 10}
              %)
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='flex-row gap-8'>
          {data.keyword &&
            data.keyword.split(',').map((el: any) => {
              if (!el) {
                return '';
              }
              return (
                <div
                  className='padding-4-8 border-radius-6 bg-blue-gray-100'
                  key={el.salesIdx || el.auctionIdx}
                >
                  <div className='font-medium f-size-12 line-h-14 color-text-tertiary'>
                    {el}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className='flex-column gap-32'>
        <div className='flex-row end-x'>
          <div style={{ height: '24px' }}></div>
          {/* <img src="/asset/images/mark/BookMark24Green.svg" alt="" /> */}
        </div>
        <div className='flex-column gap-8'>
          <img
            className='border-radius-8 w-100per'
            style={{ height: '110px', width: '80px' }}
            src={data.img && data.img.split(',')[0]}
            alt=''
            onError={(e: any) =>
              (e.currentTarget.src = '/asset/images/main/dummyhouse.png')
            }
            onClick={
              type === 'S'
                ? (e) => {
                    e.stopPropagation();
                    saleInfo();
                    setModalAddress(data.address);
                  }
                : () => {}
            }
          />
          <div className='flex-row gap-8'>
            <div className='flex-row gap-2'>
              <img src='/asset/images/mark/View12.svg' alt='' />
              <div className='font-medium f-size-11 line-h-12 color-171E2640'>
                {data.views}
              </div>
            </div>
            {/* <div className='flex-row gap-2'>
              <img src='/asset/images/mark/BookMark12.svg' alt='' />
              <div className='font-medium f-size-11 line-h-12 color-171E2640'>
                {data.likeCount}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
