import './Notice.css';
import Banner from '../../component/AIIntroduction/Footer';
import Footer from '../../component/browser/Footer';
import { TopNav2, TopNavNewBrowser } from '../../component/mobile/Topnav';
import { useEffect, useState } from 'react';
import MypageSerivce from '../../service/mypage/mypage';
import { useNavigate, useParams } from 'react-router-dom';

interface NoticeDetailProps {
  title: string;
  writeTime: string;
  content: string;
}

const NoticeDetail = () => {
  const { articleIdx } = useParams<{ articleIdx: string }>();
  const [detail, setDetail] = useState<NoticeDetailProps | undefined>(
    undefined
  );

  const navigate = useNavigate();

  const GetNoticeDetail = async (id: string) => {
    try {
      const result = await MypageSerivce.noticeDetail(id);
      setDetail(result.data);
    } catch (error) {
      alert(error);
    }
  };
  useEffect(() => {
    if (articleIdx) {
      GetNoticeDetail(articleIdx);
    }
  }, [articleIdx]);

  const goBackToList = () => {
    navigate('/notice');
  };

  const removeTags = (html: string) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };
  return (
    <div className='notice-wrap'>
      <TopNav2 text='공지사항' hamburger back />
      <TopNavNewBrowser />
      <div className='notice-inner'>
        <div className='notice-wrap-title'>공지사항</div>
        <div className='notice-content-box'>
          <div className='notice-detail-box'>
            <div className='notice-detail'>
              <div>
                <div className='notice-detail-item'>
                  <div className='notice-title-box'>
                    <p className='notice-tag'>공지</p>
                    <span className='notice-detail-title'>
                      {detail && detail.title}
                    </span>
                  </div>
                  <span className='notice-date no-wrap'>
                    {detail && detail.writeTime.split(' ')[0]}
                  </span>
                </div>
                <div className='notice-detail-content'>
                  {detail && removeTags(detail.content)}
                </div>
              </div>
            </div>
            <div className='to-the-list-btn'>
              <button onClick={goBackToList}>목록으로</button>
            </div>
          </div>
        </div>
      </div>
      <Banner />
      <Footer />
    </div>
  );
};

export default NoticeDetail;
