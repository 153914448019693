import { styled } from "styled-components";
import Logo from "./../../../images/search/logo.svg";
import Seach from "./../../../images/search/search.svg";
import Alarm from "./../../../images/search/topBar_Icon.svg";
import AlarmNew from "./../../../images/search/topBar_Icon_new.svg";
import ArrowDown from "./../../../images/search/arrowDown.svg";
import MarkGroup from "./../../../images/search/markGroup.svg";

export const LogoIcon = styled.img.attrs({
  src: `${Logo}`,
})``;

export const SearchIcon = styled.img.attrs({
  src: `${Seach}`,
})``;

export const AlramIcon = styled.img.attrs({
  src: `${Alarm}`,
})`
`;

export const MarkGroupIcon = styled.img.attrs({
  src: `${MarkGroup}`,
})`
`;

export const AlarmNewIcon = styled.img.attrs({
  src: `${AlarmNew}`,
})``;

export const BackButton = styled.img.attrs({
  src: `${ArrowDown}`,
})`
  padding-left: 9px;
  padding-right: 10px;
`;

export const TopBar = styled.div`
  width: 100%;
  display:flex;
  align-self: flex-start;
  align-items: center;
  gap: 13px;
  padding: 12px 20px 16px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px #00000020;
`

export const SearchInputRow = styled.div`
  flex:1;
  height: 41px;
  place-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 10px 0 14px;
  border-radius: 13px;
  background-color: #f4f4f5;
`
export const SearchInput = styled.input`
  width:100%;
  margin-right: 10px;
  border: none;
  background: none;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
  line-height: 21px;
  font-family: SpoqaHanSansNeoRegular;
  white-space: pre-line;
`

export const SearchWrap = styled.div`
  flex:1;
  height: 41px;
  place-content: flex-start;
  display: flex;
  align-items: center;
`