import { useEffect, useState } from "react";
import { BottomNav } from "../../component/mobile/BottomNav";
import { Topnav } from "../../component/mobile/Topnav";
import "../../asset/styles/SaleInfo.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BoardService from "../../service/board/Board";

type EditProp = {
  mode: "W" | "M";
};

const CommunityEdit = (prop: EditProp) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const params = useParams();

  const WriteApi = async () => {
    const data = {
      board: 40,
      title: title,
      content: content,
    };
    try {
      const result = await BoardService.WriteBoard(data);
      if (result.data.success) {
        navigate("/community", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ModifyApi = async () => {
    const articleIdx = params.id as string;
    const data = {
      board: "40",
      title: title,
      content: content,
    };
    try {
      const result = await BoardService.ModifyBoard(articleIdx, data);
      if (result.success) {
        navigate("/community", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex-column w-100per" style={{ paddingBottom: "75px" }}>
      <Topnav text="커뮤니티" />
      <div className="flex-column padding-0-20 w-100per">
        <div className="flex-column w-100per mt-20 gap-20">
          <div className="font-bold">
            게시글 {prop.mode == "W" ? "쓰기" : "수정"}
          </div>
          <input
            style={{
              border: "1px solid #d4d4d4",
              borderRadius: "10px",
              padding: "20px 20px",
            }}
            placeholder="제목을 입력하세요."
            onBlur={(e) => {
              setTitle(e.target.value);
            }}
          />
          <textarea
            style={{
              border: "1px solid #d4d4d4",
              borderRadius: "10px",
              padding: "20px 20px",
              whiteSpace: "normal",
              height: "300px",
            }}
            className="font-normal"
            placeholder="내용을 입력해주세요."
            onBlur={(e) => {
              setContent(e.target.value);
            }}
          />
          <div
            className="flex-center"
            style={{
              borderRadius: "10px",
              backgroundColor: "#00AF87",
            }}
            onClick={() => {
              if (prop.mode == "W") {
                WriteApi();
              } else {
                ModifyApi();
              }
            }}
          >
            <div
              className="font-bold padding-20-0 pointer"
              style={{ color: "white" }}
            >
              {prop.mode == "W" ? "작성하기" : "수정하기"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityEdit;
