import { isMobile } from '../../../../component/function/function';

export const Team = () => {
  return (
    <div className='flex-column w-100per' style={{ padding: '80px 16px' }}>
      <div
        className='flex-column  w-100per flex-center gap-16'
        style={{ paddingBottom: '48px' }}
      >
        <div
          className={`${
            isMobile(window.innerWidth) ? `m-h2-24-b` : `m-h1-32-b`
          } text-center`}
        >
          국내 유일 토지·농업 ·금융 데이터 융합 전문 분석팀 소개
        </div>
        <div
          className='text-center color-text-tertiary'
          style={{
            fontSize: isMobile(window.innerWidth) ? '16px' : '20px',
            fontWeight: isMobile(window.innerWidth) ? '500' : '400',
            lineHeight: isMobile(window.innerWidth) ? '25px' : '32px',
          }}
        >
          국내 유일 토지·농업·데이터 융합 <br />
          전문 분석팀이 함께합니다.{' '}
        </div>
      </div>
      <div
        className={`${
          isMobile(window.innerWidth) ? `flex-column gap-40` : `flex-row gap-64`
        }  padding-b-40 w-100per center-x`}
      >
        <div
          className={`flex-column gap-16 ${
            isMobile(window.innerWidth) ? `flex-center` : `center-y`
          }`}
        >
          <img
            style={{ width: '164px' }}
            src='/asset/images/promotion/person1.svg'
          />
          <div className='flex-column gap-16 flex-center'>
            <div
              className={`text-center ${
                isMobile(window.innerWidth) ? `m-t2-18-m` : `m-h2-24-m`
              } color-dark-primary`}
            >
              모든 보고서는 직접 분석, <br /> 검토하여 책임 하에 서명하여 <br />
              제공할 것을 약속드립니다.
            </div>
            <div className='flex-column gap-12 flex-center'>
              <div className='flex-row end-y gap-4'>
                <div
                  className={
                    isMobile(window.innerWidth) ? `m-b1-16-m` : `m-t2-18-m`
                  }
                >
                  김기현
                </div>
                <div
                  className={`${
                    isMobile(window.innerWidth) ? `m-c1-12-m` : `m-b2-14-m`
                  } color-dark-secondary`}
                  style={{ paddingBottom: '2px' }}
                >
                  CEO
                </div>
              </div>
              <div className='flex-column gap-8'>
                <div
                  className={`${
                    isMobile(window.innerWidth) ? `m-c1-12-m` : `m-b2-14-m`
                  } color-dark-secondary text-center`}
                >
                  성균관대 경영학/한양대 경영학(Ph D.)
                </div>
                <div
                  className='text-center'
                  style={{
                    fontWeight: '500',
                    fontSize: isMobile(window.innerWidth) ? '12px' : '14px',
                    lineHeight: isMobile(window.innerWidth) ? '16px' : '20px',
                    color: '#000000',
                  }}
                >
                  성균관대 경영학 <br />
                  한양대 경영학 박사(Ph.D) <br />
                  <br />
                  글로벌컨설팅 펌 삼정KPMG <br />
                  전략컨설팅본부 이사 <br />
                  <br />
                  한양대학교 스마트시티 공학과 겸임교수 <br />
                  DAMA(국제데이터매니지먼트협회) 한국 대표
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex-column gap-16 ${
            isMobile(window.innerWidth) ? `flex-center` : `center-y`
          }`}
        >
          <img
            style={{ width: '164px' }}
            src='/asset/images/promotion/person2.svg'
          />
          <div className='flex-column gap-16 flex-center'>
            <div
              className={`text-center ${
                isMobile(window.innerWidth) ? `m-t2-18-m` : `m-h2-24-m`
              } color-dark-primary`}
            >
              작은 토지라도 귀하게, <br /> 시간, 비용은 최소화하고 <br />{' '}
              토지가치는 최대화하도록
            </div>
            <div className='flex-column gap-12 flex-center'>
              <div className='flex-row end-y gap-4'>
                <div
                  className={
                    isMobile(window.innerWidth) ? `m-b1-16-m` : `m-t2-18-m`
                  }
                >
                  이봉석
                </div>
                <div
                  className={`${
                    isMobile(window.innerWidth) ? `m-c1-12-m` : `m-b2-14-m`
                  } color-dark-secondary`}
                  style={{ paddingBottom: '2px' }}
                >
                  COO
                </div>
              </div>
              <div className='flex-column gap-8'>
                <div
                  className={`${
                    isMobile(window.innerWidth) ? `m-c1-12-m` : `m-b2-14-m`
                  } color-dark-secondary text-center`}
                >
                  서울대 토목공학/ 도쿄대 도시공학 박사
                </div>
                <div
                  className='text-center'
                  style={{
                    fontWeight: '500',
                    fontSize: isMobile(window.innerWidth) ? '12px' : '14px',
                    lineHeight: isMobile(window.innerWidth) ? '16px' : '20px',
                    color: '#000000',
                  }}
                >
                  서울대 토목공학 <br />
                  도쿄대 도시공학 박사 <br />
                  <br />
                  글로벌컨설팅 펌 삼정KPMG <br />
                  부동산본부 이사 <br />
                  <br />
                  노무라종합연구소 팀장 <br />
                  삼성물산 건설부문 부장 <br />
                  일본 토지종합연구소 조사부 연구원
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex-column w-100per flex-center gap-8'>
        <div className='m-t2-18-m w-t2-24-b color-text-tertiary text-center'>
          +
        </div>
        <div className='flex-column gap-12 flex-center'>
          <img
            className='w-50'
            src='/asset/images/promotion/consulting_img5.svg'
          />
          <div
            className='text-center color-dark-secondary'
            style={{
              fontWeight: '500',
              fontSize: isMobile(window.innerWidth) ? '16px' : '18px',
              lineHeight: isMobile(window.innerWidth) ? '26px' : '29px',
            }}
          >
            AI·빅데이터 전문 분석팀과 <br />
            재무 컨설팅 전문 리더들이 <br />
            최적의 솔루션 제시를 위해 함께 뜁니다.
          </div>
        </div>
      </div>
    </div>
  );
};
