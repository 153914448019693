import { styled } from "styled-components";

interface font {
  fontSize: string;
  fontWeight: string;
  color: string;
  lineHeight: string;
  fontFamily?: string;
  marginTop?: string;
  textAlign?: string;
  cursor?: string;
}
export const Font = styled.div<font>`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => `${props.fontSize}px`};
  color: ${(props) => props.color};
  line-height: ${(props) => `${props.lineHeight}px`};
  font-family: ${(props) =>
    props.fontWeight === "100"
      ? "SpoqaHanSansNeoThin"
      : props.fontWeight === "300"
      ? "SpoqaHanSansNeoLight"
      : props.fontWeight === "400"
      ? "SpoqaHanSansNeoRegular"
      : props.fontWeight === "500"
      ? "SpoqaHanSansNeoMedium"
      : "SpoQaHanSansNeoBold"};
  margin-top: ${(props) => `${props.marginTop || "0"}px`};
  white-space: normal;
  text-align: ${(props) => props.textAlign || "center"};
  cursor: ${(props) => props.cursor};
`;

export const FontNoWrap = styled.div<font>`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => `${props.fontSize}px`};
  color: ${(props) => props.color};
  line-height: ${(props) => `${props.lineHeight}px`};
  font-family: ${(props) =>
    props.fontWeight === "100"
      ? "SpoqaHanSansNeoThin"
      : props.fontWeight === "300"
      ? "SpoqaHanSansNeoLight"
      : props.fontWeight === "400"
      ? "SpoqaHanSansNeoRegular"
      : props.fontWeight === "500"
      ? "SpoqaHanSansNeoMedium"
      : "SpoQaHanSansNeoBold"};
  margin-top: ${(props) => `${props.marginTop || "0"}px`};
  white-space: nowrap;
  text-align: ${(props) => props.textAlign || "center"};
  cursor: ${(props) => props.cursor};
`;

export const FontUnderLine = styled.div<font>`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => `${props.fontSize}px`};
  color: ${(props) => props.color};
  line-height: ${(props) => `${props.lineHeight}px`};
  font-family: ${(props) =>
    props.fontWeight === "100"
      ? "SpoqaHanSansNeoThin"
      : props.fontWeight === "300"
      ? "SpoqaHanSansNeoLight"
      : props.fontWeight === "400"
      ? "SpoqaHanSansNeoRegular"
      : props.fontWeight === "500"
      ? "SpoqaHanSansNeoMedium"
      : "SpoQaHanSansNeoBold"};
  margin-top: ${(props) => `${props.marginTop || "0"}px`};
  white-space: pre-line;
  text-align: ${(props) => props.textAlign || "center"};
  cursor: ${(props) => props.cursor};
  text-decoration: underline;
`;
