import { useEffect, useState } from "react";
import SignUpService from "../../../service/signUp/SignUp";
import { Inputbox } from "../../../asset/styles/mobile/common/Common";
import Button from "../../../component/mobile/Button";

export const Find1 = ({
  tab,
  setStep,
  phone1,
  phone2,
  setPhone1,
  setPhone2,
  name,
  setName,
  selectedId,
  setSelectedId,
}: any) => {
  const [sendSms, setSendSms] = useState(false);
  const [reSend, setReSend] = useState(false);
  const [btnActive, setBtnActive] = useState(false);
  const [smsCode, setSmsCode] = useState("");
  const [verifyCheck, setVerfiyCheck] = useState(true);
  const [temp, setTemp] = useState(false); // verfiycheck의 default값이 true로 정해놓아서 btnactive를 활용하기 위한 temp
  const SendSmsApi = async () => {
    const phone = `010${phone1}${phone2}`;
    const data = {
      userId: tab === 0 ? name : selectedId,
      phone: phone,
    };
    try {
      const result = await SignUpService.SendSms(data);
      console.log("tw", result);
      alert("인증번호를 발송했습니다.");
      setSendSms(true);
    } catch (error) {
      alert(error);
      console.log(error);
    }
  };
  const CheckSmsApi = async () => {
    const data = {
      userId: tab === 0 ? name : selectedId,
      code: smsCode,
      phone: `010${phone1}${phone2}`,
    };
    try {
      const result = await SignUpService.CheckSMS(data);
      setVerfiyCheck(true);
      setTemp(true);
      console.log("tw", result);
    } catch (error: any) {
      console.log("tw", error);
      if (error.response.data.message === "This authCode does not match") {
        // alert("인증번호를 확인해주세요.");
      }
      setVerfiyCheck(false);
      setTemp(false);
    }
  };
  useEffect(() => {
    if (name && phone1 && phone2) {
      if (
        name.length > 0 &&
        phone1.length === 4 &&
        phone2.length === 4 &&
        temp
      ) {
        setBtnActive(true);
      } else {
        setBtnActive(false);
      }
    }
  });
  // useEffect(() => {
  //   if (smsCode.length === 6) {
  //     CheckSmsApi();
  //   }
  // }, [smsCode]);
  return (
    <div className="padding-24-16-0-16 flex-column w-100per space-between h-100per w-1024-max-w-468">
      <div className="gap-32 flex-column w-100per ">
        <div className="mobile-body-1-bold color-000000">휴대폰 본인 확인</div>
        <div className="flex-column gap-12">
          {tab === 1 && (
            <div className="flex-column gap-2 padding-12-12-12-16 bg-FAFAFA border-radius-13">
              <div className="font-regular f-size-12 line-h-18 color-888B90">
                아이디
              </div>
              <div className="flex-row space-between bg-FAFAFA">
                <input
                  className="border-none w-90per bg-FAFAFA font-medium f-size-17 line-h-23 color-dark-primary"
                  placeholder="아이디 입력"
                  value={selectedId}
                  onChange={(e) => {
                    setSelectedId(e.target.value);
                  }}
                />
                {selectedId && selectedId.length > 0 && (
                  <img
                    className="pointer"
                    src="/asset/images/close/close_with_circle.svg"
                    onClick={() => {
                      setSelectedId("");
                    }}
                  />
                )}
              </div>
            </div>
          )}
          <div className="flex-column gap-2 padding-12-12-12-16 bg-FAFAFA border-radius-13">
            <div className="font-regular f-size-12 line-h-18 color-888B90">
              이름
            </div>
            <div className="flex-row space-between bg-FAFAFA">
              <input
                className="border-none w-90per bg-FAFAFA font-medium f-size-17 line-h-23 color-dark-primary"
                placeholder="이름 입력"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              {name && name.length > 0 && (
                <img
                  className="pointer"
                  src="/asset/images/close/close_with_circle.svg"
                  onClick={() => {
                    setName("");
                  }}
                />
              )}
            </div>
          </div>
          <div className="flex-column gap-2 padding-12-12-12-16 bg-FAFAFA border-radius-13">
            <div className="font-regular f-size-12 line-h-18 color-888B90">
              휴대폰번호
            </div>
            <div className="flex-row space-between bg-FAFAFA">
              <div className="flex-row">
                <div className="font-medium f-size-18 line-h-28 color-dark-primary">
                  010-
                </div>
                <Inputbox
                  value={phone1}
                  width="40"
                  placeholder="****"
                  type="text"
                  pattern="\d*"
                  maxLength={4}
                  onChange={(e) => {
                    setPhone1(e.target.value);
                  }}
                />
                <div className="font-medium f-size-18 line-h-26 color-dark-primary">
                  -
                </div>
                <Inputbox
                  value={phone2}
                  width="40"
                  placeholder="****"
                  type="text"
                  pattern="\d*"
                  maxLength={4}
                  onChange={(e: any) => {
                    setPhone2(e.target.value);
                  }}
                />
              </div>
              <div className="flex-row gap-8">
                {phone1 &&
                  phone2 &&
                  (phone2.length > 0 || phone1.length > 0) && (
                    <img
                      className="pointer"
                      src="/asset/images/close/close_with_circle.svg"
                      onClick={() => {
                        setPhone1("");
                        setPhone2("");
                      }}
                    />
                  )}
                <div
                  className="bg-EDEDEF padding-7-12 border-radius-6 flex-center pointer"
                  onClick={() => {
                    SendSmsApi();
                    setReSend(true);
                  }}
                >
                  <div className="font-medium f-size-13 line-h-16 color-dark-secondary">
                    {reSend ? "재요청" : "인증번호 받기"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {sendSms && (
            <div className="flex-column gap-4">
              <div
                className={`flex-column gap-2 padding-12-12-12-16 bg-FAFAFA border-radius-13 ${
                  !verifyCheck && `border-increase`
                }`}
              >
                <div className="font-regular f-size-12 line-h-18 color-888B90">
                  인증번호
                </div>
                <div className="flex-row space-between bg-FAFAFA">
                  <input
                    className="border-none w-90per bg-FAFAFA font-medium f-size-17 line-h-23 color-dark-primary"
                    placeholder="인증번호 6자리 입력"
                    maxLength={6}
                    value={smsCode}
                    onChange={(e) => {
                      setSmsCode(e.target.value);
                    }}
                    onBlur={() => {
                      CheckSmsApi();
                    }}
                  />
                  <div className="flex-row gap-8">
                    {!verifyCheck && (
                      <img src="/asset/images/warn/warnRed.svg" />
                    )}
                    {smsCode.length > 0 && (
                      <img
                        className="pointer"
                        src="/asset/images/close/close_with_circle.svg"
                        onClick={() => {
                          console.log("aa");
                          setSmsCode("");
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {!verifyCheck && (
                <div className="flex padding-x-16">
                  <div className="font-medium f-size-14 line-h-20 color-text-increase">
                    입력하신 인증번호가 올바르지 않습니다. <br />
                    다시 확인해주세요.
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="padding-16-0-48-0 flex-center border-t-171E26">
        <Button
          menu="idfind"
          on={btnActive}
          description={tab === 0 ? "아이디 찾기" : "비밀번호 재설정"}
          onClick={() => {
            btnActive && setStep(1);
          }}
        />
      </div>{" "}
    </div>
  );
};
