import { Content1, Footer, ReportTitle } from '../component';

export const Surroundings2 = () => {
  const content = [
    '본 물건의 주변의 1km 이내 실거래 물건 추이 분석 결과,',
    '과거 2018년 평당가 xx,xxx원에서 2024.03월 xx,xxx원으로 연평균 x.x% 상승하고 있음이 분석됨',
    '특이사항으로 2023년 평당가 xx,xxx원에서 2024년 05월 현재 xx,xxx원으로 하락 추세를 보이고 있음',
  ];
  return (
    <div className='flex-column w-100per space-between page'>
      <div className='flex-column w-100per'>
        <ReportTitle
          address='전북특별자치도 김제시 순동 445-19'
          category='주변 실거래 가격 추이'
        />
        <div className='flex-column padding-24 gap-12'>
          <Content1 content={content} />
        </div>
      </div>
      <Footer number={8} />
    </div>
  );
};
