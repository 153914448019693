const EmptyMessage = ({ message }: any) => {
  return (
    <div className='empty-message-box'>
      <img src='/asset/images/check/Exclamation-filled.svg' alt='' />
      <div className='empty-message'>{message}</div>
    </div>
  );
};

export default EmptyMessage;
