import { MouseEventHandler, useState } from "react";

export const ButtonSelect1N = ({
  children,
  selected,
  onClick,
}: {
  children: string;
  selected: boolean;
  onClick?: Function;
}) => (
  <div
    className='padding-6-16 border-radius-100 gap-5 hover'
    style={{
      background: selected ? "#1E9B6B" : "#ffffff66",
      border: selected ? "none" : "#f0f0f0 1px solid",
    }}
    onClick={() => {
      if (onClick) onClick();
    }}
  >
    <div
      className={`${
        selected
          ? "buttion_text_selected buttion_text_selected2"
          : "buttion_text buttion_text2"
      } no-wrap`}
    >
      {children}
    </div>
  </div>
);

export const ButtonSelect1M = ({
  children,
  selected,
  width,
  onClick,
  price,
}: {
  children: string;
  selected: boolean;
  width?: boolean | undefined;
  onClick: MouseEventHandler;
  price?: boolean;
}) => (
  <div
    onClick={onClick}
    className={selected ? "button_select_selected" : "button_select"}
    style={{ width: price ? "100px" : width ? "100%" : "auto" }}
  >
    <div className={`${selected ? "buttion_text_selected" : "buttion_text"}`}>
      {children}
    </div>
  </div>
);

export const ButtonSelect3 = ({
  children,
  selected,
  width,
  onClick,
}: {
  children: string;
  selected: boolean;
  width?: boolean | undefined;
  onClick: MouseEventHandler;
}) => (
  <div
    onClick={onClick}
    className='button_select'
    style={{
      border: selected ? "1px solid #1E9B6B" : "1px solid #171e2610",
      background: "#FFFFFF",
      width: width ? "100%" : "auto",
      // filter: selected ? "drop-shadow(2px 4px 0 #1e986b10)" : "none",
    }}
  >
    <div className={`${selected ? "buttion_text_selected" : "buttion_text"}`}>
      {children}
    </div>
  </div>
);

export const ButtonSelect4 = ({
  children,
  selected,
  onClick,
}: {
  children: string;
  selected: boolean;
  onClick: MouseEventHandler;
}) => (
  <div
    onClick={onClick}
    className='padding-8-16 border-radius-8 center-x hover'
    style={{
      border: selected ? "1px solid #1E9B6B" : "none",
      background: selected ? "#FFFFFF" : "#F0F0F0",
      filter: selected ? "drop-shadow(2px 4px 0 #1e986b10)" : "none",
      width: "102px",
    }}
  >
    <div
      className={`${
        selected ? "buttion_text_selected" : "buttion_text"
      } no-wrap`}
    >
      {children}
    </div>
  </div>
);

export const ButtonSelectorBox = ({
  btnArr,
  tab,
  setTab,
  type,
}: {
  btnArr: Array<{ children: string; selected: number } | string>;
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
  type?: number;
}) => {
  const getWidth = (type: number) => {
    switch (type) {
      case 2:
        return "0.32px";
      case 3:
        return "0.62px";
      default:
        return "";
    }
  };

  const getBackground = (type: number) => {
    return type === 2 || type === 3 ? "#757575" : "";
  };

  return (
    <div className='padding-8-16 gap-10'>
      <div className='border-1-f0f0f0 bg-light-gray-200 padding-2 border-radius-8 flex-row'>
        {btnArr.map((el, i) => {
          if (i % 2 === 0 && typeof el !== "string") {
            return (
              <ButtonSelect1M
                onClick={() => {
                  setTab(el.selected);
                }}
                children={el.children}
                selected={el.selected === tab}
                width={true}
                key={i}
              />
            );
          } else {
            let acc = btnArr[i - 1];
            let nxt = btnArr[i + 1];
            const isType =
              typeof acc !== "string" &&
              !(acc.selected === tab) &&
              nxt &&
              typeof nxt !== "string" &&
              !(nxt.selected === tab);

            return (
              <div className='padding-y-8' key={i}>
                <div
                  style={{
                    height: "100%",
                    border: isType
                      ? "0.31px solid #171e2640"
                      : "0.31px solid #00000000",
                    width: getWidth(type || 0),
                    background: getBackground(type || 0),
                  }}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
