import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Font } from '../../asset/styles/browser/font/font';

import {
  InfoBtn,
  InputBtnWrapper,
  PhoneInputWrapper,
  SignUPTextWrapper,
  SignUpContainer,
  VerifyBtn,
} from '../../asset/styles/mobile/signUp/SIgnUp';

import { Inputbox } from '../../asset/styles/mobile/common/Common';
import { useState } from 'react';
import { handleKeyDown } from '../../component/function/function';
import { Category2 } from '../../store/signUpInfoSlice';

type SelectPropsType = {
  text: string;
  placeholder: string;
  option: any;
  dispatch: any;
};

export const SelectBox = (item: SelectPropsType) => {
  const state = useSelector((state: any) => {
    return state.signUpInfo;
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();
  const handleInputChange = (inputValue: any) => {
    // 입력값이 4자리이면 자동으로 해당 값을 선택
    if (inputValue.length === 4) {
      const option = item.option.find(
        (opt: any) => opt.value.toString() === inputValue
      );
      if (option) {
        item.dispatch(option);
      }
    }
  };
  return (
    <div className="flex-column bg-FAFAFA border-radius-13 w-100per padding-12-16">
      <div className="font-regular f-size-12 line-h-18 color-888B90 text-start">
        {item.text}
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: 'none',
              backgroundColor: 'rgba(250, 250, 250, 1)',
              height: '30px',
              fontFamily: 'SpoqaHanSansNeoRegular',
              fontSize: '17px',
              color: '#888b90',
            }),
          }}
          options={item.option}
          //   value={selectedOption}
          placeholder={item.placeholder}
          onChange={(e: any) => {
            dispatch(item.dispatch(e.value));
          }}
          onInputChange={handleInputChange}
        />
      </div>
      {item.text === '소속 혹은 농촌에 대한 관심사 (선택)' &&
        state.category === 'G' && (
          <TextBox
            dispatch={Category2}
            value={state.category2}
            placeholder="하는 일 혹은 관심사를 기재해주세요"
          ></TextBox>
        )}
    </div>
  );
};

type TextPropsType = {
  text?: string;
  placeholder: string;
  onblur?: any;
  dispatch?: any;
  onChange?: any;
  value?: any;
  checkPhone?: any;
  disabled?: boolean;
};

export const TextBox = (item: TextPropsType) => {
  const dispatch = useDispatch();

  return (
    <div className="flex-column bg-FAFAFA border-radius-13 w-100per padding-12-16">
      <div className="font-regular f-size-12 line-h-18 color-888B90 text-start">
        {item.text}
        <div className="flex-row w-100per space-between">
          <Inputbox
            className="w-100per"
            disabled={item.disabled}
            // width={270}
            onBlur={item.onblur}
            value={item.value}
            onKeyDown={handleKeyDown}
            maxLength={
              item.text === '인증번호'
                ? 6
                : item.text === '휴대폰 번호'
                ? 11
                : undefined
            }
            placeholder={item.placeholder}
            onChange={(e: any) => {
              if (item.onChange) {
                item.onChange(e);
              } else {
                dispatch(item.dispatch(e.target.value));
                if (item.checkPhone) {
                  if (e.target.value.length === 6) {
                    item.checkPhone(e.target.value);
                  }
                }
              }
            }}
          />
          {item.value && item.value.length > 0 && !item.disabled && (
            <img
              src="/asset/images/close/close_with_circle.svg"
              className="pointer"
              onClick={() => {
                if (item.dispatch) {
                  dispatch(item.dispatch(''));
                } else {
                  item.onChange({ target: { value: '' } });
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export const TextBox2 = (item: TextPropsType) => {
  const dispatch = useDispatch();

  return (
    <div className="flex-column w-100per space-between mt-8">
      <Inputbox
        disabled={item.disabled}
        height={56}
        onBlur={item.onblur}
        value={item.value}
        onKeyDown={handleKeyDown}
        maxLength={item.text === '인증번호' ? 6 : undefined}
        placeholder={item.placeholder}
        onChange={(e: any) => {
          if (item.onChange) {
            item.onChange(e);
          } else {
            dispatch(item.dispatch(e.target.value));
            if (item.checkPhone) {
              if (e.target.value.length === 6) {
                item.checkPhone(e.target.value);
              }
            }
          }
        }}
      />
      {item.value && item.value.length > 0 && !item.disabled && (
        <img
          src="/asset/images/close/close_with_circle.svg"
          className="pointer"
          onClick={() => {
            dispatch(item.dispatch(''));
          }}
        />
      )}
    </div>
  );
};
export const TextBox3 = (item: TextPropsType) => {
  const dispatch = useDispatch();

  return (
    <div className="flex-column w-100per space-between mt-8">
      <Inputbox
        name=""
        disabled
        height={56}
        onBlur={item.onblur}
        value={item.value}
        onKeyDown={handleKeyDown}
        maxLength={item.text === '인증번호' ? 6 : undefined}
        placeholder={item.placeholder}
        onChange={(e: any) => {
          if (item.onChange) {
            item.onChange(e);
          } else {
            dispatch(item.dispatch(e.target.value));
            if (item.checkPhone) {
              if (e.target.value.length === 6) {
                item.checkPhone(e.target.value);
              }
            }
          }
        }}
      />
    </div>
  );
};

type TelPropsType = {
  PhoneNumber: any;
  PhoneNumber2: any;
  SendSmsApi: any;
  text?: any;
  disabled?: boolean;
};

export const TelBox = (item: TelPropsType) => {
  const dispatch = useDispatch();

  return (
    <div className="flex-column bg-FAFAFA border-radius-13 w-100per padding-12-16">
      <div className="font-regular f-size-12 line-h-18 color-888B90 text-start">
        {item.text}
      </div>
      <div className="font-regular f-size-12 line-h-18 color-888B90 text-start">
        <InputBtnWrapper>
          <PhoneInputWrapper>
            <Font
              fontSize="17"
              fontWeight="400"
              lineHeight="18"
              color="#888B90"
              textAlign="start"
            >
              010-&nbsp;
            </Font>
            <Inputbox
              disabled={item.disabled}
              width="40"
              placeholder="****"
              type="text"
              pattern="\d*"
              maxLength={4}
              style={{
                textAlign: 'center',
              }}
              onChange={(e: any) => {
                dispatch(item.PhoneNumber(e.target.value));
              }}
            />
            <Font
              fontSize="17"
              fontWeight="400"
              lineHeight="18"
              color="#888B90"
              textAlign="start"
            >
              &nbsp;-&nbsp;
            </Font>
            <Inputbox
              disabled={item.disabled}
              width="40"
              placeholder="****"
              type="text"
              pattern="\d*"
              maxLength={4}
              onChange={(e: any) => {
                dispatch(item.PhoneNumber2(e.target.value));
              }}
            />
          </PhoneInputWrapper>
          {item.disabled ? (
            ''
          ) : (
            <VerifyBtn
              onClick={() => {
                item.SendSmsApi();
              }}
            >
              인증번호 받기
            </VerifyBtn>
          )}
        </InputBtnWrapper>
      </div>
    </div>
  );
};
