import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const InfoTitle = ({
  address,
  type,
  keyword,
  auctionType,
}: {
  address: string;
  type: string;
  keyword: string;
  auctionType?: any;
}) => {
  const typeObj = {
    A: { material: '경매물건', color: '#EDF6FF', color2: '#0C64CE' },
    P: { material: '공매물건', color: '#FFE9E9', color2: '#D84836' },
    S: { material: '지자체 물건', color: '#FFFAE9', color2: '#E78B3E' },
  };
  const location = useLocation();

  return (
    <div className='padding-16 flex-column gap-8 border-b-1-171E2610 w-100per'>
      <div className='flex-row space-between'>
        <div className='font-medium f-size-18 line-h-30 color-text-primary'>
          {address}
        </div>
        <img src='/asset/images/mark/BookMark.svg' alt='' />
      </div>
      <div className='flex-row gap-8'>
        <div
          className='padding-4-8 border-radius-6'
          style={{
            background:
              (location.pathname === '/mypage' ? auctionType : type) &&
              typeObj[
                (location.pathname === '/mypage' ? auctionType : type) as 'A'
              ].color,
          }}
        >
          <div
            className='font-medium f-size-12 line-h-14'
            style={{
              color:
                location.pathname === '/mypage'
                  ? auctionType
                  : type
                  ? typeObj[
                      (location.pathname === '/mypage'
                        ? auctionType
                        : type) as 'A'
                    ].color2
                  : '',
            }}
          >
            {(location.pathname === '/mypage' ? auctionType : type) &&
              typeObj[
                (location.pathname === '/mypage' ? auctionType : type) as 'A'
              ].material}
          </div>
        </div>
        {keyword &&
          keyword.split(',').map((el: any) => {
            if (!el) {
              return '';
            }
            return (
              <div className='padding-4-8 border-radius-6 bg-blue-gray-100'>
                <div className='font-medium f-size-12 line-h-14 color-text-tertiary'>
                  {el}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default InfoTitle;
