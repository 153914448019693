import { createGlobalStyle } from "styled-components";
import SpoqaHanSansNeoBold from "../fonts/SpoqaHanSansNeoBold.ttf";
import SpoqaHanSansNeoLight from "../fonts/SpoqaHanSansNeoLight.ttf";
import SpoqaHanSansNeoRegular from "../fonts/SpoqaHanSansNeoRegular.ttf";
import SpoqaHanSansNeoThin from "../fonts/SpoqaHanSansNeoThin.ttf";
import SpoqaHanSansNeoMedium from "../fonts/SpoqaHanSansNeoMedium.ttf";
const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  @font-face {
        font-family: 'SpoqaHanSansNeoThin';
        font-weight: 100;
        font-style: normal;
        src: local('SpoqaHanSansNeoThin'), url(${SpoqaHanSansNeoThin}) format('truetype')
        
  }
  @font-face {
        font-family: 'SpoqaHanSansNeoLight';
        font-weight: 300;
        font-style: normal;
        src: local('SpoqaHanSansNeoLight'), url(${SpoqaHanSansNeoLight}) format('truetype')
        
  }
  @font-face {
        font-family: 'SpoqaHanSansNeoRegular';
        font-weight: 400;
        font-style: normal;
        src: local('SpoqaHanSansNeoRegular'), url(${SpoqaHanSansNeoRegular}) format('truetype')
        
  }
  @font-face {
        font-family: 'SpoqaHanSansNeoMedium';
        font-weight: 500;
        font-style: normal;
        src: local('SpoqaHanSansNeoMedium'), url(${SpoqaHanSansNeoMedium}) format('truetype')
        
  }
  @font-face {
        font-family: 'SpoQaHanSansNeoBold';
        font-weight: 700;
        font-style: normal;
        src: local('SpoqaHanSansNeoBold'), url(${SpoqaHanSansNeoBold}) format('truetype')
  }
  
`;
export default GlobalStyle;
