import { useEffect, useState } from 'react';
import useDidMountEffect from '../../../hooks/UseDidMountEffect';
import { Content1, Footer, ReportTitle } from '../component';
import {
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  LabelList,
  Legend,
} from 'recharts';
import { transNum } from '../../../component/function/function';
interface PropsType {
  data: any;
  address: string;
}
export const LandRank = ({ data, address }: PropsType) => {
  // const [content, setContent] = useState(['']);
  const [priceType, setPriceType] = useState('L');
  const [area1, area2, area3] = address.split(' ');
  const CustomYAxisTick = ({ x, y, payload }: any) => (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={5} textAnchor='end' fill='#666'>
        {payload.value.toLocaleString()}
      </text>
    </g>
  );
  const barData = [
    {
      name: area3,
      uv: data.last.emd?.last1year?.pricePerPyeong,
      vl:
        data.last.emd?.last1year?.pricePerPyeong !== null
          ? `${transNum(
              data.last?.emd?.last1year?.pricePerPyeong,
              true,
              true
            )}원`
          : '실거래 없음',
    },
    {
      name: area2,
      uv: data.last.sig?.last1year?.pricePerPyeong,

      vl:
        data.last?.sig?.last1year?.pricePerPyeong !== null
          ? `${transNum(
              data.last?.sig?.last1year?.pricePerPyeong,
              true,
              true
            )}원`
          : '실거래 없음',
    },
    {
      name: area1,
      uv: data.last?.ctp?.last1year?.pricePerPyeong,
      vl:
        data.last?.ctp?.last1year?.pricePerPyeong !== null
          ? `${transNum(
              data.last?.ctp?.last1year?.pricePerPyeong,
              true,
              true
            )}원`
          : '실거래 없음',
    },
  ];
  useEffect(() => {
    console.log('barchart', barData);
  }, [barData]);
  const formatDataForLineChart = (list: any) => {
    return (list ? list : []).map((item: any) => {
      return {
        month: !item.month ? '' : item.month,
        price: item.price === 'No data' ? 0 : item.price,
        pricePerPyeong: !item.pricePerPyeong ? 0 : item.pricePerPyeong,
        count: !item.count ? 0 : item.count,
        prepare1month: !item.prepare1month ? 0 : item.prepare1month,
        prepare1monthPerPyeong: !item.prepare1monthPerPyeong
          ? 0
          : item.prepare1monthPerPyeong,
        prepare1monthRate: !item.prepare1monthRate ? 0 : item.prepare1monthRate,
        prepare1year: !item.prepare1year ? 0 : item.prepare1year,
        prepare1yearPerPyeong: !item.prepare1yearPerPyeong
          ? 0
          : item.prepare1yearPerPyeong,
        prepare1yearRate: !item.prepare1yearRate ? 0 : item.prepare1yearRate,
        prepareMax: !item.prepareMax ? 0 : item.prepareMax,
        prepareMaxPerPyeong: !item.prepareMaxPerPyeong
          ? 0
          : item.prepareMaxPerPyeong,
        prepareMaxRate: !item.prepareMaxRate ? 0 : item.prepareMaxRate,
        up: !item.up ? 0 : item.up,
        upRate: !item.upRate ? 0 : item.upRate,
        down: !item.down ? 0 : item.down,
        downRate: !item.downRate ? 0 : item.downRate,
        etc: !item.etc ? 0 : item.etc,
        etcRate: !item.etcRate ? 0 : item.etcRate,
      };
    });
  };
  const { emdList, sigList, ctpList }: any = data.list || {};
  const formattedEmdList = formatDataForLineChart(emdList);
  const formattedSigList = formatDataForLineChart(sigList);
  const formattedCtpList = formatDataForLineChart(ctpList);

  const lineChartDataArray = {
    emd: formattedEmdList,
    sig: formattedSigList,
    ctp: formattedCtpList,
  };
  const combinedData: any = [];
  useEffect(() => {
    console.log('combinedData', combinedData);
  }, [combinedData]);
  const months = Object.keys(lineChartDataArray.ctp); // Assuming all arrays have same length and order
  months.forEach((month) => {
    combinedData.push({
      month: lineChartDataArray.ctp[month].month,
      ctpPrice: lineChartDataArray.ctp[month].pricePerPyeong,
      emdPrice: lineChartDataArray.emd[month].pricePerPyeong,
      sigPrice: lineChartDataArray.sig[month].pricePerPyeong,
    });
  });

  const content = [
    `본 물건의 토지 등급은 ${area3} 내 ?등급, ${area2} ?등급,  ${area1} ?등급으로 분석됨,`,
    `최근 1년 거래된 실거래 매물 ?건의 평당 가격을 분석한 결과,`,
    `${area3} ?만원, ${area2} ?만원, ${area1} ?만원으로 산출됨`,
  ];
  useDidMountEffect(() => {
    if (data) {
      // setContent([
      //   `본 물건의 토지 등급은 ${area3} 내 ?등급, ${area2} ?등급,  ${area1} ?등급으로 분석됨,`,
      //   `최근 1년 거래된 실거래 매물 ?건의 평당 가격을 분석한 결과,`,
      //   `${area3} ?만원, ${area2} ?만원, ${area1} ?만원으로 산출됨`,
      // ]);
    }
  }, [data]);
  return (
    <div className='flex-column w-100per space-between page'>
      <div className='flex-column  w-100per'>
        <ReportTitle address={address} category='토지 등급' />
        <div className='flex-column padding-24 gap-12'>
          <Content1 content={content} />
          <div className='flex-row gap-24'>
            <div className='flex-column gap-16' style={{ width: '38%' }}>
              <div className='m-t1-20-b'>트랜스파머의 토지 등급 분석</div>
              <div className='flex-row w-100per gap-24'>
                <div className='flex-column gap-12 w-100per'>
                  <div className='flex-column '>
                    <div className='m-b1-16-b'>{area3}</div>
                  </div>
                  <div className='flex-column w-100per'>
                    <div className='flex-row w-100per border-171E26 '>
                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'></div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'>상위 20%</div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'>상위 40%</div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'>중위</div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'>하위 40%</div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                      >
                        <div className='m-b2-14-m no-wrap'>하위 20%</div>
                      </div>
                    </div>
                    <div className='flex-row w-100per border-b-171E26 border-l-171E26 border-r-171E2610'>
                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'>2022년</div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'></div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'></div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'></div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'></div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                      >
                        <div className='m-b2-14-m no-wrap'></div>
                      </div>
                    </div>
                    <div className='flex-row w-100per border-b-171E26 border-l-171E26 border-r-171E2610'>
                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'>2023년</div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'></div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'></div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'></div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                        className='border-r-171E2610'
                      >
                        <div className='m-b2-14-m no-wrap'></div>
                      </div>

                      <div
                        style={{
                          width: '16.6%',
                          justifyContent: 'center',
                          display: 'flex',
                          padding: '8px',
                        }}
                      >
                        <div className='m-b2-14-m no-wrap'></div>
                      </div>
                    </div>
                  </div>
                  <div className='flex-column gap-24'>
                    <div className='flex-column gap-12'>
                      <div className='m-b1-16-b'>{area2}</div>
                    </div>
                    <div className='flex-column w-100per'>
                      <div className='flex-row w-100per border-171E26 '>
                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'>상위 20%</div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'>상위 40%</div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'>중위</div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'>하위 40%</div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                        >
                          <div className='m-b2-14-m no-wrap'>하위 20%</div>
                        </div>
                      </div>
                      <div className='flex-row w-100per border-b-171E26 border-l-171E26 border-r-171E2610'>
                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'>2022년</div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>
                      </div>
                      <div className='flex-row w-100per border-b-171E26 border-l-171E26 border-r-171E2610'>
                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'>2023년</div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>
                      </div>
                    </div>
                  </div>{' '}
                  <div className='flex-column gap-24'>
                    <div className='flex-column gap-12'>
                      <div className='m-b1-16-b'>{area1}</div>
                    </div>
                    <div className='flex-column w-100per'>
                      <div className='flex-row w-100per border-171E26 '>
                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'>상위 20%</div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'>상위 40%</div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'>중위</div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'>하위 40%</div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                        >
                          <div className='m-b2-14-m no-wrap'>하위 20%</div>
                        </div>
                      </div>
                      <div className='flex-row w-100per border-b-171E26 border-l-171E26 border-r-171E2610'>
                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'>2022년</div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>
                      </div>
                      <div className='flex-row w-100per border-b-171E26 border-l-171E26 border-r-171E2610'>
                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'>2023년</div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                          className='border-r-171E2610'
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>

                        <div
                          style={{
                            width: '16.6%',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '8px',
                          }}
                        >
                          <div className='m-b2-14-m no-wrap'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex-column w-60per center-x'>
              <div className='flex-row w-100per space-between'>
                <div className='m-t1-20-b'>지역별 토지단가(평)</div>
                <div className='m-b2-14-m'>
                  최근 1년 평균 <br /> 실거래가(평)
                </div>
              </div>
              <div className='w-100per flex-center'>
                <div className='frame-2609304 '>
                  <ResponsiveContainer width='100%' height='100%'>
                    <BarChart
                      margin={{ top: 40 }}
                      width={700}
                      height={3000}
                      data={barData}
                    >
                      <XAxis
                        dataKey='name'
                        tickLine={false}
                        style={{ fontSize: '12px' }}
                      />
                      <Bar dataKey='uv' fill='#09CE7D' radius={[10, 10, 0, 0]}>
                        <LabelList
                          dataKey='vl'
                          offset={12}
                          position='top'
                          fill='#000'
                          fontSize='13'
                        />
                        {barData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            cursor='pointer'
                            fill={
                              entry.name === '농지 추정가' ||
                              entry.name === '단독주택 추정가'
                                ? '#219C6D'
                                : '#09CE7D'
                            }
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className='flex-row'>
                <div className='flex-column gap-8 flex-center'>
                  <div className='m-b2-14-m'>{area1}</div>
                  <div className='flex-column gap-16'>
                    <LineChart
                      className='flex-medium f-size-11 center-x '
                      data={data.list.ctpList}
                      height={181}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      width={215}
                    >
                      {/* XAxis 컴포넌트는 한 번만 렌더링됩니다. */}
                      <XAxis dataKey='month' />

                      {/* 각 데이터를 Line 컴포넌트에 전달합니다. */}
                      <Line
                        type='monotone'
                        dataKey='price'
                        stroke='#8884d8'
                        // name={address.split(' ')[0]}
                      />

                      <Legend />
                      {/* YAxis 및 Tooltip 컴포넌트 추가 */}
                      <YAxis tick={<CustomYAxisTick />} />
                    </LineChart>
                  </div>
                </div>
                <div className='flex-column gap-8 flex-center'>
                  <div className='m-b2-14-m'>{area2}</div>
                  <div className='flex-column gap-16'>
                    <LineChart
                      className='flex-medium f-size-11 center-x '
                      data={data.list.sigList}
                      height={181}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      width={215}
                    >
                      {/* XAxis 컴포넌트는 한 번만 렌더링됩니다. */}
                      <XAxis dataKey='month' />

                      {/* 각 데이터를 Line 컴포넌트에 전달합니다. */}
                      <Line
                        type='monotone'
                        dataKey='price'
                        stroke='#8884d8'
                        // name={address.split(' ')[0]}
                      />

                      <Legend />
                      {/* YAxis 및 Tooltip 컴포넌트 추가 */}
                      <YAxis tick={<CustomYAxisTick />} />
                    </LineChart>
                  </div>
                </div>
                <div className='flex-column gap-8 flex-center'>
                  <div className='m-b2-14-m'>{area3}</div>
                  <div className='flex-column gap-16'>
                    <LineChart
                      className='flex-medium f-size-11 center-x '
                      data={data.list.emdList}
                      height={181}
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      width={215}
                    >
                      {/* XAxis 컴포넌트는 한 번만 렌더링됩니다. */}
                      <XAxis dataKey='month' />

                      {/* 각 데이터를 Line 컴포넌트에 전달합니다. */}
                      <Line
                        type='monotone'
                        dataKey='price'
                        stroke='#8884d8'
                        // name={address.split(' ')[0]}
                      />

                      <Legend />
                      {/* YAxis 및 Tooltip 컴포넌트 추가 */}
                      <YAxis tick={<CustomYAxisTick />} />
                    </LineChart>
                  </div>
                </div>
              </div>
              <div className='flex-column gap-16'></div>
            </div>
          </div>
        </div>
      </div>
      <Footer number={4} />
    </div>
  );
};
