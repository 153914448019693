import { combineReducers, configureStore } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import userSlice from './userSlice';
import signUpInfoSlice from './signUpInfoSlice';
import codeSlice from './codeSlice';
import checkSlice from './checkSlice';
import mypageSlice from './mypageSlice';
import boardSlice from './boardSlice';

import scrollSlice from './scrollSlice';
import topnavSlice from './topnavSlice';
import typeSlice from './typeSlice';
import landSlice from './landSlice';
import apiDataSlice from './apiDataSlice';
import MoneySlice from './moneySlice';
import inquirySlice from './inquirySlice';
import onOffSlice from './onOffSlice';

import loadingSlice from './loadingSlice';
import user2Slice from './user2Slice';

const reducers = combineReducers({
  user: userSlice,
  signUpInfo: signUpInfoSlice,
  code: codeSlice,
  check: checkSlice,
  mypage: mypageSlice,
  board: boardSlice,
  topnav: topnavSlice,
  scroll: scrollSlice,
  type: typeSlice,
  land: landSlice,
  api: apiDataSlice,
  money: MoneySlice,
  inquiry: inquirySlice,
  onOff: onOffSlice,
  loading: loadingSlice,
  user2: user2Slice,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'topnav',
    'land',
    'money',
    'inquiry',
    'type',
    'loading',
    'onOff',
    'user',
    'user2',
  ],
  blacklist: [
    'mypage',
    'board',
    'signUpInfo',
    'check',
    'scroll',
    'code',
    'api',
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
});

export default store;
