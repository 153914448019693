import {
  RowSectionTitle,
  RowUnderLine,
} from '../../../../../component/report/Row';
import { TextSectionTitle } from '../../../../../component/report/Text';
import { transNum } from '../../../../../component/function/function';
import { BoxCard } from '../../../../../component/report/Box';
interface data {
  createdAt: string;
  quantityRate: number;
  realIncome: number;
  realIncomeRate: number;
  yearOperationCost: number;
  yearSales: number;
}
const Summary1 = ({ data, data2 }: { data: data; data2: any }) => {
  return (
    <div>
      <RowSectionTitle>
        <TextSectionTitle>재무성과</TextSectionTitle>
      </RowSectionTitle>
      <div className='padding-0-16-24 gap-8'>
        <RowUnderLine>
          <BoxCard title='매출' value={transNum(data.yearSales) + '원'} />
          <BoxCard
            title='월 평균'
            value={transNum(data.yearSales / 12) + '원'}
          />
        </RowUnderLine>
        <RowUnderLine>
          <BoxCard
            title='비용'
            value={transNum(data.yearOperationCost) + '원'}
          />
          <BoxCard
            title='월 평균'
            value={transNum(data.yearOperationCost / 12) + '원'}
          />
        </RowUnderLine>
        <RowUnderLine>
          <BoxCard
            title='영업이익(영업이익률)'
            value={transNum(data2.profit) + '원(' + data2.profitRate + '%)'}
          />
          <BoxCard title='월 평균' value={transNum(data2.profit / 12) + '원'} />
        </RowUnderLine>
      </div>
    </div>
  );
};

export default Summary1;
