import { styled } from "styled-components";
interface BtnType {
  on: boolean;
}
export const SignUpContainer = styled.div`
  display: flex;
  margin-top: 10px;
  width: 90%;
  flex-direction: column;
  justify-content: center;
`;

export const SignUPTextWrapper = styled.div`
  margin-top: 28px;
  align-items: center;
  justify-content: start;
  display: flex;
  padding-left: 10px;
`;

export const SignUpInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 32px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  justify-content: center;
`;

export const InputBtnWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const VerifyBtn = styled.div`
  display: flex;
  background-color: #ededef;
  width: 98px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 6px;
  font-family: "SpoqaHanSansNeoMedium";
  font-size: 13px;
  line-height: 16px;
  color: black;
  cursor: pointer;
`;

export const PhoneInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InfoBtn = styled.button<BtnType>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.on ? "rgba(0, 175, 135, 1)" : "#00af87"};
  width: 100%;
  height: 56px;
  color: white;
  font-family: "SpoqaHanSansNeoMedium";
  font-size: 16px;
  line-height: 22px;
  border: none;
  opacity: ${(props) => (props.on ? "100%" : "40%")};
  border-radius: 13px;
  margin-top: 46px;
  margin-bottom: 72px;
  cursor: pointer;
`;
