import React, { useState, useEffect } from "react";
import "../../Main.css";

const ScrollToTopButton = ({ bottom, mbottom }: any) => {
  const [isVisible, setIsVisible] = useState(false);

  // 페이지가 스크롤될 때 isVisible 상태 업데이트
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // 스크롤 이벤트 리스너 추가
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // 페이지 상단으로 스크롤하는 함수
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const bottomStyle =
    window.innerWidth >= 1024 ? { bottom: bottom } : { bottom: mbottom };

  return (
    <div
      className={`to-top-btn ${isVisible ? "show" : "hide"}`}
      onClick={scrollToTop}
      style={bottomStyle}
    >
      <img src='/asset/images/arrow/toTop.png' alt='' />
    </div>
  );
};

export default ScrollToTopButton;
