import { useEffect, useLayoutEffect, useState } from 'react';
import { TopNav2 } from '../../../component/mobile/Topnav';
import { useLocation } from 'react-router';
import ReportService from '../../../service/report/reportApi';
import LandValueReport from '../../findLand/component/Report';
import SearchReportMain from '../../reportResultSearch/Main';
import HouseReportMain from '../../reportResultHouse/Main';
import PensionReportMain from '../../reportResultPension/Main';
import AuctionReportMain from '../../reportResultSale/Main';
import FarmingReportMain from '../../aiFarming/report/Main';
import PriceReportMain from '../../reportResultPrice/Main';

const MypageReport = () => {
  const location = useLocation();

  return (
    <div className='w-100per flex-column'>
      {/* <TopNav2 text={location.state.category} report close /> */}
      {location.state.category === '농지가치 분석보고서' ? (
        <SearchReportMain mypage type2='mypage' />
      ) : location.state.category === '내 집 건축보고서' ? (
        <HouseReportMain mypage />
      ) : location.state.category === '매물 분석' ? (
        <AuctionReportMain mypage type={location.state.auctionType} />
      ) : location.state.category === '내농장 진단보고서' ? (
        <FarmingReportMain reportIdx={location.state.reportIdx} mypage />
      ) : location.state.category === '가격 분석' ? (
        <PriceReportMain
          mypage
          reportIdx={location.state.reportIdx}
          address2={location.state.address}
          des={location.state.des}
        />
      ) : (
        <PensionReportMain mypage />
      )}
    </div>
  );
};

export default MypageReport;
