import { set } from 'lodash';

export const PointCancelPopup2 = ({ setOnPopUp2 }: any) => {
  return (
    <div
      className='fixed z-999 center-x center-y padding-24'
      style={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#00000075',
      }}
    >
      <div
        className='bg-FFFFFF border-radius-16 flex-column w-312 flex-center gap-12'
        style={{ padding: '24px 16px' }}
      >
        <div className='flex-center'>
          <img
            style={{ width: '56px', height: '56px' }}
            src='/asset/images/check/Check24GreenCircle3.svg'
          />
        </div>
        <div className='flex-column gap-24'>
          <div className='flex-column gap-8'>
            <div className='m-b1-16-b color-dark-primary text-center'>
              포인트 충전 취소 • 환불이 요청되었습니다.
            </div>
            <div className='m-b2-14-m color-disabled text-center'>
              환불은 결제사에 따라 <br />
              최대 14일 소요될 수 있습니다.
            </div>
          </div>
          <div
            style={{ width: '280px' }}
            className='flex-center bg-primary-500 border-radius-8 padding-12 pointer'
            onClick={() => {
              setOnPopUp2(false);
            }}
          >
            <div className='m-b2-14-m color-FFFFFF'>확인</div>
          </div>
        </div>
      </div>
    </div>
  );
};
