import { styled } from "styled-components";
import close from "../../../images/close/close.svg";
import location from "../../../images/search/location.svg";
import arrowDownGreen from "../../../images/search/arrowDownGreen.svg";
import refresh from "../../../images/search/refresh.svg";
import save from "../../../images/search/save.svg";
import image from "../../../images/search/image.png";
import filter from "../../../images/search/filter.svg";
import saveGrey from "../../../images/search/saveGrey.svg";
import warning from "../../../images/search/warningGreen.svg";
import change from "../../../images/search/change.svg";
import plus from "../../../images/search/plus.svg";
import rise from "../../../images/search/rise.svg";
import set from "../../../images/search/set.svg";
export const AIFarmingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: center; */
  justify-content: center;
`;
interface font {
  fontSize: string;
  fontWeight: string;
  color: string;
  lineHeight: string;
  fontFamily?: string;
  marginTop?: string;
  textAlign?: string;
  cursor?: string;
}

export const AIFarmingMapContainer = styled.div`
  width: 100%;
  display: flex;
  height: ${`${window.innerHeight - 110}px`};
`;

export const FullContainer = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  flex-shrink: 0;
  text-decoration: none;
  min-width: 0;
  overflow-wrap: anywhere;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  background-color: #fff;
  width: 100%;
`;

export const AIFarmingMapSideBarCon = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 69px;
  z-index: 10;
`;
export const SideBarCon = styled.div`
  width: 100%;
  border-top: 1px solid #f0f0f0;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e2e2e2;
  padding-inline: 20px;
`;

export const SideBarRecentSearchContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const SideBarRecentSearchContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const SideBarRecentSearchContentClose = styled.img.attrs({
  src: `${close}`,
})`
  width: 15px;
  height: 15px;
`;

export const SideBarRecentSearchFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  gap: 20px;
`;

export const SideBarRecentSearchTitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SideBarSelectFrame = styled.div`
  width: 100%;
  height: 82px;
  padding-block: 22px;
`;

export const SideBarSelectItem = styled.div`
  padding-inline: 14px;
  padding-block: 12px;
  border: 1px solid #e2e2e2;
  border-radius: 38px;
  position: relative;
`;

export const SideBarSelectRow = styled.div`
  display: inline-flex;
  overflow: hidden;
  gap: 8px;
  width: 100%;
  overflow-x: scroll;
`;
export const SideBarSelectRow2 = styled.div`
  display: inline-flex;
  gap: 8px;
  width: 100%;
`;

export const AreaSearchCon = styled.div`
  width: 100%;
  height: ${window.innerHeight}px;
  border-top: 1px solid #f0f0f0;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e2e2e2;
  padding-inline: 20px;
`;

export const AreaSearchBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 28px;
  gap: 8px;
`;

export const TipBox = styled.div`
  padding: 5px 12px;
  background-color: #e3f6eb;
  border-radius: 37px;
  width: fit-content;
`;

export const AreaSearchContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;
export const AreaSearchContent = styled.div`
  width: 100%;
  padding: 12px 0;
  display: flex;
  gap: 12px;
`;
export const AreaSearchContentLocation = styled.img.attrs({
  src: `${location}`,
})``;

export const SaleRentalSelectorCon = styled.div`
  width: 100%;
  padding: 8px 20px 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #ffffff;
  gap: 8px;
  box-shadow: 0 2px 10px #00000020;
`;

export const SaleRentalSelectorButton = styled.div<{ color: string }>`
  border-radius: 39px;
  border: 1.5px solid ${(props) => props.color};
  padding: 12px 34px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const BottomPreview = styled.div`
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding: 8px 20px 79px;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
`;

export const BottomPreviewAmountRow = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
`;

export const BreakLine = styled.div`
  border: solid 1px #f0f0f0;
`;

export const BottomPreviewArrowDown = styled.img.attrs({
  src: `${arrowDownGreen}`,
})``;

export const DetailSelectBox = styled.div`
  width: 100%;
  flex-direction: column;
  background-color: #ffffff;
`;

export const DetailSelectRow = styled.div`
  width: 100%;
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DetailCon = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 132px;
  z-index: 10;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
`;

export const DetailLocalTab = styled.div`
  overflow-x: auto;
  gap: 8px;
  display:flex;
`;

export const DetailLocalTabItem = styled.div<{ select?: boolean }>`
  width: 54px;
  place-content: center;
  padding: 12px 14px;
  border-radius: 38px;
  background-color: ${(props) => (props.select ? "#e3f6eb" : "#ffffff")};
  outline: ${(props) => (props.select ? "0" : "1px")} solid #e2e2e2;
  outline-offset: ${(props) => (props.select ? "0" : "-1px")};
`;

export const LineBreaker2 = styled.div`
  border: #f0f0f0 1px solid;
  width: 100%;
`;

export const GreyLine = styled.div`
  height: 8px;
  width: 100%;
  background-color: #f2f2f2;
`;

export const DetailForSaleListCon = styled.div`
  flex-direction: column;
  gap: 16px;
  padding: 24px 20px 250px;
  width: 100%;
  overflow-y: auto;
`;

export const DetailForSaleListTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DetailForSaleListTopRefresh = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  padding: 5px 8px;
  gap: 4px;
  border: 1px #e2e2e2 solid;
`;

export const DetailForSaleRefreshIcon = styled.img.attrs({
  src: `${refresh}`,
})``;

export const DetailForSaleListItemCon = styled.div`
  padding: 22px 0;
  border-top: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
`;

export const DetailForSaleItemTextCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DetailForSaleItemTextDescriptionCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const DetailForSaleItemTextCategoryCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const DetailForSaleItemTextCategoryRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
`;

export const DetailForSaleImageCon = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
`;

export const DetailForSaleSaveIcon = styled.img.attrs({ src: `${save}` })`
  position: absolute;
  top: 7px;
  right: 4px;
`;
export const DetailForSaleImage = styled.img.attrs({ src: `${image}` })`
  width: 100px;
  height: 100px;
`;

export const SaleRentalSelectorCon2 = styled.div`
  width: 100%;
  padding: 12px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;
  gap: 8px;
  box-shadow: 0 2px 10px #00000020;
`;

export const SaleRentalSelectorButtonCon = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

export const SaleRentalSelectorFilterCon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;

export const SaleRentalFilter = styled.img.attrs({ src: `${filter}` })``;
export const BottomPreviewLocationRow = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;
`;

export const BottomPreviewSaveGrey = styled.img.attrs({ src: `${saveGrey}` })``;

export const SaleRentalTagRow = styled.div<{ color?: boolean }>`
  display: flex;
  margin-top: -6px;
  gap: 8px;
`;

export const SaleRentalTagButton = styled.div<{ color?: boolean }>`
  border-radius: 34px;
  border: solid ${(props) => (props.color ? "0" : "1px #e2e2e2")};
  background-color: ${(props) => (props.color ? "#E3F6EB" : "#FFFFFF")};
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
`;

export const BottomDetail = styled.div`
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding: 20px 24px 79px;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
`;

export const BottomDetailTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const BottomDetailTableRow = styled.div`
  width: 100%;
  display: flex;
`;

export const BottomDetailTableItemCon = styled.div`
  flex: 0.6;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const BottomDetailTableItemRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const BottomDetailTableItemFontRow = styled.div`
  gap: 5px;
`;
export const BottomDetailTableItemUnit = styled.div`
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  gap: 3px;
  border: 1px solid #e2e2e2;
`;
export const BottomDetailTableItemTag = styled.div`
  border-radius: 8px;
  padding: 6px;
  background-color: #f1f4f7;
  width: fit-content;
`;

export const BottomDetailTableItem2Row = styled.div`
  display: flex;
  flex: 0.6;
  gap: 8px;
`;

export const BottomDetailWarning = styled.div`
  margin: 24px 0;
  padding: 12px 14px;
  background-color: #e3f6eb;
  border-radius: 10px;
  gap: 8px;
`;

export const BottomDetailWarningMark = styled.img.attrs({
  src: `${warning}`,
})``;

export const BottomDetailChangeMark = styled.img.attrs({ src: `${change}` })``;

export const BottomDetailPlusMark = styled.img.attrs({ src: `${plus}` })``;

export const JointDetailCon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const JointDetailBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const JointDetailTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const JointDetailTitle = styled.div`
  display: flex;
  gap: 12px;
`;

export const JointDetailTitleNumber = styled.div`
  padding: 4px 6px;
  border-radius: 22px;
  width: 22px;
  height: 22px;
  background-color: #e3f6eb;
`;

export const JointDetailTable = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 34px;
  gap: 12px;
`;

export const JointDetailRow = styled.div`
  display: flex;
  width: 100%;
`;

export const JointDetailSubRow = styled.div`
  display: flex;
  flex: 0.6;
  gap: 8px;
`;

export const JointDetailWrap = styled.div`
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding: 20px 24px 79px;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  max-height: ${window.innerHeight - 32}px;
  overflow: scroll;
`;

export const AnalyzeCon = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 69px;
  z-index: 10;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
`;

export const AnalyzeMenuSelector = styled.div`
  width: 100%;
  align-items: center;
  z-index: 10;
  padding: 4px 12px;
  position: relative;
  border-bottom: 1px solid #f0f0f095;
  overflow: visible;
  overflow-x: auto;
  display:flex ;
  flex-direction: row;
`;

export const AnalyzeMenuButton = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 4px 12px;
  justify-content: center;
`;

export const AnalyzeMenuSlider = styled.div<{ tabWidth: number; left: number }>`
  position: absolute;
  bottom: 0;
  width: ${(props) => props.tabWidth || 0}px;
  left: ${(props) => props.left || 0}px;
  height: 2px;
  border-radius: 4px;
  background: #009673;
  transition: left 0.3s ease;
`;

export const LandAnalyzeCon = styled.div`
  padding: 24px 20px 28px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 22px;
`;

export const LandAnalyzeTableCon = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const LandAnalyzeTable = styled.div`
  padding-left: 15px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const LandAnalyzeTable2 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const LandAnalyzeTableItem = styled.div`
  display: flex;
  width: 100%;
  padding: 9px 0;
`;

export const SoilAnalyzeTableItem = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 0;
`;

export const SoilAnalyzeStarCon = styled.div`
  padding: 14px 20px 24px;
  background-color: #18181b10;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 17px;
`;

export const RatigsRow = styled.div`
  flex: 0.5;
`;

export const ChartCon = styled.div`
  width: 100%;
  display: flex;
`;

export const OfficialPriceAnalyzeCon = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

export const AnalyzeScrollCon = styled.div`
  padding: 0 0 180px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  flex: 1;
`;

export const AnalyzeScrollItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const WeatherAnalyzeStarCon = styled.div`
  padding: 14px 18px 14px;
  background-color: #18181b10;
  width: 100%;
  display: flex;
  flex: 1;
  border-radius: 10px;
`;
export const WeatherAnalyzeStarBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const WeatherAnalyzeStarRow = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`;
export const WeatherAnalyzeStarItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  flex: 1;
`;
export const WeatherAnalyzeStarItem2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  flex: 1;
`;

export const WeatherSunRiseIcon = styled.img.attrs({ src: `${rise}` })``;

export const WeatherSunSetIcon = styled.img.attrs({ src: `${set}` })``;

export const TodayWeatherCon = styled.div`
  padding: 12px 20px 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 18px;
`;

export const TodayWeatherRow = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  gap: 15px;
  justify-content: center;
`;

export const TodayWeatherItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  flex: 1;
`;

export const WeatherAnalyzeCon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CropAnalyzeCon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  padding: 12px 20px;
`;

export const CropAnalyzeFacilityCon = styled.div`
  padding: 14px 18px 14px;
  background-color: #18181b10;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex: 1;
  border-radius: 10px;
`;

export const CropAnalyzeFacilityRow = styled.div`
  padding: 0 18px 0;
  width: 100%;
  display: flex;
`;

export const CropAnalyzeFacilityImgRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const CropAnalyzeFacilityImgItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const CropAnalyzeFacilityImg = styled.div`
  width: 90px;
  height: 60px;
  background-color: #00000050;
`;

export const CropAnalyzeMecha = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
`;

export const CropAnalyzeMechaButton = styled.div<{ click?: boolean }>`
  flex: 1;
  margin: 10px 0;
  padding: 17px 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  border-radius: 13px;
  background-color: ${(props) => (props.click ? "#00AF87" : "#FFFFFF")};
  border: solid 1px ${(props) => (!props.click ? "#00AF87" : "#FFFFFF")};
`;
