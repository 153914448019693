import { useEffect, useState } from "react";
import { TopNav2, TopNavNewBrowser } from "../../../component/mobile/Topnav";
import { Inputbox } from "../../../asset/styles/mobile/common/Common";
import SignUpService from "../../../service/signUp/SignUp";
import { Find1 } from "./Find1";
import Button from "../../../component/mobile/Button";
import { useLocation } from "react-router-dom";
import { Find2 } from "./Find2";
import Footer from "../../../component/browser/Footer";
import { FindIdPopUp } from "./PopUp";

const Find = () => {
  const [signSns, setSignSns] = useState(false);
  const [onPopUp, setOnPopUp] = useState(false);
  const [name, setName] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const location = useLocation();
  const [selectedId, setSelectedId] = useState("");
  const [tab, setTab] = useState(location.state.tab || 0);
  const [step, setStep] = useState(0);
  const [type, setType] = useState("");
  const [pwdStep, setPwdStep] = useState(0);
  const TabHandler = () => {
    setOnPopUp(true);
    setType("error");
  };
  useEffect(() => {
    setStep(0);
    setPwdStep(0);
  }, [tab]);
  useEffect(() => {
    sessionStorage.removeItem("findId");
  }, []);

  return (
    <div className="w-100per  flex-column space-between ">
      {onPopUp && <FindIdPopUp type={type} setOnPopUp={setOnPopUp} />}
      <div
        className="flex-column h-100per w-1024-center-y"
        style={{ overflow: "auto" }}
      >
        <TopNav2 text="아이디/비밀번호 찾기" />
        <TopNavNewBrowser />
        <div className="flex  w-1024-max-w-468 w-1024-w-100per">
          <div className="browser w-1024-padding-t-42 f-size-26 line-h-34 font-bold color-dark-primary">
            아이디/비밀번호 찾기
          </div>
        </div>
        <div className="padding-16 flex-center w-1024-w-100per w-1024-max-w-468">
          <div className="w-100per border-radius-8 flex-row bg-F0F0F0">
            <div
              onClick={() => {
                setTab(0);
              }}
              className={`flex-center padding-y-6 w-50per border-radius-t-l-8 pointer border-radius-b-l-8 ${
                tab === 0
                  ? ` bg-FFFFFF border-171E26 border-radius-8`
                  : `border-t-l-radius-8 border-b-l-radius-8 bg-light-gray-200`
              }`}
            >
              <div
                className={`font-medium f-size-16 line-h-24 ${
                  tab === 0 ? `color-dark-primary` : `color-disabled`
                }`}
              >
                아이디 찾기
              </div>
            </div>
            <div
              onClick={() => {
                signSns ? TabHandler() : setTab(1);
              }}
              className={`flex-center padding-y-6 w-50per border-radius-t-l-8 border-radius-b-l-8 pointer ${
                tab === 1
                  ? ` bg-FFFFFF border-171E26 border-radius-8`
                  : `border-t-r-radius-8 border-b-r-radius-8 bg-light-gray-200`
              }`}
            >
              <div
                className={`font-medium f-size-16 line-h-24 ${
                  tab === 1 ? `color-dark-primary` : `color-disabled`
                }`}
              >
                비밀번호 찾기
              </div>
            </div>
          </div>
        </div>
        {step === 0 && (
          <Find1
            tab={tab}
            setStep={setStep}
            setName={setName}
            name={name}
            phone1={phone1}
            phone2={phone2}
            setPhone1={setPhone1}
            setPhone2={setPhone2}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
          />
        )}
        {step === 1 && (
          <Find2
            tab={tab}
            name={name}
            phone={`010${phone1}${phone2}`}
            setStep={setStep}
            setTab={setTab}
            setSelectedId={setSelectedId}
            selectedId={selectedId}
            setSignSns={setSignSns}
            signSns={signSns}
            setOnPopUp={setOnPopUp}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Find;
