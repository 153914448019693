import { useEffect, useState } from 'react';
import { Content1, Footer, ReportTitle } from '../component';
import useDidMountEffect from '../../../hooks/UseDidMountEffect';
import GetLandShpaeImage from '../../../asset/styles/Land-Shape';
interface PropsType {
  data: any;
  address: string;
}
export const Summary = ({ data, address }: PropsType) => {
  console.log(data);

  const [shapeWithoutSuffix, setShapeWithoutSuffix] = useState('');
  const [areaType, setAreaType] = useState('P');

  const content = [
    `본 사업지의 토지면적은 ${data.pyeong}평(${data.area}㎡)이며,`,
    `토지의 고저는 ${data.height}대로, 형상은 ${data.shape}(설명데이터필요)으로 확인됨`,
  ];
  const Column = ({ title, content }: any) => (
    <div className='flex-row padding-b-8 border-b-171E26 space-between w-50per'>
      <div>{title}</div>
      <div>{content}</div>
    </div>
  );
  useEffect(() => {
    if (data) {
      setShapeWithoutSuffix(
        data.shape.slice(-1) === '형' ? data.shape.slice(0, -1) : data.shape
      );
    }
  }, [data]);

  return (
    <div className='flex-column w-100per h-100per space-between'>
      <div className='flex-column  w-100per' style={{ height: '90%' }}>
        <ReportTitle address={address} category='물건 개요' />
        <div className='flex-column padding-24 gap-48'>
          <Content1 content={content} />
          <div className='flex-row gap-32 space-between'>
            <div className='flex-column gap-24' style={{ width: '600px' }}>
              <div className='m-t1-20-b'>토지 정보 분석</div>
              <div className='flex-column gap-24' style={{ height: '132px' }}>
                <div className='flex-row gap-24'>
                  <Column title='토지면적' content={data.pyeong + '평'} />
                  <Column title='용도지역' content={data.zoning} />
                </div>
                <div className='flex-row gap-24'>
                  <Column title='지목' content={data.category} />
                  <Column title='이용상황' content={data.useStatus} />
                </div>
                <div className='flex-row gap-24'>
                  <Column title='고저' content={data.height} />
                  <Column title='도로' content={data.roadSurface} />
                </div>
              </div>
              <div className='flex-center'>
                <div
                  className='padding-0-16-16 gap-10'
                  style={{ width: '500px' }}
                >
                  <div className='padding-24-16 bg-light-gray border-radius-8 gap-18 flex-row center-y'>
                    <img src={GetLandShpaeImage(shapeWithoutSuffix)} alt='' />
                    <div className='flex-column gap-4'>
                      <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
                        {shapeWithoutSuffix}형
                      </div>
                      <div className='font-medium f-size-12 line-h-14 color-text-tertiary'>
                        이륜자동차~
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex-column gap-24' style={{ width: '600px' }}>
              <div className='m-t1-20-b'>용도지역</div>
              <div className='flex-column gap-24' style={{ height: '132px' }}>
                <div className='flex-row gap-24'>
                  <Column title='가능 건폐율' content={data.BCR_rate + '%'} />
                  <Column title='가능 용적률' content={data.FAR_rate + '%'} />
                </div>
                <div className='flex-row gap-24'>
                  {data.landUsePlan[0] && (
                    <Column
                      title={data.landUsePlan[0].useStatus}
                      content={data.landUsePlan[0].includeInfo}
                    />
                  )}
                  {data.landUsePlan[1] && (
                    <Column
                      title={data.landUsePlan[1].useStatus}
                      content={data.landUsePlan[1].includeInfo}
                    />
                  )}
                </div>
                {data.landUsePlan[2] && (
                  <div className='flex-row gap-24'>
                    <Column
                      title={data.landUsePlan[2].useStatus}
                      content={data.landUsePlan[2].includeInfo}
                    />
                  </div>
                )}
              </div>{' '}
              <div className='flex-center'>
                <div
                  className='padding-0-16-16 gap-10'
                  style={{ width: '500px' }}
                >
                  <div className='padding-24-16 bg-light-gray border-radius-8 gap-18 flex-row center-y'>
                    가능건폐율 가능용적률 해석 필요
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer number={3} />
    </div>
  );
};
