/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useState } from 'react';
import { isMobile, transNum } from '../../../../component/function/function';
import {
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import {
  RowSectionTitle,
  RowTableValueBold,
} from '../../../../component/report/Row';
import SignController from '../../../../controller/sign/SignController';
import { TextSectionTitle } from '../../../../component/report/Text';
import { ButtonSelectorBox } from '../../../../component/report/Button';
import {
  BoxGreen1,
  BoxTable,
  BoxTable2,
  BoxTable3,
} from '../../../../component/report/Box';
import './ReportDetail.css';
import PriceService from '../../../../service/price/priceApi';
import { useSelector } from 'react-redux';
interface LastData {
  radius1: string;
  radius2: string;
  radius3: string;
  avg: string;
}

const ReportDetail7 = ({
  address,

  mypage,
  auctionData,
  landData,
  landLineChartData,
  auctionLineChartData,
}: {
  address?: any;
  setLoading?: any;
  mypage?: boolean;
  auctionData?: any;
  landData?: any;
  landLineChartData?: any;
  auctionLineChartData?: any;
}) => {
  const state = useSelector((state: any) => {
    return state.type;
  });

  const [pyeong, setPyeong] = useState(true);

  const [type, setType] = useState('');
  const [tab2, setTab2] = useState(1);
  const [monthTab, setMonthTab] = useState(0);
  const [selectedButton, setSelectedButton] = useState(1);

  const [Table, setTable] = useState<any>(null);
  const [name, setName] = useState('');

  const getDisplayValue = (value: string | null) => {
    return value !== undefined && value !== null && value !== 'No data'
      ? value
      : '실거래 없음';
  };

  // console.log(tab2);

  useEffect(() => {
    console.log('month', monthTab);
  }, [monthTab]);
  const arr = auctionData.auctionList.map(
    (item: { month: any; price: string | null; count: string | null }) => ({
      key: item.month,
      price:
        item.price !== null && item.price !== 'No data'
          ? parseInt(item.price)
          : '-',
      count:
        item.count !== null && item.count !== 'No data'
          ? parseInt(item.count)
          : '-',
    })
  );
  const reversedArrAuction = [...arr].reverse();

  const btnArr = [
    { children: `실거래가(원/${pyeong ? '평' : '㎡'}) 흐름`, selected: 1 },
    '',
    { children: `감정평가(원/${pyeong ? '평' : '㎡'}) 흐름`, selected: 2 },
  ];

  const table = [
    [
      '1km 이내 ',
      tab2 === 1
        ? monthTab === 0
          ? landData.last3.radius1 === 'No data'
            ? '사례 없음'
            : Number(landData.last3.radius1) === 0
            ? '가격 변화 없음'
            : Number(landData.last3.radius1).toFixed(1)
          : monthTab === 1
          ? landData.last6.radius1 === 'No data'
            ? '사례 없음'
            : Number(landData.last6.radius1) === 0
            ? '가격 변화 없음'
            : Number(landData.last6.radius1).toFixed(1)
          : monthTab === 2
          ? landData.last12.radius1 === 'No data'
            ? '사례 없음'
            : Number(landData.last12.radius1) === 0
            ? '가격 변화 없음'
            : Number(landData.last12.radius1).toFixed(1)
          : ''
        : monthTab === 0
        ? auctionData.alast3.radius1 === 'No data'
          ? '사례 없음'
          : Number(auctionData.alast3.radius1) === 0
          ? '가격 변화 없음'
          : Number(auctionData.alast3.radius1).toFixed(1)
        : monthTab === 1
        ? auctionData.alast6.radius1 === 'No data'
          ? '사례 없음'
          : Number(auctionData.alast6.radius1) === 0
          ? '가격 변화 없음'
          : Number(auctionData.alast6.radius1).toFixed(1)
        : monthTab === 2
        ? auctionData.alast12.radius1 === 'No data'
          ? '사례 없음'
          : Number(auctionData.alast12.radius1) === 0
          ? '가격 변화 없음'
          : Number(auctionData.alast12.radius1).toFixed(1)
        : '',
    ],

    [
      '2km 이내 ',
      tab2 === 1
        ? monthTab === 0
          ? landData.last3.radius2 === 'No data'
            ? '사례 없음'
            : Number(landData.last3.radius2) === 0
            ? '가격 변화 없음'
            : Number(landData.last3.radius2).toFixed(1)
          : monthTab === 1
          ? landData.last6.radius2 === 'No data'
            ? '사례 없음'
            : Number(landData.last6.radius2) === 0
            ? '가격 변화 없음'
            : Number(landData.last6.radius2).toFixed(1)
          : monthTab === 2
          ? landData.last12.radius2 === 'No data'
            ? '사례 없음'
            : Number(landData.last12.radius2) === 0
            ? '가격 변화 없음'
            : Number(landData.last12.radius2).toFixed(1)
          : ''
        : monthTab === 0
        ? auctionData.alast3.radius2 === 'No data'
          ? '사례 없음'
          : Number(auctionData.alast3.radius2) === 0
          ? '가격 변화 없음'
          : Number(auctionData.alast3.radius2).toFixed(1)
        : monthTab === 1
        ? auctionData.alast6.radius2 === 'No data'
          ? '사례 없음'
          : Number(auctionData.alast6.radius2) === 0
          ? '가격 변화 없음'
          : Number(auctionData.alast6.radius2).toFixed(1)
        : monthTab === 2
        ? auctionData.alast12.radius2 === 'No data'
          ? '사례 없음'
          : Number(auctionData.alast12.radius2) === 0
          ? '가격 변화 없음'
          : Number(auctionData.alast12.radius2).toFixed(1)
        : '',
    ],
    [
      '3km 이내',
      tab2 === 1
        ? monthTab === 0
          ? landData.last3.radius3 === 'No data'
            ? '사례 없음'
            : Number(landData.last3.radius3) === 0
            ? '가격 변화 없음'
            : Number(landData.last3.radius3).toFixed(1)
          : monthTab === 1
          ? landData.last6.radius3 === 'No data'
            ? '사례 없음'
            : Number(landData.last6.radius3) === 0
            ? '가격 변화 없음'
            : Number(landData.last6.radius3).toFixed(1)
          : monthTab === 2
          ? landData.last12.radius3 === 'No data'
            ? '사례 없음'
            : Number(landData.last12.radius3) === 0
            ? '가격 변화 없음'
            : Number(landData.last12.radius3).toFixed(1)
          : ''
        : monthTab === 0
        ? auctionData.alast3.radius3 === 'No data'
          ? '사례 없음'
          : Number(auctionData.alast3.radius3) === 0
          ? '가격 변화 없음'
          : Number(auctionData.alast3.radius3).toFixed(1)
        : monthTab === 1
        ? auctionData.alast6.radius3 === 'No data'
          ? '사례 없음'
          : Number(auctionData.alast6.radius3) === 0
          ? '가격 변화 없음'
          : Number(auctionData.alast6.radius3).toFixed(1)
        : monthTab === 2
        ? auctionData.alast12.radius3 === 'No data'
          ? '사례 없음'
          : Number(auctionData.alast12.radius3) === 0
          ? '가격 변화 없음'
          : Number(auctionData.alast12.radius3).toFixed(1)
        : '',
    ],
  ];
  const CustomYAxisTick = ({ x, y, payload }: any) => (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={5} textAnchor='end' fill='#666'>
        {payload.value.toLocaleString()}
      </text>
    </g>
  );
  const TrendTitle = ({ title }: any) => (
    <div className='padding-8-16 flex-center'>
      <div
        className='flex-center border-radius-8 padding-4-8 gap-10 flex-row border-1E9B6B'
        style={{ width: 'fit-content' }}
      >
        <div className='m-b1-16-b color-primary-500 no-wrap'>{title}</div>
      </div>
    </div>
  );

  const getEvaluationMessage = () => {
    if (
      Number(auctionData.alast3.radius3) < 0 &&
      Number(auctionData.alast1.radius3) < 0
    ) {
      return (
        <>
          하락세가 예상되오니, <br /> 고점 매수에 유의해야 합니다.
        </>
      );
    }
    if (
      Number(auctionData.alast3.radius3) < 0 &&
      Number(auctionData.alast1.radius3) > 0
    ) {
      return (
        <>
          상승세 전환이 예상되오니, <br /> 저점 매수를 포착해야 합니다.
        </>
      );
    }
    if (
      Number(auctionData.alast3.radius3) > 0 &&
      Number(auctionData.alast1.radius3) > 0
    ) {
      return (
        <>
          상승세 지속이 예상되오니, <br /> 저점 매수를 포착해야 합니다.
        </>
      );
    }
    if (
      Number(auctionData.alast3.radius3) > 0 &&
      Number(auctionData.alast1.radius3) < 0
    ) {
      return (
        <>
          하락세 전환이 예상되오니, <br /> 고점 매수에 유의해야 합니다.
        </>
      );
    }
    return null;
  };

  const isDataValid =
    auctionData.alast1.radius3 !== 'No data' &&
    auctionData.alast3.radius3 !== 'No data' &&
    (Number(auctionData.alast1.radius3) !== 0 ||
      Number(auctionData.alast3.radius3) !== 0);

  return (
    <div className='padding-b-24 browser-bg-FFFFFF'>
      <div className='flex-column'>
        <div className='flex-row w-100per space-between end-y'>
          <RowSectionTitle>
            <TextSectionTitle>가격 트랜드 분석</TextSectionTitle>
          </RowSectionTitle>

          <div className='padding-b-8'>
            <div
              onClick={() => {
                setPyeong(!pyeong);
              }}
              className='hover bg-FFFFFF padding-2-8 center-x  flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary end-y'
            >
              <img src='/asset/images/arrow/Refresh20.svg' />
              <div>{pyeong ? '㎡' : '평'}</div>
            </div>
          </div>
        </div>

        <ButtonSelectorBox tab={tab2} setTab={setTab2} btnArr={btnArr} />

        {tab2 === 1 ? (
          <>
            <div className='tota'>
              <div className='title'>
                실거래가(원/{pyeong ? '평' : '㎡'}) 변동률
              </div>
            </div>
            <div className='of-x-auto relative flex-row'>
              <div className='flex-row padding-12 gap-8'>
                <div className='border-radius-100 flex-row gap-5'>
                  {['최근 3개월', '최근 6개월', '최근 12개월'].map(
                    (el, idx) => (
                      <div
                        key={idx}
                        className={`padding-6-16 border-radius-100 hover ${
                          monthTab === idx ? 'selected' : ''
                        }`}
                        style={{
                          border: '1px solid #F0F0F0',
                          background: monthTab === idx ? '#000' : '#fff',
                        }}
                        // onClick={() => handleButtonClick(idx + 1)}
                        onClick={() => {
                          setMonthTab(idx);
                        }}
                      >
                        <div
                          className='font-medium f-size-16 line-h-24 no-wrap color-text-tertiary'
                          style={{
                            color: monthTab === idx ? '#fff' : '#171e268f',
                          }}
                        >
                          {el}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className='frame-26083552'>
              <div className='flex-column'>
                <div className='flex-row bg-F7F8F9 padding-8-16 border-t-171E26 border-b-171E26'>
                  <div className='flex-center w-50per '>
                    <div className='m-b2-14-m color-text-tertiary'>거리</div>
                  </div>
                  <div className='flex-center w-50per '>
                    <div className='m-b2-14-m color-text-tertiary'>
                      {pyeong ? '평당' : '㎡'} 실거래가 평균
                    </div>
                  </div>
                </div>
                {/* <BoxTable3 table={table2} /> */}
                {table.map((item, index) => {
                  return (
                    <div
                      className={`flex-row  padding-8-16 border-b-171E26 ${
                        index === 3 && `bg-primary-100`
                      }`}
                    >
                      <div className='flex-center w-50per '>
                        <div className='m-b2-14-m color-text-tertiary'>
                          {item[0]}
                        </div>
                      </div>
                      <div className='flex-center w-50per '>
                        <div className='flex-row gap-8 center-y'>
                          {Number(item[1]) > 0 ? (
                            <div className='m-b2-14-m color-text-increase'>
                              상승중
                            </div>
                          ) : Number(item[1]) < 0 ? (
                            <div className='m-b2-14-m color-text-decrease'>
                              하락중
                            </div>
                          ) : (
                            ''
                          )}
                          <div
                            className={`padding-4-8 border-radius-999 ${
                              Number(item[1]) > 0
                                ? `bg-increase`
                                : Number(item[1]) < 0
                                ? `bg-decrease`
                                : ''
                            }`}
                          >
                            <div
                              className={`m-c1-12-b ${
                                Number(item[1]) > 0
                                  ? `color-FFFFFF`
                                  : Number(item[1]) < 0
                                  ? `color-FFFFFF`
                                  : item[1] === '가격 변화 없음'
                                  ? 'color-text-tertiary'
                                  : ''
                              }`}
                            >
                              {Number(item[1]) > 0 && '+'}
                              {Number(item[1]) > 0 || Number(item[1]) < 0
                                ? item[1] + '%'
                                : item[1]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className='tota space-between w-100per'>
              <div className='frame-2609336'>
                <div className='title'>
                  실거래가(원/{pyeong ? '평' : '㎡'}) 추이{' '}
                </div>
              </div>
              <div className='_3-km'>3km 이내 기준 </div>
            </div>
            {landLineChartData[0]?.price ? (
              <LineChart
                className='flex-medium f-size-11 center-x mt-10'
                width={
                  isMobile(window.innerWidth)
                    ? window.innerWidth - 32
                    : 360 - 16
                }
                height={180}
                data={landLineChartData}
              >
                <Line
                  type='monotone'
                  dataKey={pyeong ? 'pricePerPyeong' : 'price'}
                  stroke='#53df89'
                />
                <XAxis dataKey='month' />
                <YAxis tick={<CustomYAxisTick />} />
                <Tooltip
                  content={({ payload, label, active }) => {
                    if (active && payload && payload.length) {
                      const dataItem = payload[0].payload;
                      return (
                        <div className='custom-tooltip'>
                          <p>{`${label}`}</p>
                          <p>{`거래량: ${dataItem.count}건`}</p>
                          <p style={{ color: '#53df89' }}>
                            {pyeong
                              ? `평당가: ${Math.round(
                                  dataItem.pricePerPyeong
                                ).toLocaleString()}원`
                              : `실거래가: ${Math.round(
                                  dataItem.price
                                ).toLocaleString()}원`}
                          </p>
                        </div>
                      );
                    }

                    return null;
                  }}
                />
              </LineChart>
            ) : (
              <div className='frame-26083555 flex-center w-100per'>
                <div className='frame-2609350'>
                  <div className='frame-2608789'>
                    <div className='ellipse-463'></div>
                    <div className='group-43171'>
                      <div className='rectangle-3031'></div>
                      <div className='ellipse-475'></div>
                    </div>
                  </div>
                  <div className='frame-2608551'>
                    <div className='div'>실거래가 없습니다.</div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className={'frame-2609352'}>
              <div className='_3-km'>경공매 감정평가 기준</div>
            </div>
            <div className='tota'>
              <div className='title'>감정평가(원/평) 변동률</div>
            </div>
            <div className='of-x-auto relative flex-row'>
              <div className='flex-row padding-12 gap-8'>
                <div className='border-radius-100 flex-row gap-5'>
                  {['최근 3개월', '최근 6개월', '최근 12개월'].map(
                    (el, idx) => (
                      <div
                        key={idx}
                        className={`padding-6-16 border-radius-100 hover ${
                          monthTab === idx ? 'selected' : ''
                        }`}
                        style={{
                          border: '1px solid #F0F0F0',
                          background: monthTab === idx ? '#000' : '#fff',
                        }}
                        // onClick={() => handleButtonClick(idx + 1)}
                        onClick={() => {
                          setMonthTab(idx);
                        }}
                      >
                        <div
                          className='font-medium f-size-16 line-h-24 no-wrap color-text-tertiary'
                          style={{
                            color: monthTab === idx ? '#fff' : '#171e268f',
                          }}
                        >
                          {el}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className='frame-26083552'>
              <div className='flex-column'>
                <div className='flex-row bg-F7F8F9 padding-8-16 border-t-171E26 border-b-171E26'>
                  <div className='flex-center w-50per '>
                    <div className='m-b2-14-m color-text-tertiary'>거리</div>
                  </div>
                  <div className='flex-center w-50per '>
                    <div className='m-b2-14-m color-text-tertiary'>
                      {pyeong ? '평당' : '㎡'} 감정평가 평균
                    </div>
                  </div>
                </div>
                {table.map((item, index) => {
                  return (
                    <div
                      className={`flex-row  padding-8-16 border-b-171E26 ${
                        index === 3 && `bg-primary-100`
                      }`}
                    >
                      <div className='flex-center w-50per '>
                        <div className='m-b2-14-m color-text-tertiary'>
                          {item[0]}
                        </div>
                      </div>
                      <div className='flex-center w-50per '>
                        <div className='flex-row gap-8 center-y'>
                          {Number(item[1]) > 0 ? (
                            <div className='m-b2-14-m color-text-increase'>
                              상승중
                            </div>
                          ) : Number(item[1]) < 0 ? (
                            <div className='m-b2-14-m color-text-decrease'>
                              하락중
                            </div>
                          ) : (
                            ''
                          )}
                          <div
                            className={`padding-4-8 border-radius-999 ${
                              Number(item[1]) > 0
                                ? `bg-increase`
                                : Number(item[1]) < 0
                                ? `bg-decrease`
                                : ''
                            }`}
                          >
                            <div
                              className={`m-c1-12-b ${
                                Number(item[1]) > 0
                                  ? `color-FFFFFF`
                                  : Number(item[1]) < 0
                                  ? `color-FFFFFF`
                                  : item[1] === '가격 변화 없음'
                                  ? `color-text-tertiary`
                                  : ``
                              }`}
                            >
                              {Number(item[1]) > 0 && '+'}
                              {Number(item[1]) > 0 || Number(item[1]) < 0
                                ? item[1] + '%'
                                : item[1]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='tota w-100per space-between'>
              <div className='title'>감정평가(원/평) 추이</div>
              <div className='_3-km'>3km 이내 기준 </div>
            </div>

            {auctionLineChartData[11]?.price ? (
              <>
                <div className='padding-24-16 flex-column gap-16'>
                  <LineChart
                    className='flex-medium f-size-11 center-x mt-10'
                    width={
                      isMobile(window.innerWidth)
                        ? window.innerWidth - 32
                        : 360 - 16
                    }
                    height={180}
                    data={auctionLineChartData}
                  >
                    <Line type='monotone' dataKey='price' stroke='#53df89' />
                    <XAxis dataKey='month' />
                    <YAxis tick={<CustomYAxisTick />} />
                    <Tooltip
                      content={({ payload, label, active }) => {
                        if (active && payload && payload.length) {
                          const dataItem = payload[0].payload;

                          return (
                            <div className='custom-tooltip'>
                              <p>{`${label}`}</p>
                              <p>{`거래량: ${dataItem.count}건`}</p>
                              <p style={{ color: '#53df89' }}>
                                {pyeong
                                  ? `평당가: ${Math.round(
                                      dataItem.pricePerPyeong
                                    )}원`
                                  : `실거래가: ${Math.round(dataItem.price)}원`}
                              </p>
                            </div>
                          );
                        }

                        return null;
                      }}
                    />
                  </LineChart>
                </div>

                <div className='padding-8-16 gap-10'>
                  <BoxGreen1>
                    <div className='font-bold f-size-14 line-h-20 color-text-secondary'>
                      해당 {state.priceType === 'land' ? '농지' : '주택'} 주변
                      감정평가는 <br />
                      {isDataValid ? (
                        <>
                          최근{' '}
                          <span
                            style={{
                              color:
                                Number(auctionData.alast3.radius3) > 0
                                  ? '#EC4F4F'
                                  : '#4b47fc',
                            }}
                          >
                            3개월간{' '}
                            {Number(auctionData.alast3.radius3) > 0
                              ? '상승중 +'
                              : '하락중 '}
                            {Number(auctionData.alast3.radius3).toFixed(1)}%{' '}
                          </span>
                          입니다.
                          <br />
                          최근{' '}
                          <span
                            style={{
                              color:
                                Number(auctionData.alast1.radius3) > 0
                                  ? '#EC4F4F'
                                  : '#4b47fc',
                            }}
                          >
                            1개월간{' '}
                            {Number(auctionData.alast1.radius3) > 0
                              ? '상승중 +'
                              : '하락중 '}
                            {Number(auctionData.alast1.radius3).toFixed(1)}%{' '}
                          </span>{' '}
                          입니다.
                          <br />
                          <div
                            className='font-bold f-size-16 line-h-24'
                            style={{ color: '#1E9B6B' }}
                          >
                            앞으로 {getEvaluationMessage()}
                          </div>
                        </>
                      ) : (
                        <>최근 1년간 데이터가 없습니다.</>
                      )}
                    </div>
                  </BoxGreen1>
                </div>
              </>
            ) : (
              <div className='frame-26083555 w-100per flex-center'>
                <div className='frame-2609350'>
                  <div className='frame-2608789'>
                    <div className='ellipse-463'></div>
                    <div className='group-43171'>
                      <div className='rectangle-3031'></div>
                      <div className='ellipse-475'></div>
                    </div>
                  </div>
                  <div className='frame-2608551'>
                    <div className='div'>감정평가 데이터가 없습니다.</div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {/* Check if auctionData list is empty or not */}
        {/* {Object.keys(auctionData).length === 0 &&
          auctionData.constructor === Object && (
            <div className='frame-2608355'>
              <div className='frame-2609350'>
                <div className='frame-2608789'>
                  <div className='ellipse-463'></div>
                  <div className='group-43171'>
                    <div className='rectangle-3031'></div>
                    <div className='ellipse-475'></div>
                  </div>
                </div>
                <div className='frame-2608551'>
                  <div className='div'>실거래가 없습니다.</div>
                </div>
              </div>
            </div>
          )} */}
      </div>
    </div>
  );
};

export default ReportDetail7;
