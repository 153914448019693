import { useState } from 'react';
import { RowTable1 } from '../../../../component/report/Row';
import { BoxGreen1 } from '../../../../component/report/Box';
import { TriangleTooltip } from '../../../../component/common/tooltip';

interface data {
  address: string;
  cropName: string;
  crops: number;
  facility: string;
  farmArea: number;
  quantity: number;
  type: string;
}

const ReportInfo = ({
  data,
  data2,
  time,
  mypage,
}: {
  data: data;
  data2: any;
  time?: any;
  mypage?: any;
}) => {
  const [areaType, setAreaType] = useState('P');
  const [dateTooltip, setDateTooltip] = useState(true);
  const facilityCode = {
    O: '노지',
    P: '비닐하우스',
    S: '스마트팜',
    W: '기후맞춤스마트팜',
  };

  return (
    <div className='padding-24-16 gap-16 flex-column relative'>
      <RowTable1
        title='분석일'
        value={
          time && time.split('T')[0] + ' ' + time.split('T')[1].split('.')[0]
        }
      />
      <RowTable1 title='주소' value={data.address} />
      <RowTable1 title='내 작물' value={data.cropName} />

      <RowTable1
        title='재배 면적'
        value={
          <div className='font-medium f-size-16 line-h-24 color-text-secondary flex-row gap-3'>
            {data.farmArea &&
              Math.round(
                data.farmArea * (areaType === 'P' ? 0.3025 : 1)
              ).toLocaleString()}
            {areaType === 'P' ? '평' : '㎡'}

            <div
              className='hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary'
              onClick={() => setAreaType((prev) => (prev !== 'P' ? 'P' : 'M'))}
            >
              <img src='/asset/images/arrow/Refresh20.svg' alt='' />
              {areaType === 'P' ? '㎡' : '평'}
            </div>
          </div>
        }
      />
      <div className='flex-row gap-24 w-100per'>
        <RowTable1
          half
          title='재배 환경'
          value={facilityCode[data.facility as 'O']}
        />
        <RowTable1 half title='기작' value={data.crops + '기작'} />
      </div>
      <RowTable1
        half
        title='총 생산량'
        value={Math.round(data.quantity).toLocaleString() + 'kg'}
      />
      <BoxGreen1>
        <div className='font-bold f-size-14 line-h-20 color-primary-500'>
          내 농장 진단은 1년 농업 경영 성과를 분석합니다.
        </div>
      </BoxGreen1>
      {mypage && dateTooltip && (
        <TriangleTooltip
          text='분석 시점의 결과는 현재와 다를 수 있어요.'
          text2='최신 분석 결과는 주소지 조회 후 다시 이용해 주세요.'
          top='30'
          left='50'
          on={true}
          off={() => setDateTooltip(false)}
          xImg={true}
        />
      )}
    </div>
  );
};

export default ReportInfo;
