import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TopLogo from "../../asset/images/new/Logo(korean).svg";
import Mypage from "../../asset/images/new/mypage.svg";
import GetActService from "../../service/act/Act";
import { ChangeMenu } from "../../store/topnavSlice";
import { useAuth } from "../../utilities/auth";
import { ChangeType } from "../../store/typeSlice";
import { useEffect, useState } from "react";
import SignController from "../../controller/sign/SignController";

export const Topnav = () => {
  const { isLogin, onLogout } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const LogoutApi = () => SignController.LogoutApi(onLogout);
  const dummy = [
    { title: "가격 분석", route: "/price/map", type: "price" },
    { title: "내 농장 진단", route: "/", type: "aifarm" },
    { title: "내 농지 찾기", route: "/findland", type: "findland" },
    { title: "내 집 짓기", route: "/house", type: "house" },
  ];
  // const NavButton = ({
  //   title,
  //   route,
  //   menu,
  //   type,
  // }: {
  //   title: string;
  //   route: string;
  //   menu: string;
  //   type: string;
  // }) => (
  //   <div
  //     className={
  //       tab === menu2
  //         ? "pointer font-bold f-size-16 line-h-20 color-1E9B6B flex-1 text-center hover"
  //         : "pointer font-bold f-size-16 line-h-20 color-text-primary flex-1 text-center hover"
  //     }
  //     onClick={() => {
  //       navigate(route);
  //       setTab(menu2);
  //       setMenu2(menu);
  //       dispatch(ChangeType(type));
  //     }}
  //   >
  //     {title}
  //   </div>
  // );

  return (
    <div
      className='flex-row center-x center-y w-100per padding-16-24 browser bg-FFFFFF z-10'
      style={{ borderBottom: "0.8px solid #e2e2e2" }}
    >
      <div
        className='flex-row space-between w-100per center-y '
        style={{ maxWidth: "1392px" }}
      >
        <div
          style={{
            flex: 0.5,
            display: "flex",
            justifyContent: "start",
          }}
        >
          <img
            src={TopLogo}
            onClick={() => {
              navigate("/");
              sessionStorage.setItem("tab", "aifarm");
            }}
            className='start-x pointer h-24'
            alt=''
          />
        </div>
        <div className='flex-row gap-60 ' style={{ flex: 1 }}>
          {/* <NavButton title="내 농장 진단" route="/" menu="farm" type="aifarm" />
          <NavButton
            title="내 농지 찾기"
            route="/findland"
            menu="search"
            type="findland"
          />
          <NavButton
            title="내 집 짓기"
            route="/house"
            menu="house"
            type="house"
          /> */}
          {dummy.map((item) => {
            return (
              <div
                className={
                  sessionStorage.getItem("tab") === item.type
                    ? "pointer font-bold f-size-16 line-h-20 color-1E9B6B flex-1 text-center hover"
                    : "pointer font-bold f-size-16 line-h-20 color-text-primary flex-1 text-center hover"
                }
                onClick={() => {
                  navigate(item.route);
                  sessionStorage.setItem("tab", item.type);
                  // dispatch(ChangeType(item.type));
                }}
              >
                {item.title}
              </div>
            );
          })}
        </div>
        <div
          className='flex-row gap-32 center-y center-x'
          style={{ flex: 0.5, justifyContent: "end" }}
        >
          {isLogin() ? (
            <div
              className='font-medium -pref-size-14 line-h-20 pointer hover'
              onClick={() => LogoutApi()}
            >
              로그아웃
            </div>
          ) : (
            <div
              className='font-medium f-size-14 line-h-20 pointer hover'
              onClick={() => window.location.replace("/signup")}
            >
              회원가입
            </div>
          )}
          {isLogin() ? (
            <img src={Mypage} onClick={() => navigate("/Mypage")} alt='' />
          ) : (
            <div
              className='font-medium f-size-14 line-h-20 pointer hover'
              onClick={() => navigate("/login")}
            >
              로그인
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Topnav;
