import { useLocation, useParams } from 'react-router-dom';
import { TopNav2 } from '../../../component/mobile/Topnav';
import ReportService from '../../../service/report/reportApi';
import ReportInfo from './components/Info';
import ReportNav from './components/Nav';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReportDetail1 from './components/detail/ReportDetail1';
import ReportDetail2 from './components/detail/ReportDetail2';
import ReportDetail3 from './components/detail/ReportDetail3';
import ReportDetail4 from './components/detail/ReportDetail4';
import ReportDetail5 from './components/detail/ReportDetail5';
import ReportDetail6 from './components/detail/ReportDetail6';
import ReportDetail7 from './components/detail/ReportDetail7';
import Summary1 from './components/summary/Summary1';
import Summary2 from './components/summary/Summary2';
import Summary3 from './components/summary/Summary3';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isMobile } from '../../../component/function/function';
import { LoadingDot } from '../../../store/loadingSlice';
import { useDispatch } from 'react-redux';
import ReportDetail10 from '../../reportResultPrice/components/detail/ReportDetail10';
import { SaveBtn } from '../../../component/common/saveBtn';
import PriceService from '../../../service/price/priceApi';
import { SaveReportPopUp } from '../../../component/common/saveReportPopUp';
import useDidMountEffect from '../../../hooks/UseDidMountEffect';
import { useFeature } from '@hackler/react-sdk';

const FarmingReportMain = ({
  reportIdx,
  setClose,
  mypage,
  search,
  setCloseSearchPointDetail,
  setOnPointHistory,
}: any) => {
  let param: any;
  const param2 = useParams();
  if (isMobile(window.innerWidth)) {
    param = { reportIdx: param2.reportIdx };
  } else {
    param = { reportIdx: reportIdx };
  }
  const [report_desc, setReport_desc] = useState({
    address: '',
    cropName: '',
    crops: 0,
    facility: 'O',
    farmArea: 0,
    quantity: 0,
    type: '',
    realIncome: 0,
  });
  const [competitivenessResult, setCompetitivenessResult] = useState({
    createdAt: '',
    quantityRate: 0,
    realIncome: 0,
    realIncomeRate: 0,
    yearOperationCost: 0,
    yearSales: 0,
    dsr: 0,
    irr: 0,
    unitPriceRate: 0,
  });
  const [createdTime, setCreatedTime] = useState('');
  const [avgCompetitivenessResult, setAvgCompetitivenessResult] = useState({
    realIncome: 0,
    realIncomeRate: 0,
    yearOperationCost: 0,
    yearSales: 0,
  });

  const [cropAnalysis, setCropAnalysis] = useState({
    accumalatedTempInfo: {
      farmStartDate: '2023-01-01',
      prevFarmEndDate: '2022-06-01',
      prevFarmStartDate: '2022-01-01',
      type: 'L',
    },
    accumalatedTempList: {
      prevList: [{ date: '', value: '' }],
      curList: [{ date: '', value: '' }],
    },
    avgYearWeather: [{ point: 279, month: 2, temp: 1.8, maxTemp: 7.9 }],
    cropList: {
      recommendCrop: [],
      anotherCrop: [],
    },
    curYearWeather: [
      {
        year: 2022,
        month: 2,
        avgTemp: -0.4672985781990533,
        avgMaxTemp: 5.715809072444147,
      },
    ],
    soilResult: {
      erosion: '없음',
      frstGrd: '임지_4급지',
      fruitGrd: '과수상전_4급지',
      height: '평지',
      pastureGrd: '초지_3급지',
      pfldGrd: '밭_3급지',
      rfldGrd: '논_2급지',
      roadSurface: '중로한면',
      shape: '부정형',
      soilType: '논_보통답',
      soilUseRec: '논',
      soildra: '약간불량',
      soilslope: '경사_2-7%',
      useStatus: '상업용',
    },
  });
  const [savePopUp, setSavePopUp] = useState(false);
  const [farmValue, setFarmValue] = useState({
    estimatedPrice: 0,
    height: '',
    landTradeDate: null,
    landTradePrice: null,
    officialPriceList: {
      2014: 0,
      2015: 0,
      2016: 0,
      2017: 0,
      2018: 0,
      2019: 0,
      2020: 0,
      2021: 0,
      2022: 0,
      2023: 0,
    },
    perMeterEstimatedPrice: 0,
    perPyeongEstimatedPrice: 0,
    roadSurface: '',
    shape: '',
    useStatus: '',
    surroundings: [],
  });

  const [costAnalysis, setCostAnalysis] = useState({
    SGA: 0,
    avgFarmEquipmentCost: 0,
    avgLaborCost: 0,
    avgLandRentalCost: 0,
    avgMaterialCost: 0,
    avgOrchardCost: 0,
    avgSGA: 0,
    avgWaterHeatingCost: 0,
    farmEquipmentCost: 0,
    laborCost: 0,
    landRentalCost: 0,
    materialCost: 0,
    orchardCost: 0,
    waterHeatingCost: 0,
  });

  const [profitAnalysis, setProfitAnalysis] = useState({
    loanCost: 0,
    loanCostInterest: 0,
    loanCostPrincipal: 0,
    profit: 0,
    profitRate: 0,
    realIncome: 0,
    realIncomeRate: 0,
    yearSales: 0,
    yearSalesCost: 0,
    yearSalesSGA: 0,
  });

  const [investmentAnalysis, setInvestmentAnalysis] = useState({
    avgInvestRate: 0,
    facilityCost: 0,
    farmMachineryCost: 0,
    infracost: 0,
    investRate: 0,
    landPurchaseCost: 0,
    realIncome: 0,
  });

  const [loanAnalysis, setLoanAnalysis] = useState({
    loanList: [
      {
        loanName: '',
        loanType: '',
        loanCost: 0,
        loanDate: '',
        loanRate: 0,
        redemptionPeriod: 0,
        retentionPeriod: 0,
      },
    ],
    loanPlan: [],
    recentRepayment: {
      info: [],
    },
    totalInterest: 0,
    totalRepayment: 0,
  });

  const [curFarmDate, setcurFarmDate] = useState(
    // (cropAnalysis.accumalatedTempList &&
    //   cropAnalysis.accumalatedTempList.curList &&
    //   cropAnalysis.accumalatedTempList.curList[0] &&
    //   cropAnalysis.accumalatedTempList.curList[0].date) ||
    //   '2022-01-01'
    cropAnalysis.accumalatedTempInfo.farmStartDate
  );

  const [prevFarmDate, setPrevFarmDate] = useState(
    // (cropAnalysis.accumalatedTempList &&
    //   cropAnalysis.accumalatedTempList.prevList &&
    //   cropAnalysis.accumalatedTempList.prevList[0].date) ||
    //   '2022-01-01'
    cropAnalysis.accumalatedTempInfo.prevFarmStartDate
  );
  const [prevHarvestDate, setPrevHarvestDate] = useState(
    // (cropAnalysis.accumalatedTempList &&
    //   cropAnalysis.accumalatedTempList.prevList &&
    //   cropAnalysis.accumalatedTempList.prevList[
    //     cropAnalysis.accumalatedTempList.prevList.length - 1
    //   ].date) ||
    //   '2022-01-01'
    cropAnalysis.accumalatedTempInfo.prevFarmEndDate
  );

  const [salesAnalysis, setSalesAnalysis] = useState('');

  const [facility, setFacility] = useState('노지');
  const [type, setType] = useState('L');
  const dispatch = useDispatch();
  const [origin, setOrigin] = useState();

  const report = async () => {
    try {
      dispatch(LoadingDot('W'));
      // const data = await ReportService.report(param.reportIdx);
      const data2 = await ReportService.reportFarm(param.reportIdx);

      setReport_desc(data2.report.report_desc);
      setCompetitivenessResult(data2.report.competitivenessResult);
      setAvgCompetitivenessResult(data2.report.avgCompetitivenessResult);
      setCreatedTime(data2.report.createdTime);
      setFarmValue(data2.farmValue);
      setSalesAnalysis(data2.report.salesAnalysis);
      setCostAnalysis(data2.report.costAnalysis);
      setProfitAnalysis(data2.report.profitAnalysis);
      setInvestmentAnalysis(data2.report.investmentAnalysis);
      setLoanAnalysis(data2.report.loanAnalysis);
      setCropAnalysis(data2.cropAnalysis);
      dispatch(LoadingDot('N'));

      if (mypage) {
        setFacility(data2.cropAnalysis.cropList.facility);
        setcurFarmDate(data2.cropAnalysis.accumalatedTempInfo.farmStartDate);
        setPrevFarmDate(
          data2.cropAnalysis.accumalatedTempInfo.prevFarmStartDate
        );
        setPrevHarvestDate(
          data2.cropAnalysis.accumalatedTempInfo.prevFarmEndDate
        );
        setType(data2.cropAnalysis.accumalatedTempInfo.type);
      }
    } catch (err) {
      dispatch(LoadingDot('N'));

      console.log(err);
    }
  };

  const getOriginData = async () => {
    try {
      const result = await PriceService.origin();

      setOrigin(result.list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOriginData();
  }, []);

  const reportSales = async () => {
    try {
      const data = await ReportService.cropAnalysis(
        param.reportIdx,
        facility,
        type
      );
      // setCropAnalysis(data.result);
    } catch (err) {
      console.log(err);
    }
  };

  const location = useLocation();
  const reportAiFarm = async (crop: any, save: string) => {
    try {
      if (mypage) {
        dispatch(LoadingDot('W2'));
      } else {
        if (save === 'Y') {
          dispatch(LoadingDot('S'));
        } else {
          dispatch(LoadingDot('W'));
        }
      }
      let data = {};
      if (crop) {
        data = {
          type: type,
          farmStartDate: '',
          prevFarmStartDate: '',
          prevFarmEndDate: '',
          facility: facility,
          reportIdx: isMobile(window.innerWidth)
            ? mypage
              ? location.state.reportIdx
              : param2.reportIdx
            : reportIdx,
          saveYN: save,
        };
      } else {
        data = {
          type: type,
          farmStartDate: curFarmDate,
          prevFarmStartDate: prevFarmDate,
          prevFarmEndDate: prevHarvestDate,
          facility: facility,
          reportIdx: isMobile(window.innerWidth) ? param2.reportIdx : reportIdx,
          saveYN: save,
        };
      }
      const result = await ReportService.reportAiFarm(data);
      setCropAnalysis(result.cropAnalysis);
      console.log('reportaifarm', result);
      dispatch(LoadingDot('N'));
      if (save === 'Y') {
        setSavePopUp(true);
      }
    } catch (error) {
      console.log(error);
      dispatch(LoadingDot('N'));
    }
  };

  useEffect(() => {
    report();
    // reportSales();

    reportAiFarm(true, 'N');
  }, [facility]);
  useEffect(() => {
    report();
    // reportSales();

    reportAiFarm(false, 'N');
  }, [type]);

  useDidMountEffect(() => {
    reportAiFarm(false, 'N');
  }, [curFarmDate, prevFarmDate, prevHarvestDate]);
  // useEffect(() => {
  //   console.log('prev', prevFarmDate);
  // }, [prevFarmDate]);
  // useDidMountEffect(() => {
  //   setcurFarmDate(cropAnalysis.accumalatedTempInfo.farmStartDate);
  //   setPrevFarmDate(cropAnalysis.accumalatedTempInfo.prevFarmStartDate);
  //   setPrevHarvestDate(cropAnalysis.accumalatedTempInfo.prevFarmEndDate);
  //   getOriginData();
  // }, [cropAnalysis]);
  const conRef = useRef<HTMLDivElement>(null);
  const menuRef1 = useRef<HTMLDivElement>(null);
  const menuRef2 = useRef<HTMLDivElement>(null);
  const menuRef3 = useRef<HTMLDivElement>(null);
  const menuRef4 = useRef<HTMLDivElement>(null);
  const menuRef5 = useRef<HTMLDivElement>(null);
  const menuRef6 = useRef<HTMLDivElement>(null);
  const menuRef7 = useRef<HTMLDivElement>(null);
  const menuRef8 = useRef<HTMLDivElement>(null);
  const menuRef9 = useRef<HTMLDivElement>(null);
  const menuRef10 = useRef<HTMLDivElement>(null);
  const temp = useRef<HTMLDivElement>(null);

  const divRef = useRef<HTMLDivElement>(null);
  const divRef2 = useRef<HTMLDivElement>(null);
  const divRef3 = useRef<HTMLDivElement>(null);
  const divRef4 = useRef<HTMLDivElement>(null);
  const divRef5 = useRef<HTMLDivElement>(null);
  const divRef6 = useRef<HTMLDivElement>(null);
  const divRef7 = useRef<HTMLDivElement>(null);
  const divRef8 = useRef<HTMLDivElement>(null);
  const divRef9 = useRef<HTMLDivElement>(null);
  const divRef10 = useRef<HTMLDivElement>(null);

  return (
    <div
      style={{
        height: mypage ? (search ? '100%' : '100%') : `${window.innerHeight}px`,
        width: '100%',
        overflow: 'hidden',
        paddingBottom: '50px',
        backgroundColor: '#fff',
      }}
    >
      {savePopUp && <SaveReportPopUp setSavePopUp={setSavePopUp} />}
      {(location.pathname.slice(-1) === '/'
        ? location.pathname.slice(0, -1)
        : location.pathname) === '/mypage/point' ? (
        <TopNav2
          browser
          text='내농장 진단보고서'
          closeBack
          close
          setClose={setClose}
          report
          mypage
        />
      ) : (location.pathname.slice(-1) === '/'
          ? location.pathname.slice(0, -1)
          : location.pathname) === '/mypage/map' ? (
        ''
      ) : (
        <TopNav2
          search
          browser
          text='내농장 진단보고서'
          closeBack
          close
          setClose={setClose}
        />
      )}
      {search && (
        <div className='flex-row space-between padding-12-16 center-y'>
          <img
            src='/asset/images/arrow/arrowBack.svg'
            alt='arrow'
            className='pointer'
            onClick={() => {
              setCloseSearchPointDetail(false);
            }}
          />
          <div className='m-b1-16-b color-dark-primary'>내농장 진단보고서</div>
          <img
            src='/asset/images/close/CloseBlack24.svg'
            className='pointer'
            alt='close'
            onClick={() => {
              setCloseSearchPointDetail(false);
              setOnPointHistory(false);
            }}
          />
        </div>
      )}
      <div
        ref={temp}
        className='of-y-auto'
        style={{
          backgroundColor: '#FFFFFF',
          height: '100%',
          overflowX: 'hidden',
        }}
      >
        <div ref={divRef}>
          <ReportInfo
            mypage={mypage && true}
            data={report_desc}
            data2={competitivenessResult}
            time={createdTime}
          />

          <ReportNav
            refList={{
              conRef,
              menuRef1,
              menuRef2,
              menuRef3,
              menuRef4,
              menuRef5,
              menuRef6,
              menuRef7,
              menuRef8,
              menuRef9,
              menuRef10,
              temp,
            }}
            tab6={investmentAnalysis.investRate}
            tab7={loanAnalysis.loanList && loanAnalysis.loanList.length > 0}
          />
          <div className='flex-column' ref={conRef}>
            <div
              style={{ height: '16px', background: '#F7F8F9' }}
              ref={menuRef1}
            />
            <Summary1 data={competitivenessResult} data2={profitAnalysis} />
          </div>
          <div
            style={{ height: '16px', background: '#F7F8F9' }}
            ref={menuRef2}
          />
          <div ref={divRef2}>
            <Summary2
              mypage={mypage && true}
              data={competitivenessResult}
              data2={avgCompetitivenessResult}
              data3={report_desc}
              data4={profitAnalysis}
            />
          </div>
          <div
            style={{ height: '16px', background: '#F7F8F9' }}
            ref={menuRef3}
          />
          <div ref={divRef3}>
            <Summary3 data={competitivenessResult} data2={profitAnalysis} />
          </div>
          <div
            style={{ height: '16px', background: '#F7F8F9' }}
            ref={menuRef4}
          ></div>
          <div ref={divRef4}>
            <ReportDetail1
              mypage={mypage && true}
              data={farmValue}
              data2={report_desc}
            />
          </div>
          <div
            style={{ height: '16px', background: '#F7F8F9' }}
            ref={menuRef5}
          />
          <div ref={divRef5}>
            <ReportDetail2
              curFarmDate={curFarmDate}
              prevFarmDate={prevFarmDate}
              prevHarvestDate={prevHarvestDate}
              setcurFarmDate={setcurFarmDate}
              setPrevFarmDate={setPrevFarmDate}
              setPrevHarvestDate={setPrevHarvestDate}
              mypage={mypage}
              facility={facility}
              type={type}
              data={cropAnalysis}
              setFacility={setFacility}
              rIdx={param.reportIdx}
              setType={setType}
              reportSales={reportSales}
              reportAiFarm={reportAiFarm}
            />
          </div>
          <div
            style={{ height: '16px', background: '#F7F8F9' }}
            ref={menuRef6}
          />
          <div ref={divRef6}>
            <ReportDetail3
              mypage={mypage}
              data={salesAnalysis}
              data2={report_desc}
            />
          </div>
          <div
            style={{ height: '16px', background: '#F7F8F9' }}
            ref={menuRef7}
          />
          <div ref={divRef7}>
            <ReportDetail4 data={costAnalysis} />
          </div>
          <div
            style={{ height: '16px', background: '#F7F8F9' }}
            ref={menuRef8}
          />
          <div ref={divRef8}>
            <ReportDetail5
              data={profitAnalysis}
              data2={avgCompetitivenessResult}
            />
          </div>
          <div
            style={{ height: '16px', background: '#F7F8F9' }}
            ref={menuRef9}
          />
          <div ref={divRef9}>
            <ReportDetail6 data={investmentAnalysis} />
          </div>
          <div
            style={{ height: '16px', background: '#F7F8F9' }}
            ref={menuRef10}
          />
          {loanAnalysis.loanList && loanAnalysis.loanList.length > 0 ? (
            <div ref={divRef10}>
              <ReportDetail7 data={loanAnalysis} />
            </div>
          ) : (
            ''
          )}

          {!mypage && <ReportDetail10 originData={origin} mypage={mypage} />}
        </div>
        {!mypage && (
          <div className='w-100per flex-center padding-b-16'>
            <SaveBtn
              onClick={() => {
                reportAiFarm(false, 'Y');
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FarmingReportMain;
