import './Mobile.css';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utilities/auth';
import SignController from '../../controller/sign/SignController';
import { SetPopUpOn } from '../../store/onOffSlice';
import UserService from '../../service/checkAccount/CheckAccount';
import { clickAnnuityMenu } from '../../hackler';
import MypageSerivce from '../../service/mypage/mypage';

const Sidemenu = ({ setOpen, open }: any) => {
  const tab1 = useRef<HTMLDivElement>(null);
  const tab2 = useRef<HTMLDivElement>(null);
  const [tabWidth, setTabWidth] = useState(0);
  const [left, setLeft] = useState(0);
  const [menu, setMenu] = useState(0);
  const [point, setPoint] = useState(0);
  const dispatch = useDispatch();
  const [dropDown, setDropDown] = useState(false);
  const { onLogout, isLogin } = useAuth();
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState('');
  const [lastNotice, setLastNotice] = useState('');
  const [lastNoticeNumber, setLastNoticeNumber] = useState<number>();

  const GetNoticeList = async (page: number) => {
    try {
      const limit = 10;
      const board = 'A';
      const offset = 0;
      const result = await MypageSerivce.noticeList(board, offset, limit);
      setLastNotice(result.data.rows[0].title);
      setLastNoticeNumber(result.data.rows[0].articleIdx);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    GetNoticeList(1);
  }, []);

  const CheckPoint = async () => {
    try {
      const result = await UserService.checkPoint();
      setEmail(result.data.data.email);
      setPoint(result.data.data.freePoint + result.data.data.paidPoint);
    } catch (error) {
      console.log(error);
    }
  };
  const state = useSelector((state: any) => {
    return state.user2;
  });
  useEffect(() => {
    if (menu === 0) {
      setTabWidth(tab1.current?.offsetWidth || 0);
      setLeft(tab1.current?.offsetLeft || 0);
    } else if (menu === 1) {
      setTabWidth(tab2.current?.offsetWidth || 0);
      setLeft(tab2.current?.offsetLeft || 0);
    }
  }, [menu]);
  const navigate = useNavigate();
  const Logout = () => {
    onLogout();
    setOpen(false);
  };
  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', resizeListener);
  });

  const LogoutApi = () => SignController.LogoutApi(onLogout);
  const Column = ({ menu, src, url, tab }: any) => {
    return (
      <div className='flex-column'>
        <div
          className='flex-row gap-16 center-y padding-y-8 pointer'
          onClick={() => {
            sessionStorage.setItem('tab', tab);
            (tab === 'price' ||
              tab === 'annuity' ||
              tab === 'findland' ||
              tab === 'aifarm' ||
              tab === 'house') &&
              dispatch(SetPopUpOn(true));
            setOpen(false);
            navigate(url);
            if (tab === 'annuity') {
              clickAnnuityMenu();
            }
          }}
        >
          <img src={src} alt='' />
          <div className='font-medium f-size-16 line-h-24 color-dark-primary'>
            {menu}
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    // 모달이 열릴 때 스크롤 막기
    if (open) {
      document.body.style.overflow = 'hidden';
    }

    // 모달이 닫힐 때 스크롤 해제
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);
  useEffect(() => {
    CheckPoint();
    setMenu(0);
  }, []);
  return (
    <div
      className={
        open
          ? ' w-100per h-100per fixed sidemenu-open z-999 bg-66666690'
          : ' w-100per h-100per fixed sidemenu'
      }
      style={
        open
          ? {
              display: 'flex',
              // transform: `translate(-16px)`,
              left: '0',

              top: '0px',
              overflow: 'hidden',
            }
          : {
              display: 'flex',
              transform: `translate(${innerWidth}px)`,
              zIndex: 999,
              top: '0px',
            }
      }
    >
      <div
        // className={open && ' h-100per bg-66666690'}
        style={{ display: 'flex', width: `${window.innerWidth}px` }}
      >
        <div
          className='fixed  flex-column'
          style={{
            display: 'flex',
            height: '100%',
            width: '92%',
            right: '0px',
            top: 0,
            justifyContent: 'end',
            alignItems: 'end',
          }}
        >
          <div className='flex-column w-100per bg-FFFFFF h-100per  max-w-328 of-scroll'>
            <div className='flex-row space-between center-y padding-24-24-16-24'>
              <div className='flex-row gap-8 center-y'>
                <img
                  src='/asset/images/close/close_hamburger.svg'
                  alt=''
                  className='w-24 h-24'
                  onClick={() => {
                    setOpen(false);
                  }}
                />
                {isLogin() && (
                  <img
                    src='/asset/images/icon/logout.svg'
                    alt=''
                    onClick={() => {
                      Logout();
                    }}
                  />
                )}
              </div>
              <img
                src='/asset/images/icon/home.svg'
                alt=''
                onClick={() => {
                  setOpen(false);
                  navigate('/');
                }}
              />
            </div>
            <div className='padding-24'>
              <div
                className='bg-blue-gray-800 padding-10-16 w-280 border-radius-8 flex-row space-between'
                onClick={() => {
                  navigate(
                    '/promotion/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8-%EB%A7%9E%EC%B6%A4-%ED%86%A0%EC%A7%80-%EC%BB%A8%EC%84%A4%ED%8C%85?utm_source=TF&utm_medium=banner&utm_campaign=consulting_banner&utm_id=promotion&utm_content=consulting_banner'
                  );
                }}
              >
                <div className='flex-row gap-8'>
                  <div className='flex-center bg-primary-400 border-radius-2 '>
                    <div
                      className='m-c1-12-b'
                      style={{ color: '#2A3746', padding: '3px 6px' }}
                    >
                      맞춤 컨설팅
                    </div>
                  </div>
                  <div className='m-b2-14-m color-FFFFFF'>
                    토지 매매, 이제{' '}
                    <span style={{ color: '#09CE7D' }}>안심</span>하세요
                  </div>
                </div>
                <img src='/asset/images/arrow/ArrowRightWhite20.svg' />
              </div>
            </div>
            {isLogin() ? (
              <div className='flex-column gap-24 padding-24'>
                <div className='flex-row gap-6'>
                  <img
                    alt='userImg'
                    src='/asset/images/userimage/defaultUser.svg'
                    className='w-40 h-40'
                  />
                  <div
                    className='flex-column pointer'
                    onClick={() => {
                      navigate('/mypage');
                    }}
                  >
                    <div className='font-medium f-size-18 line-h-26 color-dark-primary'>
                      <span className='color-primary-300'>
                        {localStorage.getItem('username')}님,
                      </span>{' '}
                      반갑습니다!
                    </div>
                    <div className='font-medium f-size-14 line-h-20 color-dark-secondary'>
                      {email}
                    </div>
                  </div>
                </div>
                <div className='mypoint-box'>
                  <div className='mypoint-box-left'>
                    <span>총 포인트</span>
                    <span className='total-point'>
                      {point.toLocaleString()} P
                    </span>
                  </div>
                  <div
                    className='border-radius-8 padding-8-14 gap-2 bg-FFFFFF flex-row pointer'
                    onClick={() => {
                      navigate('/point/recharge');
                      sessionStorage.setItem('searchPointRecharge', 'N');
                    }}
                  >
                    <div className='m-b2-14-b color-primary-300'>충전소</div>
                    <img
                      src='/asset/images/icon/Green_flower.svg'
                      alt='flower'
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className='flex-column gap-24 padding-24'>
                <div className='font-medium f-size-18 line-h-26 color-dark-primary'>
                  똑똑한 농촌 생활 <br />{' '}
                  <span className='color-primary-300'>트랜스파머</span>와 함께
                  하세요!{' '}
                </div>
                <div className='flex-row gap-16 w-100per'>
                  <div
                    className='flex-center  border-radius-999 padding-6-4 w-50per border-171E26'
                    onClick={() => {
                      navigate('/login');
                    }}
                  >
                    <div className='font-medium f-size-14 line-h-20 color-dark-primary'>
                      로그인
                    </div>
                  </div>
                  <div
                    className=' flex-center bg-primary-300 border-radius-999 padding-6-4 w-50per'
                    onClick={() => {
                      navigate('/signup');
                    }}
                  >
                    <div className='    font-bold f-size-14 line-h-20 color-FFFFFF'>
                      회원가입
                    </div>
                  </div>
                </div>
              </div>
            )}
            {lastNotice ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '12px 24px',
                  fontFamily: 'Pretendard-Medium',
                  fontSize: '14px',
                  backgroundColor: '#F7F8F9',
                }}
                onClick={() => {
                  navigate(`/noticeDetail/${lastNoticeNumber}`);
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  <img src='/asset/images/icon/megaphone.svg' alt='megaphone' />
                  <span
                    style={{
                      width: '222px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {lastNotice}
                  </span>
                </div>
                <img
                  src='/asset/images/arrow/ArrowGrayRight20.svg'
                  alt='ArrowGrayRight20'
                />
              </div>
            ) : (
              ''
            )}

            <div className='flex-column '>
              <div className='flex-row border-b-171E26 padding-x-24 relative'>
                <div
                  ref={tab1}
                  className='w-50per flex-center padding-y-8'
                  onClick={() => {
                    setMenu(0);
                  }}
                >
                  <div
                    className={` ${
                      menu === 0
                        ? `m-b1-16-b color-dark-primary `
                        : ` m-b1-16-m color-text-171E2656`
                    }`}
                  >
                    서비스
                  </div>
                </div>
                <div
                  ref={tab2}
                  className='w-50per flex-center padding-y-8'
                  onClick={() => {
                    isLogin() ? setMenu(1) : alert('로그인이 필요합니다.');
                  }}
                >
                  <div
                    className={` ${
                      menu === 1
                        ? `m-b1-16-b color-dark-primary `
                        : ` m-b1-16-m color-text-171E2656`
                    }`}
                  >
                    MY
                  </div>
                </div>
                <div
                  className='slider-black2'
                  style={{ width: tabWidth, left: left }}
                />
              </div>
              {menu === 0 ? (
                <div
                  className='flex-column padding-24'
                  style={{ borderBottom: '8px solid #F7F8F9' }}
                >
                  <Column
                    menu='가격 분석'
                    src='/asset/images/icon/price_ham.svg'
                    url='/price/map'
                    tab='price'
                  />
                  <Column
                    menu='매물 분석'
                    src='/asset/images/icon/property_ham.svg'
                    url='/auction/map'
                    tab='auction'
                  />
                  <Column
                    menu='농지연금 분석'
                    src='/asset/images/icon/pension_ham.svg'
                    url='/annuity/map'
                    tab='annuity'
                  />
                  <Column
                    menu='농지 찾기'
                    src='/asset/images/icon/findland_ham.svg'
                    url='/findland/map'
                    tab='findland'
                  />
                  <Column
                    menu='내 농장 진단'
                    src='/asset/images/icon/myfarm_ham.svg'
                    url='/aiFarming/map'
                    tab='aifarm'
                  />

                  <Column
                    menu='내 집 짓기'
                    src='/asset/images/icon/house_ham.svg'
                    url='/house/map'
                    tab='house'
                  />
                  <Column
                    menu='숨은 정책 지원금'
                    url='/hidden/policy'
                    tab='policy'
                    src='/asset/images/icon/policy_ham.svg'
                  />
                </div>
              ) : (
                <div
                  className='flex-column padding-24'
                  style={{ borderBottom: '8px solid #F7F8F9' }}
                >
                  <Column
                    menu='포인트 내역'
                    url='/mypage/point'
                    tab='point'
                    src='/asset/images/icon/Green_flower.svg'
                  />
                </div>
              )}
            </div>
            <div className='flex-column gap-48 padding-24-24-48-24'>
              <div className='flex-column gap-16'>
                <div className='flex-column relative'>
                  <div className='font-medium f-size-12 line-h-14 color-text-tertiary'>
                    회사 정보
                  </div>
                  <div
                    className='padding-y-8'
                    onClick={() => {
                      navigate('/profile');
                    }}
                  >
                    <div className='font-medium f-size-16 line-h-24 color-dark-primary'>
                      회사소개
                    </div>
                  </div>
                  <div
                    className='padding-y-8'
                    onClick={() => {
                      navigate('/newsroom');
                      // alert('준비중입니다.');
                    }}
                  >
                    <div className='font-medium f-size-16 line-h-24 color-dark-primary'>
                      뉴스룸
                    </div>
                  </div>
                  <div
                    className='absolute w-10 h-10 flex-center bg-increase border-radius-999'
                    style={{ top: '60px', left: `45px` }}
                  >
                    <div className='font-bold f-size-8 color-FFFFFF'>N</div>
                  </div>
                </div>
                <div className='flex-column gap-12'>
                  <div className='m-c1-12-m color-text-tertiary'>고객 지원</div>
                  <div
                    className='m-b1-16-m color-dark-primary'
                    onClick={() => {
                      navigate('/inquiry/write');
                    }}
                  >
                    고객 문의
                  </div>
                  <div
                    className='m-b1-16-m color-dark-primary'
                    onClick={() => {
                      window.open(
                        'https://transfarmer.notion.site/20eba2a06fd246d1a1fe45b2e9d9d835?pvs=4'
                      );
                    }}
                  >
                    자주 묻는 질문
                  </div>
                </div>
                <div className='flex-column'>
                  <div className='font-medium f-size-12 line-h-14 color-text-tertiary'>
                    이용 관련
                  </div>
                  <div className='flex-column'>
                    <div className='padding-y-8'>
                      <div
                        className='font-medium f-size-16 line-h-24 color-dark-primary'
                        onClick={() => {
                          navigate('/terms/service');
                        }}
                      >
                        이용약관
                      </div>
                    </div>
                    <div className='padding-y-8'>
                      <div
                        className='font-medium f-size-16 line-h-24 color-dark-primary'
                        onClick={() => {
                          navigate('/terms/privacy_policy');
                        }}
                      >
                        개인정보처리방침
                      </div>
                    </div>
                    <div className='padding-y-8'>
                      <div
                        className='font-medium f-size-16 line-h-24 color-dark-primary'
                        onClick={() => {
                          navigate('/terms/location');
                        }}
                      >
                        위치기반서비스 이용약관
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex-row gap-8'>
                <img
                  alt='facebook'
                  src='/asset/images/icon/facebook.svg'
                  className='w-18 h-18'
                  onClick={() => {
                    window.open(
                      'https://ko-kr.facebook.com/people/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8/61554542764224/'
                    );
                  }}
                />
                <img
                  alt='blog'
                  src='/asset/images/icon/blog.svg'
                  className='w-24 h-24'
                  onClick={() => {
                    window.open('https://blog.naver.com/transfarmer2023');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidemenu;
