import { useEffect, useState } from 'react';
import { RowSectionTitle } from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import UserService from '../../../../service/checkAccount/CheckAccount';
import { useSelector } from 'react-redux';
import LandPriceCom from '../../../../component/report/LandPirce';
import { ButtonSelectorBox } from '../../../../component/report/Button';
import SignController from '../../../../controller/sign/SignController';

interface data {
  estimatedPrice: number;
  height: string;
  landTradeDate: null | string;
  landTradePrice: null | number;
  officialPriceList: { 2023: number } | any;
  perMeterEstimatedPrice: number;
  perPyeongEstimatedPrice: number;
  roadSurface: string;
  shape: string;
  useStatus: string;
  difference?: number;
  surroundings: Array<any>;
}

const ReportDetail1 = ({
  data,
  data2,
  mypage,
}: {
  data: data | any;
  data2: any;
  mypage?: any;
}) => {
  const state = useSelector((state: any) => {
    return state.type;
  });
  const [name, setName] = useState('');

  useEffect(() => {
    SignController.getName(setName);
  }, []);
  const [onToolTip, setOnToolTip] = useState(false);
  const [tab, setTab] = useState(1);
  const [tab2, setTab2] = useState(1);
  let arr = [];
  let arr2 = [];
  for (let key in data.officialPriceList) {
    arr.push([
      key,
      `${data.officialPriceList[key as '2023'].toLocaleString()}원`,
    ]);
    arr2.push({
      key: key,
      price: data.officialPriceList[key as '2023'],
    });
  }

  arr = arr.sort((a, b) => Number(b[0]) - Number(a[0]));

  const table = {
    th: ['기준연도', '개별공시지가'],
    tb: arr,
  };

  const btnArr = [
    { children: '추정가', selected: 1 },
    '',
    { children: '공시지가 추이', selected: 2 },
  ];

  return (
    <div className='padding-b-24'>
      <RowSectionTitle>
        <TextSectionTitle>1. 농지가치</TextSectionTitle>
      </RowSectionTitle>

      <ButtonSelectorBox tab={tab} setTab={setTab} btnArr={btnArr} />

      {tab === 1 ? (
        <LandPriceCom.LandPrice1
          data={data}
          name={name}
          setTab2={setTab2}
          tab2={tab2}
          data2={data2}
          onToolTip={onToolTip}
          setOnToolTip={setOnToolTip}
          mypage={mypage && true}
        />
      ) : (
        <LandPriceCom.LandPrice2
          arr2={arr2}
          table={table}
          data={data}
          mypage={mypage && true}
        />
      )}
    </div>
  );
};

export default ReportDetail1;
