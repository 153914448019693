import BoardService from "../../service/board/Board";
import { useNavigate, useLocation, useParams } from "react-router-dom";

export const getComment = async (commentIdx: any, setComment: any) => {
  try {
    const result = await BoardService.GetOneComment(commentIdx);
    setComment(result);
  } catch (error) {
    console.log(error);
  }
};

export const ModifyComment = async (commentIdx: any, newcomment: string) => {
  const data = {
    content: newcomment,
  };

  const navigate = useNavigate();

  try {
    const result = await BoardService.ModifyComment(commentIdx, data);
    if (result.success) {
      navigate(-1);
    }
  } catch (error) {
    console.log(error);
  }
};
