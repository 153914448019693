export const ReportTitle = ({ address, category }: any) => (
  <div className='flex-row padding-24 border-b-171E26 w-100per space-between end-y'>
    <div className='w-33per'></div>
    <div className='w-33per m-b1-16-m text-center no-wrap'>{address}</div>
    <div className='w-33per m-c1-12-m text-end'>{category}</div>
  </div>
);

export const Footer = ({ number }: any) => (
  <div className='w-100per flex-row space-between padding-x-24'>
    <div className='flex-row end-y'>
      <img src='/asset/images/logo/pdf_logo.png' />
      <div className='m-c2-11-m'>
        © 2024 Transfarmer Corp., All rights reserved. Printed in Korea
      </div>
    </div>
    <div className='m-c2-11-m text-center center-y center-x'>{number} Page</div>
  </div>
);

export const Content1 = ({ content }: any) => (
  <div className='flex-column gap-12'>
    {content.map((item: any, index: number) => {
      return <div className='m-b1-16-m '>• {item}</div>;
    })}
  </div>
);
