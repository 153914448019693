import { useEffect, useState } from 'react';
import PensionService from '../../../service/pension/pensionApi';
import { RowTable2, RowTable4 } from '../../../component/report/Row';
import { TriangleTooltip } from '../../../component/common/tooltip';
import { isMobile } from '../../../component/function/function';

const ReportInfo = ({
  data,
  reportIdx,
  report,
  tmpAge,
  setTmpAge,
  saveReport,
  mypage,
}: any) => {
  const [isSignUpPossible, setIsSignUpPossible] = useState('');
  const [showTooltip, setShowTooltip] = useState(true);
  const [dateTooltip, setDateTooltip] = useState(true);
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    setNow(new Date());
  }, []);

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    setTmpAge(data.tmpAge);

    setIsSignUpPossible(calculateSignUpPossibility(data.possibilityOfJoining));
    if (data.tmpAge >= 60) {
      setShowTooltip(false);
    }
  }, [data]);

  // 나이 계산
  const calculateAge = (birthdate: string) => {
    var currentDate = new Date();
    var birthdateDate = new Date(birthdate);
    var age = currentDate.getFullYear() - birthdateDate.getFullYear();

    if (
      currentDate.getMonth() < birthdateDate.getMonth() ||
      (currentDate.getMonth() === birthdateDate.getMonth() &&
        currentDate.getDate() < birthdateDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  // 연금 가입 가능성 계산
  const calculateSignUpPossibility = (possibilityOfJoining: any) => {
    if (!possibilityOfJoining) {
      return '-';
    }

    const num =
      8 -
      [
        possibilityOfJoining.isFarm === 'Y',
        possibilityOfJoining.isHave2 === 'Y',
        possibilityOfJoining.isNear === 'Y' ||
          possibilityOfJoining.isNear2 === 'Y',
        possibilityOfJoining.isRight === 'N',
        possibilityOfJoining.isIllegal === 'N',
        possibilityOfJoining.isShare === 'N',
        possibilityOfJoining.career >= 5,
        calculateAge(possibilityOfJoining.age) >= 60,
      ].filter((el) => el).length;

    return num >= 3 ? '매우 낮음' : num >= 1 ? '낮음' : '높음';
  };

  // 연금 가입  미충족 항목 계산
  const calculateSignUpCondition = (
    possibilityOfJoining: any,
    age: string,
    career: number
  ) => {
    if (!possibilityOfJoining) {
      return '-';
    }

    return [
      calculateAge(age) >= 60 ? '' : '가입연령',
      career >= 5 ? '' : '영농경력',
      possibilityOfJoining.isFarm === 'Y' &&
      possibilityOfJoining.isHave2 === 'Y' &&
      (possibilityOfJoining.isNear === 'Y' ||
        possibilityOfJoining.isNear2 === 'Y')
        ? ''
        : '농지조건',
      possibilityOfJoining.isRight === 'N' &&
      possibilityOfJoining.isIllegal === 'N' &&
      possibilityOfJoining.isShare === 'N'
        ? ''
        : '가입제한',
    ]
      .filter((el) => el.length)
      .join(', ');
  };

  //예상 가입 연령 입력후  하단  리포트 호출
  const patchTmpAge = async () => {
    try {
      if (Number(tmpAge) >= 60) {
        await PensionService.patchTmpAge(Number(tmpAge), reportIdx);
        await report();
      } else {
        setShowTooltip(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const boxItem = calculateSignUpCondition(
    data.possibilityOfJoining,
    data.age,
    data.career
  );

  return (
    <div className={`${`padding-24-16 gap-16 flex-column relative z-100`}`}>
      <div className={`${`flex-column gap-16`}`}>
        <RowTable4 title='분석일' value={formatDate(now)} />
        <RowTable4 title='주소' value={data.address} />
      </div>
      <div className={`${`flex-column gap-16`}`}>
        <div>
          <RowTable4
            title='가입가능성'
            value={isSignUpPossible}
            arrow={isSignUpPossible !== '높음'}
            boxTitle='미충족 항목'
            boxItem={boxItem}
          />
        </div>
        <div>
          {calculateAge(data.age) >= 60 ? (
            <div className='w -100per flex-row gap-24'>
              <RowTable4
                title='가입연령'
                value={`만 ${calculateAge(data.age)}세`}
              />
              <RowTable4
                title={data.career >= 5 ? '영농경력' : '예상 영농경력'}
                value={data.career >= 5 ? `${data.career}년` : '5년 이상'}
              />
            </div>
          ) : (
            <>
              <RowTable4
                title={data.career >= 5 ? '영농경력' : '예상 영농경력'}
                value={data.career >= 5 ? `${data.career}년` : '5년 이상'}
              />
              <RowTable2
                title='예상 가입연령 (만 나이)'
                value={
                  <div
                    className='flex-row border-radius-8 border-171E26 padding-6-8 gap-4 '
                    style={{ justifyContent: 'end' }}
                  >
                    <input
                      className='border-none text-end font-medium f-size-14 line-h-24 color-text-primary w-100per'
                      placeholder='입력해주세요'
                      value={tmpAge}
                      type='number'
                      onChange={(e: any) => {
                        const inputValue = e.target.value;
                        if (inputValue >= 0 || inputValue === '') {
                          setTmpAge(inputValue);
                        }
                      }}
                      onBlur={() => {
                        if (tmpAge > 60) {
                          saveReport('N');
                        }
                      }}
                    />
                    <div className='font-medium f-size-14 line-h-24 color-text-primary'>
                      세
                    </div>
                  </div>
                }
              />
              {!mypage && (
                <TriangleTooltip
                  text='예상되는 가입나이를 기재하고 연금액을 가늠해보세요!'
                  text2='단, 만 60세 이상부터 가입할 수 있어요!'
                  top='-20'
                  right='0'
                  on={showTooltip}
                  off={false}
                  xImg={false}
                />
              )}
            </>
          )}
        </div>
      </div>
      {mypage && dateTooltip && (
        <TriangleTooltip
          text='분석 시점의 결과는 현재와 다를 수 있어요.'
          text2='최신 분석 결과는 주소지 조회 후 다시 이용해 주세요.'
          top='30'
          left='50'
          on={true}
          off={() => setDateTooltip(false)}
          xImg={true}
        />
      )}
      {/* {!(data.tmpAge >= 60) && data.age >= 60 ? (
        <div
          className='bg-blue-gray-700 border-radius-8 padding-6-8 speech-bubble9 flex-center absolute'
          style={{
            bottom: '-10px',
            right: '16px',
          }}
        >
          <div className='font-medium f-size-12 line-h-14 color-white'>
            예상되는 가입나이를 기재하고 연금액을 가늠해보세요! <br />
            단, 만 60세 이상부터 가입할 수 있어요!
          </div>
        </div>
      ) : (
        ''
      )} */}
    </div>
  );
};

export default ReportInfo;
