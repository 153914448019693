export const GuideData = [
  {
    path: '/price/map',
    titleText: '이 토지/주택,',
    subtitleText: '얼마나 될까?',
    buttonText: '가격 분석 가이드',
    pcImageSrc: '/asset/images/banner/price_banner_web.svg',
    mobileImageSrc: '/asset/images/banner/price_banner_mo.svg',
    promotionPath:
      '/promotion/%EC%A0%95%ED%99%95%ED%95%9C-%ED%86%A0%EC%A7%80-%EB%86%8D%EC%A7%80-%EC%A3%BC%ED%83%9D-%EA%B0%80%EA%B2%A9%EC%9D%84-%EC%95%8C%EC%95%84%EB%B3%B4%EB%8A%94-%EB%B0%A9%EB%B2%95',
  },
  {
    path: '/annuity/map',
    titleText: '내 농지연금,',
    subtitleText: '얼마나 받을 수 있을까?',
    buttonText: '농지연금 분석 가이드',
    pcImageSrc: '/asset/images/banner/pension_banner_web.svg',
    mobileImageSrc: '/asset/images/banner/pension_banner_mo.svg',
    promotionPath:
      '/promotion/%EB%86%8D%EC%A7%80_%EA%B0%80%EA%B2%A9_%ED%99%95%EC%9D%B8%EB%B6%80%ED%84%B0_%EB%86%8D%EC%A7%80%EC%97%B0%EA%B8%88_%EA%B3%84%ED%9A%8D%EA%B9%8C%EC%A7%80_%ED%95%9C%EB%B2%88%EC%97%90',
  },
  {
    path: '/findland/map',
    titleText: '이 농지, 가격과',
    subtitleText: '농사수익은 얼마일까?',
    buttonText: '농지 찾기 가이드',
    pcImageSrc: '/asset/images/banner/findland_banner_web.svg',
    mobileImageSrc: '/asset/images/banner/findland_banner_mo.svg',
    promotionPath:
      '/promotion/%EB%86%8D%EC%82%AC-%EC%88%98%EC%9D%B5%EA%B3%BC-%EB%B9%84%EC%9A%A9%EB%B6%84%EC%84%9D-AI%EB%A1%9C-%EB%86%8D%EC%97%85-%EA%B2%BD%EC%98%81-%EA%B3%84%ED%9A%8D-%EC%84%B8%EC%9A%B0%EA%B8%B0',
  },
  {
    path: '/aiFarming/map',
    titleText: '내 농장 매출/운영비',
    subtitleText: '높을까, 낮을까?',
    buttonText: '내 농장 진단 가이드',
    pcImageSrc: '/asset/images/banner/aiFarming_banner_web.svg',
    mobileImageSrc: '/asset/images/banner/aiFarming_banner_mo.svg',
    promotionPath:
      '/promotion/AI%EB%A1%9C-%EB%86%8D%EC%9E%A5-%EC%A7%84%EB%8B%A8-%EB%86%8D%EC%97%85-%EA%B2%BD%EC%98%81-%EC%86%94%EB%A3%A8%EC%85%98',
  },
  {
    path: '/house/map',
    titleText: '나의 첫 전원주택',
    subtitleText: '예상 건축비 얼마나 될까?',
    buttonText: '내 집 짓기 가이드',
    pcImageSrc: '/asset/images/banner/house_banner_web.svg',
    mobileImageSrc: '/asset/images/banner/house_banner_mo.svg',
    promotionPath:
      '/promotion/%EC%A0%84%EC%9B%90%EC%A3%BC%ED%83%9D-%EC%84%A4%EA%B3%84%EC%99%80-%EA%B1%B4%EC%B6%95%EB%B9%84-%EB%B6%84%EC%84%9D%EC%9D%84-AI%EB%A1%9C-%EA%B0%84%ED%8E%B8%ED%95%98%EA%B2%8C',
  },
];
