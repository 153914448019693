import { createSlice } from "@reduxjs/toolkit";

const initialLoadingState = {
  loading: "",

};

const loadingSlice = createSlice({
  name: "loading",
  initialState: initialLoadingState,
  reducers: {
    LoadingDot(state, action) {
      state.loading = action.payload;
    }
  },
});

export const { LoadingDot } =
  loadingSlice.actions;
export default loadingSlice.reducer;
