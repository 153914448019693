import { Link } from 'react-router-dom';

export const Refund = () => {
  return (
    <div className='flex-column flex-center'>
      <div className='flex-column padding-40-16 flex-center gap-32'>
        <div className='flex-column gap-24 flex-center'>
          <div className='padding-8-16 bg-primary-500 border-radius-20'>
            <div className='m-b2-14-m color-FFFFFF'>환불 안내</div>
          </div>
          <div className='m-t2-18-b text-center'>
            서비스 이용을 위해 충전하신 금액에 대한 <br />
            환불은 회원님이 동의한 트랜스파머 이용약관 <br />
            규정에 따라 이루어지고 있습니다.
          </div>
        </div>
        <Link
          to={'/terms/service'}
          className='flex-row bg-F7F8F9 space-between padding-16 center-y w-100per border-radius-8 pointer decoration-none'
        >
          <div className='m-b2-14-m color-text-tertiary'>이용약관 확인하기</div>
          <img src='/asset/images/arrow/ArrowGrayRight20.svg' />
        </Link>
        <div className='flex-column padding-24-16 border-radius-16 border-171E26 flex-center gap-16 w-100per'>
          <div className='m-b1-16-b color-dark-secondary '>환불 단위</div>
          <div className='bg-F7F8F9 flex-center padding-16 border-radius-16 w-100per'>
            <div className='m-b1-16-m color-dark-primary'>
              유료상품 구매로 충전한 금액 단위
            </div>
          </div>
        </div>
      </div>
      <div className='flex-column padding-40-16 flex-center gap-32 w-100per'>
        <div className='flex-column gap-24 flex-center w-100per'>
          <div className='padding-8-16 bg-primary-500 border-radius-20'>
            <div className='m-b2-14-m color-FFFFFF'>환불 기준</div>
          </div>
          <div className='m-b1-16-b color-dark-primary text-center'>
            마지막 충전 후 주소지 조회, 분석 서비스 이용 <br />
            이력이 <span style={{ color: '#1e9b6b' }}>없는</span> 경우
          </div>
          <div className='bg-F7F8F9 flex-center padding-16 border-radius-8 w-100per'>
            <div className='text-center m-b1-16-m color-dark-primary'>
              {' '}
              1년 내 취소 시 전액 환불,
              <br />
              1년 후 취소 시{' '}
              <span style={{ color: '#d84836' }}>충전금액의 90% 환불</span>{' '}
              <br />
              (수수료 제외)
            </div>
          </div>
        </div>
        <div
          style={{
            height: '1px',
            backgroundColor: '#171E2610',
            width: '100%',
          }}
        ></div>
        <div className='flex-column gap-32 w-100per'>
          <div className='m-b1-16-b color-dark-primary text-center'>
            마지막 충전 후 주소지 조회, 분석 서비스 이용 <br />
            이력이 <span style={{ color: '#1e9b6b' }}>있는</span> 경우
          </div>
          <div className='flex-column w-100per bg-F7F8F9 border-radius-8 padding-16 flex-center w-100per'>
            <div className='m-b1-16-m color-dark-primary text-center'>
              서비스 이용 장애로 인한 유료 충전 포인트 <br />
              차감 시에만 차감된 포인트만큼 추가 충전
            </div>
          </div>
        </div>
      </div>
      <div className='flex-column padding-40-16 flex-center gap-24 w-100per'>
        <div className='padding-8-16 bg-primary-500 border-radius-20'>
          <div className='m-b2-14-m color-FFFFFF'>환불 수단</div>
        </div>
        <div className='flex-column gap-16'>
          <div className='w-100per  flex-column padding-16 bg-F7F8F9 border-radius-8 gap-16'>
            <div className='m-b1-16-m color-dark-primary'>
              <span style={{ color: '#1e9b6b' }}>· 충전 후 1년 이내</span> 취소
              시 결제 수단으로 환불
            </div>
            <div
              style={{
                height: '1px',
                backgroundColor: '#171E2610',
                width: '100%',
              }}
            />
            <div className='m-b1-16-m color-dark-primary'>
              <span style={{ color: '#1e9b6b' }}>· 충전 후 1년 이후</span> 취소
              시 회원 계좌로 환불
            </div>
          </div>
          <div className='m-b2-14-m color-text-tertiary'>
            ※고객센터에서 회원 이메일로 계좌 문의가 있을 수 있습니다.
          </div>
        </div>
      </div>
      <div className='flex-column padding-40-16 flex-center gap-24 w-100per'>
        <div className='padding-8-16 bg-primary-500 border-radius-20'>
          <div className='m-b2-14-m color-FFFFFF'>환불 처리기간</div>
        </div>
        <div className='flex-column gap-32 w-100per'>
          <div className='flex-column gap-16 bg-F7F8F9 border-radius-8 padding-16'>
            <div className='m-b1-16-m color-dark-primary'>
              · 환불 요청을 받은 날로부터 3 영업일 이내
            </div>
            <div
              style={{
                height: '1px',
                backgroundColor: '#171E2610',
                width: '100%',
              }}
            />
            <div className='flex-row gap-8'>
              <div className='m-b1-16-m color-dark-primary'>· </div>
              <div className='m-b1-16-m color-dark-primary'>
                단, 결제사 규정에 따라 영업일 기준 최대 <br /> 14일 소요될 수
                있습니다.
              </div>
            </div>
          </div>
          <div className='flex-column gap-8 bg-F7F8F9 border-radius-8 padding-16'>
            <div className='m-b1-16-b color-text-tertiary'>기타</div>
            <div className='m-b2-14-m color-text-tertiary'>
              · 무료로 지급된 포인트는 환불 대상이 되지 않습니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
