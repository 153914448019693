import axios from "axios";
import { Topnav } from "../../component/mobile/Topnav";
import "../../asset/styles/SaleInfo.css";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StatusHandler, TypeHandler } from "../../component/function/function";
import menuBtn from "./../../asset/images/community/menuBtn.svg";
interface InquiryData {
  content: string;
  createdDate: string;
  email: string;
  file: string | null;
  inquiryIdx: number;
  status: string;
  success: boolean;
  title: string;
  type: string;
}
const InquiryDetail2 = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [inquiryData, setInquiryData] = useState<InquiryData>();

  const getOneInquiry = async () => {
    const formData = new FormData();
    try {
      const result = await axios.post(
        // `http://localhost:3333/inquiry/base/${state.inquiryIdx}`,
        `${process.env.REACT_APP_HOST}/inquiry/base/${state.inquiryIdx}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.accessToken}`,
          },
        }
      );
      setInquiryData(result.data);
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message === "jwt expired") {
        alert("토큰이 만료되었습니다.");
        navigate("/login", { replace: true });
      }
    }
  };

  useEffect(() => {
    getOneInquiry();
  }, []);
  return (
    <div className="flex-column w-100per">
      <Topnav text="내 문의" />

      <div className="flex-column w-100per padding-0-20 padding-t-40">
        <div className="flex-row space-between center-y borderbottom-1px-e8ebe6 padding-20-0">
          <div className="flex-column gap-10">
            <div
              className="flex-center greybox w-100per"
              style={{ width: "60px" }}
            >
              <div className="font-medium f-size-14 padding-10-0">
                {inquiryData && TypeHandler(inquiryData.type)}
              </div>
            </div>
            <div
              className="font-medium f-size-14"
              style={{ display: "flex", width: "90%", wordBreak: "break-all" }}
            >
              {inquiryData && inquiryData.title}
            </div>
            <div className="font-regular f-size-11">
              {inquiryData && inquiryData.createdDate}
            </div>
          </div>
          <div
            className="flex-center"
            style={{
              backgroundColor: "rgba(227, 246, 235, 1)",
              borderRadius: "15px",
              width: "30%",
            }}
          >
            <div
              className="font-medium f-size-14 padding-10-10"
              style={{ color: "rgba(0, 150, 115, 1)" }}
            >
              {inquiryData && StatusHandler(inquiryData.status)}
            </div>
          </div>
        </div>
        <div className="padding-20-0 w-100per">
          <div
            className="font-medium f-size-14"
            style={{ wordBreak: "break-all" }}
          >
            {inquiryData && inquiryData.content}
          </div>
        </div>
      </div>
    </div>
  );
};
export default InquiryDetail2;
