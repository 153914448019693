import MapContainer from './MapContainer';
import Search from '../../../component/mobile/Search';
import AreaSearch from './search/Main';
import SaleRentalPreview2 from './saleRental2/Preview';
import JointDetail from './joint/Detail';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import '../../../asset/styles/SaleInfo.css';
import '../../../Main.css';
import LandService from '../../../service/land/landApi';
import LandController from '../../../controller/land/LandController';
import HousePreview from './house/House';
import HouseService from '../../../service/house/landApi';
import SideSearch from '../../../component/browser/sidebar/Search';
import { isMobile } from '../../../component/function/function';
import HousePreview2 from './house/Preview';
import { useDispatch, useSelector } from 'react-redux';
import { OnToggle } from '../../../store/inquirySlice';
import PopUp from '../../../component/common/PopUp';
import { LoadingDot } from '../../../store/loadingSlice';
import { Helmet } from 'react-helmet';
import session from 'redux-persist/lib/storage/session';
import Auction from './auction/Auction';
import AuctionPreview from './auction/Preview';
import { SetPopUpOn } from '../../../store/onOffSlice';
import loadingImg from '../../../asset/images/spinner/loading.svg';
import { MypageProfile } from '../../mypage/component/Profile';
import MypageSerivce from '../../../service/mypage/mypage';
import TopBanner from '../../../component/banner/TopBanner';
import ReportService from '../../../service/report/reportApi';
import locationNoImg from '../../../asset/images/main/locationNoImg.svg';
import { ca } from 'date-fns/locale';
interface AuctionItem {
  address: string;
  addresses: string;
  appraisedValue: number;
  auctionNumber: string;
  area: number;
  endDate: string;
  estimated: number;
  img: string;
  isFarm: string;
  keyword: string;
  likeCount: number;
  price: number;
  pyeong: number;
  regTime: string;
  roadSurface: string;
  salesIdx: number;
  startDate: string;
  views: number;
  auctionIdx: number;
  bidCount: number;
  bidDate: string;
  category: string;
  lowerPrice: number;
  type: string;
}
const AIFarmingMap = () => {
  const dispatch = useDispatch();

  const [mapClick, setMapClick] = useState(false);
  const [kmTab, setKmTab] = useState(0);
  const locationHook = useLocation();
  const [onInputFocus, setOnInputFocus] = useState(false);
  const [anchor, setAnchor] = useState('');
  const [query, setQuery] = useState('');
  const [isSearch, setIsSearch] = useState(true);
  const [onSearchList, setOnSearchList] = useState(false);
  const topref1 = useRef(null);
  const topref2 = useRef(null);
  const [point, setPoint] = useState(0);
  const [email, setEmail] = useState('');
  const [createdDate, setCreatedDate] = useState('');
  const [onMyLandList, setOnMyLandList] = useState(false);
  const [isFarm, setIsFarm] = useState('');
  const [type, setType] = useState('S'); // 매물분석 타입
  const [selectedItem, setSelectedItem] = useState<AuctionItem | null>(null);
  const [close, setClose] = useState(false);
  const [houseReportIdx, setHouseReportIdx] = useState(0);
  const [isSales, setIsSales] = useState(true);

  useEffect(() => {
    LandController.coordinate(panToAndMarker, drawCircle, query, isSearch);
  }, [kmTab]);

  const navigate = useNavigate();
  const state = useSelector((state: any) => {
    return state.inquiry;
  });
  const state2 = useSelector((state2: any) => {
    return state2.type;
  });
  const state3 = useSelector((state3: any) => {
    return state3.onOff;
  });

  const isOnPricePage =
    (locationHook.pathname.slice(-1) === '/'
      ? locationHook.pathname.slice(0, -1)
      : locationHook.pathname) === '/price/map';

  const isOnAnnuityPage =
    (locationHook.pathname.slice(-1) === '/'
      ? locationHook.pathname.slice(0, -1)
      : locationHook.pathname) === '/annuity/map';
  const isOnFindLandPage =
    (locationHook.pathname.slice(-1) === '/'
      ? locationHook.pathname.slice(0, -1)
      : locationHook.pathname) === '/findland/map';
  const isOnAiFarmingPage =
    (locationHook.pathname.slice(-1) === '/'
      ? locationHook.pathname.slice(0, -1)
      : locationHook.pathname) === '/aiFarming/map';

  const isOnMyHousePage =
    (locationHook.pathname.slice(-1) === '/'
      ? locationHook.pathname.slice(0, -1)
      : locationHook.pathname) === '/house/map';

  const getInfo = async () => {
    try {
      const result = await MypageSerivce.getMyInfo();

      setPoint(result.data.data.freePoint + result.data.data.paidPoint);
      setEmail(result.data.data.email);
      setCreatedDate(result.data.data.createdDate);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!!localStorage.getItem('accessToken')) {
      getInfo();
    }
  }, []);
  useEffect(() => {
    setAnchor(locationHook.hash);
    if (
      locationHook.search.split('=')[1] &&
      !locationHook.search.includes('n_media')
    ) {
      if (
        decodeURI(locationHook.search.split('=')[0]).startsWith('?utm_source')
      ) {
        setQuery('');
      } else {
        setQuery(decodeURI(locationHook.search.split('=')[1]));
      }
    } else {
      setQuery('');
    }
  }, [locationHook]);

  /** 검색 자동완성 리스트 */
  const [searchList, setSearchList] = useState([]);

  /** 함필 리스트 */
  const [areaList, setAreaList]: any = useState([]);
  const [temp, setTemp] = useState(false);
  const [currentMarker, setCurrentMarker]: any = useState();
  const [fixMarker, setFixMarker]: any = useState(); //function
  const [zoomIn, setZoomIn]: any = useState(); //function
  const [zoomIn2, setZoomIn2]: any = useState(true); //function
  const [panTo, setPanTo]: any = useState(); //function
  const [markerArray, setMarkerArray]: any = useState([]);
  const [panToAndMarker, setPanToAndMarker]: any = useState(); //function
  const [drawCircle, setDrawCircle]: any = useState();
  const [onToggle, setOnToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noLand, setNoLand] = useState(false);
  const [landCondition, setLandCondition] = useState(false);
  const [farmCondition, setFarmCondition] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [stickPolygon, setStickPolygon]: any = useState(() => {});
  const protoCurrentInfo = {
    area: 0,
    avg: 0,
    estimatedPrice: 0,
    officialPrice: {},
    perMeterPrice: 0,
    perPyeongPrice: 0,
    pnuCode: '',
    pyeong: 0,
    tranApplyAt: null,
    transaction: null,
    warnning: '',
    zoning: 'ㅇㅇ 지역',
    category: '',
  };
  const [currentInfo, setCurrentInfo] = useState(protoCurrentInfo);

  const checkAddress = async () => {
    let type: string = '';
    if (locationHook.pathname === '/price/map') {
      if (state2.priceType === 'land') {
        type = 'AF';
      } else if (state.priceType === 'house') {
        type = 'AH';
      }
    } else if (locationHook.pathname === '/annuity/map') {
      if (state.priceType === 'land') {
        type = 'PF';
      } else if (state.priceType === 'house') {
        type = 'PA';
      }
    } else if (locationHook.pathname === '/findland/map') {
      type = 'FS';
    } else if (locationHook.pathname === '/house/map') {
      type = 'HH';
    } else if (locationHook.pathname)
      try {
        const result = await ReportService.check(
          type,
          decodeURI(locationHook.search.split('=')[1])
        );
      } catch (error) {}
  };

  const [onPopUp, setOnPopUp] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem('tab') === 'price') {
      setOnPopUp(true);
    }
  }, [sessionStorage.getItem('tab')]);

  useEffect(() => {
    if (zoomIn && zoomIn2) {
      zoomIn();
    } else {
      setZoomIn2(true);
    }
    let q = query;
    if (query.slice(0, 4) !== 'list') {
      LandController.coordinate(panToAndMarker, drawCircle, query, isSearch);
    } else {
      q = query.split(']')[1];
    }

    LandController.coordinate(panToAndMarker, drawCircle, query, true);
    const info = async (query: string) => {
      let type: string = '';
      if (locationHook.pathname.split('/')[1] === 'price') {
        if (state2.priceType === 'land') {
          type = '가격분석 - 농지';
        } else if (state2.priceType === 'house') {
          type = '가격분석 - 전원주택';
        }
      } else if (locationHook.pathname.split('/')[1] === 'annuity') {
        if (state2.priceType === 'land') {
          type = '농지연금 분석 - 내 농지';
        } else if (state2.priceType === 'house') {
          type = '농지연금 분석 - 경공매 농지';
        }
      } else if (locationHook.pathname.split('/')[1] === 'findland') {
        type = '농지 찾기';
      } else if (locationHook.pathname.split('/')[1] === 'aiFarming') {
        type = '내 농장 진단';
      } else if (locationHook.pathname.split('/')[1] === 'house') {
        type = '내 집 짓기';
      }

      if (locationHook.pathname === '/auction/map') {
        return;
      } else {
        dispatch(LoadingDot('Y'));
        setNoLand(false);
      }
      try {
        if (query) {
          if (
            isOnFindLandPage ||
            isOnAiFarmingPage ||
            (isOnPricePage && state2.priceType === 'land') ||
            isOnAnnuityPage
          ) {
            // const res =
            //   (window.location.pathname.slice(-1) === '/'
            //     ? window.location.pathname.slice(0, -1)
            //     : window.location.pathname) !== '/annuity/map'
            //     ? await LandService.info(query, type)
            //     : await LandService.infoPension(query);
            // setCurrentInfo(res.result);
            const res = await LandService.info(query, type);

            setCurrentInfo(res.result);
          } else {
            const res = await HouseService.info(query, type);
            setCurrentInfo((prev) => {
              return { ...res.result };
            });
          }
          try {
            stickPolygon(query);
          } catch (err) {
            console.log(err);
          }
        }

        if (locationHook.pathname === '/house/map') {
          setLoading(false);
        }
        {
          dispatch(LoadingDot('N'));
        }
        sessionStorage.removeItem('errMsg');
      } catch (err: any) {
        if (locationHook.pathname === '/house/map') {
          setLoading(false);
        }
        {
          dispatch(LoadingDot('N'));
        }
        console.log(err);
        setNoLand(true);
        setCurrentInfo(protoCurrentInfo);
        console.log('err', err.response.data.message);
        if (err.response.data.message !== undefined) {
          sessionStorage.setItem('errMsg', err.response.data.message);
        }
      }
    };
    info(q);
  }, [query, stickPolygon]);
  useEffect(() => {
    LandController.coordinate(panToAndMarker, drawCircle, query, true);
  }, [panToAndMarker]);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setOnMyLandList(false);
  }, [currentMarker]);

  useEffect(() => {
    if (typeof sessionStorage.areaList === 'string') {
      const parseArea = JSON.parse(sessionStorage.areaList);

      const strigifyArea =
        areaList.length > 0
          ? JSON.stringify([areaList[areaList.length - 1]])
          : JSON.stringify([]);

      if (areaList[areaList.length - 1] !== areaList[0]) {
        setAreaList([areaList[areaList.length - 1]]);
      }
      if (load) {
        if (locationHook.hash === '#7') {
          setAreaList(parseArea);
        }
        setLoad(false);
        // if (parseArea.length > 0) {
        //   setOnMyLandList(true);
        // }
      } else {
        sessionStorage.setItem('areaList', strigifyArea);
      }
    } else {
      sessionStorage.setItem('areaList', JSON.stringify(areaList));
    }
  }, [areaList]);
  // Toast
  useEffect(() => {
    if (state.toggle) {
      setTimeout(() => {
        dispatch(OnToggle(false));
      }, 3000);
    }
  }, [state.toggle]);

  const isCategoryValid = () => {
    const category = currentInfo?.category; // 현재 정보에서 카테고리를 소문자로 가져옵니다.

    if (
      (isOnPricePage || isOnMyHousePage) &&
      ['전', '답', '과수원', '임야', '대'].includes(category)
    ) {
      return true; // 가격페이지 또는 내집맵 페이지이고 카테고리가 전, 답, 과수원, 임야, 대인 경우 true를 반환합니다.
    } else if (
      (isOnAnnuityPage || isOnFindLandPage) &&
      ['전', '답', '과수원'].includes(category)
    ) {
      return true; // 농지연금페이지 또는 농지찾기맵 페이지이고 카테고리가 전, 답, 과수원인 경우 true를 반환합니다.
    }

    return false; // 위의 조건들에 해당하지 않으면 false를 반환합니다.
  };
  useEffect(() => {
    console.log(onSearchList, searchList, 'searchList');
  }, [searchList]);
  return (
    <div
      className='flex-row center-y center-x w-100per h-100per'
      style={{ overflowY: 'hidden' }}
    >
      <Helmet>
        <title>
          {sessionStorage.getItem('tab') === 'price'
            ? '트랜스파머 - 가격 분석'
            : sessionStorage.getItem('tab') === 'house'
            ? '트랜스파머 - 내 집 짓기'
            : sessionStorage.getItem('tab') === 'aifarm'
            ? '트랜스파머 - 내 농장 진단'
            : sessionStorage.getItem('tab') === 'findland'
            ? '트랜스파머 - 농지 찾기'
            : sessionStorage.getItem('tab') === 'annuity'
            ? '트랜스파머 - 농지연금 분석'
            : sessionStorage.getItem('tab') === 'auction'
            ? '트랜스파머 - 매물 분석'
            : sessionStorage.getItem('tab') === 'policy'
            ? '트랜스파머 - 숨은 정책 지원금'
            : ''}
        </title>
      </Helmet>
      {state3.PopUp && sessionStorage.getItem('tab') === 'price' ? (
        <PopUp type='price' />
      ) : state3.PopUp && sessionStorage.getItem('tab') === 'annuity' ? (
        <PopUp type='annuity' />
      ) : (
        ''
      )}
      {/* {(window.location.pathname.slice(-1) === "/"? window.location.pathname.slice(0,-1) : window.location.pathname) === "/price/map" &&
      !sessionStorage.getItem("temp") ? (
        <PopUp type="price" />
      ) : (window.location.pathname.slice(-1) === "/"? window.location.pathname.slice(0,-1) : window.location.pathname) === "/annuity/map" &&
        !sessionStorage.getItem("temp") ? (
        <PopUp type="annuity" />
      ) : (
        ""
      )} */}
      {/* {(window.location.pathname.slice(-1) === "/"? window.location.pathname.slice(0,-1) : window.location.pathname) === "/aiFarming/map" && (
        <div
          className="hover absolute z-10 bg-primary-500 color-FFFFFF font-bold f-size-14 text-center cetner-x center-y padding-12-16 border-radius-100 line-h-20 top-69 tablet"
          style={{ left: "16px" }}
          onClick={() => {
            if (locationHook.hash === "#4") {
              navigate(locationHook.search);
            }
            setOnMyLandList(!onMyLandList);
          }}
        >
          내 농지 목록 ∨
        </div>
      )} */}
      <div className='flex-column w-100per center-x h-100per'>
        <div className='flex-column center-y' ref={topref2}>
          {/* <Topnav /> */}
        </div>
        <div className='flex-column center-y ' ref={topref1}>
          <Search
            noLand={noLand}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            isFarm={isFarm}
            query={query}
            setMapClick={setMapClick}
            mapClick={mapClick}
            setOnInputFocus={setOnInputFocus}
            onInputFocus={onInputFocus}
            searchState={{
              onSearchList: onSearchList,
              setOnSearchList: setOnSearchList,
            }}
            // logo={query === ""}
            target={
              anchor !== '#5' &&
              query &&
              (decodeURI(query).slice(0, 4) !== 'list'
                ? decodeURI(query)
                : query.split(']')[1])
            }
            setSearchList={setSearchList}
          />
          {isMobile(window.innerWidth) &&
            (noLand || !isCategoryValid()) &&
            query !== '' &&
            (sessionStorage.getItem('errMsg') ||
              sessionStorage.getItem('errMsg2')) && (
              <div
                className='w-100per flex-row gap-8 h-100per center-y bg-light-gray '
                style={{
                  zIndex: 100,
                  padding: '30px 20px',
                  justifyContent: 'center',
                }}
              >
                <img src={locationNoImg} className='w-20 h-20' />

                {sessionStorage.getItem('errMsg2') ? (
                  <div className='flex-column'>
                    <div className='center-x  font-medium f-size-14 color-text-tertiary text-center line-h-24'>
                      {sessionStorage.getItem('errMsg2')}
                    </div>
                    <div className='center-x  font-medium f-size-14 color-text-tertiary text-center line-h-24'>
                      또는 지도를 움직여 위치를 찾아보세요{' '}
                    </div>
                  </div>
                ) : (
                  <div>{sessionStorage.getItem('errMsg')}</div>
                )}
              </div>
            )}
          {!(
            (locationHook.pathname.slice(-1) === '/'
              ? locationHook.pathname.slice(0, -1)
              : locationHook.pathname) === '/auction/map'
          ) &&
            isMobile(window.innerWidth) && <TopBanner search />}
        </div>

        {onSearchList && (
          <AreaSearch
            onInputFocus={onInputFocus}
            setSearchList={setSearchList}
            searchValue={searchValue}
            searchList={searchList}
            searchState={{
              onSearchList: onSearchList,
              setOnSearchList: setOnSearchList,
            }}
          />
        )}

        <div
          className='flex-row w-100per '
          // style={
          //   isMobile()
          //     ? { height: `${window.innerHeight - 53}px` }
          //     : { height: `${window.innerHeight}px` }
          // }
        >
          {/* <SideBar /> */}
          {/* <SideBar2 location={query} /> */}
          <SideSearch location={query} on={true} banner recycle={false} />
          {(window.location.pathname.slice(-1) === '/'
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname) === '/aiFarming/map' ? (
            <MapContainer
              setDrawCircle={setDrawCircle}
              temp={temp}
              setMapClick={setMapClick}
              setStickPolygon={setStickPolygon}
              location={
                window.location.pathname.slice(-1) === '/'
                  ? window.location.pathname.slice(0, -1)
                  : window.location.pathname
              }
              setCurrentMarker={setCurrentMarker}
              setFixMarker={setFixMarker}
              setZoomIn={setZoomIn}
              setZoomIn2={setZoomIn2}
              setMarkerArray={setMarkerArray}
              setAreaList={setAreaList}
              setPanTo={setPanTo}
              setPanToAndMarker={setPanToAndMarker}
              setIsSearch={setIsSearch}
              hash={locationHook.hash}
              setOnInputFocus={setOnInputFocus}
            />
          ) : (
            ''
          )}
          {(window.location.pathname.slice(-1) === '/'
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname) === '/house/map' ? (
            <MapContainer
              setDrawCircle={setDrawCircle}
              setMapClick={setMapClick}
              setStickPolygon={setStickPolygon}
              location={
                window.location.pathname.slice(-1) === '/'
                  ? window.location.pathname.slice(0, -1)
                  : window.location.pathname
              }
              setCurrentMarker={setCurrentMarker}
              setFixMarker={setFixMarker}
              setMarkerArray={setMarkerArray}
              setAreaList={setAreaList}
              setPanTo={setPanTo}
              setZoomIn2={setZoomIn2}
              setZoomIn={setZoomIn}
              setPanToAndMarker={setPanToAndMarker}
              setIsSearch={setIsSearch}
              hash={locationHook.hash}
              setOnInputFocus={setOnInputFocus}
            />
          ) : (
            ''
          )}
          {(window.location.pathname.slice(-1) === '/'
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname) === '/findland/map' ? (
            <MapContainer
              setDrawCircle={setDrawCircle}
              address={query}
              mapClick={mapClick}
              setOnInputFocus={setOnInputFocus}
              setStickPolygon={setStickPolygon}
              location={
                window.location.pathname.slice(-1) === '/'
                  ? window.location.pathname.slice(0, -1)
                  : window.location.pathname
              }
              setCurrentMarker={setCurrentMarker}
              setFixMarker={setFixMarker}
              setZoomIn={setZoomIn}
              setMarkerArray={setMarkerArray}
              setAreaList={setAreaList}
              setPanTo={setPanTo}
              setZoomIn2={setZoomIn2}
              setPanToAndMarker={setPanToAndMarker}
              setIsSearch={setIsSearch}
              hash={locationHook.hash}
              setMapClick={setMapClick}
            />
          ) : (
            ''
          )}

          {(window.location.pathname.slice(-1) === '/'
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname) === '/price/map' ? (
            <MapContainer
              kmTab={kmTab}
              setKmTab={setKmTab}
              setDrawCircle={setDrawCircle}
              temp={temp}
              setMapClick={setMapClick}
              setStickPolygon={setStickPolygon}
              location={
                state2.priceType === 'land' ? '/aiFarming/map' : '/house/map'
              }
              setCurrentMarker={setCurrentMarker}
              setFixMarker={setFixMarker}
              setZoomIn={setZoomIn}
              setZoomIn2={setZoomIn2}
              setMarkerArray={setMarkerArray}
              setAreaList={setAreaList}
              setPanTo={setPanTo}
              setPanToAndMarker={setPanToAndMarker}
              setIsSearch={setIsSearch}
              hash={locationHook.hash}
              setOnInputFocus={setOnInputFocus}
            />
          ) : (
            ''
          )}
          {(window.location.pathname.slice(-1) === '/'
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname) === '/annuity/map' ? (
            <MapContainer
              setDrawCircle={setDrawCircle}
              setMapClick={setMapClick}
              setStickPolygon={setStickPolygon}
              location='/aiFarming/map'
              setCurrentMarker={setCurrentMarker}
              setFixMarker={setFixMarker}
              setMarkerArray={setMarkerArray}
              setAreaList={setAreaList}
              setZoomIn={setZoomIn}
              setZoomIn2={setZoomIn2}
              setPanTo={setPanTo}
              setPanToAndMarker={setPanToAndMarker}
              setIsSearch={setIsSearch}
              hash={locationHook.hash}
              setOnInputFocus={setOnInputFocus}
            />
          ) : (
            ''
          )}
          {(window.location.pathname.slice(-1) === '/'
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname) === '/mypage/map' ? (
            <MapContainer
              setDrawCircle={setDrawCircle}
              setMapClick={setMapClick}
              setStickPolygon={setStickPolygon}
              location='/mypage/map'
              setCurrentMarker={setCurrentMarker}
              setFixMarker={setFixMarker}
              setMarkerArray={setMarkerArray}
              setAreaList={setAreaList}
              setZoomIn={setZoomIn}
              setZoomIn2={setZoomIn2}
              setPanTo={setPanTo}
              setPanToAndMarker={setPanToAndMarker}
              setIsSearch={setIsSearch}
              hash={locationHook.hash}
              setOnInputFocus={setOnInputFocus}
            />
          ) : (
            ''
          )}
          {(window.location.pathname.slice(-1) === '/'
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname) === '/auction/map' ? (
            isSales ? (
              <MapContainer
                setDrawCircle={setDrawCircle}
                setMapClick={setMapClick}
                setStickPolygon={setStickPolygon}
                location='/auction/map'
                setCurrentMarker={setCurrentMarker}
                setFixMarker={setFixMarker}
                setMarkerArray={setMarkerArray}
                setAreaList={setAreaList}
                setZoomIn={setZoomIn}
                setZoomIn2={setZoomIn2}
                setPanTo={setPanTo}
                setPanToAndMarker={setPanToAndMarker}
                setIsSearch={setIsSearch}
                hash={locationHook.hash}
                setOnInputFocus={setOnInputFocus}
              />
            ) : (
              <MapContainer
                setDrawCircle={setDrawCircle}
                setMapClick={setMapClick}
                setStickPolygon={setStickPolygon}
                location='/auction/map'
                setCurrentMarker={setCurrentMarker}
                setFixMarker={setFixMarker}
                setMarkerArray={setMarkerArray}
                setAreaList={setAreaList}
                setZoomIn={setZoomIn}
                setZoomIn2={setZoomIn2}
                setPanTo={setPanTo}
                setPanToAndMarker={setPanToAndMarker}
                setIsSearch={setIsSearch}
                hash={locationHook.hash}
                setOnInputFocus={setOnInputFocus}
              />
            )
          ) : (
            ''
          )}
        </div>
      </div>

      {/* {typeof Number(query.slice(query.length - 1)) === "number" &&
        query.slice(query.length - 1) &&
        anchor === "#4" && (
          <SaleRentalDetail2
            fixMarker={fixMarker}
            currentMarker={currentMarker}
            query={query}
            areaList={areaList}
            currentInfo={currentInfo}
            markerArray={markerArray}
            setAreaList={setAreaList}
            setMarkerArray={setMarkerArray}
            setOnMyLandList={setOnMyLandList}
          />
        )} */}

      {typeof Number(query.slice(query.length - 1)) === 'number' &&
        ((window.location.pathname.slice(-1) === '/'
          ? window.location.pathname.slice(0, -1)
          : window.location.pathname) === '/aiFarming/map' ||
          (window.location.pathname.slice(-1) === '/'
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname) === '/findland/map' ||
          ((window.location.pathname.slice(-1) === '/'
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname) === '/price/map' &&
            state2.priceType === 'land') ||
          (window.location.pathname.slice(-1) === '/'
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname) === '/annuity/map') &&
        query.slice(query.length - 1) &&
        !onInputFocus &&
        anchor === '' && (
          <SaleRentalPreview2
            setDrawCircle={setDrawCircle}
            kmTab={kmTab}
            setKmTab={setKmTab}
            address={query}
            fixMarker={fixMarker}
            currentMarker={currentMarker}
            query={query}
            areaList={areaList}
            currentInfo={currentInfo}
            setOnMyLandList={setOnMyLandList}
            markerArray={markerArray}
            setAreaList={setAreaList}
            setMarkerArray={setMarkerArray}
            onMyLandList={onMyLandList}
            drawCircle={drawCircle}
          />
        )}

      {typeof Number(query.slice(query.length - 1)) === 'number' &&
        ((window.location.pathname.slice(-1) === '/'
          ? window.location.pathname.slice(0, -1)
          : window.location.pathname) === '/house/map' ||
          ((window.location.pathname.slice(-1) === '/'
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname) === '/price/map' &&
            state2.priceType === 'house')) &&
        query.slice(query.length - 1) &&
        anchor === '' &&
        !onInputFocus &&
        isMobile(window.innerWidth) && (
          <HousePreview
            address={query}
            fixMarker={fixMarker}
            currentMarker={currentMarker}
            query={query}
            areaList={areaList}
            currentInfo={currentInfo}
            setOnMyLandList={setOnMyLandList}
            markerArray={markerArray}
            setAreaList={setAreaList}
            setMarkerArray={setMarkerArray}
            onMyLandList={onMyLandList}
            setOnToggle={setOnToggle}
          />
        )}

      {typeof Number(query.slice(query.length - 1)) === 'number' &&
        ((window.location.pathname.slice(-1) === '/'
          ? window.location.pathname.slice(0, -1)
          : window.location.pathname) === '/house/map' ||
          ((window.location.pathname.slice(-1) === '/'
            ? window.location.pathname.slice(0, -1)
            : window.location.pathname) === '/price/map' &&
            state2.priceType === 'house')) &&
        query.slice(query.length - 1) &&
        anchor === '' &&
        !isMobile(window.innerWidth) && (
          <HousePreview2
            address={query}
            fixMarker={fixMarker}
            currentMarker={currentMarker}
            query={query}
            areaList={areaList}
            currentInfo={currentInfo}
            setOnMyLandList={setOnMyLandList}
            markerArray={markerArray}
            setAreaList={setAreaList}
            setMarkerArray={setMarkerArray}
            onMyLandList={onMyLandList}
            setOnToggle={setOnToggle}
            setKmTab={setKmTab}
            kmTab={kmTab}
          />
        )}

      {/* <JointDetail /> */}
      {onMyLandList &&
        (window.location.pathname.slice(-1) === '/'
          ? window.location.pathname.slice(0, -1)
          : window.location.pathname) === '/aiFarming/map' && (
          <JointDetail
            areaList={areaList}
            panTo={panTo}
            markerArray={markerArray}
            setMarkerArray={setMarkerArray}
            setAreaList={setAreaList}
            setOnMyLandList={setOnMyLandList}
            setOnToggle={setOnToggle}
          />
        )}
      {isMobile(window.innerWidth) &&
        (window.location.pathname.slice(-1) === '/'
          ? window.location.pathname.slice(0, -1)
          : window.location.pathname) === '/auction/map' && (
          <Auction
            setHouseReportIdx={setHouseReportIdx}
            houseReportIdx={houseReportIdx}
            setClose={setClose}
            type={type}
            setType={setType}
            setIsFarm={setIsFarm}
            isFarm={isFarm}
            setIsSales={setIsSales}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
            panto={(query: string) =>
              LandController.coordinate(panToAndMarker, drawCircle, query, true)
            }
          />
        )}
      {!isMobile(window.innerWidth) &&
        (window.location.pathname.slice(-1) === '/'
          ? window.location.pathname.slice(0, -1)
          : window.location.pathname) === '/auction/map' && (
          <AuctionPreview
            setIsSales={setIsSales}
            panto={(query: string) =>
              LandController.coordinate(panToAndMarker, drawCircle, query, true)
            }
          />
        )}

      {!isMobile(window.innerWidth) &&
        (window.location.pathname.slice(-1) === '/'
          ? window.location.pathname.slice(0, -1)
          : window.location.pathname) === '/mypage/map' && (
          <MypageProfile
            search
            email={email}
            createdDate={createdDate}
            point={point}
          />
        )}
    </div>
  );
};

export default AIFarmingMap;

export async function getStaticProps({ params }: any) {
  const { area } = params;
  return {
    props: {
      area,
    },
  };
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: true,
  };
}

// {/* {anchor === "#1" && <RecentSearch />} */}

//  {/* {query === encodeURI("경기도") && anchor === "#3" && (
//     <SaleRentalDetail />
//    )} */}
//     {/* {query === encodeURI("경기도") && <SaleRentalSelector />} */}
//
//    {/* {typeof Number(query.slice(query.length - 1)) === "number" &&
//       query.slice(query.length - 1) && <SaleRentalSelector2 />} */}

//    {/* {anchor === "#6" && <SaleRentalTag />} */}

//-----------------

//       {/* {query === encodeURI("경기도") && anchor === "" && <SaleRentalPreview />} */}

// {/* {anchor === "#6" && <Analyze />} */}
