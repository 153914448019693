import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "../../../../component/function/function";
import LandService from "../../../../service/land/landApi";
import MapService from "../../../../service/map/Map";
interface IMapContainerData {
  // setCurrentMarker: React.Dispatch<React.SetStateAction<any>>;
  // setFixMarker: React.Dispatch<React.SetStateAction<any>>;
  // setMarkerArray: React.Dispatch<React.SetStateAction<Array<any>>>;
  // setAreaList: React.Dispatch<React.SetStateAction<Array<any>>>;
  // setPanTo: React.Dispatch<React.SetStateAction<any>>;
  // setPanToAndMarker: React.Dispatch<React.SetStateAction<any>>;
  // setIsSearch: React.Dispatch<React.SetStateAction<any>>;
  // hash: string;
  // location: string;
  // setZoomIn: any;
  // setZoomIn2: any;
  spaceUseStatus: any;
  modalAddress: any;
  tab: number;
  spaceInfo: any;
}

const MapContainer = (props: IMapContainerData) => {
  const obj = {
    strokeWeight: 3, // 선의 두께입니다
    strokeColor: "#09ce7d", // 선의 색깔입니다
    strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
    strokeStyle: "solid", // 선의 스타일입니다
    fillColor: "#09ce7d", // 채우기 색깔입니다
    fillOpacity: 0.25, // 채우기 불투명도 입니다
  };
  const [mapTypeChanger, setMapTypeChanger]: any = useState();
  const [overlay, setOverlay] = useState(false);
  const [addOverLay, setAddOverlay]: any = useState();
  const [mapTypeTitle, setMapTypeTitle] = useState("roadmap");
  useEffect(() => {
    window.kakao?.maps.load(() => {
      let locPosition = new window.kakao.maps.LatLng(
        37.50437802028816,
        127.04996591045683
      );

      const container = document.getElementById("map2"); //지도를 담을 영역의 DOM 레퍼런스
      const options = {
        //지도를 생성할 때 필요한 기본 옵션
        center: locPosition, //지도의 중심좌표.
        level: 1, //지도의 레벨(확대, 축소 정도)
      };
      const map = new window.kakao.maps.Map(container, options); //지도 생성 및 객체 리턴
      const addOverlay = (overlay: boolean) => {
        if (overlay) {
          map.removeOverlayMapTypeId(window.kakao.maps.MapTypeId.USE_DISTRICT);
        } else {
          map.addOverlayMapTypeId(window.kakao.maps.MapTypeId.USE_DISTRICT);
        }
        setOverlay(!overlay);
      };
      //   function zoomIn() {
      //     map.setLevel(1);
      //   }
      //   props.setZoomIn(() => () => zoomIn());
      function setMapType(maptype: any) {
        if (maptype === "roadmap") {
          map.setMapTypeId(window.kakao.maps.MapTypeId.ROADMAP);
        } else {
          map.setMapTypeId(window.kakao.maps.MapTypeId.HYBRID);
        }
        setMapTypeTitle(maptype);
      }
      if (true) {
        addOverlay(true);
        setMapType("skyview");
      } else {
        addOverlay(false);
        setMapType("roadmap");
      }
      setAddOverlay(() => (overlay: any) => addOverlay(overlay));
      setMapTypeChanger(() => (maptype: any) => setMapType(maptype));

      async function panTo() {
        try {
          const res = await LandService.coordinate(props.modalAddress);
          var moveLatLon = new window.kakao.maps.LatLng(
            Number(res.coordinate.ypos),
            Number(res.coordinate.xpos)
          );
          await map.panTo(moveLatLon);

          markerFunc();
        } catch (err) {
          console.log(err);
        }
      }
      panTo();

      const markerFunc = async function () {
        if (props.tab === 1) {
          const res = await LandService.coordinate(props.modalAddress);
          var moveLatLon = new window.kakao.maps.LatLng(
            Number(res.coordinate.ypos),
            Number(res.coordinate.xpos)
          );

          var content = document.createElement("div");
          var child = document.createElement("img");
          child.src = "/asset/images/marker/marker.png";
          child.className = "pointer green-marker";
          var child1 = document.createElement("div");
          var child12 = document.createElement("div");
          var child2 = document.createElement("div");
          var child22 = document.createElement("div");
          var childX = document.createElement("div");
          childX.className =
            "flex-row border-1-09CE7D border-radius-27 bg-white pointer absolute z-10 bottom-m-16 mt-92";
          child1.className =
            "padding-6 bg-primary-400 border-radius-100 border-1-09CE7D min-w-35 mb-20";
          child1.append(child12);
          child2.className = "padding-6-10 ";
          child22.className =
            "font-bold f-size-12 line-h-16 color-text-primary bg-FFFFFF";
          child22.innerText = props.modalAddress;
          child2.append(child22);
          childX.append(child2);
          content.append(child);
          content.append(childX);
          content.className =
            "green-marker relative center-x center-y flex-column padding-b-32";

          var marker = new window.kakao.maps.CustomOverlay({
            map: map, // 마커를 표시할 지도
            position: moveLatLon, // 마커의 위치
            content: content,
          });
        }

        if (props.tab === 2) {
          let polygon: any;
          const res2 = await MapService.getPolygon2(props.modalAddress);
          let x = JSON.parse(res2.data)
            .rectangle.split("MULTIPOLYGON")[1]
            .split("(")
            .map((el: any) => {
              if (el.length > 0) {
                return el
                  .split(")")
                  .map((el: any) => {
                    if (el.length > 0 && el !== ",") {
                      return JSON.stringify(
                        el.split(",").map((el: any) => {
                          return el.split(" ").map((el: any) => Number(el));
                        })
                      ).slice(1, -1);
                    } else {
                      return "";
                    }
                  })
                  .join("]");
              } else {
                return el;
              }
            })
            .join("[")
            .split("][")
            .join("],[");
          let y = JSON.parse(x);
          var polygonPath: any;
          polygonPath = y;
          const one = polygonPath.map((el: any) => {
            if (Array.isArray(el[0])) {
              return el.map((el: any) => {
                if (Array.isArray(el[0])) {
                  return el.map((el: any) => {
                    return new window.kakao.maps.LatLng(el[1], el[0]);
                  });
                }
                return new window.kakao.maps.LatLng(el[1], el[0]);
              });
            }
            return new window.kakao.maps.LatLng(el[1], el[0]);
          });
          if (marker) {
            marker.poly = one;
          }
          one.map((el: any) => {
            polygon = new window.kakao.maps.Polygon({
              path: el, // 그려질 다각형의 좌표 배열입니다
              ...obj,
            });
            polygon.setMap(map);
            // 다각형에 마우스오버 이벤트가 발생했을 때 변경할 채우기 옵션입니다
            var mouseoverOption = {
              fillColor: "#1ca56c", // 채우기 색깔입니다
            };

            // 다각형에 마우스아웃 이벤트가 발생했을 때 변경할 채우기 옵션입니다
            var mouseoutOption = {
              fillColor: "#09ce7d", // 채우기 색깔입니다
            };

            // 다각형에 마우스오버 이벤트를 등록합니다
            window.kakao.maps.event.addListener(
              polygon,
              "mouseover",
              function () {
                // 다각형의 채우기 옵션을 변경합니다
                polygon.setOptions(mouseoverOption);
              }
            );

            window.kakao.maps.event.addListener(
              polygon,
              "mouseout",
              function () {
                // 다각형의 채우기 옵션을 변경합니다
                polygon.setOptions(mouseoutOption);
              }
            );
          });
        }
      };

      window.kakao.maps.event.addListener(map, "click", function () {
        setOnModal(true);
      });
    });
  }, [props.modalAddress, props.tab]);

  const [tab, setTab] = useState(1);

  const [onModal, setOnModal] = useState(true);

  useEffect(() => {
    setOnModal(true);
    setTab(1);
  }, [props.tab]);

  return (
    <div className='w-100per z-100 h-100per' id='map2'>
      <div className='flex-row'>
        <div className='custom_typecontrol radius_border flex-row'>
          <span
            id='btnRoadmap'
            className={`${mapTypeTitle === "roadmap" ? "selected_btn" : "btn"}`}
            onClick={() => mapTypeChanger("roadmap")}
          >
            지도
          </span>
          <span
            id='btnSkyview'
            className={`${mapTypeTitle === "skyview" ? "selected_btn" : "btn"}`}
            onClick={() => mapTypeChanger("skyview")}
          >
            스카이뷰
          </span>
        </div>
        {/* <div className="custom_typecontrol2 radius_border flex-row">
          <span
            id="btnRoadmap"
            className={`${overlay ? "selected_btn" : ""}`}
            onClick={() => addOverLay(overlay)}
          >
            지적편집도
          </span>
        </div> */}
      </div>
      <div className='absolute z-10' style={{ bottom: 16, right: 16 }}>
        <div className='bg-blue-gray-800 border-radius-999 padding-8-16 pointer'>
          <div
            className='mobile-body-2-medium color-FFFFFF'
            onClick={() => addOverLay(overlay)}
          >
            지적도 {!overlay ? "ON" : "OFF"}
          </div>
        </div>
      </div>
      {onModal && props.tab === 2 ? (
        <div
          style={{
            zIndex: 10,
            position: "absolute",
            top: "10%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            className='flex-column overflow-hidden'
            style={{
              width: "312px",
              background: "#FFFFFF",
              borderRadius: "16px",
            }}
          >
            <div className='end-x padding-16-16-0'>
              <img
                className='pointer'
                src='/asset/images/close/Close24.svg'
                alt=''
                onClick={() => {
                  setOnModal(false);
                }}
              />
            </div>
            <div className='padding-x-16'>
              <div className='mobile-title-2-bold'>
                {props.spaceInfo.address}
              </div>
            </div>
            <div className='padding-16-16-0'>
              <div className='border-radius-8 border-1-f0f0f0 flex-row bg-F0F0F0'>
                {[
                  { title: "토지대장", tab: 1 },
                  { title: "공시지가", tab: 2 },
                  { title: "토지이용현황", tab: 3 },
                ].map((el) => (
                  <div
                    className={
                      tab === el.tab
                        ? "flex-1 border-radius-8 border-1-171e2610 bg-FFFFFF padding-y-6 center-x flex-row pointer  no-wrap"
                        : "flex-1 padding-y-6 center-x flex-row pointer no-wrap"
                    }
                    onClick={() => setTab(el.tab)}
                  >
                    <div
                      className={
                        tab === el.tab
                          ? "mobile-body-1-medium"
                          : "mobile-body-1-medium color-disabled"
                      }
                    >
                      {el.title}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='mobile-body-2-medium padding-16'>
              {tab === 1 ? (
                <div className='flex-row '>
                  <div className='flex-column bg-light-gray-100 '>
                    <div
                      className='color-text-tertiary border-b-1-171E2610'
                      style={{
                        padding: "11px 16px",
                        borderTop: "1px solid #171e2610",
                      }}
                    >
                      지목
                    </div>
                    <div
                      className='color-text-tertiary border-b-1-171E2610'
                      style={{
                        padding: "11px 16px",
                      }}
                    >
                      면적(m²)
                    </div>
                  </div>
                  <div className='flex-column'>
                    <div
                      className='color-text-primary border-b-1-171E2610'
                      style={{
                        padding: "11px 16px",
                        borderTop: "1px solid #171e2610",
                      }}
                    >
                      {props.spaceInfo.category}
                    </div>
                    <div
                      className='color-text-primary border-b-1-171E2610'
                      style={{ padding: "11px 16px" }}
                    >
                      {props.spaceInfo.area &&
                        Number(props.spaceInfo.area).toLocaleString()}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {tab === 2 ? (
                <div className='flex-row'>
                  <div className='flex-column bg-light-gray-100'>
                    <div
                      className='color-text-tertiary border-b-1-171E2610'
                      style={{
                        padding: "11px 16px",
                        borderTop: "1px solid #171e2610",
                      }}
                    >
                      기준년도(년)
                    </div>
                    <div
                      className='color-text-tertiary border-b-1-171E2610'
                      style={{
                        padding: "11px 16px",
                      }}
                    >
                      개별공시지가(원/m²)
                    </div>
                  </div>
                  <div className='flex-column'>
                    <div
                      className='color-text-primary border-b-1-171E2610'
                      style={{
                        padding: "11px 16px",
                        borderTop: "1px solid #171e2610",
                      }}
                    >
                      {
                        Object.keys(props.spaceInfo.officialPriceList).sort(
                          (a, b) => Number(b) - Number(a)
                        )[0]
                      }
                    </div>
                    <div
                      className='color-text-primary border-b-1-171E2610'
                      style={{ padding: "11px 16px" }}
                    >
                      {Number(
                        props.spaceInfo.officialPriceList[
                          Object.keys(props.spaceInfo.officialPriceList).sort(
                            (a, b) => Number(b) - Number(a)
                          )[0]
                        ] || 0
                      ).toLocaleString()}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {tab === 3 ? (
                <div
                  className='flex-row'
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  <div className='flex-column'>
                    {!props.spaceUseStatus.length ? (
                      <div className='flex-row  border-b-1-171E2610 center-y'>
                        <div
                          className='color-text-tertiary bg-light-gray-100 flex-1'
                          style={{
                            padding: "11px 16px",
                            borderTop: "1px solid #171e2610",
                          }}
                        >
                          {props.spaceInfo.zoning}
                        </div>
                        <div
                          className='color-text-primary flex-half'
                          style={{ padding: "11px 16px" }}
                        >
                          포함
                        </div>
                      </div>
                    ) : (
                      props.spaceUseStatus.map((el: any) => (
                        <div className='flex-row  border-b-1-171E2610  center-y'>
                          <div
                            className='color-text-tertiary bg-light-gray-100 flex-1'
                            style={{
                              padding: "11px 16px",
                              borderTop: "1px solid #171e2610",
                            }}
                          >
                            {el.useStatus} <br />
                            {el.info ? `(${el.info})` : ""}
                          </div>
                          <div
                            className='color-text-primary flex-half'
                            style={{
                              padding: "11px 16px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {el.includeInfo}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                  {/* <div className='flex-column'>
                    {!props.spaceUseStatus.length ? (
                      <div
                        className='color-text-primary border-b-1-171E2610 flex-1'
                        style={{ padding: '11px 16px' }}
                      >
                        포함
                      </div>
                    ) : (
                      props.spaceUseStatus.map((el: any) => (
                        <div
                          className='color-text-primary border-b-1-171E2610 flex-1'
                          style={{ padding: '11px 16px', whiteSpace: 'nowrap' }}
                        >
                          {el.includeInfo}
                        </div>
                      ))
                    )}
                  </div> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MapContainer;
