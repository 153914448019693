import { useEffect } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../../component/function/function";
export const PointPopUp = ({ open, setOpen, type }: any) => {
  const navigate = useNavigate();
  const BtnHandlerLeft = () => {
    setOpen(false);
    if (type === "recharge") {
      navigate("/");
    }
  };
  const BtnHandlerRight = () => {
    setOpen(false);
    if (type === "payment") {
      navigate("/", { replace: true });
    } else if (type === "recharge") {
      navigate("/");
    }
  };
  useEffect(() => {
    // 모달이 열릴 때 스크롤 막기
    if (open) {
      document.body.style.overflow = "hidden";
    }

    // 모달이 닫힐 때 스크롤 해제
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);
  return (
    <Modal
      isOpen={open}
      shouldCloseOnOverlayClick={true}
      // onAfterOpen={afterOpenModal}
      // onRequestClose={setOpen(false)}
      ariaHideApp={false}
      style={{
        overlay: {
          // position: "fixed",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 9999,
          //   outline: "none",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          // color: "black",
          backgroundColor: "white",
          whiteSpace: "normal",
          width: `${isMobile(window.innerWidth) ? "85%" : "360px"}`,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          // border: "none",
          flexDirection: "column",
          gap: "12px",
          borderRadius: "16px",
          padding: "24px 16px",
        },
      }}
    >
      <img src='/asset/images/warn/exclaim.svg' />
      <div className='flex-column gap-24 w-100per'>
        <div className='flex-column gap-8 center-y'>
          <div className='font-bold f-size-16 line-h-24 color-000000'>
            충전을 취소하고 나가시겠습니까?
          </div>
          <div className='font-medium f-size-14 line-h-20 color-disabled'>
            선택한 내용들은 모두 초기화됩니다.
          </div>
        </div>
        <div className='flex-row gap-8'>
          <div
            className='w-50per bg-blue-gray-100 padding-y-12 border-radius-8 flex-center pointer'
            onClick={() => {
              BtnHandlerLeft();
            }}
          >
            <div className='font-medium f-size-14 line-h-20 color-disabled'>
              {type === "payment" ? "아니요" : "충전소 나가기"}
            </div>
          </div>
          <div
            className='w-50per bg-primary-500 padding-y-12 border-radius-8 flex-center pointer'
            onClick={() => {
              BtnHandlerRight();
            }}
          >
            <div className='font-medium f-size-14 line-h-20 color-FFFFFF'>
              {type === "payment" ? "네, 취소할게요" : "확인"}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
